import { configureStore } from '@reduxjs/toolkit'
import authSlice from '../features/authSlice'
import userReducer from '../features/userSlice'
import profileReducer from '../features/profileSlice'
import passwordReducer from '../features/passwordSlice';
import jobReducer from '../features/jobcardSlice';
import cardReducer from '../features/cardSlice'
import recruiterReducer from '../features/recruiterSlice';
import fileUploadReducer from '../features/resumeUpload';
import allUserSlice from '../features/allUserSlice';
import resumeReducer from '../features/resumeSlice';
import teamHeadFilterReducer from '../features/teamHeadFilterSlice';
import overallRthSlice from '../features/overallRthSlice';
import roleSlice from '../features/roleSlice';
import businessSlice from '../features/businessSlice';
import wtMJobSlice from '../features/wTMJob';
import interviewSlice from '../features/interviewScheduleSlice';
import departmentSlice from '../features/departmentSlice';

export const store = configureStore({
  reducer: {
   authLogin : authSlice,
   user: userReducer,
   profile: profileReducer,
   password: passwordReducer,
   teamHeadFilter: teamHeadFilterReducer,
   jobs: jobReducer,
   cards: cardReducer,
   recruiter: recruiterReducer,
   fileUpload:fileUploadReducer,
   allUserLogin:allUserSlice,
   resume:resumeReducer,
   interviewSchedule:interviewSlice,   
   overallRth:overallRthSlice,  
   role: roleSlice,
   wtMJob:wtMJobSlice,
   business: businessSlice,
   interviewDetailSchedule:interviewSlice,
   department: departmentSlice
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch