import {
  Avatar,
  Box,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import feedbackIcon from "../../assets/images/feedbackIcon.svg";
import searchIcon from "../../assets/images/searchIcon.svg";
// import locationIcon from "../../assets/images/locationIcon.svg";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { getSearchSuggestions } from "../../redux/features/allUserSlice";
import { useSelector } from "react-redux";
import citplLogo from "../../assets/images/citplLogo.svg";
import { Job } from "../../redux/features/jobcardSlice";
import { ProfileMenu } from "../dashboard/commoncomponent/HeaderBar";

interface prop{
  setClickedIndex:Function;
  clickedIndex:number;
  allPostedJobs:Job[];
}



const AjHeader: React.FC<prop> = ({setClickedIndex,clickedIndex,allPostedJobs}) => {

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);


  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [searchQuery, setSearchQuery] = useState<string>("");

  // const [suggestions, setSuggestions] = useState<string[]>([]);

  // const [showSuggestions, setShowSuggestions] = useState(false);

  const [lenOfSearchResults,setLenOfSearchResults]=useState(0);

  const searchInputRef=useRef<HTMLInputElement | null>(null);

  const dispatch = useDispatch<AppDispatch>();

  const searchSuggestions = useSelector(
    (state: RootState) => state.allUserLogin.searchSuggestionsData
  );

  console.log("len -> ",lenOfSearchResults)

  useEffect(()=>{
    if(searchSuggestions){
      setLenOfSearchResults(searchSuggestions.length);
    }
  },[searchSuggestions])


  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchQuery(value);
  };

  console.log(searchSuggestions.length)

  const handleSearchIconButtonClick=()=>{
    searchInputRef.current?.focus();
  }

  useEffect(() => {
    if (searchQuery !== "") {
      dispatch(getSearchSuggestions({ searchString: searchQuery }));
    }
  }, [searchQuery,dispatch]);

  const handleSuggestionClick=(rthId:string | undefined)=>{
    // console.log("selected Rth Id is "+rthId);
    setSearchQuery("");

    const index=allPostedJobs.findIndex((job)=>job.rthId === rthId);
    setClickedIndex(index);

  }

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  // const handleChange = (event: SelectChangeEvent,key:string) => {
  //   console.log(event.target.value,key);
  //   setSelectedValues((prev)=>({
  //     ...prev,
  //     [key]:event.target.value === "None" ? "":event.target.value,
  //   }))
  // };

  // const [selectedValues,setSelectedValues]=useState<{[key:string]:string}>({
  //   "Date Posted": "",
  //   "Job Type": "",
  //   "Education Level": "",
  //   "Industry": "",
  //   "Location": "",
  // })

  // const dropDownData=["Date Posted","Job Type","Education Level","Industry","Location"]

  // const dropDownOptions:{[key:string]:string[]}={
  //   "Date Posted":["Last 24 hours", "Last 7 days", "Last 30 days"],
  //   "Job Type":["Internal onRole", "Internal contract", "External contract", "External oneTime"],
  //   "Education Level":["High School", "Bachelor's", "Master's", "PhD"],
  //   "Industry":["HEPL","CITPL"],
  //   "Location":["Chennai", "Cuddalore", "Ponddycherry"]
  // }

  return (
    <Box sx={{ backgroundColor: "white" }} p={1}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginBottom={"5px"}
      >
        <Box sx={{ display: "contents" }}>
          <img src={feedbackIcon} alt="" width="40px" height="40px" />
        </Box>

        <Box
          sx={{
            p: "2px 4px",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            alignContent:"center",
            width: 350,
            height: 30,
            borderRadius: "2px",
            border: "1px solid #00000008",
            backgroundColor: "#F8F8F8",
            position: "relative",
          }}
        >
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={handleSearchIconButtonClick}>
            <img src={searchIcon} alt="" />
          </IconButton>
          <InputBase
            inputRef={searchInputRef}
            sx={{ ml: 1, flex: 1, color: "grey" }}
            placeholder="Search Jobs, Location..."
            onChange={handleSearchChange}
          />
          {searchQuery.length > 0 && (
            <Paper
              sx={{
                position: "absolute",
                top: 40,
                left: 0,
                right: 0,
                zIndex: 10,
                maxHeight: 200,
                overflowY: "auto",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",             
              }}
            >
              <List>                
                {searchSuggestions.map((suggestion, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                    onClick={() => handleSuggestionClick(suggestion.rthId)}
                    >
                      {/* <Typography variant="body2">{suggestion.positionTitle}</Typography> */}
                      <Box width={"100%"} display={"flex"} flexDirection={"row"} alignItems={"center"}>
                        <Box flexBasis={"10%"}>
                          <img
                            src={citplLogo}
                            alt="B Icon"
                            width={"60px"}
                            height={"60px"}
                          />
                        </Box>

                        <Box flexBasis={"90%"} color={"#384250"}>
                          <Typography fontSize={13}>
                            {suggestion.positionTitle}
                          </Typography>

                          <Typography fontSize={11} color={"#0000004D"}>
                            {suggestion.business.businessName} | {suggestion.createdAt}
                          </Typography>
                        </Box>

                      </Box>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}
        </Box>
        {/* <Box
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: {xs:200,md:350} ,
            height: 30,
            borderRadius: "2px",
            border: "1px solid #00000008",
            backgroundColor: "#F8F8F8",
          }}
        >
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <img src={locationIcon} alt="" />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1, color: "black" }}
            placeholder="City, Region"
          />
        </Box> */}

        <Box sx={{ display: "contents" }}>
        <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            color="inherit"
            onClick={handleProfileMenuOpen}
          >
            <Avatar
              alt="Profile"
              src={"https://mui.com/static/images/avatar/1.jpg"}
            />
          </IconButton>
          <ProfileMenu anchorEl={anchorEl} onClose={handleProfileMenuClose} />
          
        </Box>
      </Box>

      {/* <Box justifyContent={"center"} display={"flex"} flexWrap={"wrap"}>
        {
            dropDownData.map((data,index)=>(
                <FormControl sx={{ m: 1, minWidth: 150,}} size="small">
                <InputLabel id="demo-select-small-label" sx={{fontSize:"14px",}}>{data}</InputLabel>
                <Select
                  sx={{borderRadius:"8px" }}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={selectedValues[data]  || ""}
                  label={data}
                  onChange={(event)=>handleChange(event,data)}
                >
                  <MenuItem value="None">None</MenuItem>
                  {
                    dropDownOptions[data].map((data2)=>(
                      <MenuItem value={data2} >{data2}</MenuItem>
                    ))
                    
                  }
                  
                </Select>
              </FormControl>
            ))
        }

      </Box> */}
    </Box>
  );
};

export default AjHeader;
