import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { getOverAllDeclinedAPIData } from "../../../Crud/card";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { overallRthUpdate } from "../../../redux/features/overallRthSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface ApprovalActionsProps {
  onApprove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDecline: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  data: any;
}

const ApprovalActions: React.FC<ApprovalActionsProps> = ({
  onApprove,
  onDecline,
  data,
}) => {
  const [openDeclinedPopup, setOpenDeclinedPopup] = useState(false);
  const [comments, setComments] = useState("");
  const [error, setError] = useState("");
  const approvedStatus = useSelector(
    (state: RootState) => state.overallRth.approved
  );
  const dispatch = useDispatch();
  // console.log("data====>",data);
  const handleDeclined = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setOpenDeclinedPopup(true);
  };
  const handleCloseDialog = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setOpenDeclinedPopup(false);
  };
  const handleDeclinedSucess = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (comments.length >= 1) {
      setOpenDeclinedPopup(false);
      await getOverAllDeclinedAPIData(data?.rthId, comments)
        .then(() => toast.success("Successfully Declined"))
        .catch(() => toast.error("Failed Declined"));
      await dispatch(overallRthUpdate(true));
      await dispatch(overallRthUpdate(!approvedStatus));
      setError("")
    }else{
      setError("Enter your reason")
    }
  };
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 1 }}>
        <Button
          // variant="contained"
          onClick={handleDeclined}
          sx={{
            textTransform: "none",
            minWidth: "80px",
            marginRight: 1,
            padding: "4px 8px",
            fontSize: "12px",
            background: "#FEF2F3",
            color: "#E8505B",
          }}
        >
          Decline
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={onApprove}
          sx={{
            textTransform: "none",
            minWidth: "80px",
            padding: "4px 8px",
            fontSize: "12px",
          }}
        >
          Approve
        </Button>
      </Box>
      {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography
          color="textSecondary"
          sx={{ fontSize: "10px", color: "#8C8C8C", mt: "5px" }}
        >
          {" "}
          one new Request for your Approvel
        </Typography>
      </Box> */}
      <Dialog
        open={openDeclinedPopup}
        onClose={handleCloseDialog}
        onClick={(e) => e.stopPropagation()}
        maxWidth="xs"
        fullWidth
        sx={{ maxWidth: "500px", margin: "auto" }}
      >
        <DialogTitle sx={{ p: 2, borderBottom: "1px solid #f0f0f0" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: 500 }}>
              Decline
            </Typography>
            <IconButton
              aria-label="close"
              onClick={(event) => {
                event.stopPropagation();
                handleCloseDialog(event);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                backgroundColor: "#ECECEC",
              }}
            >
              <GridCloseIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography sx={{ mb: 2, fontSize: "16px", fontWeight: 700 }}>
            Are you sure you want to decline?
          </Typography>
          <Typography
            variant="body2"
            sx={{ mb: 1, fontSize: "12px", color: "#757575" }}
          >
            Please provide a reason for declining
          </Typography>
          <TextField
            placeholder="Enter your reason here..."
            fullWidth
            multiline
            rows={3}
            inputProps={{ maxLength: 200 }}
            value={comments}
            onChange={(e) => {
              e.stopPropagation();
              setComments(e.target.value);
              if(e.target.value.length===0){
                setError("Enter your reason")
              }else{
                setError("")
              }
            }}
            sx={{
              "& .MuiInputBase-input": {
                fontSize: "12px",
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                mt: 1,
                fontSize: "10px",
                color: "#8C8C8C",
              }}
            >
              Max 200 characters.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mt: 1,
                fontSize: "12px",
                color: "red",
                textAlign: "start",
              }}
            >
              {error}
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions
          sx={{ p: 2.5, pt: 0, bottom: 0, bgcolor: "background.paper" }}
        >
          <Button
            onClick={handleCloseDialog}
            variant="text"
            sx={{
              textTransform: "none",
              fontSize: "14px",
              color: "#757575",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              fontSize: "14px",
              backgroundColor: "#E8505B",
              "&:hover": {
                backgroundColor: "#E8505B",
              },
            }}
            onClick={handleDeclinedSucess}
          >
            Decline
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ApprovalActions;
