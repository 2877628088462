import {
  Box,
  Chip,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getMrfHubData } from "../../../../Crud/card";
import { StyledTableCell } from "./RthClosedTable";
 
const MrfHub: React.FC = () => {
  // const userId = localStorage.getItem("userId") ?? "";
  // const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
  const [tableData, setTableData] = useState<any[]>([]);
  const tableHead = [
    "MRF ID","MRF Name",
    "Total Positions",
    "MRF Stage",
    "Recruiter",
    "Assigned Positions",
    "Rectr. MRF Stage",
    "Cloed Position",
    "Open Position",    
  ];
  const [loading, setLoading] = useState<boolean>(false);
  console.log("loading",loading);
  // const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
  // const [searchQuery, setSearchQuery] = useState<string>("");
  // useEffect(() => {
  //   setLoading(true);
  //   getMrfHubData()
  //     .then((res) => {
  //       setLoading(false);
  //       setTableData(res?.data?.data);
  //     })
  //     .catch((error) => {
  //       // setLoading(false);
  //       console.error(error);
  //     }).finally(()=>{
  //       setLoading(false)
  //     });
  //   console.log("finger");
  //   console.log(tableData);
  // }, [tableData]);
  useEffect(() => {
    setLoading(true);
    getMrfHubData()
      .then((res) => {
        setTableData(() => res?.data?.data); 
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false); 
      });
  }, []);

  return (
    <Box>
      <Box sx={{ width: "auto", backgroundColor: "#f0f4ff" }}>
        <Box sx={{ width: "100%", overflowX: "auto"}}>
        {loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '300px',
                        }}
                    >
                        <CircularProgress size={100} sx={{color: '#2F54EB'}}/>
                    </Box>
                ) :
        (  <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="RTH table">
              <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
                <TableRow
                  sx={{
                    "& th": {
                      padding: "4px 8px", // Adjust padding for header cells here
                      fontSize: "14px", // Optional: Adjust font size
                    },
                  }}
                >
                  {tableHead.map((data) => (
                    <StyledTableCell>{data}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((data, index) => (
                  <TableRow key={data.index} sx={{
                    '& td, & th': {
                      padding: '4px 8px', // Adjust padding here
                    }
                  }}>
                    <StyledTableCell>{index+1}</StyledTableCell>
                    <StyledTableCell>{data?.mrfName}</StyledTableCell>
                    <StyledTableCell>{data?.totalPosition}</StyledTableCell>                    
                    <StyledTableCell>
                    <Chip
                      label={data?.mrfStage}
                      size="small"
                      sx={{
                        fontSize: "12px",
                        padding: "3px 6px 0px 6px",
                        height: "px",
                        borderRadius: "1px",
                        backgroundColor: "#389E0D1A",
                        color: "#389E0D",
                      }}
                    />
                  </StyledTableCell>
                    <StyledTableCell>{data?.recruiterName}</StyledTableCell>
                    <StyledTableCell>{data?.assignedPosition}</StyledTableCell>
                    <StyledTableCell>{data?.rctrMrfStage}</StyledTableCell>
                    <StyledTableCell>{data?.closedPosition}</StyledTableCell>
                    <StyledTableCell>{data?.openPosition}</StyledTableCell>
                   
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>)}
        </Box>
      </Box>
    </Box>
  );
};
 
export default MrfHub;