import axios from "axios";
import { BaseUrl } from "../constants/Crudurl";

export const getMrfApprovalStatusTh = async (id:string) => {  
  const token=localStorage.getItem("token");  
    try {
      const response = await axios.get(
        `${BaseUrl}/report/mrfApprovalStatusTh/${id}`,{
          headers:{
            Authorization:`Bearer ${token}`,
          }
        }
      );
      return response.data;
    } catch (error: any) {
      return error.response?.data || { message: 'An unknown error occurred' };
    }
};

export const getMrfStageReportTh = async (id:string) => {   
  const token=localStorage.getItem("token");   
    try {
      const response = await axios.get(
        `${BaseUrl}/report/mrfStageReportTh/${id}`,{
          headers:{
            Authorization:`Bearer ${token}`,
          }
        }
      );
      return response.data;
    } catch (error: any) {
      return error.response?.data || { message: 'An unknown error occurred' };
    }
};


export const getMrfApprovedReportTh=async (id:string) => {    
  const token=localStorage.getItem("token");  
    try {
      const response = await axios.get(
        `${BaseUrl}/report/mrfApprovedReportTh/${id}`,{
          headers:{
            Authorization:`Bearer ${token}`,
          }
        }
      );
      return response.data;
    } catch (error: any) {
      return error.response?.data || { message: 'An unknown error occurred' };
    }
};

export const getOverallMrfReportTh=async (id:string) => {
  const token=localStorage.getItem("token");  
    try {
      const response = await axios.get(
        `${BaseUrl}/report/overAllMrfReportTh/${id}`,{
          headers:{
            Authorization:`Bearer ${token}`,
          }
        }
      );
      return response.data;
    } catch (error: any) {
      return error.response?.data || { message: 'An unknown error occurred' };
    }
};