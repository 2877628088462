export const processMap: { [key: string]: number }  = {
    "Open": 2,
    // "Open": 2,
    "MoveToJob": 3,
    "JobPost": 4,
    "UploadingResumes": 5,
    "HiringProcess": 6,
    "Closed": 7
  };
//   Open -> Open and Move to job
// MoveToJob -> CreateJD
// JobPost -> JobPost
// UploadResumes -> Upload Resumes
// balaceNames add