import { Box, Button, Typography } from '@mui/material';
import React from 'react'

function InterviewRounds() {
  return (
    <Box
    sx={{
      p: 3,
      backgroundColor: "background.default",
      borderRadius: 2,
      ml: "16px",
      mr: "16px",
      mb: "20px",
      position: "relative",
    }}
  >
    <Typography sx={{fontSize:"12px",color:"#8C8C8C",fontWeight:"700px"}}>Interview Rounds</Typography>

     {['Round 1', 'Round 2', 'Round 3', 'Round 4', 'Round 5'].map((round, index) => (
    <Button
      key={index}
      sx={{
        border: '1px solid #9A9A9A',
        backgroundColor: 'white',
        color: '#9A9A9A',
        margin: '8px',  
        fontSize:10
       
      }}
    >
      {round}
    </Button>
  ))}
  </Box>
  )
}

export default InterviewRounds