import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Divider, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store/index";
import { toast } from "react-toastify";
import { addBusiness, fetchBusiness } from "../../../../redux/features/businessSlice";

interface AddUserFormProps {
    open: boolean;
    onClose: () => void;
}

export interface BusinessData {
    id?: string;
    businessId?: string;
    businessName: string;
    status: string;
}

const AddBusinessForm: React.FC<AddUserFormProps> = ({ open, onClose }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [formData, setFormData] = useState<Partial<BusinessData>>({ businessName: "", status: "Active" });
    const [loading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!open) {
            setFormData({ businessName: "", status: "Active" });
        }
    }, [open])

    const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, ""); 
        setFormData((prev) => ({ ...prev, [name]: sanitizedValue }));
    };

    const handleSubmit = async () => {
        if (!formData.businessName?.trim()) {
            toast.error("Please add a Business name");
            return; 
        }
        if (loading) return; 
        setIsLoading(true); 
        try {
            const response = await dispatch(
                addBusiness({ businessName: formData.businessName })
            ).unwrap();
            await dispatch(fetchBusiness());
            if (response.status && response.message === "business Name already exist") {
                toast.error("Business name already exists. Please use a different name.");
            } else if (response.status) {
                toast.success("Business added successfully.");
                onClose();
                setFormData({ businessName: "" });
            } else {
                throw new Error(response.message || "Unknown error");
            }
        } catch (error) {
            toast.error("Failed to add business. Please try again.");
            console.error("Error:", error);
        } finally {
            setIsLoading(false); 
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth={false} fullWidth sx={{ maxWidth: '500px', margin: 'auto' }}>
            <DialogTitle sx={{ p: 2, borderBottom: '1px solid #f0f0f0' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 500 }}>
                        Add Business
                    </Typography>
                    <IconButton aria-label="close" onClick={onClose} sx={{ position: "absolute", right: 8, top: 8, backgroundColor: "#ECECEC" }}>
                        <CloseIcon sx={{ fontSize: '12px' }} />
                    </IconButton>
                </Box>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Box component="form" noValidate autoComplete="off" sx={{ mt: 1 }}>
                    <Typography variant="subtitle2" sx={{ mb: 1, fontSize: '12px' }}> Business Name </Typography>
                    <TextField
                        name="businessName"
                        placeholder="Enter Business Name"
                        fullWidth
                        value={formData.businessName}
                        size="small"
                        onChange={handleTextFieldChange}
                        sx={{
                            mb: 3,
                            "& .MuiInputBase-input": {
                                fontSize: "12px",
                            },
                            "& .MuiInputLabel-root": {
                                fontSize: "12px",
                            },
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{ p: 2.5, pt: 0, bottom: 0, bgcolor: 'background.paper' }}>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{ textTransform: 'none', fontSize: '12px', borderRadius: '8px', backgroundColor: '#1677FF' }}
                    disabled={!formData.businessName?.trim() || loading} 
                >
                    {loading ? "Adding..." : "Add"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddBusinessForm;