import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import CommonTextField from '../../../../common/CommonTextField'
 
export const Education = ({formValues,setFormValues}:any) => {

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = event.target;
  
    // Create a copy of the educations array
    const updatedEducations = [...formValues.educations];
  
    // Update the specific education entry at the given index
    updatedEducations[index] = {
      ...updatedEducations[index],
      [name]: value, // Update the field dynamically using the `name` attribute
    };
  
    // Update the formValues state with the modified educations array
    setFormValues((prevValues: any) => ({
      ...prevValues,
      educations: updatedEducations,
    }));
  };
  
  return (
    <>
    <Typography sx={{ fontSize: '14px', mb: '17px', m: '14px', backgroundColor: '#1677FF1A', color: '#1677FF', width: '70px', p: '3px', borderRadius: '3px', textAlign: 'center', fontWeight: '500' }}>Education</Typography>
    <Grid container spacing={2}>
    {formValues.educations && formValues.educations.length > 0 ? (
    formValues?.educations?.map((entry: any, index: number) => (
    <>
    <Box sx={{ mt: '20px', ml: '14px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '60px' }}>
    <Grid item xs={12} md={3}>
      <Typography sx={{ mb: '16px', color: '#E3E3E3', fontSize: '14px' }}>University</Typography>
      <CommonTextField
        label=""
        name="university"
        value={entry.university}
        onChange={(event) => handleInputChange(event, index)} />
    </Grid>
    <Grid item xs={12} md={3}>
      <Typography sx={{ mb: '16px', color: '#E3E3E3', fontSize: '14px' }}>Degree</Typography>
      <CommonTextField
        label=""
        name="degree"
        value={entry.degree}
        onChange={(event) => handleInputChange(event, index)} />
    </Grid>
    <Grid item xs={12} md={4}>
      <Typography sx={{ mb: '16px', color: '#E3E3E3', fontSize: '14px' }}>Field of Study</Typography>
      <CommonTextField
        label=""
        name="fieldOfStudy"
        value={entry.fieldOfStudy}
        onChange={(event) => handleInputChange(event, index)} />
    </Grid>
    <Grid item xs={12} md={2}>
      <Typography sx={{ mb: '16px', color: '#E3E3E3', fontSize: '14px' }}>From</Typography>
      <CommonTextField
        label=""
        name="from"
        value={entry.from}
        onChange={(event) => handleInputChange(event, index)} />
    </Grid>
      </Box>
      <Box sx={{ mt: '20px', ml: '14px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '60px' }}>
      <Grid item xs={12}>
      <Typography sx={{mb:'16px',color:'#E3E3E3',fontSize:'14px'}}>Year</Typography>
      <CommonTextField
      label=""
      name="to"
      value={entry.to}
      onChange={(event) => handleInputChange(event, index)}
      />
    </Grid>
    </Box>
    </>
    ))
    ):(
    <Typography sx={{ color: '#E5E3E3', pl:"30px", pt:'20px' }}>No education data available.</Typography>
    )}
    </Grid></>
    )
    }
 
 