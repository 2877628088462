import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUsers } from "../../../redux/features/userSlice";
import { RootState, AppDispatch } from "../../../redux/store";
import {Box} from "@mui/material";
import MasterTab from "../MasterTab";
import RolesTable from "./Roles/RolesTable";
import UsersTable from "./User/UserTable";
import { fetchRoles } from "../../../redux/features/roleSlice";
import { fetchBusiness } from "../../../redux/features/businessSlice";
import BusinessTable from "./Business/BusinessTable";
import DepartmentTable from "./Department/DepartmentTable";
import { fetchDepartment } from "../../../redux/features/departmentSlice";

const UserMasterTable: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const dispatch = useDispatch<AppDispatch>();
  const {  status, error } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchUsers());
      dispatch(fetchRoles()); 
      dispatch(fetchBusiness())
      dispatch(fetchDepartment())
    }
  }, [status, dispatch]);

  // if (status === "loading") {
  //   return <div>Loading...</div>;
  // }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }
  
  return (
    <Box sx={{ width: "auto", backgroundColor: "#f0f4ff", overflowX: "hidden" }} p={1}>
      <MasterTab
        tabIndex={tabIndex}
        onTabChange={(e, newIndex) => setTabIndex(newIndex)}
      />
      {/* <MasterSearch /> */}
      {tabIndex === 0 && <UsersTable />}
      {tabIndex === 1 && <RolesTable />}
     {/* {tabIndex === 2 && <OrganizationsTable />} */}
      {tabIndex === 2 && <DepartmentTable />}
      {tabIndex === 3 && <BusinessTable />}
  
    </Box>
  );
};

export default UserMasterTable;
