import { Box, Typography, Grid, Tooltip, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BaseUrl } from '../../../../../constants/Crudurl';

const maxBarValue = 100;

function PieChartBoard4() {
  const [barsData, setBarsData] = useState([
    { name: 'Internal', onRole: 0, contract: 0 },
    { name: 'External', onRole: 0, contract: 0 },
  ]);

  const data = useSelector((state: RootState) => state.profile.data);
  const userID = data?.data?.userId;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStatusReport = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${BaseUrl}/dashboard/categoryReportByRl/${userID}`
        );
        const responseData = response?.data?.data;
        const internalOnRole = responseData?.InternalOnrole || 0;
        const internalContract = responseData?.InternalContract || 0;
        const externalOneTime = responseData?.ExternalOneTime || 0;
        const externalContract = responseData?.ExternalContract || 0;
        setBarsData([
          { name: 'Internal', onRole: internalOnRole, contract: internalContract },
          { name: 'External', onRole: externalOneTime, contract: externalContract },
        ]);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    if (userID) {
      fetchStatusReport();
    }
  }, [userID]);

  return (
    <Grid container sx={{ borderRadius: '8px', height: '260px', backgroundColor: '#FFFFFF', padding: '8px', overflow: 'hidden', position: 'relative' }}>
      <Grid item xs={12} container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '30px' }}>
        <Typography sx={{ fontWeight: '500', fontSize: '10px' }}>MRF Category Report</Typography>
      </Grid>
      {loading ? (
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10 }}>
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1, height: '220px', width: '100%' }}>
          <Grid container spacing={2} alignItems="center" sx={{ marginLeft: 0 }}>
            <Grid item display="flex" flexDirection="column" alignItems="flex-start" sx={{ marginBottom: '24px', marginRight: '16px' }}>
              <Typography sx={{ fontSize: '10px' }}>100</Typography>
              <Typography sx={{ fontSize: '10px', marginTop: '14px' }}>80</Typography>
              <Typography sx={{ fontSize: '10px', marginTop: '14px' }}>60</Typography>
              <Typography sx={{ fontSize: '10px', marginTop: '14px' }}>40</Typography>
              <Typography sx={{ fontSize: '10px', marginTop: '14px' }}>20</Typography>
              <Typography sx={{ fontSize: '10px', marginTop: '14px' }}>00</Typography>
            </Grid>
            {barsData.map((bar, index) => (
              <Grid item key={index} display="flex" flexDirection="column" alignItems="center" sx={{ margin: '0 12px' }}>
                <Box sx={{ height: '150px', position: 'relative', display: 'flex', flexDirection: 'column-reverse' }}>
                  <Tooltip title={`${bar.contract} Contract`} arrow placement='top'>
                    <Box
                      sx={{
                        width: '40px',
                        height: `${(bar.contract / maxBarValue) * 150}px`,
                        backgroundColor: '#357AF6',
                        cursor: 'pointer',
                        marginTop: '1px',
                      }}
                    />
                  </Tooltip>
                  <Tooltip title={`${bar.onRole} On-role`} arrow placement='top'>
                    <Box
                      sx={{
                        width: '40px',
                        height: `${(bar.onRole / maxBarValue) * 150}px`,
                        backgroundColor: '#5CC8BE',
                        cursor: 'pointer',
                        borderRadius: '2px 2px 0 0'
                      }}
                    />
                  </Tooltip>
                </Box>
                <Typography sx={{ color: '#6B7280', textAlign: 'center', fontSize: '10px', marginTop: '8px' }}>
                  {bar.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 2, marginLeft: '40px' }}>
            <Box display="flex" alignItems="center" mx={1} sx={{ backgroundColor: '#FAFAFA', borderRadius: '8px', padding: '2px 4px', }}>
              <Box sx={{ width: 8, height: 8, backgroundColor: '#5CC8BE', mr: 1, borderRadius: '2px' }} />
              <Typography sx={{ fontSize: '10px', color: '#6B7280' }}>On-role</Typography>
            </Box>
            <Box display="flex" alignItems="center" mx={1} sx={{ backgroundColor: '#FAFAFA', borderRadius: '8px', padding: '2px 4px', }}>
              <Box sx={{ width: 8, height: 8, backgroundColor: '#357AF6', mr: 1, borderRadius: '1px' }} />
              <Typography sx={{ fontSize: '10px', color: '#6B7280' }}>Contract</Typography>
            </Box>
          </Grid>
        </Box>
        )}
    </Grid>
  );
}

export default PieChartBoard4;
