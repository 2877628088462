import { Box, Grid } from '@mui/material';
import React from 'react';
import { Job } from '../../redux/features/jobcardSlice';
import JobCard from '../common/JobCard';
import NoDataAvail from '../../assets/images/NoDataAvail.svg'

export interface ViewCardProps {
  data: Job[];
  label?: string;
  teamHeadFilterDto?: any;
  userId?: string;
  drawerOpen?: boolean;
  tabName?:string;
}

const ViewCard: React.FC<ViewCardProps> = ({ data = [], label, userId, teamHeadFilterDto, drawerOpen }) => (
  <Box>
    {data?.length > 0 ? 
    <Grid container spacing={2} sx={{ display: 'flex', flexWrap: 'wrap', }}>
    {
      data?.map((job: Job, index: number) => {
        return <Grid xs={drawerOpen ? 3 : 2.4}>
          <JobCard
            userId={userId}
            teamHeadFilterDto={teamHeadFilterDto}
            key={index}
            label={label}
            job={job}
          />
        </Grid>
      })
    }
  </Grid> : <Box display={"flex"} width={"100%"}>      
       <img src={NoDataAvail} alt="No Data Available" />
     </Box>
    }
    {label === "My Requests" && 
          <div> Hiii</div>
    }
  </Box>

);

export default ViewCard;