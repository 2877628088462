import React from 'react';
import { Box, Button } from '@mui/material';

interface ResumeDetailsProps {
  typeOfRth?: string;
  modeOfRth?: string;
  modesOfRth?: string;
  rthProcess?: string;
  status?: string;
}

const ResumeDetails: React.FC<ResumeDetailsProps> = ({ typeOfRth, modeOfRth, rthProcess, status, modesOfRth }) => {

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: "2px" }}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button size="small" sx={{ borderRadius: '8px', backgroundColor: '#F0F0F0', border: "1px solid #D3D3D3", fontSize: '0.438rem', padding: '1px', margin: '1px', color: "#8C8C8C", minWidth: '50px',  }}>
          {typeOfRth}
        </Button>
        <Button size="small" sx={{ mr: 1, borderRadius: '8px', backgroundColor: '#F0F0F0', border: "1px solid #D3D3D3", fontSize: '0.438rem', padding: '1px', margin: '1px', color: "#8C8C8C", minWidth: '50px' }}>
          {modeOfRth}
        </Button>
        {modesOfRth &&
          <Button size="small" sx={{ mr: 1, borderRadius: '8px', backgroundColor: '#F0F0F0', border: "1px solid #427BC5", fontSize: '0.438rem', padding: '1px', margin: '1px', color: "#427BC5", minWidth: '50px' }}>
            {modesOfRth}
          </Button>}
        {status === "In Progress" &&
          <Button size="small" sx={{ mr: 1, borderRadius: '8px', backgroundColor: '#427BC51A', border: "1px solid #427BC5", fontSize: '0.438rem', padding: '1px', margin: '1px', height: '15px', minWidth: '60px' }}>
            {rthProcess}
          </Button>}
      </Box>
    </Box>
  );
}

export default ResumeDetails;
