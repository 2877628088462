import axios from "axios";
import { BaseUrl } from "../constants/Crudurl";
// import { AppDispatch } from "../redux/store";
// import { useDispatch } from "react-redux";
// import { useAppSelector } from "../redux/hooks";
import { constructQuery } from "../helpers/constructQuery";
// import { jwtDecode } from "jwt-decode";
// const token: any = localStorage.getItem("token");
// const decoded: any = jwtDecode(token);
//   const userId = decoded.userId;

const reqBody = {
  l1StatusPending: false,
  l1StatusDeclined: false,
  l2StatusPending: false,
  l2StatusApproved: false,
  internalOnRole: false,
  internalContract: false,
  externalOneTime: false,
  externalContract: false,
  priorityLow: false,
  priorityMedium: false,
  priorityHigh: false,
  rthStages: null,
  createdBy: null,
  recruiterLead: null,
  positionTitle: null,
  business: null,
  location: null,
  recruiter: null,
  fromDate: null,
  toDate: null,
  page: 0,
  size: 100,
};

export const getMyRequestsAPIData = async (userId: string, teamHeadFilter: any, search?: string) => {
  return await axios.post(
    `${BaseUrl}/homePage/myRequest/${userId}${search ? `?search=${search}` : ''}`,
    teamHeadFilter
  );
};

export const getInProgressAPIData = async (userId: string, teamHeadFilter: any, search?: string) => {
  return await axios.post(
    `${BaseUrl}/homePage/getInProgress/${userId}${search ? `?search=${search}` : ''}`,
    teamHeadFilter
  );
};

export const getClosedAPIData = async (userId: string,teamHeadFilter: any, ) => {
  return await axios.post(
    `${BaseUrl}/homePage/getClosed/${userId}`,
    teamHeadFilter
  );
};

export const getToBeAssignedAPIData = async (userId: string, teamHeadFilter: any, search?: string) => {
  return await axios.post(
    `${BaseUrl}/homePage/getToBeAssigned/${userId}${search ? `?search=${search}` : ''}`,
    teamHeadFilter
  );
};

export const getAssignedAPIData = async (userId: string, teamHeadFilter: any, search?: string) => {
  return await axios.post(
    `${BaseUrl}/homePage/getAssigned/${userId}${search ? `?search=${search}` : ''}`,
    teamHeadFilter
  );
};

export const getWaitingToMoveToJobAPIData = async (userId: string, teamHeadFilter: any, search?: string) => {
  return await axios.post(
    `${BaseUrl}/homePage/getWaitingToMoveToJob/${userId}${search ? `?search=${search}` : ''}`,
    teamHeadFilter
  );
};

export const getReadyForJdCreationAPIData = async (userId: string, teamHeadFilter: any, search?: string) => {
  return await axios.post(
    `${BaseUrl}/homePage/getReadyForJdCreation/${userId}${search ? `?search=${search}` : ''}`,
    teamHeadFilter
  );
};
export const getMoveToJobAPIData = async (rthId: string, teamHeadFilter: any) => {
  return await axios.put(
    `${BaseUrl}/rth/setRthUpdate/${rthId}`
  );
};
export const getJobPostAPIData = async (userId: string, search?:string) => {
  return await axios.post(
    `${BaseUrl}/homePage/getJobPost/${userId}${search ?`?search=${search}`:''}`,
    reqBody
  );
};

export const getResumeAPIData = async (userId: string) => {
  return await axios.post(
    `${BaseUrl}/homePage/getResume/${userId}`,
    reqBody
  );
};

export const getOverAllRthAPIData = async (userId: string, teamHeadFilter: any, search?: string) => {
  const condition = teamHeadFilter ? teamHeadFilter : reqBody
  return await axios.post(
    `${BaseUrl}/homePage/getOverAllRth/${userId}${search?`?search=${search}`:''}`,
    condition
  )
};

export const getOverAllClosedAPIData = async (userId: string, teamHeadFilter: any, search?: string) => {
  return await axios.post(
    `${BaseUrl}/homePage/getOverAllClosed/${userId}${search ? `?search=${search}` : ''}`,
    teamHeadFilter
  )
};

export const getJdEditAPIData = async (rthId: string, data: any) => {
  return await axios.post(
    `${BaseUrl}/rth/jdSubmission/${rthId}`, data,
  )
};
export const getJobPostedInAPIData = async (rthId?: string, data?: any) => {
  const checkCondtion = Object.values(data).some((value) => value) ? constructQuery(data) : "postedIn=''"
  return await axios.post(
    `${BaseUrl}/rth/postIn/${rthId}?${checkCondtion}`
  )
};

export const getOverAllDeclinedAPIData = async (seqId: string, comments: string) => {
  return await axios.put(
    `${BaseUrl}/rth/setL1Status/${seqId}/Declined?comments=${comments}`,)
};

export const getResumeCardAPIData = async (userId: string, teamHeadFilter: any, search?: string) => {
  return await axios.post(
    `${BaseUrl}/homePage/getResume/${userId}${search ? `?search=${search}` : ''}`,
    teamHeadFilter
  )
};
export const getMrfHubData = async () => {  
  return await axios.get(
    `${BaseUrl}/homePage/getMrfHub`
  );
};


