import { Grid, Typography, Box, CircularProgress } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import DropDownIcon from '../../../../../assets/images/dropdown.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BaseUrl } from '../../../../../constants/Crudurl';

const chartColors: string[] = ['#00A76F', '#FFAB00', '#00B8D9', '#05EEFF', '#FF5630', '#F635C6'];

const getRandomColor = (colorSet: string[]) => {
    return colorSet[Math.floor(Math.random() * colorSet.length)];
};

function PieChartBoard3() {
    const data = useSelector((state: RootState) => state.profile.data);
    const userID = data?.data?.userId;
    const [statusReport, setStatusReport] = useState<{ label: string; value: number; color: string }[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchStatusReport = async () => {
            try {
                setLoading(true);
                const response = await axios.get(
                    `${BaseUrl}/dashboard/clientwiseRthByTa/${userID}`
                );
                const responseData = response?.data?.data;
                const updatedStatusReport = Object.entries(responseData).map(([label, value]) => ({
                    label,
                    value: Number(value),
                }));

                // Assign random colors from the chartColors set
                const updatedStatusReportWithColors = updatedStatusReport.map((item) => ({
                    ...item,
                    color: chartColors.length >= updatedStatusReport.length
                        ? chartColors[updatedStatusReport.indexOf(item) % chartColors.length]
                        : getRandomColor(chartColors),
                }));
                setStatusReport(updatedStatusReportWithColors);
            } catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        if (userID) {
            fetchStatusReport();
        }
    }, [userID]);

    return (
        <Grid container sx={{ borderRadius: '8px', height: '260px', backgroundColor: '#FFFFFF', padding: '8px', overflow: 'hidden', position: 'relative' }}>
            <Grid item xs={12} container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '30px' }}>
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: '500', fontSize: '10px' }}>Client wise MRF</Typography>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '12px' }}>External</Typography>
                    <img src={DropDownIcon} alt="dropdownIcon" style={{ height: '18px', width: '18px', marginLeft: '4px' }} />
                </Grid>
            </Grid>
            {loading ? (
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10 }}>
                    <CircularProgress size={50} />
                </Box>
            ) : (
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '170px', position: 'relative' }}>
                <PieChart
                    width={300}
                    height={160}
                    margin={{ right: 0, left: 0, top: 0, bottom: 0 }}
                    slotProps={{
                        legend: { hidden: true },
                    }}
                    series={[
                        {
                            data: statusReport.map((item) => ({
                                ...item,
                                color: item.color, // Use the randomly assigned color
                            })),
                        }]}
                />
            </Grid>
            )}
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                {statusReport.map((item, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', margin: '4px', backgroundColor: '#FAFAFA', borderRadius: '8px', padding: '2px 4px', }}>
                        <Box sx={{ width: 6, height: 6, borderRadius: '1px', backgroundColor: item.color, marginRight: '8px' }}></Box>
                        <Typography sx={{ color: '#6B7280', fontSize: '7px', padding: '2px' }}>{item.label}</Typography>
                        <Typography sx={{ color: '#6B7280', fontSize: '7px', fontWeight: 'bold' }}>{item.value}</Typography>
                    </Box>
                ))}
            </Grid>
        </Grid>
    );
}

export default PieChartBoard3;
