import React, { useCallback } from 'react'
import { Box, Button, Card, MenuItem, Paper, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import Img from "../../../../../assets/images/interviewDetailsImg.svg";
import { useEffect, useState } from "react";
import ScheduleInterviewDialog from "./ScheduleInterviewDialog";
import StepIcon from "../../../../../assets/images/stepIcon.svg";
import CurrentStepIcon from "../../../../../assets/images/currentStepIcon.svg";
import StepperConnectorIcon from "../../../../../assets/images/stepperConnectorIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { getCandidateInterviewDetails } from "../../../../../redux/features/interviewSchedule";
import MSTeamIcon from "../../../../../assets/images/Teams-icon.svg";
import MeetIcon from '../../../../../assets/images/google-meet.svg';
import Zoom from '../../../../../assets/images/zoom-icon.svg';
import dateAndTimeIcon from "../../../../../assets/images/dateAndTimeIcon.svg";
import FillFeedbackIcon from "../../../../../assets/images/fillFeedbackFormIcon.svg";
import FeedBackCompleted from '../../../../../assets/images/FeedBack-SubmitIcon.svg';
import InterviewIcon from "../../../../../assets/images/interviewerIcon.svg";
import { setStatus } from "../../../../../redux/features/interviewScheduleSlice";
// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

export const InterviewDetails = ({handleFirstStep}:any) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { jobTitle, id, candidateName, candidateId } = useParams();
  const [steps, setSteps] = useState<any>([]); 
  const [selectedStep, setSelectedStep] = useState(0); // Track selected step for highlight
  const[localStatus,setLocalStatus]=useState('');

  const handleClickOpen = () => setOpen(true);
  const [expandedStep, setExpandedStep] = useState<number | null>(null); // Expanded step state
  const state=useAppSelector((state:any)=>state.interviewSchedule.candidateInterviewDetails)
  const dispatch=useAppDispatch();
  const handleClose = () => setOpen(false);
  // const interviewDetails = [state.interviewDetL1, state.interviewDetL2, state.interviewDetL3];
  // const validInterviewDetails = interviewDetails.filter((detail) => detail !== null);
//  const statusFromSlice=useAppSelector((state)=>state.interviewSchedule.status);
const handleNavigate = useCallback(
  ({ interviewRound, interviewId }: { interviewRound: any; interviewId: any }) => {
    navigate(`/newRequestToHire/${jobTitle}/${id}/${candidateName}/${candidateId}/${interviewRound}/${interviewId}/InterviewEvaluationForm`);
  },
  [navigate, jobTitle, id, candidateName, candidateId]
);

// const handleNavigateReview = useCallback(
//   ({ interviewRound, interviewId }: { interviewRound: any; interviewId: any }) => {
//     navigate(`/newRequestToHire/${jobTitle}/${id}/${candidateName}/${candidateId}/${interviewRound}/${interviewId}/InterviewEvaluationFormReview`);
//   },
//   [navigate, jobTitle, id, candidateName, candidateId]
// );

const handleChange = useCallback(
  (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as string;
    setLocalStatus(selectedValue); // Set the local state
    dispatch(setStatus(selectedValue)); // Update Redux state
  },
  [dispatch] // Dependencies: functions or state used inside handleChange
);

  const handleStepClick = (index: any) => {
    setExpandedStep(index); // Toggle expanded step
  };
  
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    
    // Get the day, month, and year
    const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits for day
    const month = date.toLocaleString('default', { month: 'short' }); // Get abbreviated month
    const year = date.getFullYear();
  
    return `${day} ${month} ${year}`;
  };

  const formatTimeRange = (from: string) => {
    const formatTime = (time: string) => {
      const date = new Date(time);
      const hours = date.getHours().toString().padStart(2, '0'); // Ensure 2 digits for hours
      const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure 2 digits for minutes
      return `${hours}:${minutes}`; // 24-hour format
    };
  
    return `${formatTime(from)}`;
  };
  
const meetingIcon=(meetName:any)=>{
  switch(meetName){
    case 'Teams':
      return <img src={MSTeamIcon} style={{width:'20px',height:'20px'}} alt='Teams Icon'/>
    case 'Google Meet':
      return <img src={MeetIcon} style={{width:'20px',height:'20px'}} alt='Meet Icon'/>
    case 'Zoom':
      return <img src={Zoom} style={{width:'20px',height:'20px'}} alt='Zoom Icon'/>
  }
}
 
  useEffect(() => {
    const cardStyles = {
      width: '250px',
      display: 'flex',
      alignItems: 'center',
      p: 1,
      bgcolor: '#00000005',
      boxShadow: 'none',
      border: 'none',
      borderRadius: '12px',
    };
  
    // Create an array of valid interview details for L1, L2, and L3 rounds, filtering out null or empty objects
    const interviewDetails = [
      state?.interviewDetL1 ? { ...state?.interviewDetL1, round: "interviewDetL1" } : null,
      state?.interviewDetL2 ? { ...state?.interviewDetL2, round: "interviewDetL2" } : null,
      state?.interviewDetL3 ? { ...state?.interviewDetL3, round: "interviewDetL3" } : null,
    ]
    .filter(detail => 
      detail && 
      Object.values(detail).some(value => value !== null && value !== undefined)
    );

    const isValidInterview = (detail:any) => {
      if (!detail) return false;
    
      const keysToCheck = [
        "interviewRound", "interviewName", "interviewMode", "interviewLink", "interviewDate",
        "from", "to", "interviewer", "optionalAttendees", "feedbackForm", "status"
      ];
    
      // Check if any of the key fields is not null or undefined
      return keysToCheck.some(key => detail[key] !== null && detail[key] !== undefined);
    };
    
    // Filter out rounds with all null or undefined values
    const filteredDetails = interviewDetails.filter(isValidInterview); 
    
  
    // If no valid interview details, clear steps and return early
    if (filteredDetails?.length === 0) {
      setSteps([]);
      return;
    }
  
  
    // Map over interview details to initialize steps with content
    const initializedSteps = filteredDetails.map((detail, index) => ({
      label: (
        <Box
          sx={{ display: 'flex', flexDirection: 'column' }}
          onClick={() => handleStepClick(index)}
        >
          <Typography
            variant="h6"
            sx={{
              color: expandedStep === index ? '#1677FF' : '#161C24',
              marginRight: '16px',
            }}
          >
            {`${detail?.interviewRound} Interview `}
          </Typography>
          <Typography sx={{ color: '#C4CDD5', fontSize: '16px' }}>
            {formatDate(detail?.interviewDate) || 'No Date Scheduled'}
          </Typography>
        </Box>
      ),
      content: expandedStep === index && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, ml: '20px', mt: '30px' }}>
          {/* Interview Mode Card */}
          <Card sx={{ ...cardStyles }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: '12px' }}>
              <Typography variant="body2" sx={{ color: '#919EAB', fontSize: '10px' }}>
                Interview Mode
              </Typography>
              <Box sx={{display:'flex',flexDirection:'row',gap:'10px'}}>
              {meetingIcon(detail?.interviewMode)}
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {detail?.interviewMode || 'Not Specified'}
              </Typography>
              </Box>
            </Box>
          </Card>
  
          {/* Date & Time Card */}
          <Card sx={{ ...cardStyles }}>
            <img src={dateAndTimeIcon} alt="Date and Time" />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: '12px' }}>
              <Typography variant="body2" sx={{ color: '#919EAB', fontSize: '10px' }}>
                Date & Time
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {detail?.from
                  ? `${formatDate(detail?.interviewDate)} | ${formatTimeRange(detail?.from)} - ${formatTimeRange(detail?.to)}`
                  : 'Not Specified'}
              </Typography>
            </Box>
          </Card>
  
          {/* Interviewer Card */}
          <Card sx={{ ...cardStyles }}>
            <img src={InterviewIcon} alt="Interviewer" />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: '12px' }}>
              <Typography variant="body2" sx={{ color: '#919EAB', fontSize: '10px' }}>
                Interviewer
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {detail?.interviewer?.userName?.length > 15
                  ? `${detail?.interviewer?.userName.slice(0, 15)}...`
                  : detail?.interviewer?.userName || 'Not Specified'}
              </Typography>
            </Box>
          </Card>
  
          {/* Feedback Button */}
          <Button
            variant="outlined"
            sx={{
              textTransform: 'none',
              bgcolor: '#F0F5FF',
              color: detail?.feedbackForm ? '#389E0D' : '#1677FF',
              border: detail?.feedbackForm ? '1px solid #389E0D' : '1px solid #1677FF',
              borderRadius: '8px',
              ':hover': { border: detail?.feedbackForm ? '1px solid #389E0D' : '1px solid #1677FF' },
            }}
            startIcon={detail?.feedbackForm ? <img src={FeedBackCompleted} alt="Feedback completed" />
              : <img src={FillFeedbackIcon} alt="Fill Feedback Form Icon" />}
              // endIcon={detail?.feedbackForm ? <VisibilityOutlinedIcon sx={{color:'#389E0D'}} /> : null}

            onClick={() => {
              if (!detail?.feedbackForm)
                handleNavigate({ interviewRound: detail?.round, interviewId: state?.interviewId });
              // if(detail?.feedbackForm)
              //   handleNavigateReview({ interviewRound: detail?.round, interviewId: state?.interviewId });
            }}
          >
            {detail?.feedbackForm ? 'Feedback Submitted' : 'Fill Feedback Form'}
            {!detail?.feedbackForm &&
              <span style={{ color: 'red', marginLeft: '10px', marginRight: '14px' }}>*</span>
            }
          </Button>
  
          {/* Status TextField */}
          {detail?.feedbackForm ? (
            <TextField
              variant="outlined"
              value={detail?.status}
              sx={{
                width: '200px',
                color: detail?.status === 'Selected' ? '#389E0D' :
                  detail?.status === 'Rejected' ? '#ff8f8f' :
                    detail?.status === 'On Hold' ? '#ffa769' : undefined,
                backgroundColor: detail?.status === 'Rejected' ? '#ffe8e8' :
                  detail?.status === 'Selected' ? '#ebf5e6' :
                    detail?.status === 'On Hold' ? '#fff7f2' : undefined,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: detail?.status === 'Selected' ? '#389E0D' :
                      detail?.status === 'Rejected' ? '#ffc9c9' :
                        detail?.status === 'On Hold' ? '#ffaa6e' : undefined,
                  },
                  '&:hover fieldset': {
                    borderColor: detail?.status === 'Selected' ? '#389E0D' :
                      detail?.status === 'Rejected' ? '#ffc9c9' :
                        detail?.status === 'On Hold' ? '#ffaa6e' : undefined,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: detail?.status === 'Selected' ? '#389E0D' :
                      detail?.status === 'Rejected' ? '#ffc9c9' :
                        detail?.status === 'On Hold' ? '#ffaa6e' : undefined,
                  },
                },
                '& .MuiInputBase-input': {
                  color: detail?.status === 'Selected' ? '#389E0D' :
                    detail?.status === 'Rejected' ? '#ff8f8f' :
                      detail?.status === 'On Hold' ? '#ffa769' : undefined,
                },
              }}
            />
          ) : (
            <TextField
              select
              variant="outlined"
              label="Choose Status"
              value={localStatus}
              onChange={handleChange}
              sx={{ width: '200px' }}
            >
              <MenuItem value="On Hold">On Hold</MenuItem>
              <MenuItem value="Selected">Selected</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </TextField>
          )}
        </Box>
      ),
    }));
  
    // Add "Schedule L2 Interview" step if there are fewer than 3 rounds
    if (filteredDetails.length < 3) {
      const nextRound = filteredDetails.length === 1 ? 'L2' : 'L3';
      initializedSteps.push({
        label: <span style={{ display: 'none' }} />,
        content: (
          <Button
            variant="outlined"
            sx={{ color: '#0D6EFD', borderColor: '#0D6EFD', textTransform: 'none' }}
            onClick={handleClickOpen}
          >
            + Schedule {nextRound} Interview
          </Button>
        ),
      });
    }
  
    // Set initialized steps to state
    setSteps(initializedSteps);
  }, [expandedStep, state, localStatus, handleChange, handleNavigate]);
  

  useEffect(()=>{
    setSteps([]);
    dispatch(getCandidateInterviewDetails(candidateId));
    setExpandedStep(0);
  },[candidateId,dispatch,setSteps])

  return (
    <>  
      <Box sx={{ width: '90vw', height: '80px', ml: '70px', mt: '10px' }}>
        <Paper sx={{ height: 'auto', width: '90vw', display: 'flex', flexDirection: 'column', borderRadius: '8px', overflow: 'hidden', boxShadow: 'none', pb: '10px' }}>
        
         {steps?.length > 0 ? (
        <Stepper orientation="vertical" activeStep={0} sx={{ ml: '30px' }}>
          {steps?.map((step:any, index:any) => (
            <Box
              key={index}
              sx={{ 
                mb: 1,
                cursor: 'pointer',
                transform: expandedStep === index ? 'scale(1.02)' : 'scale(1)',
                transition: 'transform 0.2s ease, background-color 0.2s ease',
                backgroundColor: expandedStep === index ? 'transparent' : 'transparent',
                p: expandedStep === index ? 2 : 0,
                borderRadius: expandedStep === index ? '8px' : 0,
              }}
              onClick={() => handleStepClick(index)}
            >
              <StepLabel
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                icon={
                  <img
                    src={expandedStep === index ? CurrentStepIcon : StepIcon}
                    alt={expandedStep === index ? "current step" : "step icon"}
                    width="10px"
                  />
                }
              >
                <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#161C24' }}>
                  {step?.label}
                </Typography> 
              </StepLabel>
              
              <Box sx={{ mt: 1 }}>{step?.content}</Box>
              
              {index < steps?.length - 1 && (
                  <img src={StepperConnectorIcon} alt="step connector" style={{ width: '8px', height: '50px' }} />
              )}
            </Box>
          ))}
        </Stepper>
        ):(
            <Paper sx={{height:'70vh', width:'90vw', display:'flex', justifyContent:'center', alignItems:'center', borderRadius:'8px', overflow:'hidden', boxShadow:'none'}}>
        <Box>
        <Box sx={{display:'flex', justifyContent:'center'}}>
        <img src={Img} style={{width:'300px'}} alt="Interview Details imageshow" />
        </Box>
        <Box sx={{mt:'30px', display:'flex', justifyContent:'center', flexDirection:'column'}}>
        <Typography sx={{color:'#212B36', fontSize:'18px', fontWeight:600, width:'350px', textAlign:'center'}}>No Interview Scheduled Yet</Typography>
        <Typography sx={{color:'#919EAB', fontSize:'14px', width:'350px', textAlign:'center', pt:'14px'}}>It looks like you haven't schedule any Interview yet.</Typography>
        <Typography sx={{color:'#919EAB', fontSize:'14px', width:'350px', textAlign:'center'}}>Get started by clicking "Add" below.</Typography>
        <Typography  onClick={handleClickOpen} sx={{color:'#1677FF', fontWeight:600, width:'350px', textAlign:'center', pt:'14px', cursor: 'pointer', '&:hover':{color:'#1f69d1'}}}>+ Schedule Interview</Typography>
        </Box>        
        </Box>
        </Paper> 
        )}

        </Paper>
      </Box>
      <ScheduleInterviewDialog DialogOpen={open} close={handleClose} setSteps={setSteps} setSelectedStep={setSelectedStep} selectedStep={selectedStep} handleNavigate={handleNavigate} handleFirstStep={handleFirstStep} />
    </>
  );
};