import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";

interface CommonSelectProps {
  labelId: string;
  label: string;
  name: string;
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  options: { value: any; label: string }[];
  error?: boolean;
  helperText?: string;
}

const CommonSelect: React.FC<CommonSelectProps> = ({
  labelId,
  label,
  name,
  value,
  onChange,
  options,
  error,
  helperText,
}) => {
  
  return (
    <FormControl
      fullWidth
      size="small"
      error={error}
      sx={{ borderColor: "divider" }}
    >
      <InputLabel id={labelId} sx={{ fontSize: "0.8rem", fontWeight: 410, color: "gray"}}>
        {label}
      </InputLabel>
      <Select
        labelId={labelId}
        label={label}
        value={value}
        onChange={onChange}
        name={name}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{`${helperText} field is required`}</FormHelperText>}
    </FormControl>
  );
};

export default CommonSelect;
