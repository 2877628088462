import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Typography } from '@mui/material';
import CustomTabs from '../../../common/tabs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EmployeInformation } from './EmployeInformation';
import { InterviewDetails } from './resumeCardTabFiles/InterviewDetails';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getCandidateByCandidateId, getInterviewStatus, setInterviewStatus } from '../../../../redux/features/resume';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { toast } from 'react-toastify';


export const CandidateInfoTab = () => {
    const drawerOpen = true;
    const [tabIndex, setTabIndex] = useState(0);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [categories, setCategories] = useState<Record<string, string[]>>({});
    const[buttonName,setButtonName]=useState<any>([]);
    const {candidateName,jobTitle,id,candidateId}=useParams();
    const recruiterId=localStorage.getItem('userId');
    const state=useAppSelector((state)=>state.resume.interviewStatus);
    const candidateDetails=useAppSelector((state:any)=>state.resume.candidate)
    const navigate=useNavigate();
    const dispatch=useAppDispatch();
    const tabsData = [
        { label: 'Employee Information' },
        { label: 'Interview Details' },
      ];

    const handleFirstStep=()=>{
      setTabIndex(0);
    }
      const renderTabContent = () => {
        switch (tabIndex) {
          case 0:
            return <EmployeInformation />;
          case 1:
            return <InterviewDetails handleFirstStep={handleFirstStep} />;
          default:
            return null;
        }
      };

      const categorizeData = (data: any[]) => {
        return data.reduce(
          (acc: Record<string, string[]>, item: any) => {
            const statusName=item?.statusName
            if (typeof statusName === 'string') {
              if (statusName.toLowerCase().includes('screening')) {
                acc['Screening'] = [...(acc['Screening'] || []), statusName];
              } else if (statusName.toLowerCase().includes('l1')) {
                acc['L1 Interview'] = [...(acc['L1 Interview'] || []), statusName];
              } else if (statusName.toLowerCase().includes('l2')) {
                acc['L2 Interview'] = [...(acc['L2 Interview'] || []), statusName];
              } else if (statusName.toLowerCase().includes('l3')) {
                acc['L3 Interview'] = [...(acc['L3 Interview'] || []), statusName];
              } else {
                acc['Other'] = [...(acc['Other'] || []), statusName];
              }
            } else {
              // Optional: Handle non-string statusNames
              acc['Other'] = [...(acc['Other'] || []), JSON.stringify(statusName)];
            }
            return acc;
          },
          {}
        );
      };
      
    
      const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
      };
       
      const handleClose = () => {
        setAnchorEl(null);
      };

    const handleButtonName=async(label:string)=>{
      setButtonName(label);

      const resultAction=await dispatch(setInterviewStatus({candidateId:candidateId,recruiterId:recruiterId,status:label}));

      if(setInterviewStatus.fulfilled.match(resultAction)){
        const { status }=resultAction.payload || {};
        if(status===true){
          toast.success('Interview Status Updated Successfully');
          dispatch(getCandidateByCandidateId(candidateId));
        }
        else if(status===400){
          toast.error('Interview Status not updated');
        }
      }
      dispatch(getCandidateByCandidateId(candidateId));
      handleClose();
    }
    
const handleBack=()=>{
   navigate(`/dashboard/MRH/${jobTitle}/${id}`);
};

useEffect(()=>{
  if(candidateDetails)
  setButtonName(candidateDetails?.interviewStatus)
},[candidateDetails])

useEffect(()=>{
   dispatch(getInterviewStatus());
   dispatch(getCandidateByCandidateId(candidateId));
},[candidateId,dispatch]);

useEffect(() => {
  if (state) {
    const categorizedData = categorizeData(state);
    setCategories(categorizedData);
  }
}, [state]);

  return (
    <><Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', ml: '20px' }}>
        <ArrowBackIcon onClick={handleBack} sx={{ mt: '10px', color: '#948F8F', cursor: 'pointer' }} />
        <CustomTabs
          tabsData={tabsData}
          drawerOpen={drawerOpen}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex} />
      </Box>
      <Box sx={{ mt: '20px', ml: '70px', display: 'flex', flexDirection: 'row', gap: '4px' }}>
        <Typography sx={{ fontSize: '22px', fontWeight: '500' }}>{candidateName}</Typography>
        {tabIndex === 0 && (
          <>
          <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}} >
            <Box>
             <Button variant='contained' sx={{ width: '180px', height: '34px', textTransform: 'none', fontSize: '12px', backgroundColor: '#1677FF', ml: '14px', p: 0, borderRadius: '4px' }} endIcon={<KeyboardArrowDownIcon sx={{ fontSize: '14px' }} />} onClick={handleClick}>
             {buttonName}
             </Button>
             </Box>
          </Box>
          </>
        )}
      </Box>
      <Box sx={{height:'auto'}}>
      {renderTabContent()}
      </Box>
      
    </Box>
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{
        style: {
          padding: '16px', 
          borderRadius: '8px', 
        },
      }}
    >
       <Box
    sx={{
      maxHeight: 300, // Limit the height
      overflowY: 'auto', // Enable vertical scrolling
    }}
  >
          {Object.entries(categories).map(([category, items]) => (
          <div key={category}>
            <Typography variant="subtitle2" color="textSecondary" style={{ marginBottom: '8px' }}>
              {category}:
            </Typography>
            {items.map((item, index) => (
              <MenuItem key={index} onClick={() =>handleButtonName(item)}>
                {item}
              </MenuItem>
            ))}
          </div>
        ))}
        </Box>
      </Menu>

      </>
  )
}
