import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { BaseUrl } from "../../constants/Crudurl";

export const getL1Approvers=createAsyncThunk('resume/getL1Approvers',
    async(search:any)=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.get(`${BaseUrl}/master/getL1Approvers?search=${search}`,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response.data;
        }catch(error:any){
            return error.response.data;
        }
    }
)
export const getCandidateInterviewDetails=createAsyncThunk('resume/getCandidateInterviewDetails',
    async(candidateId:any)=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.get(`${BaseUrl}/interview/getInterviewByCandidateId/${candidateId}`,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response.data;
        }catch(error:any){
            return error.response.data;
        }
    }
)

export const addInterview=createAsyncThunk('resume/addInterview',
    async(data:any)=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.post(`${BaseUrl}/interview/addInterview`,data,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response.data;
        } catch(error:any){
            return error.response.data;
        }
    }
)

export const getInterviewDetailsByRecruiterId=createAsyncThunk('resume/getCandidateByCandidateId',
    async({ RecruiterId, rthId, data}:any)=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.post(`${BaseUrl}/interview/getInterviewByRecAndRthId?recruiterId=${RecruiterId}&rthId=${rthId}`,data,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response?.data;
        }catch(error:any){
          return error.response.data;
        }
    }
)

export const getInterviewDetailsByInterviewId=createAsyncThunk('resume/getInterviewDetailsByInterviewId',
    async(interviewId:any)=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.get(`${BaseUrl}/interview/getInterviewById/${interviewId}`,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response?.data;
        }catch(error:any){
          return error.response.data;
        }
    }
)

export const interviewFeedBackL1=createAsyncThunk('resume/interviewFeedBackL1',
    async({interviewId,status,data}:any)=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.put(`${BaseUrl}/interview/setL1FeedBack?interviewId=${interviewId}&status=${status}`,data,
                
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
                
            );
            if (response.status === 200) {
                toast.success('FeedBack Submitted Successfully'); // Show success toast
              }
        
            return response?.data;
        }catch(error:any){
            toast.error('Failed to submit feedback');
            return error.response.data;
        }
    }
)

export const interviewFeedBackL2=createAsyncThunk('resume/interviewFeedBackL2',
    async({interviewId,status,data}:any)=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.put(`${BaseUrl}/interview/setL2FeedBack?interviewId=${interviewId}&status=${status}`,data,
                
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            if (response.status === 200) {
                toast.success('FeedBack Submitted Successfully'); // Show success toast
              }
        
            return response?.data;
        }catch(error:any){
            toast.error('Failed to submit feedback');
            return error.response.data;
        }
    }
)