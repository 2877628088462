import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import React, { useEffect, useState } from "react";

interface Prop {
  currentReportData: any[];
  selectedTab: String;
}

const ReportTableView: React.FC<Prop> = ({ currentReportData, selectedTab }) => {
  const [rows, setRows] = useState<{ [key: string]: string | number | null | undefined }[]>([]);

  const [headerTitle, setHeaderTitle] = useState<string[]>([]);

  useEffect(() => {
    if (selectedTab === "MRF Approval Status") {
      const headerNames = [
        "S no",
        "MRF ID",
        "MRF Title",
        "MRF Category",
        "MRF Subcategory",
        "MRF creation Date",
        "Position",
        "Recruiter Lead",
        "Recruiter",
        "Candidate",
        "MRF Stage",
        "Interview Stage",
        "Interview Status",
        "L1 Interviewer Name",
        "L2 Interviewer Name",
        "Ageing / Hiring Cycle(in days)",
        "Closed date",
      ];
      setHeaderTitle(headerNames);

      const updatedRows = currentReportData?.map((data, index) => ({
        id: index + 1,
        seqId: data.seqId || "N/A",
        positionTitle: data.positionTitle || "N/A",
        typeOfRth: data.typeOfRth || "N/A",
        modeOfRth: data.modeOfRth || "N/A",
        createdAt: data.createdAt || "N/A",
        numOfPosition: data.numOfPosition || "N/A",
        recruiterLead: null,
        recruiter: null,
        firstName: data.firstName || "N/A",
        status: data.rthDetails?.status || "N/A",
        interviewStage: data.interviewStage,
        interviewStatus: data.interviewStatus,
        l1InterviewerName: data.l1InterviewerName,
        l2InterviewerName: data.l2InterviewerName,
        ageing: data.ageing,
        closedDate: data.closedDate,
      }));
      setRows(updatedRows);
    }
  }, [currentReportData, selectedTab]);

  return (
    <Box sx={{ overflowX: "scroll"}}>
    <TableContainer component={Paper} >
      <Table  aria-label="RTH table">        
        <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
          <TableRow
            sx={{
              "& th": {
                padding: "4px 8px", // Adjust padding for header cells
                fontSize: "14px",   // Optional: Adjust font size
                whiteSpace: "nowrap", // Prevent header text wrapping
              },
            }}
          >
            {headerTitle.map((title, index) => (
              <TableCell key={index}>{title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
  
        {/* Table Body */}
        <TableBody
          sx={{
            "& td, & th": {
              padding: "4px 8px", // Adjust padding for body cells
              whiteSpace: "nowrap", // Prevent text wrapping in cells
            },
          }}
        >
          {rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {Object.values(row).map((value, cellIndex) => (
                <TableCell key={cellIndex}>{value}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
  
      
  );
};

export default ReportTableView;