import React, { useRef, useState } from 'react'
import CustomTabs from '../../../common/tabs';
import { Box, Typography } from '@mui/material';
import FilterSidebar from '../../commoncomponent/FilterSidebar';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from 'react-router-dom';
import SearchBar from '../../commoncomponent/SearchBar';
import JobDetails from '../JobDetails';
import { CandidateDetails } from '../rthtable/CandidateDetails';
import FileUploadDialog from './BulkUploadDialog';
import { useAppDispatch } from '../../../../redux/hooks';
import { SelectedFile } from '../../../../redux/features/resumeSlice';
import { InterviewDetailsScheduler } from './resumeCardTabFiles/InterviewDetailsScheduler';


export default function ResumeCardTab() {
    const [tabIndex, setTabIndex] = React.useState(1);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [viewType, setViewType] = useState<'grid' | 'list'>('grid');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<any[]>([]); 
    const [fromBulkUploadClick, setFromBulkUploadClick] = useState(false); 

    const {jobTitle,id}=useParams();
    const navigate=useNavigate();
    const dispatch=useAppDispatch();

const tabsData = [
  { label: 'Job Details' }, 
  { label: 'Candidate' },
  { label: 'Interview Details' },
];

const [teamHeadFilterDto, setTeamHeadFilterDto] = useState({
  l1StatusPending: false,
  l1StatusDeclined: false,
  l2StatusApproved: false,
  internalOnRole: false,
  internalContract: false,
  externalOneTime: false,
  externalContract: false,
  priorityLow: false,
  priorityMedium: false,
  priorityHigh: false,
  rthStages: null,
  createdBy: null,
  recruiterLead: null,
  PositionTitle: null,
  fromYear: 0,
  fromMonth: 0,
  fromDay: 0,
  toYear: 0,
  toMonth: 0,
  toDay: 0,
  page: 0,
  size: 10
});

const fileInputRef = useRef<HTMLInputElement | null>(null);


const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const files = event.target.files;
  const maxSize = 2 * 1024 * 1024; // 2MB in bytes
  const allowedExtensions = ['pdf', 'doc', 'docx']; // Allowed file types

  if (files) {
    const validFiles: File[] = [];
    const invalidFiles: File[] = [];
    const invalidTypes: File[] = [];


    Array.from(files).forEach((file) => {
      const extension = file.name.split('.').pop()?.toLowerCase();

      if (!allowedExtensions.includes(extension || '')) {
        invalidTypes.push(file); // File has an invalid type
      } else if (file.size <= maxSize) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file);
      }
    });

    if (invalidTypes.length > 0) {
      alert(
        `The following files have unsupported file types and were not uploaded:\n${invalidTypes
          .map((file) => file.name)
          .join(', ')}\n\nPlease upload files in PDF, DOC, or DOCX formats only.`

      );
    }

    if (invalidFiles.length > 0) {
      alert(
        `Some files exceed the maximum size of 2MB and were not uploaded: ${invalidFiles
          .map((file) => file.name)
          .join(', ')}\n\nPlease ensure each file is 2MB or smaller.`

      );
    }

    setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);

    if (validFiles.length > 0) {
      if (fromBulkUploadClick) {
        setModalOpen(true); 
      } else {
        dispatch(SelectedFile(validFiles));
        navigate(`/newRequestToHire/${jobTitle}/${id}/Forms`);
      }
    }

    setFromBulkUploadClick(false);
  }
};


const handleViewChange = (view: 'grid' | 'list') => {
  setViewType(view);
};

const handleBulkUploadClick = () => {
  if (fileInputRef.current) {
    setFromBulkUploadClick(true); 
    fileInputRef.current.click(); 
  }
};

const handleButtonClick = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0]; 
  if (fileInputRef.current) {
    setFromBulkUploadClick(false); 
    fileInputRef.current.click(); 
  }
  if (file) {
    dispatch(SelectedFile([file]));
    navigate(`/newRequestToHire/${jobTitle}/${id}/Forms`);
  }
};

const handleCloseModal = () => {
  setModalOpen(false);
};

const handleBackNavigate=()=>{
 navigate(`/dashboard?tab=resume`)
}

 const renderTabContent = () => {
    switch (tabIndex) {
      case 0:
        return <JobDetails drawerOpen={drawerOpen}/>;
      case 1:
        return <CandidateDetails viewType={viewType} handleViewChange={handleViewChange} drawerOpen={drawerOpen} />;
      case 2:
        return <InterviewDetailsScheduler drawerOpen={drawerOpen}/>;
      default:
        return null;
    }
  };
  
  return (
    <Box sx={{ width: '100%', backgroundColor: '#f0f4ff', display: 'flex', position: 'relative', flexDirection:'row' }} >    
    <Box sx={{height:'80vh'}}>    
    <FilterSidebar onChangeFilter={setDrawerOpen} drawerOpen={drawerOpen} tabIndex={tabIndex} tabName={"Resume"} teamHeadFilterDto={teamHeadFilterDto} setTeamHeadFilterDto={setTeamHeadFilterDto}/>
    </Box>
    <Box sx={{ width: '100%', transition: 'margin-left 0.3s' }}>
    <Box>
    <Box sx={{ ml: drawerOpen? '16px' : '100px', mt:'8px', display:'flex', flexDirection:'row', gap:'10px', position:'sticky', top:"8px"}}>
    <KeyboardBackspaceIcon sx={{color:'#948F8F',fontSize:'24px',mt:'4px',cursor:'pointer'}} onClick={handleBackNavigate}/>
    <Typography variant="h6" sx={{fontWeight:'bold'}}>{jobTitle}</Typography>
    </Box>

    <Box sx={{ ml:'4px', position:'sticky', top:"40px", pb:'8px',zIndex:1}}>    
    <CustomTabs
      tabsData={tabsData}
      drawerOpen={drawerOpen} 
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
    />
    </Box>

    {tabIndex === 1 &&(
    <Box sx={{ position:'sticky', top:"100px" }}>
    <SearchBar viewType={viewType} handleViewChange={handleViewChange}handleBulkUploadClick={handleBulkUploadClick} handleButtonClick={handleButtonClick} />
    </Box>
    )}
    
    
    <input
      type="file"
      ref={fileInputRef}
      style={{ display: 'none' }}
      onChange={handleFileChange} 
      accept=".pdf,.doc,.docx"
      multiple
    />
    
    <FileUploadDialog
      open={modalOpen}
      onClose={handleCloseModal}
      selectedFiles={selectedFiles}
      setSelectedFiles={setSelectedFiles}
    />
    </Box>
    {renderTabContent()}
    </Box>
    </Box>
    )
    }
