import React from "react";
import { Paper, Tabs, Tab } from "@mui/material";

interface CustomPaperProps {
  tabIndex: number;
  onTabChange: (event: React.SyntheticEvent, newIndex: number) => void;
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const MasterTab: React.FC<CustomPaperProps> = ({ tabIndex, onTabChange }) => {
  return (
    <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
      <Tabs
        value={tabIndex}
        onChange={onTabChange}
        indicatorColor="primary"
        aria-label="job tabs"
        TabIndicatorProps={{
          style: {
            width: "20px",
            marginLeft: tabIndex === 0 ? "30px" :
            tabIndex === 1 ? "30px" :
            tabIndex === 2 ? "45px" :
            tabIndex === 3 ? "45px" :
            tabIndex === 4 ? "35px" : "0px"},
        }}
      >
        <Tab label="Users" {...a11yProps(0)} sx={{
          textTransform: "none",
          fontSize: "12px", 
          minWidth: "80px",
          textAlign: "center", 
          color: "#A9A9A9", 
          "&:hover": {
            color: "#1677FF", 
            backgroundColor: "rgba(30, 136, 229, 0.1)", 
            borderRadius: "8px", 
          },
        }} />
        <Tab label="Roles" {...a11yProps(1)} sx={{
          textTransform: "none",
          fontSize: "12px", 
          minWidth: "80px",
          textAlign: "center", 
          color: "#A9A9A9", 
          "&:hover": {
            color: "#1677FF", 
            backgroundColor: "rgba(30, 136, 229, 0.1)", 
            borderRadius: "8px", 
          },
        }} />
        <Tab label="Departments" {...a11yProps(2)} sx={{
          textTransform: "none",
          fontSize: "12px", 
          minWidth: "120px",
          textAlign: "center", 
          color: "#A9A9A9", 
          "&:hover": {
            color: "#1677FF", 
            backgroundColor: "rgba(30, 136, 229, 0.1)", 
            borderRadius: "8px", 
          },
        }} />
        <Tab label="Business" {...a11yProps(3)} 
        sx={{
          textTransform: "none",
          fontSize: "12px", 
          minWidth: "100px",
          textAlign: "center", 
          color: "#A9A9A9", 
          "&:hover": {
            color: "#1677FF", 
            backgroundColor: "rgba(30, 136, 229, 0.1)", 
            borderRadius: "8px", 
          },
        }}/>
         {/* <Tab label="Organizations" {...a11yProps(4)} sx={{
          textTransform: "none",
          fontSize: "12px", 
          minWidth: "120px",
          textAlign: "center", 
          color: "#A9A9A9", 
          "&:hover": {
            color: "#1677FF", 
            backgroundColor: "rgba(30, 136, 229, 0.1)", 
            borderRadius: "8px", 
          },
        }} /> */}
      </Tabs>
    </Paper>
  );
};

export default MasterTab;
