import { Box, Paper, Tabs, Tab } from '@mui/material';
import React, { useState } from 'react';

interface prop{
  setSelectedTab:Function;
  tabsData:string[];
}

const ReportTabs: React.FC<prop> = ({setSelectedTab,tabsData}) => {

  // State to track the active tab
  const [activeTab, setActiveTab] = useState<number>(0);
  
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    setSelectedTab(tabsData[newValue]);
  };

  return (
    <Box >
      <Paper elevation={0} sx={{ backgroundColor: 'transparent' }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          aria-label="report tabs"
          TabIndicatorProps={{
            style: {
              width: '20px', // Custom width for the tab indicator
              // new 
              marginLeft: `${'45px'}`,
              // old
              // marginLeft: `${0 === 0 ? '45px' : '36px'}`,
            },
          }}
        >
          {tabsData?.map((tab, index) => (
            <Tab
              key={index}
              label={tab}
              sx={{
                fontSize: '13px', // Custom font size
                textTransform: 'none', // Prevent uppercase transformation
              }}
            />
          ))}
        </Tabs>
      </Paper>
    </Box>
  );
};

export default ReportTabs;