import { Paper, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, Chip } from "@mui/material";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ScheduleInterviewDialog from "./ScheduleInterviewDialog";
import { useState, useEffect } from "react";
import calendarImage from '../../../../../assets/images/calendarIconSchedule.svg';
import { getInterviewDetailsByRecruiterId } from "../../../../../redux/features/interviewSchedule";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { useParams } from "react-router-dom";

export const InterviewDetailsScheduler = ({drawerOpen}:any) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [selectedStep, setSelectedStep] = useState(0);
  const [hoveredId, setHoveredId] = useState<string | null>(null); // Allow string or null
  console.log("hoveredId",hoveredId)
  const dispatch=useAppDispatch();
  const [interviewDetails, setInterviewDetails] = useState<any[]>([]);
  const interview=useAppSelector((state)=>state.interviewDetailSchedule.interviewSchedule)
  const recruiterId = localStorage.getItem('userId');
  const {id} = useParams(); 
  
  const timeSlots = Array.from({ length: 12 }, (_, i) => {
    const hour = 8 + i;
    const period = hour < 12 ? "am" : "pm";
    const displayHour = hour % 12 === 0 ? 12 : hour % 12;
    return `${displayHour} ${period}`;
  });

  const getDaysInMonth = (date: Date): number => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month + 1, 0).getDate();
  };

  const handlePreviousMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() - 1);
    setCurrentDate(newDate);
  };

  const handleNextMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() + 1);
    setCurrentDate(newDate);
  };

  const daysInMonth = Array.from({ length: getDaysInMonth(currentDate) }, (_, i) => {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), i + 1);
    const day = new Intl.DateTimeFormat("en-US", { weekday: "short" }).format(date);
    const monthShort = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date);
    return { day: i + 1, weekday: day, month: monthShort, date: date.toISOString().split("T")[0] };
  });

  const handleCellClick = (day: number, month: string, time: string) => {
    // console.log(`Selected: ${day} ${month} at ${time}`);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

 

  useEffect(() => {
    // Fetch API data
    const fetchInterviewDetails = async () => {
      try {
        const filterOptions = {
          "interviewDateFrom": null,
          "interviewDateTo": null,
          "interviewStages": null,
          "source": null,  
          "expectedCtc": null,
            "noticePeriod": null  
        }
        await dispatch(getInterviewDetailsByRecruiterId({RecruiterId:recruiterId,rthId: id,data: filterOptions}));
      } catch (error) {
        
      }
    };
    fetchInterviewDetails();
  }, [recruiterId,dispatch,id,open]);

  useEffect(()=>{
    if(interview){
      setInterviewDetails(interview);
    }
  },[interview])
  

  return (
    <Paper sx={{ width: drawerOpen? "75vw": "91vw", margin: "auto", mt: 2, p: 2, ml: 3, borderRadius: '10px', boxShadow: 'none' }}>
      {/* Header with month navigation */}
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <IconButton sx={{ width: "20px", height: '20px' }} onClick={handlePreviousMonth}>
            <ArrowBackIosRoundedIcon sx={{ fontSize: '16px', color: '#1A1A1A' }} />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ textAlign: 'center', color: '#1A1A1A', fontSize: '14px', width: '85px' }}>
            {new Intl.DateTimeFormat("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }).format(currentDate)}
          </Typography>
          <IconButton sx={{ width: "20px", height: '20px' }} onClick={handleNextMonth}>
            <ArrowForwardIosRoundedIcon sx={{ fontSize: '16px', color: '#1A1A1A' }} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '16px' }}>
          <Chip sx={{ width: '16px', height: '16px', border: '0.5px solid #C6C5C5', borderRadius: '2px', bgcolor: '#FFEBC2', ml: '50px' }} />
          <Typography sx={{ ml: '6px', mr: '20px', fontWeight: 500, fontSize: '14px', color: '#1A1A1A' }}>Screening</Typography>
          <Chip sx={{ width: '16px', height: '16px', border: '0.5px solid #C6C5C5', borderRadius: '2px', bgcolor: '#F7F1FF' }} />
          <Typography sx={{ ml: '6px', mr: '20px', fontWeight: 500, fontSize: '14px', color: '#1A1A1A' }}>L1 Interview</Typography>
          <Chip sx={{ width: '16px', height: '16px', border: '0.5px solid #C6C5C5', borderRadius: '2px', bgcolor: '#CAF1D9' }} />
          <Typography sx={{ ml: '6px', mr: '20px', fontWeight: 500, fontSize: '14px', color: '#1A1A1A' }}>L2 Interview</Typography>
          <Chip sx={{ width: '16px', height: '16px', border: '0.5px solid #C6C5C5', borderRadius: '2px', bgcolor: '#C2EEF6' }} />
          <Typography sx={{ ml: '6px', mr: '20px', fontWeight: 500, fontSize: '14px', color: '#1A1A1A' }}>HR Round</Typography>
          <Chip sx={{ width: '16px', height: '16px', border: '0.5px solid #C6C5C5', borderRadius: '2px', bgcolor: '#FFD7CE' }} />
          <Typography sx={{ ml: '6px', mr: '20px', fontWeight: 500, fontSize: '14px', color: '#1A1A1A' }}>Other Statuses</Typography>
        </Box>
      </Box>

      {/* Calendar Scheduler Table */}
      <TableContainer sx={{ height: 420, width:'100%',
      overflowX:'auto', '&::-webkit-scrollbar': {
      width: '0px', // Set the scrollbar width
      height: '0px', // Set the scrollbar height (if horizontal)
      }}}>
        <Table>
          {/* Table Header */}
          <TableHead sx={{ position: "sticky", bgcolor: "white", top: 0 }}>
            <TableRow>
              <TableCell
                sx={{ borderRight: "1px solid #E8E8E8", borderTop: "1px solid #E8E8E8" }}
              ></TableCell>
              {timeSlots.map((slot) => (
                <TableCell
                  key={slot}
                  align="center"
                  sx={{
                    color: "#A9A9A9",
                    fontWeight: "normal",
                    borderRight: "1px dashed #E8E8E8",
                    borderTop: "1px solid #E8E8E8",
                  }}
                >
                  {slot}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
          {daysInMonth.map(({ day, weekday, month, date: dayDate }) => (
            <TableRow key={day}>
              <TableCell
                sx={{
                  textAlign: "center",
                  position: 'sticky',
                  left:0,
                  borderRight: "1px solid #E8E8E8",
                  width: "100px",
                  fontWeight: 500,
                  fontSize: "14px",
                  color: "#1A1A1A",
                  bgcolor:'white',
                  zIndex:1
                }}
              >
                {day} {month}
                <br />
                <Typography variant="caption" sx={{ color: "#A9A9A9" }}>
                  {weekday}
                </Typography>
              </TableCell>
              {timeSlots.map((slot, index) => {
                const matchedInterviews = Array.isArray(interviewDetails)
                  ? interviewDetails.flatMap((interview) => {
                      const interviewRounds = Object.keys(interview)
                        .filter((key) => key.startsWith("interviewDetL"))
                        .map((key) => ({
                          ...interview[key],
                          candidateName: interview.candidateName,
                          roundKey: key, // To identify which round it is
                        }))
                        .filter((details) => details.interviewDate); // Only valid interview rounds

                      return interviewRounds.filter((details) => {
                        const interviewDate = new Date(details.interviewDate);
                        interviewDate.setDate(interviewDate.getDate() - 1); // Adjust for one day before
                        const adjustedDate = interviewDate.toLocaleDateString("en-CA");

                        const formattedDayDate = new Date(dayDate).toLocaleDateString("en-CA");

                        const interviewFrom = new Date(details.from).toLocaleTimeString("en-US", {
                          hour: "numeric",
                          hour12: true,
                        }).toLowerCase();

                        return adjustedDate === formattedDayDate && interviewFrom === slot.toLowerCase();
                      });
                    })
                  : [];

                return (
                  <TableCell
                    key={`${day}-${index}`}
                    align="center"
                    sx={{
                      borderRight: "1px dashed #E8E8E8",
                      "&:hover": {
                        backgroundColor: "#E8F0FE",
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => handleCellClick(day, month, slot)}
                  >
                    {matchedInterviews?.length > 0 &&
                      matchedInterviews.map((details, idx) => {
                        // Determine styles based on the interview round
                        let boxBgColor = "#F9F4FF";
                        let textColor = "#4A0FB2";

                        if (details.roundKey === "interviewDetL2") {
                          boxBgColor = "#CAF1D9";
                          textColor = "#0E8B3E";
                        } else if (details.roundKey === "interviewDetL3") {
                          boxBgColor = "#C2EEF6";
                          textColor = "#0D6070";
                        }

                        const interviewId = `${day}-${slot}-${idx}`; // Create a unique ID

                        return (
                          <Box
                            key={idx}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              justifyContent: "space-between",
                              padding: "8px",
                              borderRadius: "8px",
                              backgroundColor: boxBgColor,
                              position: "relative",
                              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                              width: "200px",
                              cursor: "pointer",
                            }}
                            onMouseEnter={() => setHoveredId(interviewId)}
                            onMouseLeave={() => setHoveredId(null)}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                color: textColor,
                                fontWeight: 500,
                                marginBottom: "4px",
                              }}
                            >
                              {details.candidateName}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#A9A9A9",
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              <img style={{ width: "14px" }} src={calendarImage} alt="Calendar" />
                              {new Date(details.interviewDate).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                              })}
                              {" | "}
                              {new Date(details.from).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true, // Keeps 12-hour format but excludes 'am'/'pm'
                              }).replace(' AM', '').replace(' PM', '')}
                              {" - "}
                              {new Date(details.to).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </Typography>
                            {/* {hoveredId === interviewId && (
                              <Button
                                variant="contained"
                                sx={{
                                  backgroundColor: textColor,
                                  color: "#FFF",
                                  position: "absolute",
                                  right: "8px",
                                  height: "24px",
                                  top: "6px",
                                  textTransform: "none",
                                  fontSize: "12px",
                                  boxShadow: "none",
                                  "&:hover": {
                                    backgroundColor: textColor,
                                  },
                                }}
                              >
                                Edit
                              </Button>
                            )} */}
                          </Box>
                        );
                      })}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ScheduleInterviewDialog DialogOpen={open} close={handleClose} setSelectedStep={setSelectedStep} selectedStep={selectedStep} />
    </Paper>
  );
};
