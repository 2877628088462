import React, { useState, useEffect } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, TextField, Select, MenuItem, Divider, FormControl, InputLabel } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { fetchUsers, updateUser } from "../../../../redux/features/userSlice";
import { AppDispatch } from "../../../../redux/store";
import { SelectChangeEvent } from "@mui/material";
import { getRoleList } from "../../../../Crud/rth";
import { toast } from "react-toastify";

interface EditUserFormProps {
    open: boolean;
    onClose: () => void;
    user: UserData;
}

export interface UserData {
    id?: string;
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: string;
    department: string;
    designation: string;
    roleName: string;
    roleId: string;
}

const EditUserData: React.FC<EditUserFormProps> = ({ open, onClose, user }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [formData, setFormData] = useState<UserData>(user);
    const [roleData, setRoleData] = useState<{ value: string; label: string }[]>([]);

    useEffect(() => {
        getRoleList()
            .then((res) => {
                const data = res.data?.map((i: any) => ({
                    value: i.roleId,
                    label: i.roleName,
                }));

                setRoleData(data || []);
            })
            .catch((err) => console.error(err));
    }, []);

    const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleRoleChange = (e: SelectChangeEvent<string>) => {
        const { value } = e.target;
        const selectedRole = roleData.find((role) => role.value === value);
        setFormData((prev) => ({
            ...prev,
            roleId: value,
            roleName: selectedRole ? selectedRole.label : "",
        }));
    };

    const handleSubmit = async () => {
        try {
            const { userId, roleId } = formData;
            if (!userId || !roleId) {
                console.error("User ID or Role ID is missing.");
                return;
            }
            await dispatch(updateUser(formData)).unwrap();
            await dispatch(fetchUsers());
            toast.success("Role name updated")
            onClose();
        } catch (error) {
            toast.error("Failed to update the role")
            console.error("Failed to update the user: ", error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                Edit User
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: "absolute", right: 8, top: 8, backgroundColor: "#ECECEC" }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Box component="form" noValidate autoComplete="off" sx={{ mt: 1 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="First Name"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleTextFieldChange}
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Last Name"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleTextFieldChange}
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                value={formData.email}
                                onChange={handleTextFieldChange}
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Mobile No"
                                name="mobileNumber"
                                value={formData.mobileNumber}
                                onChange={handleTextFieldChange}
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Department"
                                name="department"
                                value={formData.department}
                                onChange={handleTextFieldChange}
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth size="small">
                                <InputLabel  sx={{ fontSize: '0.75rem', top: '-6px',  left: '0'}} shrink>Role</InputLabel> 
                                <Select
                                    name="roleId"
                                    value={formData.roleId || ''}
                                    onChange={handleRoleChange}
                                    displayEmpty
                                    sx={{ fontSize: '12px' }}
                                >
                                    {roleData.map((role) => (
                                        <MenuItem key={role.value} value={role.value} sx={{ fontSize: '12px' }}>
                                            {role.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{
                        backgroundColor: "#1677FF",
                        borderRadius: "8px",
                        textTransform: "none",
                    }}
                >
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditUserData;
