import { Box } from '@mui/material';
import { TabsPanelNew } from './commoncomponent/TabpannelNew';
import { useEffect, useState } from 'react';
// import { jwtDecode } from 'jwt-decode';




const Dashboard = () => {

    const [userRole, setUserRole] = useState<string>('');
console.log('userRole', userRole)
    const [tabNamee,setTabNamee]=useState<string>('')

    useEffect(() => {
        const token = localStorage.getItem("token");
        console.log("token sir -> " + token);
    
        if (token) {
          const user = localStorage.getItem("user") ? JSON.parse(localStorage.user) : null;
          const { roleName } = user || {};
    
          if (roleName) {
            setUserRole("role name sir -> " + roleName);
    
            switch (roleName) {
              case "TEAMHEAD":
                setTabNamee("My Requests");
                break;
              case "APPROVERHEAD":
                setTabNamee("Overall RTH");
                break;
              case "RECRUITER":
                setTabNamee("Waiting to Move to Job");
                break;
              case "TALENTACQUISITIONHEAD":
              case "RECRUITERLEAD":
                setTabNamee("To Be Assigned");
                break;
              default:
                setTabNamee("");
            }
          }
        }
      }, []);

      

    

    
    return (
        <Box>
            <Box sx={{ flexDirection: 'row', flex: 1, display: "flex",width:"100%" }}>
                <TabsPanelNew tabNamee={tabNamee}/>                                
            </Box>
        </Box>
    )
}

export default Dashboard;