import React, { useEffect } from "react";
import {
  // Container,
  // Box,
  // Typography,
  // createTheme,
  // ThemeProvider,
  // useMediaQuery,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
// import theronimg from "../assets/images/theronlogoblue.svg";
// import SignupImageBox from "./signupimgbox";
// import bgimg from "../assets/images/login-pgimg.png";
// import circle from "../assets/images/circlelogo.svg";
import microsoft from "../assets/images/microsoft.svg";
import { SSO_LOGIN } from "../constants/Crudurl";
// import { useDispatch } from "react-redux";
// import { AppDispatch } from "../redux/store";
import { socialLoginValid } from "../Crud/auth";
import { jwtDecode } from "jwt-decode";
import { navigateBasedOnPriority } from "../helpers/navigateBasedOnPriority";

// const theme = createTheme({
//   breakpoints: {
//     values: {
//       xs: 0,
//       sm: 600,
//       md: 960,
//       lg: 1280,
//       xl: 1920,
//     },
//   },
// });

// interface UserPayload {
//   id: string;
//   uniqueId: string;
//   email: string;
//   coachVisibility: boolean;
//   supervisorVisibility: boolean;
//   accountabilityVisibility: boolean;
// }

// interface ValidateSocialLoginResponse {
//   data: {
//     user: UserPayload;
//     token: string;
//   };
// }

const SSOLogin: React.FC = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  // const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  // const isXLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  // Debugging: Log the full location object to check if `location.search` is correct
  console.log("Location object:", location);
  // const socialLoginCall=async()=>{
  //   try {
  //     const response =  await axios.get(
  //         `${SSO_DEFAULT}`
  //       );
  //       console.log("response",response);
  //     } catch (error) {
  //       console.error(error)
  //     }
  // }
  // Remove any existing token on component mount
  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("userId");
    // socialLoginCall();
    // socialLoginValid();
  }, []);

  useEffect(() => {
    const refreshPage = () => {
      window.history.go(1);
      window.history.replaceState(null, "", window.location.href);
    };
    refreshPage();
    const handleNavigation = (event: PopStateEvent) => {
      event.preventDefault();
      refreshPage();
      navigate("/", { replace: true });
    };
    window.addEventListener("popstate", handleNavigation);
    return () => {
      window.removeEventListener("popstate", handleNavigation);
    };
  }, [navigate]);
  // Handle the URL params when the component mounts (e.g., after successful login)

  useEffect(() => {
    // Function to handle the URL params extraction and validation logic
    const handleCheck = () => {
      const urlParams = new URLSearchParams(location.search);
      console.log("url", urlParams);
      const tokenParam = urlParams.get("token");
      const imageUrlParam = urlParams.get("imageUrl");

      console.log("Extracted token:", tokenParam);
      console.log("Extracted imageUrl:", imageUrlParam);

      if (tokenParam) {
        try {
          const arrayToken = tokenParam.split(".");
          console.log("arraytoken", arrayToken);

          if (arrayToken.length === 3) {
            const { email, name } = JSON.parse(atob(arrayToken[1]));
            // const email = "amarnath.b@cavininfotech.com";
            // const name = "amarnath";
            // console.log("email", email);
            socialLoginValid(email, name)
              .then((res) => {
                // localStorage.setItem("user",JSON.stringify(res));
                // console.log("res",res);
                if (res?.status === 200) {
                  // navigate("/admin");
                  localStorage.setItem("token", res?.data.accessToken);
                  const decoded: any = jwtDecode(res?.data.accessToken);
                  localStorage.setItem("userId", decoded?.userId);
                  // viewPostedJobs
                  // console.log("ress=====>",res?.data)
                  if (res?.data?.role?.roleName === "EMPLOYEE") {
                    navigate("/viewPostedJobs");
                  } else {
                    console.warn("res====>",res?.data?.role?.roleName)
                    if (res?.data?.role?.roleName === "ADMIN") {
                      navigate("/masters");
                    } else {
                      navigateBasedOnPriority(
                        res?.data?.role?.permissionsList,
                        navigate
                      );
                    }
                  }

                  localStorage.setItem("user", JSON.stringify(res?.data?.role));
                }
              })
              .catch((err) => console.log(err));
            // Dispatch validation action and handle response
            // dispatch(validateSocialLogin1({ email, name, imageUrl: imageUrlParam || "", token: tokenParam }))
            //   .then((result: any) => {
            //     if (result.payload && (result.payload as ValidateSocialLoginResponse).data) {
            //       const { id, uniqueId, email, coachVisibility, supervisorVisibility, accountabilityVisibility } =
            //         (result.payload as ValidateSocialLoginResponse).data.user;
            //       const token = (result.payload as ValidateSocialLoginResponse).data.token;

            //       // Store user data in local storage
            //       localStorage.setItem(
            //         "user",
            //         JSON.stringify({
            //           token,
            //           userId: id,
            //           uniqueId,
            //           email,
            //           coachVisibility,
            //           supervisorVisibility,
            //           accountabilityVisibility,
            //         })
            //       );

            //       // Redirect to dashboard if valid data is found
            //       if (token && uniqueId && id) {
            //         navigate("/dashboard");
            //       }
            //     }
            //   })
            //   .catch((error: any) => {
            //     console.error("Promise rejected with error:", error);
            //     toast.error("Failed to authenticate.");
            //   });
          } else {
            console.error("Invalid token format:", tokenParam);
          }
        } catch (error) {
          console.error("Error decoding token:", error);
        }
      } else {
        console.error("No token parameter found in URL");
      }
    };

    // Run the handleCheck function when the component mounts
    handleCheck();
  }, [location.search, navigate]); // Depend on location.search to handle changes

  return (
    <a href={SSO_LOGIN} style={{ color: "#fff", textDecoration: "none" }}>
      <Button
        fullWidth
        sx={{
          mb: 2,
          mt: 2,
          borderRadius: 2,
          background: "#2F5BE9",
          color: "#fff",
          height: "55px",
          transition: "all 0.3s ease",
          "&:hover": {
            background: "#1a46d4",
            // transform: "translateY(-2px)", // Optional: slight lift effect
            // boxShadow: "0 4px 8px rgba(47, 91, 233, 0.2)" // Optional: subtle shadow on hover
          },
        }}
        onClick={() => {}}
      >
        <img
          src={microsoft}
          alt="Microsoft"
          style={{ height: 24, marginRight: 8 }}
        />
        Login with Microsoft
      </Button>
    </a>
    // <ThemeProvider theme={theme}>
    //   <Container
    //     component="main"
    //     maxWidth="xl"
    //     sx={{ backgroundColor: "#f0f4ff" }}
    //   >
    //     <Box
    //       display="flex"
    //       flexDirection={isSmallScreen || isMediumScreen ? "column" : "row"}
    //       height="100vh"
    //     >
    //       {!isSmallScreen && !isMediumScreen && <SignupImageBox />}
    //       <Box
    //         flex={isSmallScreen || isMediumScreen ? "none" : 0.7}
    //         display="flex"
    //         alignItems="center"
    //         justifyContent="center"
    //         sx={{
    //           backgroundImage: `url(${bgimg})`,
    //           backgroundSize: "cover",
    //           backgroundPosition: "center",
    //           padding: isSmallScreen || isMediumScreen ? "1rem" : "3rem",
    //           height: isSmallScreen || isMediumScreen ? "90%" : "84vh",
    //         }}
    //       >
    //         <Box
    //           sx={{
    //             width: "100%",
    //             maxWidth: isXLargeScreen ? 400 : 340,
    //             display: "flex",
    //             flexDirection: "column",
    //             justifyContent: "center",
    //             minHeight: "85vh",
    //           }}
    //         >
    //           <img
    //             src={theronimg}
    //             alt="Theron"
    //             style={{
    //               height: isXLargeScreen ? 120 : 55,
    //               marginBottom: "1rem",
    //             }}
    //           />
    //           <Typography
    //             variant={isLargeScreen || isXLargeScreen ? "h5" : "h6"}
    //             textAlign="center"
    //             sx={{
    //               fontSize: "38px",
    //               background:
    //                 "linear-gradient(92.12deg, #36CACA -7.67%, #3066E6 98.55%)",
    //               backgroundClip: "text",
    //               WebkitBackgroundClip: "text",
    //               color: "transparent",
    //               WebkitTextFillColor: "transparent",
    //             }}
    //           >
    //             Welcome to Theron
    //           </Typography>
    //           <Typography
    //             variant={isLargeScreen || isXLargeScreen ? "h5" : "h6"}
    //             textAlign={"center"}
    //             gutterBottom
    //             sx={{ color: "#B3C3F6", fontSize: "15px" }}
    //           >
    //             Interview Smarter, Hire Better
    //           </Typography>
    //           <a
    //             href={SSO_LOGIN}
    //             style={{ color: "#fff", textDecoration: "none" }}
    //           >
    //           <Button
    //               fullWidth
    //               sx={{
    //                 mb: 2,
    //                 mt: 2,
    //                 borderRadius: 2,
    //                 background: "#2F5BE9",
    //                 color: "#fff",
    //                 height: "55px",
    //                 transition: "all 0.3s ease",
    //                 "&:hover": {
    //                   background: "#1a46d4",
    //                   // transform: "translateY(-2px)", // Optional: slight lift effect
    //                   // boxShadow: "0 4px 8px rgba(47, 91, 233, 0.2)" // Optional: subtle shadow on hover
    //                 }
    //               }}
    //               onClick={() => {  }}
    //             >

    //             <img
    //               src={microsoft}
    //               alt="Microsoft"
    //               style={{ height: 24, marginRight: 8 }}
    //             />
    //             Login with Microsoft

    //           </Button>
    //           </a>
    //           <Typography
    //             variant={isLargeScreen || isXLargeScreen ? "h5" : "h6"}
    //             textAlign={"center"}
    //             gutterBottom
    //             sx={{
    //               color: "#B3C3F6",
    //               fontSize: "15px",
    //               display: "flex",
    //               alignItems: "center",
    //               justifyContent: "center",
    //               gap: "8px",
    //             }}
    //           >
    //             <img src={circle} alt="logo" />
    //             2024 all rights reserved
    //           </Typography>
    //         </Box>
    //       </Box>
    //     </Box>
    //   </Container>
    // </ThemeProvider>
  );
};

export default SSOLogin;
