import React from "react";
import { Box, Typography } from "@mui/material";

import priorityHighSymbol from "../../../assets/images/priorityHighSymbol.svg";
import priorityMediumSymbol from "../../../assets/images/priorityMediumSymbol.svg";
import priorityLowSymbol from "../../../assets/images/priorityLowSymbol.svg";
import { calculateDays } from "../../../helpers/calculateDays";

interface JobTimeProps {
  posted?: string;
  priority?: string;
  seqId?: string;
  createdAt?: string;
}

const JobCardTime: React.FC<JobTimeProps> = ({
  posted,
  priority,
  seqId,
  createdAt,
}) => {
  // console.log("createdAt", createdAt);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "5px",
        margin: "0 5px",
        paddingBottom: "5px",
      }}
      gap={1}
    >
      {/* <AccessTimeIcon sx={{ fontSize: '16px', marginLeft: '1px', color: '#8C8C8C' }} /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems:"center",
          marginLeft: "1px",
        }}
      >
        <Typography
          sx={{ fontWeight: "bold", fontSize: "10px", color: "#A3A1A1" }}
        >
          #{seqId} |{" "}
        </Typography>
        <Typography
          color="textSecondary"
          sx={{ paddingLeft: "1px", fontSize: "10px", color: "#8C8C8C" }}
        >
          {`   posted ${
            createdAt ? calculateDays(createdAt) : "No date"
          }`}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        gap={1}
      >
        {priority === "High" ? (
          <Box>
            <img src={priorityHighSymbol} alt="" />
          </Box>
        ) : priority === "Medium" ? (
          <Box>
            <img src={priorityMediumSymbol} alt="" />
          </Box>
        ) : (
          <Box>
            <img src={priorityLowSymbol} alt="" />
          </Box>
        )}
        <Typography
          sx={{
            color:
              priority === "High"
                ? "#F04E4E"
                : priority === "Medium"
                ? "#FFA940"
                : "#73D13D",
            fontSize: "100%",
          }}
        >
          {priority}
        </Typography>
      </Box>
    </Box>
  );
};

export default JobCardTime;
