import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Button, Rating, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import InterviewEvaluationFormLogo from '../../../../../assets/images/InterviewEvaluationFormLogo.svg';
import BackGroundImage from '../../../../../assets/images/bgImageOfInterviewEvaluationForm.svg';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { getCandidateByCandidateId } from '../../../../../redux/features/resume';
import { getInterviewDetailsByInterviewId, interviewFeedBackL1, interviewFeedBackL2 } from '../../../../../redux/features/interviewSchedule';
 
interface Skill {
  name: string;
  rating: number;
  experience: string; // e.g., 'Low', 'Fair', etc.
  technicalConcepts: string;
  developmentTools: string;
  logic: string;
  experienceEmoji: string;
  technicalConceptsEmoji: string;
  developmentToolsEmoji: string;
  logicEmoji: string;
}
 
// interface nonTechSkill {
//   rating: string;
// }
 
interface Skills {
  "Communication Level": { rating: string };
  "Attention to Details": { rating: string };
  Integrity: { rating: string };
  Cooperation: { rating: string };
  "Stress Tolerance": { rating: string };
  "Learning Ability": { rating: string };
}
 
  export const InterviewEvaluationForm = () => {
  const [javaApiEvaluation, setJavaApiEvaluation] = useState('');
  // const [rating, setRating] = useState<any>(0);
  const [canChangeRating, setCanChangeRating] = useState<any>({}); // New state to control rating changes
  const [interviewScore, setInterviewScore] = useState<number | undefined>(undefined);
  const [recommendation, setRecommendation] = useState<string | undefined>(undefined);
  // const [finalRecommendationRating, setFinalRecommendationRating] = useState(0); // State for Final Recommendation
  // const [canChangeFinalRating, setCanChangeFinalRating] = useState(true); // State to control rating change
 
//  console.log('finalRecommendationRating', finalRecommendationRating)
  const Navigate = useNavigate();
 
  // New state for emojis
  const [javaApiEmoji, setJavaApiEmoji] = useState('');
  const [open, setOpen] = React.useState(false);
  const [skillName, setSkillName] = React.useState('');
  const [techSkills,setTechSkills]=useState<Skill[]>([]);
  const [skills, setSkills] = useState({
    "Communication Level": { rating: "" },
    "Attention to Details": { rating: "" },
    "Integrity": { rating: "" },
    "Cooperation": { rating: "" },
    "Stress Tolerance": { rating: "" },
    "Learning Ability": { rating: "" }
  });
  const [ratings, setRatings] = useState<any>({});
  const [sectionType,setSectionType]=useState('');
  const [selectedButton, setSelectedButton] = useState(null);
  const state=useAppSelector((state:any)=>state.resume.candidate);
  const interview=useAppSelector((state:any)=>state.interviewSchedule.interviewDetailsByInterviewId);
  const{candidateId,interviewId,jobTitle,candidateName,id}=useParams();
  const { interviewRound } = useParams<{ interviewRound ?: string }>();
  const [selectedInterview, setSelectedInterview] = useState<any>(null); // State to store the selected interview details
  const dispatch=useAppDispatch();
  const status=useAppSelector((state)=>state.interviewSchedule.status);
  const navigate=useNavigate();
  // const [feedbackData,setFeedbackData]=useState<any>([]);
 
  const getEmoji = (label: string) => {
    switch (label) {
      case 'Low':
        return '🙁'; // Sad face
      case 'Fair':
        return '☹️'; // Neutral face
      case 'Average':
        return '🙂'; // Relaxed face
      case 'Good':
        return '😀'; // Smiley face
      case 'Excellent':
        return '🤩'; // Heart eyes
      default:
        return '';
    }
  };
 
  const handleNavigate = () =>{
    Navigate(-1)
  }
 
  const handleButtonClickOthers = (buttonName:any, setEvaluation:any,  setEmoji: any) => {
    setEvaluation(buttonName);
    setEmoji(getEmoji(buttonName));
  };
 
 
  const handleResetSkill = (skill:any) => {
    setRatings({ ...ratings, [skill]: 0 }); // Reset rating for the specific skill
    setCanChangeRating({ ...canChangeRating, [skill]: true });
  };
 
  // Function to handle button click
  const handleButtonClick = (fieldName: string, value: string, index: number) => {
    setTechSkills((prevSkills) => {
      const updatedSkills = [...prevSkills];
      updatedSkills[index] = {
        ...updatedSkills[index],
        [fieldName]: value, // Update the specific field
        [`${fieldName}Emoji`]: getEmoji(value), // Get emoji using the getEmoji function
      };
      return updatedSkills;
    });
  };
 
 
  const handleClickOpen = (type:string) => {
    setOpen(true);
    setSectionType(type);
  };
 
  const handleClose = () => {
    setOpen(false);
    setSkillName("");
  };
 
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSkillName(event.target.value);
  };
 
  const handleSubmit = () => {
   
    switch(sectionType){
      case 'non-technical':
    if (skillName) {
      // const newSkill={
      //   name:skillName,
      //   rating:0,
      // }
      // setSkills([...skills, newSkill]);
      // // setRatings({ ...ratings, [skillName]: 0 });
      // // setCanChangeRating({ ...canChangeRating, [skillName]: true });
      // setSkillName('');
      // handleClose();
   
  }
  break;
  case 'Technical':
    if(skillName){
      const newSkill = {
        name:skillName, // default name or you can prompt the user
        rating: 0,
        experience: "",
        technicalConcepts: "",
        developmentTools: "",
        logic: "",
        experienceEmoji: '',
        technicalConceptsEmoji: '',
        developmentToolsEmoji: '',
        logicEmoji: '',
      };
      setTechSkills([...techSkills,newSkill]);
      setRatings({ ...ratings, [skillName]: 0 });
      setCanChangeRating({ ...canChangeRating, [skillName]: true });
      setSkillName('');
      handleClose();
   
  }
    break;    
  }
  };
 
  const handleRatingChange = (skill:any, newValue:any) => {
    if (canChangeRating) {
      setRatings({ ...ratings, [skill]: newValue });
      setCanChangeRating({ ...canChangeRating, [skill]: false });
    }
  };
 
  const handleRatingChangeNonTechnical=(skill:any,newValue:any)=>{
    setRatings({ ...ratings, [skill]: newValue });
    setSkills((prevskills)=>({
      ...prevskills,
      [skill]:{rating:newValue.toString()},
    }))
  };
 
  const handleClick = (button:any) => {
    setSelectedButton(button);
  };
 
  const toCamelCase = (str: string) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
        index === 0 ? match.toLowerCase() : match.toUpperCase()
      )
      .replace(/\s+/g, '');
  };
 
  const hanldeFormSubmit = () => {
    // Ensure type safety by asserting the type of skillKey as keyof Skills
    const updatedNonTechSkills = Object.keys(skills).reduce((acc, skillKey) => {
      // Assert that skillKey is a key of the Skills type
      const key = skillKey as keyof Skills;
   
      // Convert the skill name to camelCase and add to the accumulator
      const camelCaseKey = toCamelCase(key);
      acc[camelCaseKey] = String(skills[key].rating); // Convert rating to string
   
      return acc;
    }, {} as { [key: string]: string });
   
 
    const updatedTechSkills = techSkills.map(skill => ({
      technologyName:skill.name,
      rating: skill.rating.toString(),
      experience:skill?.experience,
      technicalConcepts:skill?.technicalConcepts,
      logics:skill?.logic,
      developmentTools:skill?.developmentTools
    }));
    const nonTechnicalEvaluationList = [
      {
        communicationLevel: updatedNonTechSkills.communicationLevel || 'string',
        attentionToDetails: updatedNonTechSkills.attentionToDetails || 'string',
        integrity: updatedNonTechSkills.integrity || 'string',
        coOperation: updatedNonTechSkills.coOperation || 'string',
        stressTolerance: updatedNonTechSkills.stressTolerance || 'string',
        learningAbility: updatedNonTechSkills.learningAbility || 'string'
      }
    ];
 
    // Prepare Technical Skills List
    const technicalEvalutionList = updatedTechSkills.map(skill => ({
      technologyName: skill.technologyName || 'string',
      rating: skill.rating || 'string',
      experience: skill.experience || 'string',
      technicalConcepts: skill.technicalConcepts || 'string',
      logics: skill.logics || 'string',
      developmentTools: skill.developmentTools || 'string'
    }));
 
    const otherSkills = {
      windowViolation: String(selectedButton || 'string'),
      interviewerComments: String(javaApiEvaluation || 'string'),
      interviewScore: String(interviewScore || 'string'),
      recommended: String(recommendation || 'string')
    };
 
   
  const payload={
    technicalEvalutionList,
    nonTechnicalEvaluationList,
    ...otherSkills
  }
 
  if(interviewRound==='interviewDetL1'){
    dispatch(interviewFeedBackL1({interviewId:interviewId,status:status,data:payload}));
    navigate(`/newRequestToHire/${jobTitle}/${id}/candidate/${candidateName}/${candidateId}`);
  }
  else if(interviewRound==='interviewDetL2'){
    dispatch(interviewFeedBackL2({interviewId:interviewId,status:status,data:payload}));
    navigate(`/newRequestToHire/${jobTitle}/${id}/candidate/${candidateName}/${candidateId}`);
  }
     
  };
 
 
  const formatTimeRange = (from: string, to: string) => {
    const formatTime = (time: string) => {
      const date = new Date(time);
      const hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const period = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
      return `${formattedHours}:${minutes}${period}`;
    };
 
    return `${formatTime(from)} To ${formatTime(to)}`;
  };
 
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
   
    // Get the day, month, and year
    const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits for day
    const month = date.toLocaleString('default', { month: 'short' }); // Get abbreviated month
    const year = date.getFullYear();
 
    return `${day} ${month} ${year}`;
  };
 
 
  useEffect(()=>{
    dispatch(getCandidateByCandidateId(candidateId));
    dispatch(getInterviewDetailsByInterviewId(interviewId));
  },[candidateId,interviewId,dispatch])
 
  // useEffect(()=>{
  //  if(interview){
  //   let roundData=null;
 
  //   if(interviewRound==='interviewDetL1'){
  //     roundData=interview?.interviewDetL1?.feedbackForm;
  //   } else if(interviewRound==='interviewDetL2'){
  //     roundData=interview?.interviewDetL2?.feedbackForm;
  //   } else if(interviewRound==='interviewDetL3'){
  //     roundData=interview?.interviewDetL3?.feedbackForm;
  //   }
  //   if (roundData) {
  //     // If roundData is found, log it to the console
  //     setFeedbackData(roundData);
  //   } else {
  //     console.log('No matching interview round found');
  //   }
  //  }
 
  // },[interview])
 
  useEffect(() => {
   
    const selectedInterview = interviewRound && interview ? interview[interviewRound] : null;
    setSelectedInterview(selectedInterview);
  }, [interview, state,interviewRound]);
 
  // useEffect(()=>{
 
  //   const nonTechnicalEvaluation=feedbackData?.nonTechnicalEvaluationList[0];
  //   console.log(nonTechnicalEvaluation?.communicationLevel,'nonTechnical');
   
  //   const updatedSkills = {
  //     "Communication Level": { rating: nonTechnicalEvaluation?.communicationLevel || "" },
  //     "Attention to Details": { rating: nonTechnicalEvaluation?.attentionToDetails || "" },
  //     "Integrity": { rating: nonTechnicalEvaluation?.integrity || "" },
  //     "Cooperation": { rating: nonTechnicalEvaluation?.coOperation || "" },
  //     "Stress Tolerance": { rating: nonTechnicalEvaluation?.stressTolerance || "" },
  //     "Learning Ability": { rating: nonTechnicalEvaluation?.learningAbility || "" },
  //   };
 
  //   setSkills(updatedSkills);
  // },[feedbackData])
 
    return (
    <>
    <Box sx={{backgroundImage: `url(${BackGroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', overflowY:'auto', width:"100vw" , height:'100vh'}}>
    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',  mt:'50px' }}>
      <KeyboardBackspaceRoundedIcon onClick={handleNavigate} sx={{position:'fixed', left:'30px', top:'30px', cursor:'pointer', transition: 'transform 0.2s ease-in-out', '&:hover': { transform: 'scale(1.1)'}}}/>
      {/* Header Section */}
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <img src={InterviewEvaluationFormLogo} style={{ width: '40px' }} alt='InterviewEvaluationFormLogo' />
        <Typography sx={{ fontSize: '26px', fontWeight: 700, mt: '30px' }}>Java Lead Engineer</Typography>
        <Typography sx={{ fontSize: '12px', color: '#919EAB', fontWeight: 500, mt: '12px' }}>Interview Evaluation Form</Typography>
      </Box>    
     
      {/* Candidate & Interview Details Section */}
      <Paper sx={{ width: '840px', mt: '40px', p: 3, mb: 4, boxShadow:'none' }}>
        <Box sx={{display:"flex", flexDirection:'row', justifyContent:'space-between'}}>
          <Box sx={{ display:"flex", flexDirection:'row' }}>
            <Box sx={{ ml:'30px', mr:'60px'}}>
              <Typography sx={{ color: '#919EAB', mb:'10px' }}>Candidate Name</Typography>
              <Typography sx={{ color: '#919EAB', mb:'10px' }}>Interviewer Name</Typography>
              <Typography sx={{ color: '#919EAB', mb:'10px' }}>Job Title</Typography>
              <Typography sx={{ color: '#919EAB', mb:'10px' }}>Interview Round</Typography>
            </Box>
            <Box>
              <Typography sx={{mb:'10px'}}>{`${state.firstName} ${state.lastName}`}</Typography>
              <Typography sx={{mb:'10px'}}>{selectedInterview?.interviewer?.userName||'Not Available'}</Typography>
              <Typography sx={{mb:'10px'}}>{jobTitle}</Typography>
              <Typography sx={{mb:'10px'}}>{selectedInterview?.interviewRound}</Typography>
            </Box>
          </Box>
          <Box sx={{ display:"flex", flexDirection:'row' }}>
            <Box sx={{ mr:'60px'}}>
              <Typography sx={{ color: '#919EAB', mb:'10px' }}>Mode of Interview</Typography>
              <Typography sx={{ color: '#919EAB', mb:'10px' }}>Candidate Email ID</Typography>
              <Typography sx={{ color: '#919EAB', mb:'10px' }}>Interview Date</Typography>
              <Typography sx={{ color: '#919EAB', mb:'10px' }}>Interview Time</Typography>
            </Box>
            <Box sx={{mr:'30px'}}>
              <Typography sx={{mb:'10px'}}>{selectedInterview?.interviewMode}</Typography>
              <Typography sx={{mb:'10px'}}>{state?.emailAddress||'Null'}</Typography>
              <Typography sx={{mb:'10px'}}>{formatDate(selectedInterview?.interviewDate)}</Typography>
              <Typography sx={{mb:'10px'}}>{formatTimeRange(selectedInterview?.from,selectedInterview?.to)}</Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
 
      {/* Technical Evaluation Section */}
      <Paper sx={{ width: '840px', p: 3, pb:0, boxShadow:'none' }}>
        <Box sx={{display:'flex', flexDirection:"row", justifyContent:'space-between', alignItems:'center', mb:'40px', position:'sticky', top:0, backgroundColor: 'white', zIndex: 1}}>
          <Box>
            <Typography sx={{ fontSize: '18px', fontWeight: 600, mb: '6px' }}>Technical Evaluation (Skills)</Typography>
            <Typography sx={{ fontSize: '14px', color: '#5C6F82'}}>
              Please Evaluate the candidate on the following on a scale of 1 to 5 (1 lowest, 5 highest)
            </Typography>
          </Box>
          <Typography onClick={()=>handleClickOpen('Technical')} sx={{ fontSize: '14px', fontWeight: 600, mb: '6px', color:'#1677FF',cursor:'pointer' }}>+ New Skills</Typography>
        </Box>
 
        {/* Skill 1: Java API */}
        {techSkills?.map((skill:any, index:any) => (
        <Box sx={{ mb: 4, mt: 3 }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 600, mb: "10px"}}>{skill?.name}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', borderBottom:'1px dashed #DBDBDB', pb:'10px'}}>
          <Typography variant="body2" sx={{ textWrap: 'nowrap'}}>Rating</Typography>
          <Rating
            name={`${skill.name}-rating`}
            value={ratings[skill.name]}
            onChange={(event, newValue) => handleRatingChange(skill.name, newValue)}
            onMouseOver={(event) => {
              if (!canChangeRating) {
                event.preventDefault(); // Prevent hover effect when canChangeRating is false
              }
            }}
 
            precision={1}
            sx={{ ml: '120px' }}
            icon={<StarIcon />}
          />
          <Typography sx={{ ml: 1 }}>{ratings[skill.name]}/5</Typography>
          <Button
          variant="outlined"
          size="small"
          onClick={()=>handleResetSkill(skill.name)}
          sx={{
            ml: 2,
            borderRadius: '40px',
            fontSize: '10px',
            color: ratings[skill.name] > 0 ? 'black' : '#DBDBDB',
            textTransform:'none',
            border: '1px solid #DBDBDB',
            ":hover": {
              border: '1px solid #DBDBDB',
              color: 'black',
            },
          }}
          startIcon={<CachedIcon/>} // Add the icon here
        >
          Reset
        </Button>
        </Box>
 
 
        {/* Criteria Evaluation */}
        <Box sx={{display:'flex', borderBottom:'1px dashed #DBDBDB', pb:'10px', pt:'10px'}}>
        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%' }}>    
        <Box sx={{display:'flex', flexDirection:'row', }}>
        <Typography variant="body2" sx={{textWrap:'nowrap', display:'flex', alignItems:'center'}}>Experience</Typography>
        <Box sx={{ display: 'flex', gap: '10px', ml:'94.5px' }}>
          {['Low', 'Fair', 'Average', 'Good', 'Excellent'].map((label) => (
            <Button
              key={label}
              variant="outlined"
              size="small"
              sx={{                      
                color: skill.experience === label ? '#2F54EB' : '#919EAB',
                borderColor: skill.experience === label ? '#2F54EB' : '#919EAB',
                textTransform:'none',
                ":hover":{ borderColor: skill.experience === label ? '#2F54EB' : '#919EAB', color: skill.experience === label ? '#2F54EB' : '#919EAB' }
              }}
              onClick={() => handleButtonClick('experience',label, index)}
            >
              {label}
            </Button>
          ))}
          </Box>
        </Box>
        <Box sx={{mr:'20px', display:'flex', alignItems:'center'}}>
        <Typography sx={{ fontSize: '20px', ml: '20px' }}>{skill.experienceEmoji}</Typography>
        </Box>
        </Box>
      </Box>
 
      {/* Technical Concepts */}
      <Box sx={{display:'flex', borderBottom:'1px dashed #DBDBDB', pb:'10px', pt:'10px'}}>
        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
          <Box sx={{display:'flex', flexDirection:'row'}}>
            <Typography variant="body2" sx={{textWrap:'nowrap', display:'flex', alignItems:'center'}}>Technical Concepts</Typography>
            <Box sx={{ display: 'flex', gap: '10px', ml:'39px' }}>
              {['Low', 'Fair', 'Average', 'Good', 'Excellent'].map((label) => (
                <Button
                  key={label}
                  variant="outlined"
                  size="small"
                  sx={{                      
                    color: skill.technicalConcepts=== label ? '#2F54EB' : '#919EAB',
                    borderColor: skill.technicalConcepts === label ? '#2F54EB' : '#919EAB',
                    textTransform:'none',
                    ":hover": { borderColor: skill.technicalConcepts === label ? '#2F54EB' : '#919EAB', color: skill.technicalConcepts === label ? '#2F54EB' : '#919EAB' }
                  }}
                  onClick={() => handleButtonClick('technicalConcepts',label,index)}
                >
                  {label}
                </Button>
              ))}
            </Box>
          </Box>
          <Box sx={{mr:'20px', display:'flex', alignItems:'center'}}>
          <Typography sx={{ fontSize: '20px', ml: '20px' }}>{skill.technicalConceptsEmoji}</Typography>
          </Box>
        </Box>
      </Box>
 
      {/* Logic */}
      <Box sx={{display:'flex', borderBottom:'1px dashed #DBDBDB', pb:'10px', pt:'10px'}}>
        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
          <Box sx={{display:'flex', flexDirection:'row'}}>
            <Typography variant="body2" sx={{textWrap:'nowrap', display:'flex', alignItems:'center'}}>Logic</Typography>
            <Box sx={{ display: 'flex', gap: '10px', ml:'129px' }}>
              {['Low', 'Fair', 'Average', 'Good', 'Excellent'].map((label) => (
                <Button
                  key={label}
                  variant="outlined"
                  size="small"
                  sx={{                      
                    color: skill.logic === label ? '#2F54EB' : '#919EAB',
                    borderColor: skill.logic === label ? '#2F54EB' : '#919EAB',
                    textTransform:'none',
                    ":hover": { borderColor: skill.logic === label ? '#2F54EB' : '#919EAB', color: skill.logic === label ? '#2F54EB' : '#919EAB' }
                  }}
                  onClick={() => handleButtonClick('logic',label,index)}
                >
                  {label}
                </Button>
              ))}
            </Box>
          </Box>
          <Box sx={{mr:'20px', display:'flex', alignItems:'center'}}>
          <Typography sx={{ fontSize: '20px', ml: '20px' }}>{skill.logicEmoji}</Typography>
          </Box>
        </Box>
      </Box>
 
      {/* Development Tools */}
      <Box sx={{display:'flex', borderBottom:'1px dashed #DBDBDB', pb:'10px', pt:'10px'}}>
        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
          <Box sx={{display:'flex', flexDirection:'row'}}>
            <Typography variant="body2" sx={{textWrap:'nowrap', display:'flex', alignItems:'center'}}>Development Tools</Typography>
            <Box sx={{ display: 'flex', gap: '10px', ml:'42px' }}>
              {['Low', 'Fair', 'Average', 'Good', 'Excellent'].map((label) => (
                <Button
                  key={label}
                  variant="outlined"
                  size="small"
                  sx={{                      
                    color: skill.developmentTools === label ? '#2F54EB' : '#919EAB',
                    borderColor: skill.developmentTools === label ? '#2F54EB' : '#919EAB',
                    textTransform:'none',
                    ":hover": { borderColor: skill.developmentTools === label ? '#2F54EB' : '#919EAB', color: skill.developmentTools === label ? '#2F54EB' : '#919EAB' }
                  }}
                  onClick={() => handleButtonClick('developmentTools',label, index)}
                >
                  {label}
                </Button>
              ))}
            </Box>
          </Box>
          <Box sx={{mr:'20px', display:'flex', alignItems:'center'}}>
          <Typography sx={{ fontSize: '20px', ml: '20px' }}>{skill.developmentToolsEmoji}</Typography>
          </Box>
        </Box>
      </Box>
 
        </Box>
        ))}
      </Paper>
     
     {/* Non-Technical */}
      <Paper sx={{ width: '840px', mt: '40px', p: 3, pb:0, mb: 4, boxShadow:'none' }}>
      <Box sx={{display:'flex', flexDirection:"row", justifyContent:'space-between', alignItems:'center', mb:'40px', position:'sticky', top:0, backgroundColor: 'white', zIndex: 1}}>
          <Box>
            <Typography sx={{ fontSize: '18px', fontWeight: 600, mb: '6px' }}>Non-Technical Evaluation</Typography>
            <Typography sx={{ fontSize: '14px', color: '#5C6F82'}}>
            Please Evaluate the candidate on the following on a scale of 1 to 5 (1 lowest, 5 highest)
            </Typography>
          </Box>
          {/* <Typography onClick={()=>handleClickOpen('non-technical')} sx={{ fontSize: '14px', fontWeight: 600, mb: '6px', color:'#1677FF',cursor:'pointer' }}>+ New Skills</Typography> */}
      </Box>
       
      {Object.keys(skills).map((skill: string, index: number) => (
  <Box sx={{ display: 'flex', alignItems: 'center', borderBottom:'1px dashed #DBDBDB', pb:'10px'}} key={index}>
    <Typography variant="body2" sx={{ minWidth:'180px', textWrap: 'nowrap', pt:'10px', pb:'10px'}}>
      {skill} {/* Display the skill name */}
    </Typography>
    <Rating
      name={`${skill}-rating`}
      value={ratings[skill] || 0}
      onChange={(event, newValue) => handleRatingChangeNonTechnical(skill, newValue)}
      onMouseOver={(event) => {
        if (!canChangeRating) {
          event.preventDefault();
        }
      }}
      precision={1}
      icon={<StarIcon />}
    />
    <Typography sx={{ ml: 1 }}>
      {ratings[skill] || 0}/5
    </Typography>
    <Button
      variant="outlined"
      size="small"
      onClick={() => handleResetSkill(skill)}
      sx={{
        ml: 2,
        borderRadius: '40px',
        fontSize: '10px',
        color: ratings[skill] > 0 ? 'black' : '#DBDBDB',
        textTransform: 'none',
        border: '1px solid #DBDBDB',
        ":hover": {
          border: '1px solid #DBDBDB',
          color: 'black',
        },
      }}
      startIcon={<CachedIcon />}
    >
      Reset
    </Button>
  </Box>
))}
 
      </Paper>  
 
      {/* Others */}
 
      <Paper sx={{ width: '840px', p: 3, boxShadow:'none' }}>
       
       <Box sx={{display:'flex', flexDirection:"row", justifyContent:'space-between', alignItems:'center', position:'sticky', top:0, backgroundColor: 'white', zIndex: 1}}>
       <Box>
       <Typography sx={{ fontSize: '18px', fontWeight: 600, mb: '6px' }}>Others </Typography>
       <Typography sx={{ fontSize: '14px', color: '#5C6F82'}}>
       Please Evaluate the candidate on the following on a scale of 1 to 5 (1 lowest, 5 highest)
       </Typography>
       </Box>
       {/* <Typography onClick={()=>handleClickOpen('non-technical')} sx={{ fontSize: '14px', fontWeight: 600, mb: '6px', color:'#1677FF',cursor:'pointer' }}>+ New Skills</Typography> */}
       </Box>
 
       <Box sx={{mt:'20px'}}>
       <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
       <Typography sx={{fontSize:'14px'}}>Window Violation</Typography>
       <Box>
       <Button variant='outlined' sx={{ml:'60px', textTransform:'none', height:'30px', color: selectedButton === 'yes' ? '#2F54EB' : '#919EAB', borderColor: selectedButton === 'yes' ? '#2F54EB' : '#919EAB', ":hover":{ color:'#2b67ba', border:'1px solid #2b67ba' }}} onClick={() => handleClick('yes')}>Yes</Button>
       <Button variant='outlined' sx={{ml:'10px', textTransform:'none', height:'30px', color: selectedButton === 'no' ? '#2F54EB' : '#919EAB', borderColor: selectedButton === 'no' ? '#2F54EB' : '#919EAB', ":hover":{ color:'#2b67ba', border:'1px solid #2b67ba' }}} onClick={() => handleClick('no')}>No</Button>
       </Box>
       </Box>
 
       <Box>
       <Box sx={{display:'flex', flexDirection:'row', mt:'20px'}}>
       <Typography sx={{fontSize:'14px'}}>Final Recommendation</Typography>
       {/* <Rating
               name="final-recommendation-rating"
               value={finalRecommendationRating}
               onChange={(event, newValue) => handleFinalRatingChange(newValue)}            
               onMouseOver={(event) => {
             if (!canChangeRating) {
               event.preventDefault(); // Prevent hover effect when canChangeRating is false
             }
           }}
           precision={1}
           sx={{ ml: '21px' }}
           icon={<StarIcon />}
         />
         <Typography sx={{ ml: 1 }}>{finalRecommendationRating}/5</Typography>
         <Button
         variant="outlined"
         size="small"
         onClick={handleFinalRatingReset}
         sx={{
           ml: 2,
           borderRadius: '40px',
           fontSize: '10px',
           color: finalRecommendationRating > 0 ? 'black' : '#DBDBDB',
           textTransform:'none',
           border: '1px solid #DBDBDB',
           ":hover": {
             border: '1px solid #DBDBDB',
             color: finalRecommendationRating > 0 ? 'black' : 'black',
 
           },
         }}
         startIcon={<CachedIcon/>} // Add the icon here
       >
         Reset
       </Button> */}
       </Box>
       </Box>
 
       <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%', mt:'20px' }}>    
       <Box sx={{display:'flex', flexDirection:'row', }}>
       <Typography variant="body2" sx={{textWrap:'nowrap', display:'flex', alignItems:'center'}}>Interviewer Comments</Typography>
       <Box sx={{ display: 'flex', gap: '10px', ml:'26.5px' }}>
         {['Low', 'Fair', 'Average', 'Good', 'Excellent'].map((label) => (
           <Button
             key={label}
             variant="outlined"
             size="small"
             sx={{                      
               color: javaApiEvaluation === label ? '#2F54EB' : '#919EAB',
               borderColor: javaApiEvaluation === label ? '#2F54EB' : '#919EAB',
               textTransform:'none',
               ":hover":{ borderColor: javaApiEvaluation === label ? '#2F54EB' : '#919EAB', color: javaApiEvaluation === label ? '#2F54EB' : '#919EAB' }
             }}
             onClick={() => handleButtonClickOthers(label, setJavaApiEvaluation, setJavaApiEmoji)}
           >
             {label}
           </Button>
         ))}
         </Box>
       </Box>
       <Box sx={{mr:'20px', display:'flex', alignItems:'center'}}>
       <Typography sx={{ fontSize: '20px', ml: '20px' }}>{javaApiEmoji}</Typography>
       </Box>
       </Box>
       </Box>  
 
       <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', mt:'20px'}}>  
       <Typography sx={{fontSize:'14px'}}>Interview Score</Typography>  
       <Box sx={{ display: 'flex', gap: '10px', ml: '73px' }}>
       {[...Array(10).keys()].map((score) => (
         <Button
           key={score + 1}
           variant="outlined"
           size="small"
           sx={{
             width: '30px',    // Set width to 15px
             height: '30px',   // Set height to 15px
             p: 0,             // Remove padding
             minWidth: '35px', // Ensure the button maintains the width
             fontSize: '10px', // Set font size to fit the number inside the 15px size
             lineHeight: '1',  // Adjust line height to ensure the text is centered
             color: (interviewScore ?? 0) >= score + 1 ? '#059212' : '#919EAB',  // Default to 0 if undefined
             borderColor: (interviewScore ?? 0) >= score + 1 ? '#059212' : '#919EAB',
             bgcolor:  (interviewScore ?? 0) >= score + 1 ? '#D8DBBD' : '#none',              
             textTransform: 'none',
             ":hover": { borderColor: (interviewScore ?? 0) >= score + 1 ? '#059212' : '#919EAB', color: (interviewScore ?? 0) >= score + 1 ? '#059212' : '#919EAB' }
           }}
           onClick={() => setInterviewScore(score + 1)}
         >
           {score + 1}
         </Button>
       ))}
       </Box>
       </Box>
 
     {/* Recommendation */}
       <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '20px' }}>
       <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>Recommended</Typography>
       <Box sx={{ display: 'flex', gap: '10px', ml: '74.5px' }}>
         {['Hire', 'Move to Next Round', 'Keep in Consideration', 'Needs Improvement', 'Do Not Hire'].map((label) => (
           <Button
             key={label}
             variant="outlined"
             size="small"
             sx={{
               color: recommendation === label ? '#2F54EB' : '#919EAB',
               borderColor: recommendation === label ? '#2F54EB' : '#919EAB',
               textTransform: 'none',
               ":hover": { borderColor: recommendation === label ? '#2F54EB' : '#919EAB', color: recommendation === label ? '#2F54EB' : '#919EAB' }
             }}
             onClick={() => setRecommendation(label)}
           >
             {label}
           </Button>
         ))}
       </Box>
       </Box>
       </Paper>
     
 
        <Box sx={{display:'flex', justifyContent:'flex-end', mt:'20px',  width: '890px', pb: 5}}>
        <Button variant='contained' sx={{textTransform:'none'}} onClick={hanldeFormSubmit}>Submit Form</Button>
        </Box>      
        </Box>
        </Box>
 
        <Dialog open={open}>
          <DialogTitle sx={{width:'20vw'}}>
            Add New Skill
            <IconButton
              onClick={handleClose}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="normal"
              placeholder="Enter Skills Name"
              fullWidth
              value={skillName}
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
                else if (e.key === 'Escape') {
                  handleClose();
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" sx={{textTransform:'none'}} color="primary" onClick={handleSubmit}>
            Submit
            </Button>
          </DialogActions>
        </Dialog>
  </>  
  );
};
 
export default InterviewEvaluationForm;