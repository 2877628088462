import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCandidateByCandidateId, getCity, getCountry, getInterviewStatus, getL1Approvers, getNationality, getResumeRepo, getRthDetailsByRthId, getSkill, getState } from "./resume";


export const initialState={
    cities:[],
    nationality:[],
    citizenship:[],
    country:[],
    state:[],
    skills:[],
    error:{},
    candidate:[],
    l1Approvers:[],
    selectedFiles: [] as File[], 
    rthDetails:[],
    interviewStatus:[],
    resumeRepo:[]
}

const ResumeSlice=createSlice({
    name:'resumeSlice',
    initialState,
    reducers:{
        SelectedFile: (state, action: PayloadAction<File[]>) => {
            state.selectedFiles = action.payload;
          },
    },
    extraReducers:(builder)=>{
        builder.addCase(getCity.fulfilled,(state:any,action:any)=>{
            // console.log(action?.payload?.data,'522');
            state.cities=action.payload?.data;
        })
        builder.addCase(getCity.rejected,(state:any,action:any)=>{
            state.error = action.payload ? action.payload?.error : null;
        })
        builder.addCase(getState.fulfilled,(state:any,action:any)=>{
            state.state=action?.payload?.data;
        })
        builder.addCase(getState.rejected,(state:any,action:any)=>{
            state.error=action?.payload ? action.payload?.error : null;
        })
        builder.addCase(getCountry.fulfilled,(state:any,action:any)=>{
            state.country=action?.payload?.data;
        })
        builder.addCase(getCountry.rejected,(state:any,action:any)=>{
            state.error=action?.payload ? action.payload?.error : null;
        })
        builder.addCase(getSkill.fulfilled,(state:any,action:any)=>{
            state.skills=action?.payload?.data;
        })
        builder.addCase(getSkill.rejected,(state:any,action:any)=>{
            state.error=action?.payload ? action.payload?.error : null;
        })
        builder.addCase(getNationality.fulfilled,(state:any,action:any)=>{
            state.nationality=action?.payload?.data;
        })
        builder.addCase(getNationality.rejected,(state:any,action:any)=>{
            state.error=action?.payload ? action.payload?.error : null;
        })
        builder.addCase(getCandidateByCandidateId.fulfilled,(state:any,action:any)=>{
            state.candidate=action?.payload?.data;
        })
        builder.addCase(getCandidateByCandidateId.rejected,(state:any,action:any)=>{
            state.error=action?.payload ? action.payload?.error : null;
        })
        builder.addCase(getL1Approvers.fulfilled,(state:any,action:any)=>{
            state.l1Approvers=action?.payload?.data;
        })
        builder.addCase(getL1Approvers.rejected,(state:any,action:any)=>{
            state.error=action?.payload ? action.payload?.error : null;
        })
        builder.addCase(getRthDetailsByRthId.fulfilled,(state:any,action:any)=>{
            state.rthDetails=action?.payload?.data;
        })
        builder.addCase(getRthDetailsByRthId.rejected,(state:any,action:any)=>{
            state.error=action?.payload ? action?.payload?.error : null;
        })
        builder.addCase(getInterviewStatus.fulfilled,(state:any,action:any)=>{
            state.interviewStatus=action?.payload?.data;
        })
        builder.addCase(getInterviewStatus.rejected,(state:any,action:any)=>{
            state.error=action?.payload ? action?.payload?.error : null;
        })
        builder.addCase(getResumeRepo.fulfilled,(state:any,action:any)=>{
            state.resumeRepo=action.payload?.data;
        })
        builder.addCase(getResumeRepo.rejected,(state:any,action:any)=>{
            state.error=action?.payload ? action?.payload?.error : null;
        })
    }
})

export const { SelectedFile } = ResumeSlice.actions;
export default ResumeSlice.reducer;