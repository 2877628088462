import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Alert, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { getRthBusinessInputData, submitRthForm } from "../../../../redux/features/rthSlice";
import CommonSelect from "../../../common/CommomSelect";
import { SelectChangeEvent } from "@mui/material";
import CommonTextField from "../../../common/CommonTextField";
import type { AppDispatch } from "../../../../redux/store";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { uploadFile } from "../../../../Crud/choosefile";
import {
  getRthBandData,
  getRthDepartmentData,
  getRthLocationData,
  getRthL1ApproverData,
  getRthL2ApproverData,
  getRthPositionReportsTo,
  getRthPrimarySkillsData,
  // getRthPriorityData,
  getRthQualificationData,
  getRthSecondarySkillsData,
} from "../../../../Crud/rth";
import { getRthL1L2GetAPIData } from "../../../../Crud/newRth";
import { useNavigate } from "react-router-dom";
import SearchableDropdown from "../../../CustomComponents/SearchableDropdown";
interface SkillOption {
  value: string;
  label: string;
}

export const useAppDispatch: () => AppDispatch = useDispatch;

interface FormData {
  userId: string;
  businessId: string;
  positionTitle: string;
  l1ApprovalId: string;
  l2ApprovalId: string;
  departmentId: string;
  band: string;
  qualNeeded: string;
  minExperience: string;
  maxExperience: string;
  primarySkills?: any;
  secondarySkills?: any;
  workMode: string;
  priority: string;
  // budget: string;
  numOfPosition: string;
  posReportsToId: string;
  reasonForHire: string;
  location: string;
  ctc: string;
  prefStartDate: string;
  comments: string;
  modeOfRth: string;
  typeOfRth: string;
  // approvalAttachment: string;
  jdAttachmentFileUrl: string;
  otherFileUrl: String;
  l2ApprovalLabel?:string;
  l1ApprovalLabel?:string;
  posReportsToLabel?:string;

  // approvalAttachment1?: string;
}

const arr: any = [];
const secondarySkills: string[] = [];
const OnRoleTab: React.FC = () => {
  const [chooseFile, setChooseFile] = useState();
  const [chooseFile1, setChooseFile1] = useState();
  const [LocationData, setLocationData] = useState([]);
  const [posReportsTo, setposReportsTo] = useState([]);
  const [QualificationData, setQualificationData] = useState([]);
  const [L2ApproverData, setL2ApproverData] = useState([]);
  const [L1ApproverData, setL1ApproverData] = useState([]);
  console.log("L1ApproverData", posReportsTo)
  console.log("L1ApproverData", L2ApproverData)
  console.log("L1ApproverData", L1ApproverData)
  console.log("setL1ApproverData", setL1ApproverData)
  const [secondarySkillsData, setSecondarySkillsData] = useState([]);
  const [primarySkillsData, setPrimarySkillsData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  console.log("departmentData", departmentData)
  const [bandData, setBandData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [submitted, setSubmitted] = useState(false);
  const [pSkills, setPSkills] = useState<any>([]);
  const [sSkills, setSSkills] = useState<any>([]);
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const token: any = localStorage.getItem("token");
  const decoded: any = jwtDecode(token);
  const userId = decoded.userId;
  console.log(userId, "useridddd");

  const [formData, setFormData] = useState<FormData>({
    userId: userId || "",
    businessId: "",
    positionTitle: "",
    l1ApprovalId: "",
    l2ApprovalId: "",
    l1ApprovalLabel: "",
    l2ApprovalLabel: "",
    departmentId: "",
    band: "",
    qualNeeded: "",
    minExperience: "",
    maxExperience: "",
    primarySkills: "",
    secondarySkills: "",
    workMode: "",
    priority: "",
    // budget: "",
    numOfPosition: "",
    posReportsToId: "",
    posReportsToLabel: "",
    reasonForHire: "",
    location: "",
    ctc: "",
    prefStartDate: "",
    comments: "",
    modeOfRth: "OnRole",
    typeOfRth: "Internal",
    // approvalAttachment: "",
    jdAttachmentFileUrl: "",
    otherFileUrl: "",
    // approvalAttachment1:"",
  });

  console.log(formData,"change man!");
  

  useEffect(() => {
    getRthBusinessInputData()
      .then((res) => {
        const data = res.data?.map((i: any) => {
          return { value: i.businessId, label: i.businessName };
        });
        setBusinessData(data);
      })
      .catch((err) => console.error(err));

    getRthBandData()
      .then((res) => {
        console.log("res----->", res);
        const data = res.data?.map((i: any) => {
          return { value: i.bandName, label: i.bandName };
        });
        setBandData(data);
      })
      .catch((err) => console.error(err));

    getRthDepartmentData()
      .then((res) => {
        const data = res.data?.map((i: any) => {
          return { value: i.departmentId, label: i.departmentName };
        });
        setDepartmentData(data);
        console.log(data, "data");
      })
      .catch((err) => console.error(err));

    // getRthWorkModeData().then(res => {
    //   const data = res.data?.map((i: any) => { return { value: i.workId, label: i.workName } })
    //   setWorkModeData(data)
    // }).catch((err) => console.error(err));

    getRthPrimarySkillsData()
      .then((res) => {
        const data = res.data?.map((i: any) => {
          return { value: i.id, label: i.skillName };
        });
        setPrimarySkillsData(data);
      })
      .catch((err) => console.error(err));

    getRthSecondarySkillsData()
      .then((res) => {
        const data = res.data?.map((i: any) => {
          return { value: i.id, label: i.skillName };
        });
        setSecondarySkillsData(data);
      })
      .catch((err) => console.error(err));

    getRthL1ApproverData()
      .then((res) => {
        const data = res.data?.map((i: any) => {
          return { value: i.userId, label: i.firstName };
        });
        setL1ApproverData(data);
      })
      .catch((err) => console.error(err));

    getRthL2ApproverData()
      .then((res) => {
        const data = res.data?.map((i: any) => {
          return { value: i.userId, label: i.firstName };
        });
        setL2ApproverData(data);
      })
      .catch((err) => console.error(err));

    // getRthPriorityData().then(res => {
    //   const data = res.data?.map((i: any) => { return { value: i.priorityId, label: i.priorityName } })
    //   setPriorityData(data)
    // }).catch((err) => console.error(err));

    getRthQualificationData()
      .then((res) => {
        const data = res.data?.map((i: any) => {
          return { value: i.degree, label: i.degree };
        });
        setQualificationData(data);
      })
      .catch((err) => console.error(err));

    getRthPositionReportsTo()
      .then((res) => {
        const data = res.data?.map((i: any) => {
          return { value: i.userId, label: i.firstName };
        });
        setposReportsTo(data);
      })
      .catch((err) => console.error(err));

    getRthLocationData()
      .then((res) => {
        console.log("ressss =====>", res);
        const data = res.data?.map((i: any) => {
          return { value: i.locationName, label: i.locationName };
        });
        setLocationData(data);
      })
      .catch((err) => console.error(err));

    // getRthReasonForHire().then(res => {
    //   const data = res.data?.map((i: any) => { return { value: i.reasonOfHireId, label: i.reasonOfHireName } })
    //   setReasonForHire(data)
    // }).catch((err) => console.error(err));
  }, []);

  const reasonForHireOptions = [
    { value: "replacement", label: "Replacement" },
    { value: "newPosition", label: "New Position" },
    { value: "expansion", label: "Expansion" },
  ];

  const workModeOptions = [
    { value: "onsite", label: "Onsite" },
    { value: "remote", label: "Remote" },
    { value: "hybrid", label: "Hybrid" },
  ];

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target;
    console.log("change", name, value);

    if (name === "secondarySkills") {
      secondarySkills.push(value);
    }

    if (name === "primarySkills") {
      arr.push(value);
      console.log("arr", arr);
      setFormData({
        ...formData,
        primarySkills: [arr],
      });
      console.log("....formData", formData);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      const formData = new FormData();
      formData.append("file", file);

      reader.onload = (e) => {
        uploadFile(formData)
          .then((res) => setChooseFile(res.data.data))
          .catch((err) => console.error(err));
      };
      reader.readAsDataURL(file);
    }
  };

  const validate = () => {
    const newErrors: { [key: string]: string } = {};

    Object.keys(formData).forEach((key) => {
      if (
        key === "jdAttachmentFileUrl" &&
        formData.jdAttachmentFileUrl === ""
      ) {
        return;
      }
      if (key === "otherFileUrl" && formData.otherFileUrl === "") {
        return;
      }
      if (!formData[key as keyof typeof formData]) {
        newErrors[key] = key;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

const handleL1Change = (item:any)=>{
  console.log("search",item);
  setFormData({
    ...formData,
    l1ApprovalId: item?.id,
    l1ApprovalLabel: item?.label
  });
}

const handleL2Change = (item:any)=>{
  console.log("search",item);
  setFormData({
    ...formData,
    l2ApprovalId: item?.id,
    l2ApprovalLabel: item?.label
  });
}

const handlePReportChange = (item:any)=>{
  console.log("search",item);
  setFormData({
    ...formData,
    posReportsToId: item?.id,
    posReportsToLabel: item?.label,
  });
}
  const handleFileChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      const formData = new FormData();
      formData.append("file", file);

      reader.onload = (e) => {
        uploadFile(formData)
          .then((res) => setChooseFile1(res.data.data))
          .catch((err) => console.error(err));
      };
      reader.readAsDataURL(file);
    }
  };

  // const validate = () => {
  //   const newErrors: { [key: string]: string } = {};
  //   Object.keys(formData).forEach((key) => {
  //     if (!formData[key as keyof typeof formData]) {
  //       newErrors[key] = key+" ";
  //     }
  //   });
  //   setErrors(newErrors);
  //   return Object.keys(newErrors).length === 0;;
  // };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitted(true);
    console.log("arr", arr);
    delete formData.l1ApprovalLabel
    delete formData.l2ApprovalLabel
    delete formData.posReportsToLabel
    
    let data = {
      ...formData,
    };

    console.log("dataaaaa", data);
    if (pSkills.length >= 1) {
      data = {
        ...data,
        primarySkills: pSkills,
      };
    }
    if (setSSkills.length >= 1) {
      data = {
        ...data,
        secondarySkills: sSkills,
      };
    }
    if (chooseFile) {
      data = {
        ...data,
        jdAttachmentFileUrl: chooseFile,
      };
    }
    if (chooseFile1) {
      data = {
        ...data,
        otherFileUrl: chooseFile1,
      };
    }

    if (validate()) {
      await submitRthForm(data);
      toast.success("Form Submitted Sucessfully");
      navigate("/dashboard?tab=my-requests")
      setFormData({
        userId: userId || "",
        businessId: "",
        positionTitle: "",
        l1ApprovalId: "",
        l2ApprovalId: "",
        departmentId: "",
        band: "",
        qualNeeded: "",
        minExperience: "",
        maxExperience: "",
        primarySkills: "",
        secondarySkills: "",
        workMode: "",
        priority: "",
        // budget:"",
        numOfPosition: "",
        posReportsToId: "",
        reasonForHire: "",
        location: "",
        ctc: "",
        prefStartDate: "",
        comments: "",
        typeOfRth: "Internal",
        modeOfRth: "OnRole",
        // approvalAttachment: "",
        jdAttachmentFileUrl: "",
        otherFileUrl: "",
        l1ApprovalLabel: "",
        l2ApprovalLabel: "",
        // approvalAttachment1:"",
      });
      console.log("onrole details " + formData);
      setPSkills([]);
      setSSkills([]);
      setErrors({});
      setSubmitted(false);
    }
  };
  // useEffect(() => {
  //   if (formData?.departmentId) {
  //     getRthL1L2GetAPIData(formData.departmentId)
  //       .then(
  //         (res) => {
  //           if (res.data?.data) {
  //             // setFormData({
  //             //   ...formData,
  //             //   l1ApprovalId: res.data.data?.l1Approver.userId,
  //             //   l2ApprovalId: res.data.data?.l2Approver.userId,
  //             // });
  //             setFormData((prevFormData) => ({
  //               ...prevFormData,
  //               l1ApprovalId: res.data.data?.l1Approver?.userId,
  //               l2ApprovalId: res.data.data?.l2Approver?.userId,
  //             }));
  //           }
  //         }
  //       )
  //       .catch((error) => console.error(error));
  //   }
  // }, [formData?.departmentId]);
  useEffect(() => {
    if (formData?.departmentId) {
      getRthL1L2GetAPIData(formData.departmentId)
        .then((res) => {
          console.log(res.data?.data, "API"); 
          if (res.data?.data) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              l1ApprovalId: res.data.data?.l1Approver?.userId || '',
              l2ApprovalId: res.data.data?.l2Approver?.userId || '',
              l1ApprovalLabel: res.data.data?.l1Approver?.firstName || '',
              l2ApprovalLabel: res.data.data?.l2Approver?.firstName || '',
              
            }));
            setErrors((prevErrors) => ({
              ...prevErrors,
              l1ApprovalId: "",
              l2ApprovalId: "",
              l1ApprovalLabel:"",
              l2ApprovalLabel:""
            }));
          }
        })
        .catch((error) => console.error("Error", error));
    }
  }, [formData?.departmentId]);//DEPARTMENT API END

  console.log("errrrr------", errors.numOfPosition)
  return (
    <>
      <Box component="form" onSubmit={handleSubmit}>
        <Box
          sx={{
            p: 3,
            backgroundColor: "white",
            marginTop: "10px",
          }}
        >
          <Typography color="gray" sx={{ fontSize: '12px' }}>On-Role Details</Typography>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={4}>
              <Grid
                item
                xs={12}
                md={2}
                sx={{ paddingTop: "33px", paddingLeft: "30px" }}
              >
                <CommonSelect
                  labelId="business-label"
                  label="Business"
                  name="businessId"
                  value={formData.businessId}
                  onChange={handleChange}
                  options={businessData}
                  error={!!errors.businessId}
                  helperText={
                    errors.businessId === "businessId" ? "Business" : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Department"
                  label="Department"
                  name="departmentId"
                  value={formData.departmentId}
                  onChange={handleChange}
                  options={departmentData}
                  error={!!errors.departmentId}
                  helperText={
                    errors.departmentId === "departmentId" ? "Department" : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonTextField
                  label="Number of Positions"
                  name="numOfPosition"
                  value={formData.numOfPosition
                    ?.replace(/[^0-9]/g, "")
                    .substring(0, 4)}
                  onChange={handleChange}
                  error={
                    !!errors.numOfPosition ||
                    (formData.numOfPosition?.length || 0) > 4
                  }
                  helperText={
                    (formData.numOfPosition?.length || 0) > 4
                      ? "Number of Positions cannot exceed 4 Letters"
                      : errors.numOfPosition === "numOfPosition"
                      ? "Number of Positions field is requierd"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <SearchableDropdown
                  searchAPICall={getRthPositionReportsTo}
                  label="Position Reports To"
                  value={
                    formData.posReportsToId && formData.posReportsToLabel
                      ? { id: formData.posReportsToId, label: formData.posReportsToLabel }
                      : null
                  }
                  onOptionSelect={handlePReportChange}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                {/* <SearchableDropdown
                  searchAPICall={getRthL1ApproverData}
                  label="L1 approval"
                  onOptionSelect={handleL1Change}
                  value={formData?.l1ApprovalId}
                /> */}
                <SearchableDropdown
                  searchAPICall={getRthL1ApproverData}
                  label="L1 approval"
                  value={
                    formData.l1ApprovalId && formData.l1ApprovalLabel
                      ? { id: formData.l1ApprovalId, label: formData.l1ApprovalLabel }
                      : null
                  }
                  onOptionSelect={handleL1Change}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <SearchableDropdown
                  searchAPICall={getRthL2ApproverData}
                  label="L2 approval"
                  value={
                    formData.l2ApprovalId && formData.l2ApprovalLabel
                      ? { id: formData.l2ApprovalId, label: formData.l2ApprovalLabel }
                      : null
                  }
                  onOptionSelect={handleL2Change}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="reason-for-hire"
                  label="Reason for Hire"
                  name="reasonForHire"
                  value={formData.reasonForHire}
                  onChange={handleChange}
                  error={!!errors.reasonForHire}
                  helperText={
                    errors.reasonForHire === "reasonForHire"
                      ? "Reason for Hire"
                      : ""
                  }
                  options={reasonForHireOptions}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="location"
                  label="Location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  error={!!errors.location}
                  helperText={errors.location === "location" ? "Location " : ""}
                  options={LocationData}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <CommonTextField
                  label="Position Title"
                  name="positionTitle"
                  value={formData.positionTitle
                    ?.replace(/[^a-zA-Z\s]/g, "")
                    .substring(0, 32)}
                  onChange={handleChange}
                  error={
                    !!errors.positionTitle ||
                    (formData.positionTitle?.length || 0) > 32
                  }
                  helperText={
                    (formData.positionTitle?.length || 0) > 32
                      ? "Position Title cannot exceed 32 characters"
                      : errors.positionTitle === "positionTitle"
                      ? "Position Title field is required"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Band"
                  label="Band"
                  name="band"
                  value={formData.band}
                  onChange={handleChange}
                  options={bandData}
                  error={!!errors.band}
                  helperText={errors.band === "band" ? "Band " : ""}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Qualification"
                  label="Qualification"
                  name="qualNeeded"
                  value={formData.qualNeeded}
                  onChange={handleChange}
                  options={QualificationData}
                  error={!!errors.qualNeeded}
                  helperText={
                    errors.qualNeeded === "qualNeeded" ? "Qualification" : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Min Experience"
                  label="Min Experience "
                  name="minExperience"
                  value={formData.minExperience}
                  onChange={handleChange}
                  options={[
                    { value: "1 year", label: "1 year" },
                    { value: "2 years", label: "2 years" },
                  ]}
                  error={!!errors.minExperience}
                  helperText={
                    errors.minExperience === "minExperience"
                      ? "Min Experience"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Max Experience"
                  label="Max Experience"
                  name="maxExperience"
                  value={formData.maxExperience}
                  onChange={handleChange}
                  options={[
                    { value: "5 years", label: "5 years" },
                    { value: "10 years", label: "10 years" },
                  ]}
                  error={!!errors.maxExperience}
                  helperText={
                    errors.maxExperience === "maxExperience"
                      ? "Max Experience"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonTextField
                  label="CTC"
                  name="ctc"
                  value={formData.ctc?.replace(/[^0-9]/g, "").substring(0, 15)}
                  onChange={handleChange}
                  error={!!errors.ctc||
                    (formData.ctc?.length || 0) > 15}
                  helperText={
                    (formData.ctc?.length || 0) > 15
                      ? "CTC cannot exceed 15 Numbers"
                      : errors.ctc === "ctc" ? "CTC field is requierd" : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Autocomplete
                  size="small"
                  multiple
                  limitTags={1}
                  options={primarySkillsData}
                  getOptionLabel={(option: SkillOption) => option.label}
                  onChange={(event, newValue) => {
                    console.warn("newValue", newValue);
                    const pData = newValue.map((i) => i.value);
                    setPSkills(pData);
                    const sSkills = secondarySkillsData.filter(
                      (i: any) => !pData.includes(i.value)
                    );
                    if (newValue.length >= 1) {
                      setSecondarySkillsData(sSkills);
                    } else {
                      setSecondarySkillsData(primarySkillsData);
                    }
                    setFormData((prevData) => ({
                      ...prevData,
                      primarySkills: pData,
                    }));
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      primarySkills: "",
                    }));
                  }}
                  sx={{paddingBottom: "10px"}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Primary Skills"
                      error={!!errors.primarySkills}
                      helperText={
                        errors.primarySkills === "primarySkills"
                          ? "Primary skills field is requierd"
                          : ""
                      }
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          fontSize: "0.8rem", 
                          paddingLeft: "2px",
                          paddingRight: "40px",
                          paddingBottom: "9px",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "0.8rem",
                          fontWeight: 410, color: "gray",
                        },
                      }}
                    />
                  )}
                  disableCloseOnSelect
                  renderOption={(props, option: SkillOption, { selected }) => (
                    <li
                      {...props}
                      style={{
                        fontSize: "0.8rem",
                        padding: "1px 1px",
                        margin: "0px 0",
                      }}
                    >
                      <Checkbox
                        sx={{
                          transform: "scale(0.8)",
                          marginRight: 2,
                          padding: 0,
                        }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Autocomplete
                  size="small"
                  multiple
                  limitTags={1}
                  options={secondarySkillsData}
                  getOptionLabel={(option: SkillOption) => option.label}
                  onChange={(event, newValue) => {
                    const sData = newValue.map((i) => i.value);
                    setSSkills(sData);

                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      secondarySkills: "",
                    }));
                    setFormData((prevData) => ({
                      ...prevData,
                      secondarySkills: sData,
                    }));
                  }}
                  
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Secondary Skills"
                      error={!!errors.secondarySkills}
                      helperText={
                        errors.secondarySkills === "secondarySkills"
                          ? "Secondary skills field is requierd"
                          : ""
                      }
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          fontSize: "0.8rem",
                          paddingLeft: "2px",
                          paddingRight: "40px",
                          paddingBottom: "9px",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "0.8rem",
                          fontWeight: 410, color: "gray",
                        },
                      }}
                    />
                  )}
                  disableCloseOnSelect
                  renderOption={(props, option: SkillOption, { selected }) => (
                    <li
                      {...props}
                      style={{
                        fontSize: "0.8rem",
                        padding: "1px 1px",
                        margin: "0px 0",
                      }}
                    >
                      <Checkbox
                        sx={{
                          transform: "scale(0.8)",
                          marginRight: 2,
                          padding: 0,
                        }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <CommonTextField
                  label="Preferred Start Date"
                  type="date"
                  name="prefStartDate"
                  value={formData.prefStartDate}
                  onChange={handleChange}
                  error={!!errors.prefStartDate}
                  helperText={
                    errors.prefStartDate === "prefStartDate"
                      ? "Preferred Start Date"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="work-mode-label"
                  label="Work Mode"
                  name="workMode"
                  value={formData.workMode}
                  onChange={handleChange}
                  options={workModeOptions}
                  error={!!errors.workMode}
                  helperText={errors.workMode === "workMode" ? "Work Mode" : ""}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="priority-label"
                  label="Priority"
                  name="priority"
                  value={formData.priority}
                  onChange={handleChange}
                  options={[
                    { value: "High", label: "High" },
                    { value: "Low", label: "Low" },
                    { value: "Medium", label: "Medium" },
                  ]}
                  error={!!errors.priority}
                  helperText={errors.priority === "priority" ? "Priority" : ""}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <input
                  accept=".pdf,.doc,.docx"
                  id="file-input"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label htmlFor="file-input">
                  <Button
                    variant="outlined"
                    component="span"
                    sx={{
                      width: "100%",
                      fontSize: "0.75rem",
                      padding: "2px 4px",
                      minHeight: "40px",
                      border: "1px solid",
                      borderColor: "darkgray",
                      borderRadius: "4px",
                      marginTop: "1px",
                      backgroundColor: "transparent",
                    }}
                  >
                    {!formData.jdAttachmentFileUrl && !chooseFile
                      ? "JD Upload"
                      : formData.jdAttachmentFileUrl || "JD Uploaded"}
                  </Button>
                </label>
              </Grid>
              <Grid item xs={12} md={2}>
                <input
                  accept=".pdf,.doc,.docx"
                  id="file-input1"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange1}
                />
                <label htmlFor="file-input1">
                  <Button
                    variant="outlined"
                    component="span"
                    sx={{
                      width: "100%",
                      fontSize: "0.75rem",
                      padding: "2px 4px",
                      minHeight: "40px",
                      border: "1px solid",
                      borderColor: "darkgray",
                      borderRadius: "4px",
                      marginTop: "1px",
                      backgroundColor: "transparent",
                    }}
                  >
                    {!formData.otherFileUrl && !chooseFile1
                      ? "File Upload"
                      : formData.otherFileUrl || "File Uploaded"}
                  </Button>
                </label>
              </Grid>

              {/* <Grid item xs={12} md={2}>
              <input
                accept=".pdf,.doc,.docx"
                id="file-input1"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange1}
              />
              <label htmlFor="file-input1">
                <Button
                  variant="outlined"
                  component="span"
                  sx={{
                    minWidth: "200px",
                    fontSize: "0.75rem",
                    padding: "2px 4px",
                    minHeight: "40px",
                    border: "1px solid",
                    borderColor: "darkgray",
                    borderRadius: "4px",
                    marginTop: "1px",
                    backgroundColor: "transparent",
                  }}
                  // sx={{ textTransform: "none" }}
                >
                  {(!formData.approvalAttachment1&&!chooseFile1 )? "File Upload" : (formData.approvalAttachment1||"Resumee Uploaded")}
                </Button>
              </label>
            </Grid> */}
              <Grid item xs={12} md={4}>
                <CommonTextField
                  label="Comments"
                  name="comments"
                  value={formData.comments.substring(0, 500)}
                  onChange={handleChange}
                  error={!!errors.comments||
                    (formData.comments?.length || 0) > 500}
                  helperText={
                    (formData.comments?.length || 0) > 500
                      ? "comments cannot exceed 500 characters"
                      : errors.comments === "comments"
                      ? "Comments field is requierd"
                      : ""
                  }
                />
              </Grid>
              {/* <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="budget-label"
                  label="Budget"
                  name="budget"
                  value={formData.budget}
                  onChange={handleChange}
                  options={[
                    { value: "Budgeted", label: "Budgeted" },
                    { value: "Non-Budgeted", label: "Non-Budgeted" },
                    
                  ]}
                  error={!!errors.budget}
                  helperText={errors.budget}
                />
              </Grid> */}
              {/* <Grid item xs={12} md={2}>
                <Autocomplete
                  size="small"
                  multiple
                  limitTags={1}
                  options={primarySkillsData}
                  getOptionLabel={(option: SkillOption) => option.label}
                  onChange={(event, newValue) => {
                    const pData = newValue.map(i => i.value);
                    setPSkills(pData);

                    // Update form data with new primary skills
                    setFormData((prevData) => ({
                      ...prevData,
                      primarySkills: pData,
                    }));

                    // Clear any errors
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      primarySkills: "",
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Evaluations Skills"
                      error={!!errors.primarySkills}
                      helperText={errors.primarySkills}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          fontSize: '0.875rem',
                          paddingLeft: '2px',
                          paddingRight: '2px',
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: '0.875rem',
                        },
                      }}
                    />
                  )}
                  disableCloseOnSelect
                  renderOption={(props, option: SkillOption, { selected }) => (
                    <li {...props}
                      style={{
                        fontSize: '0.875rem',
                        padding: '1px 1px',
                        margin: '0px 0',
                      }}
                    >
                      <Checkbox
                        sx={{
                          transform: 'scale(0.8)',
                          marginRight: 2,
                          padding: 0,
                        }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                />
              </Grid>     */}
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            sx={{
              mr: 2,
              color: "black",
              backgroundColor: "transparent",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }}
            onClick={() => {
              setFormData({
                userId: userId || "",
                businessId: "",
                positionTitle: "",
                l1ApprovalId: "",
                l2ApprovalId: "",
                departmentId: "",
                band: "",
                qualNeeded: "",
                minExperience: "",
                maxExperience: "",
                primarySkills: [],
                secondarySkills: [],
                workMode: "",
                priority: "",
                // budget:"",
                numOfPosition: "",
                posReportsToId: "",
                reasonForHire: "",
                location: "",
                ctc: "",
                prefStartDate: "",
                comments: "",
                modeOfRth: "",
                typeOfRth: "",
                // approvalAttachment: "",
                jdAttachmentFileUrl: "",
                otherFileUrl: "",
                // approvalAttachment1: ""
              });
              setErrors({});
              setSubmitted(false);
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" sx={{ color: "#F0F5FF" }} type="submit">
            Submit
          </Button>
        </Box>
        {submitted && Object.keys(errors).length > 0 && (
          <Alert severity="error" sx={{ mt: 2 }}>
            Please correct the errors in the form before submitting.
          </Alert>
        )}
      </Box>
    </>
  );
};

export default OnRoleTab;
