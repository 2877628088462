import React, { useRef, useState } from 'react';
import { Typography, Radio, Grid, Box, SelectChangeEvent, Button } from '@mui/material';
import CommonTextField from '../../../common/CommonTextField';
import CommonSelect from '../../../common/CommomSelect';
import { gender, knownOptions } from '../../../../constants/dummyConst';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../../redux/hooks';
import { getFileUrl } from '../../../../redux/features/resume';
 
const BulkUploadForm = ({ formValues, setFormValues, handleNextPage, handleBack,validateForm,errors,aadharError,aadharFile,setAadharFile,setErrors,setAadharError }: any) => {
  const location = useLocation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const path = location.pathname.includes("candidateForm");
  const dispatch=useAppDispatch();
  const [loading, setLoading] = useState(false);
 
  const handleInputChangepreEmployed = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value === "true", 
    });

    setErrors((prevErrors:any) => ({
      ...prevErrors,
      [name]: '', // Clear the error
    }));
 
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    
   setFormValues({
    ...formValues,
    [name]: value,
  });

  setErrors((prevErrors:any) => ({
    ...prevErrors,
    [name]: '', // Clear the error
  }));

  };

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
 
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      const maxFileSize = 2 * 1024 * 1024; // 2 MB in bytes
  
      // Check if any file exceeds the size limit
      const oversizedFiles = files?.filter(file => file.size > maxFileSize);
  
      if (oversizedFiles?.length > 0) {
        alert("Please upload files less than 2 MB.");
      } else {
        setAadharError('');
        setAadharFile(files);
      }
    }
  };
  
 
  // const validateForm = () => {
  //   const newErrors: { [key: string]: string } = {};
  //   if (!formValues.howDidYouHeardAbtUs) {
  //     newErrors.howDidYouHeardAbtUs = 'This is required';
  //   }
  //   if (!formValues.firstName) {
  //     newErrors.firstName = 'First Name is required';
  //   }
  //   if (!formValues.lastName) {
  //     newErrors.lastName = 'Last Name is required';
  //   }
  //   if(!formValues.middleName){
  //     newErrors.middleName='Middle Name is required'
  //   }
  //   if(!formValues.expectedCtc){
  //     newErrors.expectedCtc='Expected CTC is required'
  //   }
  //   if(!formValues.noticePeriods){
  //     newErrors.noticePeriods='Required field'
  //   }
  //   if(!formValues.currentCtc){
  //     newErrors.currentCtc='Current CTC is required'
  //   }
  //   if (!formValues.emailAddress) {
  //     newErrors.emailAddress = 'Email Address is required';
  //   } else if (!/\S+@\S+\.\S+/.test(formValues.emailAddress)) {
  //     newErrors.emailAddress = 'Email Address is invalid';
  //   }
  //   if (!formValues.phoneNumber) {
  //     newErrors.phoneNumber = 'Phone Number is required';
  //   } else if (!/^\d{10}$/.test(formValues.phoneNumber)) {
  //     newErrors.phoneNumber = 'Phone Number must be 10 digits';
  //   }
  //   if (!formValues.aadharNumber) {
  //     newErrors.aadharNumber = 'Aadhar Number is required';
  //   } else if (!/^\d{12}$/.test(formValues.aadharNumber)) {
  //     newErrors.aadharNumber = 'Aadhar Number must be 12 digits';
  //   }
  //   if (!formValues.dateOfBirth) {
  //     newErrors.dateOfBirth = 'Date of Birth is required';
  //   }
  //   if(aadharFile.length <=0){
  //     setAadharError('Aadhar card is required');
  //   }
 
  //   setErrors(newErrors);
 
  //   return Object.keys(newErrors).length === 0;
  // };
 
  const handleContinue = async() => {
    const isValid = validateForm();
    console.log(isValid,'isvalid');
    console.log(errors,'errors');
    
    if(isValid){
      if (aadharFile.length > 0) {
        const formData = new FormData();
        aadharFile.forEach((file:any) => {
          formData.append("file", file); 
        });
  
  
        try {
          // Dispatch or send the FormData
          setLoading(true);
          const response = await dispatch(getFileUrl(formData));
          if (response?.payload) {
            setFormValues((prevValues:any) => ({
              ...prevValues,
              aadharUrl: response?.payload?.data,
            }));
          }
  
          handleNextPage();
        } catch (error) {
          console.error("Error uploading file:", error);
        }
        finally {
          setLoading(false); 
        }
      } else {
        setAadharError("Aadhar card is required");
      }
    }
    }  
   
  return (
 
    <Box sx={{ pt: '4px', ml: path ? '30px' : '' }}>
      {/* <Paper elevation={0} sx={{overflowY:'auto',pt:'10px',position:'relative'}}> */}
      <Typography sx={{ fontSize: '20px', fontWeight: '500', mb: '27px', mt: '14px' }}>
        {path ? '2. Personal Information' : 'Personal Information'}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <Grid container spacing={2}>
            <Box sx={{ mb: '20px', display: 'flex', flexDirection: 'row', gap: '20px', ml: '20px', mt: '10px' }}>
              <Box >
                <Typography sx={{ fontSize: '14px', color: '#c2c2c2', mb: '17px' }}>How did you heard about us?<span style={{ color: 'red' }}> *</span></Typography>
                <CommonSelect
                  labelId=""
                  label=""
                  name="howDidYouHeardAbtUs"
                  value={formValues.howDidYouHeardAbtUs || ''}
                  onChange={handleInputChange}
                  error={!!errors.howDidYouHeardAbtUs}
                  helperText={errors.howDidYouHeardAbtUs}
                  options={knownOptions}
                />
              </Box>
              <Box >
                <Box sx={{ display: 'flex', mt: '0px', flexDirection: 'column' }}>
                  <Typography sx={{ fontSize: '14px', color: '#c2c2c2', mb: '6px' }}>Have you previously been employed by HEPL?<span style={{ color: 'red' }}> *</span></Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Radio name="preEmployed" value={true}
                      checked={formValues.preEmployed === true}
                      onChange={handleInputChangepreEmployed}
                    />
                    <Typography sx={{ mt: '10px' }}>Yes</Typography>
                    <Radio name="preEmployed" value={false}
                      checked={formValues.preEmployed === false}
                      onChange={handleInputChangepreEmployed}
                    />
                    <Typography sx={{ mt: '10px' }}>No</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: '76vw', display: 'flex', flexDirection: 'row', gap: '20px', ml: '20px', mt: '2px', mb: '14px' }}>
              <Box>
                <Typography sx={{ fontSize: '14px', color: '#c2c2c2', mb: '17px' }}>First Name<span style={{ color: 'red' }}> *</span></Typography>
                <CommonTextField
                  label=""
                  name="firstName"
                  value={formValues.firstName || ''}
                  onChange={handleInputChange}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                />
              </Box>
              {/* <Box>
                <Typography sx={{ fontSize: '14px', color: '#c2c2c2', mb: '17px' }}>Middle Name<span style={{ color: 'red' }}> *</span></Typography>
                <CommonTextField
                  label=""
                  name="middleName"
                  value={formValues.middleName || ''}
                  onChange={handleInputChange}
                  error={!!errors.middleName}
                  helperText={errors.middleName}
                />
              </Box> */}
              <Box>
                <Typography sx={{ fontSize: '14px', color: '#c2c2c2', mb: '17px' }}>Last Name<span style={{ color: 'red' }}> *</span></Typography>
                <CommonTextField
                  label=""
                  name="lastName"
                  value={formValues.lastName || ''}
                  onChange={handleInputChange}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: '14px', color: '#c2c2c2', mb: '17px' }}>Email Address<span style={{ color: 'red' }}> *</span></Typography>
                <CommonTextField
                  label=""
                  name="emailAddress"
                  value={formValues.emailAddress || ''}
                  onChange={handleInputChange}
                  error={!!errors.emailAddress}
                  helperText={errors.emailAddress}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: '14px', color: '#c2c2c2', mb: '17px' }}>Phone Number<span style={{ color: 'red' }}> *</span></Typography>
                <CommonTextField
                  label=""
                  name="phoneNumber"
                  value={formValues.phoneNumber || ''}
                  onChange={handleInputChange}
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber}
                />
              </Box>
              <Box sx={{ width: '14vw' }}>
                <Typography sx={{ fontSize: '14px', color: '#c2c2c2', mb: '17px' }}>Date Of Birth<span style={{ color: 'red' }}> *</span></Typography>
                <CommonTextField
                  label=""
                  type="date"
                  name="dateOfBirth"
                  value={formValues.dateOfBirth}
                  onChange={handleInputChange}
                  error={!!errors.dateOfBirth}
                  helperText={errors.dateOfBirth}
                />
              </Box>
              {/* <Box sx={{mt:'20px',width:'50vw',display:'flex',gap:'14px',ml:'18px'}}> */}
            </Box>
           <Box sx={{ width: '76vw', display: 'flex', flexDirection: 'row', gap: '20px', ml: '20px', mt: '10px' }}>
              <Box sx={{ width: '14vw' }}>
                <Typography sx={{ fontSize: '14px', color: '#c2c2c2', mb: '17px' }}>Gender<span style={{ color: 'red' }}> *</span></Typography>
                <CommonSelect
                  labelId="gender"
                  label=""
                  name="gender"
                  value={formValues.gender || ''}
                  onChange={handleInputChange}
                  error={!!errors.gender}
                  helperText={errors.gender}
                  options={gender}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: '14px', color: '#c2c2c2', mb: '17px' }}>Current CTC<span style={{ color: 'red' }}> *</span></Typography>
                <CommonTextField
                  label=""
                  name="currentCtc"
                  value={formValues.currentCtc || ''}
                  onChange={handleInputChange}
                  error={!!errors.currentCtc}
                  helperText={errors.currentCtc}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: '14px', color: '#c2c2c2', mb: '17px' }}>Expected CTC<span style={{ color: 'red' }}> *</span></Typography>
                <CommonTextField
                  label=""
                  name="expectedCtc"
                  value={formValues.expectedCtc || ''}
                  onChange={handleInputChange}
                  error={!!errors.expectedCtc}
                  helperText={errors.expectedCtc}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: '14px', color: '#c2c2c2', mb: '17px' }}>Notice Period(In days)<span style={{ color: 'red' }}> *</span></Typography>
                <CommonTextField
                  label=""
                  name="noticePeriods"
                  value={formValues.noticePeriods || ''}
                  onChange={handleInputChange}
                  error={!!errors.noticePeriods}
                  helperText={errors.noticePeriods}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: '14px', color: '#c2c2c2', mb: '17px' }}>Aadhar Number<span style={{ color: 'red' }}> *</span></Typography>
                <CommonTextField
                  label=""
                  name="aadharNumber"
                  value={formValues.aadharNumber || ''}
                  onChange={handleInputChange}
                  error={!!errors.aadharNumber}
                  helperText={errors.aadharNumber}
                />
              </Box>
            </Box>
            <Box sx={{ width: '76vw', display: 'flex', flexDirection: 'row', gap: '20px', ml: '20px', mt: '20px' }}>
            
              <Box>
                <Typography sx={{ fontSize: '14px', color: '#c2c2c2', mb: '10px' }}>Aadhar Card<span style={{ color: 'red' }}> *</span></Typography>
                <input
          type="file"
          accept=".pdf,.doc,.docx"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <Button
          onClick={handleBrowseClick}
          sx={{
            width:'220px',
            height:'40px',
            backgroundColor: '#f5f5f5',
            color: '#666666',
            border: '1px dashed #ccc',
            boxShadow: 'none',
            textTransform: 'none',
            cursor:'pointer',
           
          }}
        >
          Browse
        </Button>
        {aadharFile.length > 0&& (
          <Typography sx={{ mt: '10px', color: '#1677FF' }}>
            Selected file: {aadharFile[0].name}
          </Typography>
        )}
        {aadharError && (
          <Typography sx={{ mt: '10px', color: 'red' }}>
            {aadharError}
          </Typography>
        )}
 
              </Box>
            </Box>
          </Grid>
          <Box sx={{ width: '35%', display: 'flex', justifyContent: 'flex-end' }}>
 
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: path ? 'flex-start' :'flex-end', justifyContent:path?'flex-start': 'flex-end', mt: '10px', mr: '20px' }}>
        {path && (
        <Button
          onClick={handleBack}
          sx={{ border: 'InactiveBorder', color: '#848484', textTransform: 'none' }}>
          Previous
        </Button>
        )}
        <Button variant='contained' disabled={loading} sx={{ textTransform: 'none',backgroundColor:'#1677FF',color:'#fff' }} onClick={handleContinue}>Continue</Button>
      </Box>
      {/* </Paper> */}
    </Box>
  );
};
 
export default BulkUploadForm;