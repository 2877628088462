import { Chip, Grid, Typography, TextField, Box } from '@mui/material';
import React from 'react';
import CommonTextField from '../../../../common/CommonTextField';
// import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

export const Experience = ({ formValues, setFormValues }: any) => {
 
  // const dispatch=useAppDispatch();
  // const State=useAppSelector((state)=>state.resume);
  // const handleDeleteSkill = (skillToDelete: string) => {
  //   setFormValues((prev: any) => ({
  //     ...prev,
  //     skills: prev.skills.filter((skill: string) => skill !== skillToDelete),
  //   }));
  // };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index?: number) => {
    const { name, value } = event.target;
    if (typeof index === 'number') {
      const updatedEducation = [...formValues.experience];
      updatedEducation[index][name] = value;
      setFormValues({
        ...formValues,
        experience: updatedEducation,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };   
   
  return (
    <>
    <Box sx={{m:'10px'}}>
      <Typography
        sx={{
          fontSize: '14px',
          mb: '40px',
          backgroundColor: '#1677FF1A',
          color: '#1677FF',
          width: '100px',
          p: '3px',
          borderRadius: '3px',
          textAlign: 'center',
          fontWeight: '500',
        }}
      >
        Experience
      </Typography>

      <Typography variant="body2" sx={{ mb: '20px', ml:'14px', color:'#c2c2c2' }}>
        Skills
      </Typography>
      <Box sx={{ display:'flex', flexDirection:'row', justifyContent:'space-between', ml:'14px' }}>
      <Box>
        {formValues?.skills?.map((skill: string, index: number) => (
          
            <Chip
              label={skill || "Skill not Selected"}
              // onDelete={() => handleDeleteSkill(skill)}
              sx={{ margin: '4px' }}
            />
          
        ))}
        </Box>
        <Box sx={{mr:'180px'}}>
          <Typography sx={{ mb: '10px',fontSize:'14px', color:'#c2c2c2' }}>Total Years of Experience</Typography>
        <TextField
            variant="outlined"
            size="small"
            value={formValues?.totYearsOfExp || ''}
          />
          </Box>
        </Box>

      {formValues?.experience?.map((entry: any, index: number) => (
        <Box key={index} sx={{ml:'14px',mt:'10px'}}>
          <Typography sx={{mb:'10px',fontWeight:'bold'}}>{entry?.label}</Typography>
          <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
        
         <Grid item xs={4}>
         <Typography sx={{mb:'18px',color:'#adadad',fontSize:'14px'}}>Job Title</Typography>
         <CommonTextField
                  label=""
                  name="jobTitle"
                  value={entry.jobTitle}
                  onChange={(event) => handleInputChange(event, index)}
                />
         </Grid>
         <Grid item xs={8}>
         <Typography sx={{mb:'18px',color:'#c2c2c2',fontSize:'14px'}}>Company</Typography>
         <CommonTextField
                  label=""
                  name="company"
                  value={entry.company}
                  onChange={(event) => handleInputChange(event, index)}
                />
         </Grid>
         <Box sx={{width:'100px'}}>
         <Grid item>
         <Typography sx={{mb:'18px',color:'#c2c2c2',fontSize:'14px'}}>From</Typography>
         <CommonTextField
                  label=""
                  name="from"
                  value={entry.from}
                  onChange={(event) => handleInputChange(event, index)}
                />
         </Grid>
         </Box>
         <Box sx={{width:'100px'}}>
         <Grid item >
         <Typography sx={{mb:'18px',color:'#c2c2c2',fontSize:'14px'}}>To</Typography>
         <CommonTextField
                  label=""
                  name="to"
                  value={entry.to}
                  onChange={(event) => handleInputChange(event, index)}
                />
         </Grid>
         </Box>
        </Box>
        </Box>
        ))}
</Box>
    </>
  );
};
