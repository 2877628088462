import React from 'react';
import Switch from '@mui/material/Switch';

interface SwitchWithLabelProps {
  label: string;
  imageSrc: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SwitchWithLabel = ({ label, imageSrc, checked, onChange }:SwitchWithLabelProps) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
       <Switch
        checked={checked}
        onChange={onChange}
        inputProps={{ 'aria-label': label }}
        size="small"
      />
      <img src={imageSrc} alt={label} style={{ width: '20px', height: '20px' }} />
      <span style={{fontWeight:"bold"}}>{label}</span>
    </div>
  );
};

export default SwitchWithLabel;
