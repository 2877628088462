import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
// import { alpha, styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { fetchProfile } from "../../../redux/features/profileSlice";
import { AppDispatch, RootState } from "../../../redux/store";

const defaultPermissions = {
  addInterviewer: false,
  allPersonLogin: false,
  approveDeclineMrf: false,
  assignAndEditRecruiter: false,
  assignAndEditRecruiterLead: false,
  assignMrf: false,
  assignRolesToUsers: false,
  editMrfStage: false,
  interviewCalenderStatus: false,
  manageUserSettings: false,
  mrfUsers: false,
  mrfUsersApprovers: false,
  overAllReport: false,
  receiveAlerts: false,
  recruiterAccess: false,
  recruitment: false,
  scheduleRemindersForInterviews: false,
  useFiltersForLeadsApprovers: false,
  viewAddComments: false,
  viewAndManageRoles: false,
  viewInterviewFeedback: false,
  viewMrfStage: false,
  viewResumes: false,
};

const RoleDashboardDropdown: React.FC<{
  roleName: string;
  onSelectDashboard: (path: string) => void;
}> = ({ roleName, onSelectDashboard }) => {
  const [roleNames, setRoleName] = useState<string>("");
  const [permissionsList, setPermissionsList] = useState<Record<string, boolean>>({});
  const activePath = window.location.pathname;
  const users = localStorage.getItem("user");
  const parsedUser = users ? JSON.parse(users) : null;
  const roleNameLocal = parsedUser?.roleName || "Default Role";

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      const parsedUser = JSON.parse(user);
      setRoleName(parsedUser.roleNames || "");
      setPermissionsList(parsedUser.permissionsList || {});
    }
  }, []);

  const roleDashboardMap: {
    [key: string]: { path: string; permissionKey: keyof typeof defaultPermissions };
  } = {
    TEAMHEAD: { path: "/DashBoardRTH", permissionKey: "mrfUsers" },
    TALENTACQUISITIONHEAD: { path: "/DashBoardTAH", permissionKey: "assignAndEditRecruiterLead" },
    RECRUITERLEAD: { path: "/DashBoardRL", permissionKey: "assignAndEditRecruiter" },
    RECRUITER: { path: "/DashBoardR", permissionKey: "recruiterAccess" },
    APPROVERHEAD: { path: "/DashBoardAH", permissionKey: "approveDeclineMrf" },
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedRole = event.target.value;
    const dashboardDetails = roleDashboardMap[selectedRole];
    const permissionGranted = permissionsList[dashboardDetails.permissionKey];
    if (permissionGranted) {
      onSelectDashboard(dashboardDetails.path);
      setRoleName(selectedRole)
    } else {
      alert("You do not have permission to access this dashboard.");
    }
  };

  const isDashboardPath = Object.values(roleDashboardMap).some(
    (dashboard) => dashboard.path === activePath
  );

  return isDashboardPath ? (
    <Select
      value={roleNames || roleNameLocal}
      onChange={handleChange}
      displayEmpty
      style={{
        marginLeft: "1rem",
        color: "#000",
        backgroundColor: "white",
        borderRadius: "4px",
        padding: "0.25rem",
        height: "2rem",
        fontSize: "12px",
      }}
    >
      {Object.entries(roleDashboardMap).map(([role, details]) =>
        permissionsList[details.permissionKey] ? (
          <MenuItem sx={{ fontSize: "12px" }} key={role} value={role}>
            {role}
          </MenuItem>
        ) : null
      )}
    </Select>
  ) : null;
};

const NotificationDrawer: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleToggleDrawer}>
        <Badge badgeContent={1} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Drawer anchor="right" open={open} onClose={handleToggleDrawer}>
        <div style={{ width: 300, padding: 16 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Notifications</Typography>
            <IconButton onClick={handleToggleDrawer}>
              <CloseIcon />
            </IconButton>
          </Box>
          <List>
            <ListItem>
              <ListItemText
                primary="Hi Scott (Team Head 1)"
                secondary="Sr. UX Designer (RTH) has been approved by Michael (L1 Approver)"
              />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </>
  );
};

export const ProfileMenu: React.FC<{
  anchorEl: HTMLElement | null;
  onClose: () => void;
}> = ({ anchorEl, onClose }) => {
  const navigate = useNavigate();
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

  const handleProfileClick = () => {
    onClose();
    navigate("/profile");
  };

  const handleLogout = () => {
    // Logout logic
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("userId");
    navigate("/", { replace: true });
  };

  const handleCloseLogoutDialog = (confirm: boolean) => {
    setOpenLogoutDialog(false);
    if (confirm) {
      handleLogout();
    }
  };

  const handleLogoutClick = () => {
    onClose();
    setOpenLogoutDialog(true);
  };

  return (
    <>
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <List>
        <ListItem button onClick={handleProfileClick}>
          <ListItemText primary="Profile Settings" />
        </ListItem>
        <ListItem button onClick={handleLogoutClick}>
          <ListItemText
            primary="Logout"
            primaryTypographyProps={{ color: "error" }}
          />
        </ListItem>
      </List>
    </Popover>
     {/* Logout Confirmation Dialog */}
     <Dialog
        open={openLogoutDialog}
        onClose={() => handleCloseLogoutDialog(false)}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title">
          {"Are you sure you want to logout?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            { }
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => handleCloseLogoutDialog(false)}
            color="primary"
            sx={{ mr: "45px" }}
          >
            No, Cancel
          </Button>
          <Button
            onClick={() => handleCloseLogoutDialog(true)}
            color="primary"
            sx={{ mr: "35px" }}
            autoFocus
          >
            Yes, Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const HeaderBar: React.FC<{ title: string, subTitle: string }> = ({ title, subTitle }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const token = localStorage.getItem("token");
  const profile = useSelector((state: RootState) => state.profile.data?.data);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  let userId: string | undefined;
  if (token) {
    try {
      const decoded: any = jwtDecode(token);
      userId = decoded?.userId;
    } catch (error) {
      console.error("Invalid token:", error);
    }
  }

  useEffect(() => {
    if (userId) {
      dispatch(fetchProfile(userId));
    }
  }, [dispatch, userId]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDashboardSelect = (path: string) => {
    navigate(path);
  };

  return (
    <Box sx={{ backgroundColor: "#f0f4ff", padding: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold", fontSize: "1.25rem" }}>
            {title}
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: "300", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }}>
            <Box component="span" sx={{ color: "text.primary", fontSize: "12px" }}> Home </Box>
            <Box component="span" sx={{ color: "text.secondary", marginLeft: "4px", fontSize: "12px" }}>
              <Box component="span" sx={{ fontWeight: "bold" }}>.</Box>{" "}
              {subTitle}
            </Box>
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <RoleDashboardDropdown
            roleName={profile?.role}
            onSelectDashboard={handleDashboardSelect}
          />
          <NotificationDrawer />
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            color="inherit"
            onClick={handleProfileMenuOpen}
          >
            <Avatar
              alt="Profile"
              src={profile?.imageUrl || "https://mui.com/static/images/avatar/1.jpg"}
            />
          </IconButton>
          <Box sx={{ ml: 2, textAlign: "center", fontSize: "8px" }}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {profile?.firstName}
                </Typography>
                <Typography variant="caption">{profile?.designation}</Typography>
          </Box>
          <ProfileMenu anchorEl={anchorEl} onClose={handleProfileMenuClose} />
        </Box>
      </Box>
    </Box>
  );
};


export default HeaderBar;
