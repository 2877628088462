import {
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CandidateCard from "../../../common/CandidateCard";
import pdfImage from "../../../../assets/images/pdf 1.svg"
import {
  deleteCandidateById,
  getCandidateByRthId,
} from "../../../../redux/features/candidate";
import { useParams } from "react-router-dom";
import { StyledTableCell } from "./RthClosedTable";
import { GridDeleteIcon } from "@mui/x-data-grid";
import { toast } from "react-toastify";

export const CandidateDetails = ({ viewType, drawerOpen }: any) => {
  const [candidate, setCandidate] = useState<any>([]);
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [candidateIdd, setCandidateId] = useState<string>("");

  const handleDeleteButton = async (candidateId: string) => {
    await setCandidateId(candidateId);

    console.log("candidateIdd", candidateIdd);

    await deleteCandidateById(candidateId);

    setCandidateId("");
    toast.success("Deleted Sucessfully");
  };

  console.log(loading);
  console.log(id);
  const tableHead = [
    "SI.No",
    "Profile Details",
    "Email ID",
    "Mobile Number",
    "Resume",
    "Arrived Status",
    "Interview Status",
    "Action",
  ];

  useEffect(() => {
    setLoading(true);
    getCandidateByRthId(id)
      .then((response) => {
        setCandidate(response.data);
        console.log("response.data", response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, candidateIdd]);

  const getFileName = (url: string): string => {
    const fileNameWithParams = url.split("/").pop() || ""; // Get the last part of the URL
    const fileName = fileNameWithParams.split("?")[0]; // Remove query params

    // Truncate if the name exceeds 32 characters
    return fileName.length > 32 ? `${fileName.slice(0, 29)}...` : fileName;
  };

  return (
    <>
      {viewType === "grid" ? (
        <Box
          sx={{
            ml: "20px",
            mt: "0",
            mb: 3,
            height: 510,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "0px",
              height: "0px",
            },
          }}
        >
          {candidate.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50%",
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                Candidate not Assigned
              </Typography>
            </Box>
          ) : (
            <Grid container>
              {candidate.map((candidate: any) => (
                <Grid item key={candidate.id}>
                  <CandidateCard
                    name={candidate.firstName}
                    lastName={candidate.lastName}
                    status={candidate.status}
                    daysAgo={candidate.daysAgo}
                    phone={candidate.phoneNumber}
                    email={candidate.emailAddress}
                    pdfLink={candidate.resumeUrl || ""}
                    schedule={candidate.interviewStatus}
                    candidate={candidate}
                    drawerOpen={drawerOpen}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      ) : (
        <Box sx={{ width: "auto", backgroundColor: "#f0f4ff" }} p={1}>
          {candidate.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50%",
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                Candidate not Assigned
              </Typography>
            </Box>
          ) : (
            <Box sx={{ width: "100%", overflowX: "auto" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="RTH table">
                  <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
                    <TableRow
                      sx={{
                        "& th": {
                          padding: "4px 8px",
                          fontSize: "14px",
                        },
                      }}
                    >
                      {tableHead.map((data, index) => (
                        <StyledTableCell key={index}>{data}</StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {candidate.map((data: any, index: number) => (
                      <TableRow
                        key={index}
                        sx={{
                          "& td, & th": {
                            padding: "4px 8px",
                          },
                        }}
                      >
                        <StyledTableCell>{index + 1}</StyledTableCell>
                        <StyledTableCell>{data.firstName}</StyledTableCell>
                        <StyledTableCell>{data.emailAddress}</StyledTableCell>
                        <StyledTableCell>
                          
                          {data.phoneNumber}
                          </StyledTableCell>
                        <StyledTableCell>
                        <img src={pdfImage} alt="" />
                          {getFileName(data.resumeUrl)}
                        </StyledTableCell>
                        <StyledTableCell>{data.createdAt}</StyledTableCell>
                        <StyledTableCell>
                          <Chip
                            label={data.interviewStatus}
                            size="small"
                            sx={{
                              fontSize: "12px",
                              height: "px",
                              borderRadius: "1px",
                              backgroundColor: "#1677FF1A",
                              color: "#1677FF",
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            onClick={() => handleDeleteButton(data.candidateId)}
                          >
                            <GridDeleteIcon sx={{ color: "grey" }} />
                          </IconButton>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
