import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Box,
  SelectChangeEvent,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
} from '@mui/material';
import CommonTextField from '../../../common/CommonTextField';
import CommonSelect from '../../../common/CommomSelect';
import {  YearsInExp, YesNo } from '../../../../constants/dummyConst';
import CommonMultiSelect from '../../../common/CommonMultiSelect';
import { useLocation } from 'react-router-dom';
import DeleteIcon from '../../../../assets/images/del_Icon.svg';
import {useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { getSkill } from '../../../../redux/features/resume';
 
 
const Experience = ({ formValues, setFormValues,errors,setErrors }: any) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [newExperienceLabel, setNewExperienceLabel] = useState('');
  const location=useLocation();
  const dispatch=useAppDispatch();
  const path=location.pathname.includes("candidateForm");
  const State=useAppSelector((state)=>state.resume);
  const {skills}=State;
  const[skillOptions,setSkillOptions]=useState<any[]>([]);
 
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    
   setFormValues({
    ...formValues,
    [name]: value,
  });
  };
 
  const handleDeleteExperience = (index: number) => {
    const updatedExperience = formValues.experience.filter((_: any, i: number) => i !== index);
    setFormValues({
      ...formValues,
      experience: updatedExperience,
    });
  };
 
  const handleInputChangeNew = (event: React.ChangeEvent<HTMLInputElement>, index?: number) => {
    const { name, value } = event.target;
   
    if (typeof index === 'number') {
      setErrors((prevErrors: any) => {
        const updatedErrors = [...(prevErrors.experience || [])];
        updatedErrors[index] = updatedErrors[index] || {};
  
        // Validation: Check for max length (64 characters)
        if (value.length > 64) {
          updatedErrors[index][name] = `Maximum of 64 characters allowed for ${name}`;
        } else {
          updatedErrors[index][name] = ''; // Clear the error if input is valid
        }
  
        return { ...prevErrors, experience: updatedErrors };
      });
  
  
      const updatedEducation = [...formValues.experience];
      updatedEducation[index][name] = value;
      setFormValues((prevFormData:any) => ({
        ...prevFormData,
        experience: updatedEducation,
      }));

    } else {

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: value.length > 64 ? `Maximum of 64 characters allowed for ${name}` : '',
      }));

      setFormValues((prevFormData:any) => ({
        ...prevFormData,
        [name]: value,
      }));

    }
  };
 
  const handleAddExperience = () => {
    const currentCount = formValues.experience.length;
    if (currentCount < 20) {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        experience: [
          ...prevValues.experience,
          {
            expLabel: `Experience ${currentCount + 1}`,
            jobTitle: '',
            company: '',
            from: '',
            to: '',
          },
        ],
      }));
    }
    setErrors((prevErrors: any) => {
      const updatedErrors = [...(prevErrors.experience || []), {}];
      return { ...prevErrors, experience: updatedErrors };
    });
  
  };

 
  const handleDialogClose = () => {
    // Close the dialog
    setOpenDialog(false);
  };
 
  const handleDialogSubmit = () => {
    // Append the new experience to the formValues
    setFormValues((prevValues: any) => ({
      ...prevValues,
      experience: [
        ...(prevValues.experience|| []),
        {
          expLabel: newExperienceLabel,
          jobTitle: '',
          company: '',
          from: '',
          to: '',
        },
      ],
    }));
    // Reset the label and close the dialog
    setNewExperienceLabel('');
    setOpenDialog(false);
  };
 
  const toRoman = (num: number) => {
    const romanNumerals: [number, string][] = [
      [1000, 'M'], [900, 'CM'], [500, 'D'], [400, 'CD'],
      [100, 'C'], [90, 'XC'], [50, 'L'], [40, 'XL'],
      [10, 'X'], [9, 'IX'], [5, 'V'], [4, 'IV'],
      [1, 'I'],
    ];
 
    let result = '';
    for (let [value, symbol] of romanNumerals) {
      while (num >= value) {
        result += symbol;
        num -= value;
      }
    }
    return result;
  };

  const transformCityData = (data: any[]) => {
    return data.map(data => ({
      label: data.skillName,      // Use cityName as the label
      value: data.skillName, // Use cityId as value, fallback to cityName if cityId is null
    }));
  };

  useEffect(()=>{
    if (!formValues.experience || formValues.experience.length === 0) {
      setFormValues((prevFormData:any) => ({
        ...prevFormData,
        experience: [{
          jobTitle: '',
          company: '',
          from: '',
          to: '',
          expLabel: 'Latest Experience', // Label for the default entry
        }]
      }));

    }
  },[formValues,setFormValues])

  useEffect(()=>{
   dispatch(getSkill());
   
  },[dispatch])

  useEffect(()=>{
    const skillData=transformCityData(skills); 
   setSkillOptions(skillData);
  },[skills])

  
  return (
    <Box sx={{ pt: '4px',ml:path?'30px':'' }}>
      {/* <Paper elevation={0} sx={{ overflowY: 'auto', pt: '10px', position: 'relative', height: '62vh' }}> */}
        <Typography sx={{ fontSize: '20px', fontWeight: '500', mb: '27px',mt:'14px' }}>
        {path ? '5.Experience' : 'Experience'}
          </Typography>
        <Box sx={{ width: '50vw' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '10px' }}>Skills(You can choose upto 5 skills)</Typography>
              <CommonMultiSelect
                labelId=""
                label=""
                name="skills"
                value={formValues.skills || []}
                onChange={handleInputChange}
                options={skillOptions}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
          <Grid item xs={12} md={4} sx={{mt:'10px'}}>
             <Typography sx={{fontSize:'14px',color:'#bababa' , mb:'10px'}}>Do you have work Experience?</Typography>
             <CommonSelect
                  labelId="workExperience"
                  label=""
                  name="workExperience"
                  value={formValues.workExperience||''}
                  onChange={handleInputChange}
                  // error={!!errors.experience}
                  // helperText={errors.experience}
                  options={YesNo}
                />
             </Grid>
             <Grid item xs={12} md={4} sx={{mt:'10px'}}>
             <Typography sx={{fontSize:'14px',color:'#bababa' , mb:'10px'}}>Total Years in Experience</Typography>
             <CommonSelect
                  labelId="totYearsOfExp"
                  label=""
                  name="totYearsOfExp"
                  value={formValues.totYearsOfExp||''}
                  onChange={handleInputChange}
                  // error={!!errors.yearsInExperience}
                  // helperText={errors.yearsInExperience}
                  options={YearsInExp}
                />
             </Grid>
             </Grid>
          {formValues?.experience && formValues?.experience?.map((experience: any, index: number) => (
            <div key={index}>
                          <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography sx={{ mt: '16px', fontSize: '16px', fontWeight: 'bold' }}>
                 {index === 0
            ? `${toRoman(index + 1)}) Latest Experience`
        : `${toRoman(index + 1)}) ${experience.expLabel || `Experience ${index + 1}`}`}
 
              </Typography>
              {index > 0 && ( // Render the delete icon only for indexes greater than 0
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteExperience(index)}
                size="small"
                sx={{
                  ml: 1, // Add some margin for spacing
                  color: 'inherit', // Default color
                  '&:hover': {
                    color: 'red', // Change color to red on hover
                  },
                }}            
              >
                <img src={DeleteIcon} alt='Delete Icon' />
              </IconButton>
              )}
            </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '10px', mt: '22px' }}>Job Title</Typography>
                  <CommonTextField
                    label=""
                    name={`jobTitle`}
                    value={experience.jobTitle || ''}
                    onChange={(event) => handleInputChangeNew(event, index)}
                    error={!!errors.experience?.[index]?.jobTitle} // Check error for specific index
                    helperText={errors.experience?.[index]?.jobTitle} // Display error for specific index
                  
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '10px', mt: '22px' }}>Company</Typography>
                  <CommonTextField
                    label=""
                    name={`company`}
                    value={experience.company || ''}
                    onChange={(event) => handleInputChangeNew(event, index)}
                    error={!!errors.experience?.[index]?.company} // Check error for specific index
                    helperText={errors.experience?.[index]?.company} // Display error for specific index
                  
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '10px', mt: '22px' }}>From</Typography>
                  <CommonTextField
                    label=""
                    type={'date'}
                    name={`from`}
                    value={experience.from || ''}
                    onChange={(event) => handleInputChangeNew(event, index)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '10px', mt: '22px' }}>To</Typography>
                  <CommonTextField
                    label=""
                    name={`to`}
                    type={'date'}
                    value={experience.to || ''}
                    onChange={(event) => handleInputChangeNew(event, index)}
                  />
                </Grid>
              </Grid>
            </div>
          ))}
 
          <Box sx={{ mt: '8px' }}>
            <Button onClick={handleAddExperience}>+ Add Experience</Button>
          </Box>
        </Box>
 
        {/* Dialog for Adding Experience */}
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Add Experience</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter the label for the new experience.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Experience Label"
              type="text"
              fullWidth
              variant="outlined"
              value={newExperienceLabel}
              onChange={(e) => setNewExperienceLabel(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handleDialogSubmit}>OK</Button>
          </DialogActions>
        </Dialog>
      {/* </Paper> */}
    </Box>
  );
};
 
export default Experience;
 
 
