import React from 'react';
import { Box, Typography} from '@mui/material';

interface JobFooterProps {
  status?: string
}

const JobFooter: React.FC<JobFooterProps> = ({status }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
      { status === "waitingForL1L2" && (
       <Typography variant="body2" color="textSecondary" sx={{ padding:"2px", fontSize:'8px',color:"#8C8C8C" }}>
        Progress
      </Typography>)}
    </Box>
  );
}

export default JobFooter;
