import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReportTableView from "./ReportTableView";
import ReportTabs from "./ReportTabs";
import {  
  getMrfApprovalStatusTh,  
  getMrfApprovedReportTh,  
  getMrfStageReportTh,  
  getOverallMrfReportTh,
} from "../../Crud/report";

const ReportMain: React.FC = () => {
  const [tabsData, setTabsData] = useState<string[]>([]);

  const [currentReportData, setCurrentReportData] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = useState<String>(tabsData[0]);
  const userId = localStorage.getItem("userId") ?? "";
  const {roleName} = localStorage.getItem("user")?JSON.parse(localStorage.user):null;

  useEffect(()=>{
    const fetchTabsData=async()=>{
      if((roleName === "TEAMHEAD") || (roleName === "APPROVERHEAD"))
      {
      await setTabsData([
        "MRF Approval Status",
        "MRF Stage Report",
        "Approved MRF Report",
        "Overall MRF Report",
      ]);
      await setSelectedTab("MRF Approval Status");
    }
    else if((roleName === "TALENTACQUISITIONHEAD") || (roleName === "RECRUITERLEAD") || (roleName === "RECRUITER") ){
      await setTabsData(["Candidate Interview Status","Source Report","MRF Stage Report","Department wise MRF","Client wise MRF","Selection Rate","MRF Category Report","Overall report"]);
      await setSelectedTab("Candidate Interview Status")
    }
    }
    fetchTabsData();
  },[roleName])

  useEffect(() => {
    if (selectedTab === "MRF Approval Status") {
      getMrfApprovalStatusTh(userId).then((res) => {
        setCurrentReportData(res.data);
        console.log("respone of api",res);
      });
    } else if (selectedTab === "MRF Stage Report") {
      getMrfStageReportTh(userId).then((res) => {
        setCurrentReportData(res?.data);
        console.log(res);
      });
    } else if (selectedTab === "Approved MRF Report") {
      getMrfApprovedReportTh(userId).then((res) => {
        setCurrentReportData(res?.data);
        console.log(res);
      });
    } else if (selectedTab === "Overall MRF Report") {
      getOverallMrfReportTh(userId).then((res) => {
        setCurrentReportData(res?.data);
        console.log(res);
      });
    }
  }, [selectedTab,userId]);

  console.log("currentReportData", currentReportData);

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Box  sx={{flexBasis : "5%" }}>
        <Typography>SideBar</Typography>
      </Box>
      <Box sx={{maxWidth:"100%", flexBasis : "95%",display: "flex", flexDirection: "column" , overflow : "scroll"  }}>
        <Box>
          <ReportTabs setSelectedTab={setSelectedTab} tabsData={tabsData} />
        </Box>
        <Box >
          <ReportTableView
            currentReportData={currentReportData}
            selectedTab={selectedTab}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ReportMain;
