import React from 'react';
import { Box, Typography } from '@mui/material';
import priorityHighSymbol from '../../assets/images/highPriority.svg';
import priorityLowSymbol from '../../assets/images/lowPriority.svg';
import priorityMediumSymbol from '../../assets/images/mediumPriority.svg';

interface ResumeTimeProps {
    priority?: string;
    seqId?: string;
}

const ResumeTime: React.FC<ResumeTimeProps> = ({ priority, seqId }) => {

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 1, pb: '5px' }} gap={2}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', ml: '1px' }}>
                <Typography sx={{ fontWeight: '600', fontSize: '0.625rem', color: '#A3A1A1' }} > #{seqId}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography gap={1} sx={{ fontWeight: "700", fontSize: "0.625rem", color: "#8C8C8C" }}>
                    {priority === "High" && <img src={priorityHighSymbol} alt="High Priority" style={{ width: "80%" }} />}
                    {priority === "Medium" && <img src={priorityMediumSymbol} alt="Medium Priority" style={{ width: "80%" }} />}
                    {priority === "Low" && <img src={priorityLowSymbol} alt="Low Priority" style={{ width: "80%" }} />}
                </Typography>
            </Box>
        </Box>
    );
};

export default ResumeTime;

