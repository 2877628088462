import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { UserData } from '../../components/admin/adminTabs/User/EditUser';
import { BaseUrl } from '../../constants/Crudurl';

interface UserState {
  users: {
    data: UserData[];
  };
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: UserState = {
  users: { data: [] },
  status: 'idle',
  error: null,
};

export const fetchUsers = createAsyncThunk('user/fetchUsers', async (search?:string) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(`${BaseUrl}/theronAdmin/getAllActiveUser${search?`?search=${search}`:''}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
});

export const addUser = createAsyncThunk('user/addUser', async (userData: Partial<UserData>) => {
  const token = localStorage.getItem("token");
  const response = await axios.post(`${BaseUrl}/auth/register`, userData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
});

export const deleteUser = createAsyncThunk('user/deleteUser', async (userId: string) => {
  const token = localStorage.getItem("token");
  await axios.delete(`${BaseUrl}/auth/deleteUser/${userId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return userId;
});

export const updateUser = createAsyncThunk('user/updateUser', async ({ userId, roleId }: { userId: string; roleId: string }) => {
  const token = localStorage.getItem('token');
  await axios.put(
    `${BaseUrl}/theronAdmin/editUserRoleById?userId=${userId}&roleId=${roleId}`,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return { userId, roleId };
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      })
      .addCase(addUser.fulfilled, (state, action: PayloadAction<UserData>) => {
        if (state.users.data) {
          state.users.data.push(action.payload);
        } else {
          state.users.data = [action.payload];
        }
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.users.data = state.users.data.filter((user: UserData) => user.id !== action.payload);
      })
      .addCase(updateUser.fulfilled, (state, action: PayloadAction<{ userId: string, roleId: string }>) => {
        const { userId, roleId } = action.payload;
        const index = state.users.data.findIndex(user => user.id === userId);
        if (index !== -1) { state.users.data[index].roleId = roleId; }
      })
  },
});

export default userSlice.reducer;