import { Grid, Typography, Box, CircularProgress } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BaseUrl } from '../../../../../constants/Crudurl';

const chartColors: { [key: string]: string } = {
    "In-Progress": '#00A76F',
    Closed: '#FFAB00',
};

type StatusReportItem = {
    label: string;
    value: number;
};

function PieChartBoard2() {
    const data = useSelector((state: RootState) => state.profile.data);
    const userID = data?.data?.userId;
    const [statusReport, setStatusReport] = useState<StatusReportItem[]>([]);
    const total = statusReport.reduce((sum, item) => sum + item.value, 0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPieChartReport = async () => {
            try {
                setLoading(true)
                const response = await axios.get(
                    `${BaseUrl}/dashboard/approvedMrfReportForAssigned/${userID}`
                );
                const { "In-Progress": inProgress, Closed } = response?.data?.data || {};
                setStatusReport([
                    { label: "In-Progress", value: inProgress || 0 }, 
                    { label: "Closed", value: Closed || 0 }, 
                ]);
            } catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        if (userID) {
            fetchPieChartReport();
        }
    }, [userID]);

    return (
        <Grid container sx={{ borderRadius: '8px', height: '280px', backgroundColor: '#FFFFFF', padding: '8px', position: 'relative' }}>
            <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <Typography sx={{ fontWeight: '500', fontSize: '12px', padding: '8px' }}>
                    Approved MRF Report
                </Typography>
            </Grid>
            {loading ? (
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10 }}>
            <CircularProgress size={50} />
          </Box>
        ) : (
            <Grid item sx={{ alignItems: 'center', marginLeft: '80px' }}>
                <Box sx={{ position: 'relative', height: 200, width: 260 }}>
                    <PieChart
                        height={200}
                        width={260}
                        slotProps={{
                            legend: { hidden: true },
                        }}
                        series={[
                            {
                                data: statusReport.map((item) => ({
                                    ...item,
                                    color: chartColors[item.label],
                                })),
                                innerRadius: 50,
                            },
                        ]}
                    />
                    {!loading && (
                    <Grid item sx={{ position: 'absolute', top: '45%', left: '31%', transform: 'translate(-45%, -31%)', textAlign: 'center', pointerEvents: 'none' }}>
                        <Typography component="span" sx={{ fontSize: '16px', fontWeight: 'bold', color: '#000' }}>
                            {total}
                        </Typography>
                        <Typography component="span" sx={{ fontSize: '8px', display: 'block' }}>
                            Total Approved
                        </Typography>
                    </Grid>
                    )}
                </Box>
            </Grid>
             )}
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                {statusReport.map((item, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginRight: '16px', marginBottom: '8px' }}>
                        <Box sx={{ width: 9, height: 9, borderRadius: '1px', backgroundColor: chartColors[item.label], marginRight: '8px', padding: '2px' }}></Box>
                        <Typography sx={{ color: '#6B7280', fontSize: '10px', padding: '2px' }}>{item.label}</Typography>
                        <Typography sx={{ color: '#6B7280', fontSize: '10px', fontWeight: 'bold', marginLeft: '8px' }}>{item.value}</Typography>
                    </Box>
                ))}
            </Grid>
        </Grid>
    );
}

export default PieChartBoard2;
