import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FileUploadState {
  selectedFiles: File[];
}

const initialState: FileUploadState = {
  selectedFiles: [],
};

const fileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState,
  reducers: {
    setSelectedFiles: (state, action: PayloadAction<File[]>) => {
      state.selectedFiles = action.payload;
    },
    clearSelectedFiles: (state) => {
      state.selectedFiles = [];
    },
  },
});

export const { setSelectedFiles, clearSelectedFiles } = fileUploadSlice.actions;
export default fileUploadSlice.reducer;
