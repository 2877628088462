import axios from "axios";
import { PROFILE_API_URL } from "../constants/Crudurl";

export const profilePictureUpdate = async (id: string, formData: FormData) => {
  return axios.post(
    `${PROFILE_API_URL}/updateProfilePicture?id=${id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
export const getProfilePictureURL = async ( formData: FormData) => {
  return await axios.post(
    `${PROFILE_API_URL}/getImageUrl`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
export const updateProfilePictureAPI = async (id:string, imageUpdate: any) => {
  return await axios.post(
    `${PROFILE_API_URL}/updateProfilePicture/${id}`,imageUpdate
  );
};
export const deleteProfilePictureAPI = async (userId:string) => {
  return await axios.put(
    `${PROFILE_API_URL}/removeProfilePic/${userId}`
  );
};