import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import shareIcon2 from "../../assets/images/shareIcon2.svg";
import citplLogo from "../../assets/images/citplLogo.svg";
import yearsIcon from "../../assets/images/yearsIcon.svg";
import locationIconAj from "../../assets/images/locationIconAj.svg";
import salaryIconAj from "../../assets/images/salaryIconAj.svg";
import postedIconAj from "../../assets/images/postedIconAj.svg";
import { Job } from "../../redux/features/jobcardSlice";
import { BASE_COPY_URL } from "../../constants/Crudurl";
import { toast } from "react-toastify";

const CustomLogoValueComp = ({
  logo,
  logoValue,
}: {
  logo: string;
  logoValue: string | undefined;
}) => {
  return (
    <Box display={"flex"} gap={2}>
      <img src={logo} alt="" />
      <Typography color={"#00000099"} fontSize={"14px"}>
        {logoValue}
      </Typography>
    </Box>
  );
};

interface prop {  
  setClickedIndex: Function;
  clickedIndex: number;
  allPostedJobs: Job[];
}

const AjSelectAndView: React.FC<prop> = ({
  setClickedIndex,  
  clickedIndex,
  allPostedJobs,
}) => {

  const handleItemClick = (index: number) => {
    setClickedIndex(index);
  };

  const handleShareButtonClick=()=>{
    const link=`${BASE_COPY_URL}/candidateForm/${allPostedJobs[clickedIndex]?.rthId}`;
    console.log("clickIndex",allPostedJobs[clickedIndex].rthId);
    navigator.clipboard.writeText(link);
    toast.success("Link Copied Sucessfully")
  }

  return (
    <Box
      sx={{
        backgroundColor: "white",
        width: "95%",
        margin: "auto",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
      }}
    >
      <Box display={"flex"} flexDirection={"row"}>
        <Box
          flexBasis={"25%"}
          sx={{
            maxHeight: "100%",
            overflowY: "auto",
          }}
        >
          {allPostedJobs.length === 0 ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="100%" // Ensures it takes up the full height of its container
              textAlign="center"
              border="1px solid #e5e8e8"
            >
              <Typography fontSize={14} color="#384250">
                No Jobs Posted
              </Typography>
            </Box>
          ) : (
            allPostedJobs.map((data, index) => (
              <Box
                key={index} // Add a key to each mapped item
                display={"flex"}
                flexDirection={{ md: "row", xs: "column" }}
                justifyContent={"space-between"}
                p={1}
                alignItems={"center"}
                flexWrap={"wrap"}
                alignContent={"center"}
                borderBottom={"1px solid #e5e8e8"}
                borderRight={"1px solid #e5e8e8"}
                borderLeft={"none"}
                onClick={() => handleItemClick(index)}
                sx={{
                  cursor: "pointer",
                  backgroundColor:
                    index === clickedIndex ? "#1677FF05" : "none",
                }}
              >
                <Box flexBasis={"10%"}>
                  <img
                    src={citplLogo}
                    alt="B Icon"
                    width={"60px"}
                    height={"60px"}
                  />
                </Box>

                <Box flexBasis={"50%"} color={"#384250"}>
                  <Typography fontSize={13}>{data.positionTitle}</Typography>

                  <Typography fontSize={11} color={"#0000004D"}>
                    {data.business.businessName} | {data.createdAt}
                  </Typography>
                </Box>

                <Box flexBasis={"10%"}>
                  <IconButton onClick={handleShareButtonClick}>
                    <img src={shareIcon2} alt="" />
                  </IconButton>
                </Box>
              </Box>
            ))
          )}
        </Box>

        <Box
          flexBasis={"75%"}
          sx={{
            height: "500px",
            overflowY: "auto",
            border: "1px solid #FCFCFD",
            backgroundColor: "#FCFCFD",
          }}
        >
          {allPostedJobs.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Typography
                fontWeight={"bold"}
                color={"#000000CC"}
                fontSize={"16px"}
              >
                No contents to show
              </Typography>
            </Box>
          ) : (
            <>
              <Box
                margin={"auto"}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
                sx={{
                  backgroundColor: "white",
                  width: "70%",
                  borderRadius: "2px",
                  marginTop: "10px",
                }}
                p={2}
              >
                <CustomLogoValueComp
                  logo={yearsIcon}
                  logoValue={
                    allPostedJobs[clickedIndex]?.minExperience +
                    " - " +
                    allPostedJobs[clickedIndex]?.maxExperience                    
                  }
                />
                <CustomLogoValueComp
                  logo={locationIconAj}
                  logoValue={allPostedJobs[clickedIndex]?.location}
                />
                <CustomLogoValueComp
                  logo={salaryIconAj}
                  logoValue={allPostedJobs[clickedIndex]?.ctc}
                />
                <CustomLogoValueComp
                  logo={postedIconAj}
                  logoValue={allPostedJobs[clickedIndex]?.createdAt}
                />
              </Box>

              <Box
                margin={"auto"}
                display={"flex"}
                flexDirection={"column"}
                marginBottom={"10px"}
                gap={3}
                sx={{
                  backgroundColor: "white",
                  width: "70%",
                  borderRadius: "2px",
                  marginTop: "10px",
                }}
                p={2}
              >
                <Box>
                  {/* <Box marginBottom={"20px"}>
                    <Typography
                      fontWeight={"bold"}
                      color={"#000000CC"}
                      fontSize={"14px"}
                    >
                      What You Will Do
                    </Typography>
                  </Box> */}
                  {/* <Box display={"flex"} flexDirection={"column"} gap={1}>
                    <ul style={{ paddingLeft: "30px", margin: 0 }}>
                      {datas[clickedIndex].whatYouWillDo.map(
                        (whatYouWillDoData: string) => (
                          <li
                            key={whatYouWillDoData}
                            style={{ paddingBottom: "6px" }}
                          >
                            <Typography color={"#00000099"} fontSize={"14px"}>
                              {whatYouWillDoData}
                            </Typography>
                          </li>
                        )
                      )}
                    </ul>
                  </Box> */}
                              <div
              dangerouslySetInnerHTML={{
                __html: allPostedJobs[clickedIndex]?.jdTemplateText?.replace(/class=/g, "className="),
              }}
            />
                </Box>
                {/* <Box>
                  <Box marginBottom={"20px"}>
                    <Typography
                      fontWeight={"bold"}
                      color={"#000000CC"}
                      fontSize={"14px"}
                    >
                      Preferred Qualifications
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"column"} gap={1}>
                    <ul style={{ paddingLeft: "30px", margin: 0 }}>
                      {datas[clickedIndex].preferredQualifications.map(
                        (preferredQualificationsData: string) => (
                          <li
                            key={preferredQualificationsData}
                            style={{ paddingBottom: "6px" }}
                          >
                            <Typography color={"#00000099"} fontSize={"14px"}>
                              {preferredQualificationsData}
                            </Typography>
                          </li>
                        )
                      )}
                    </ul>
                  </Box>
                </Box> */}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AjSelectAndView;
