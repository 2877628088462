import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BaseUrl } from '../../constants/Crudurl';

export interface Job {
    department?: any;
    typeOfRth?: string;
    modeOfRth?: string;
    modesofRth?: string;
    posted?: string;
    business?: any;
    tabStatus?: string;
    image?: string;
    closeImage?: string;
    name?: string;
    reqName?: string;
    moveTo?: string;
    l1Status?: string;
    l2Status?: string;
    status?: string;
    priority?: string;
    rthId? : string;
    rthProcess?: string;
    id?: Number;
    level?: string;
    positionTitle?:string;
    businessName?:string;
    seqId?:string;
    posReportsTo:any;
    userId?: any;
    numOfPosition?: any;
    jobPosted?: any;
    createdAt?:string;
    maxExperience?:string;
    minExperience?:string;
    location?:string;
    ctc?:string;
    recruiterAssigned?:boolean
    numOfOpenPosition?:any
    jdTemplateText?:any;
    postedIn?:any;
    l1Approval: {
      userId: string;
    };

  }

  interface JobState {
    overAllRth: Job[];
    tobeAssigned: Job[];
    asignedRTh: Job[];
    myRequest: Job[];
    inProgress: Job[];
    closed: Job[];
    WaitingToMoveToJob:Job[];
    ReadyForJDCreation:Job[];
    Resume:Job[];
    JobPost:Job[];

    myRquestCounts: any;
    loading: boolean;
    error: string | null;
    businessName: string | null;
    jobData: Job | null;  
  }

  const initialState: JobState = {
    overAllRth: [],
    tobeAssigned: [],
    asignedRTh:[],
    myRequest: [],
    inProgress: [],
    closed: [],
    JobPost:[],
    WaitingToMoveToJob:[],
    ReadyForJDCreation:[],
    Resume:[],
    myRquestCounts:{
      "L1 Status Declined":0,
      "L1 Status Pending":0,
      "2 Status Approved":0,
      "L2 Status Declined":0,
      "L2 Status Pending":0
    },
    loading: false,
    error: null,
    businessName: null,
    jobData: null,  
  };



export const fetchJobs = createAsyncThunk(
    'jobs/fetchJobs',
    async ({ userId, teamHeadFilterDto }: { userId: string | undefined; teamHeadFilterDto: any }, { rejectWithValue }) => {
      try {        
        const token = localStorage.getItem('token'); 
        const response = await axios.post(
          `${BaseUrl}/homePage/teamHead/${userId}`,teamHeadFilterDto,
          {
            headers: {
              Authorization: `Bearer ${token}`, 
            },
          }
        );              
        console.log("hey inga paaru"); 
        console.log(response.data); 
        return response.data.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );


  export const movetojob = createAsyncThunk(
    'movetojob/job',
    async ({ rthId, status }: { rthId: string; status: string }, { rejectWithValue }) => {
      console.log(rthId,status);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.put(
          `${BaseUrl}/rth/setRthUpdate/${rthId}/${status}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data);
        return response.data.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );




  export const jobpost = createAsyncThunk(
    'jobpost/job',
    async ({ rthId }: { rthId: string }, { rejectWithValue }) => {
      console.log(rthId);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(
          `${BaseUrl}/rth/${rthId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data);
        return response.data.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const fetchJobById = createAsyncThunk('job/fetchJobById', async (id: string, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token'); 
      const response = await axios.get(`${BaseUrl}/rth/viewRthByRthId/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
    });
      return response.data.data;
    } catch (error) {
      return rejectWithValue('Failed to fetch job data');
    }
  });  





const jobSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.loading = false;     
        state.overAllRth = action.payload['OverAllRth'];
        state.tobeAssigned = action.payload['ToBeAssigned'];
        state.asignedRTh = action.payload['AssignedRth'];
        state.myRequest = action.payload['MyRequest'];
        state.inProgress = action.payload['InProgress'];
        state.closed = action.payload['Closed'];
        state.JobPost=action.payload['JobPost'];
        state.WaitingToMoveToJob=action.payload['WaitingToMoveToJob']
        state.ReadyForJDCreation=action.payload['ReadyForJDCreation']

        const myRequestCounts=action.payload["MyRequest's Count"];
        if(myRequestCounts){
          state.myRquestCounts=myRequestCounts["MyRequest L1L2 COUNT"] || {};
        }
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      builder
      .addCase(fetchJobById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchJobById.fulfilled, (state, action) => {
        state.loading = false;
        state.jobData = action.payload; // Store fetched job data
      })
      .addCase(fetchJobById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default jobSlice.reducer;