import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'

interface postinProps{
  label:string
  jobPosted: string
  handleRecruiterButton:()=>void
}

const Postin:React.FC<postinProps> = ({label,handleRecruiterButton, jobPosted}) => {
  

  // const dispatch = useDispatch<AppDispatch>();  
  const [buttonName,setButtonName]=useState("");

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation(); // Prevent the parent Box's onClick event
    handleRecruiterButton(); // Call the passed recruiter button handler
  };
    

  useEffect(()=>{
    
    if(label === "Job Post")
    { if(!jobPosted){
      setButtonName("Job Post")
    } else if (jobPosted){
      setButtonName("Post in")
    }
    
    
      
    }
    // if(label === "Job Post" )
    //   {
    //     setButtonName("post in")
    //   }
    if(label === "Ready for JD Creation")
    {
      setButtonName("Create JD")
    }
    if(label === "Waiting to Move to Job")
    {
      setButtonName("Move to Job")
    }
  },[label,jobPosted])

  return (
    <>
    <Button variant="contained" 
    sx={{ padding: '6px 16px', fontSize: '10px', minWidth: '80px'}} 
    onClick={handleButtonClick}
    >{buttonName}</Button>
    </>
  )
}

export default Postin