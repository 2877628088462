import { List, ListItem, ListItemText,Box, ListItemIcon, Tooltip} from '@mui/material';
import completed from '../../../../assets/images/Resume_tick.svg';
import Draft from '../../../../assets/images/Resume_Clock.svg';

const FileList= ({ files, selectedFile, onSelect }:any) => {

    
  const formatFileName = (name: string): { shortName: string; isTruncated: boolean; originalName: string } => {
    if (!name) return { shortName: '', isTruncated: false, originalName: '' };
  
    // Extract the actual filename by removing the timestamp prefix
    const actualName = name.split('_').slice(1).join('_');
    const maxLength = 7;
    const extensionIndex = actualName?.lastIndexOf('.');
  
    if (extensionIndex === -1) return { shortName: actualName, isTruncated: false, originalName: actualName };
  
    const baseName = actualName.substring(0, extensionIndex);
    const extension = actualName.substring(extensionIndex);
  
    const isTruncated = baseName.length > maxLength;
    const shortName = isTruncated
      ? `${baseName.substring(0, maxLength)}...${extension}`
      : actualName;
  
    return { shortName, isTruncated, originalName: actualName };
  };
  
//  useEffect(() => {
//     if (isFirstRender.current && files?.length > 0) {
//       onSelect(files[0]?.id);
//       isFirstRender.current = false; // Prevent subsequent runs from selecting the first file
//     }
//   }, [files, onSelect]);

  return (
    <Box sx={{ width: '20vw',height:'50vh' }}>
    <Box sx={{ padding: 2, borderRadius: 2 }}>
      
      <List sx={{width:'14vw'}}>
        {files?.map((file:any) => {
          const { shortName,originalName } = formatFileName(file?.name);
          const isCompleted = file?.status === 'Completed';
          return(        
          <ListItem
            key={file?.id}
            sx={{
              border:'1px solid transparent',
              borderRadius: '8px',
              marginBottom: 1,
              backgroundColor:selectedFile===file?.id ? '#F0F5FF':'#fff',
              color:selectedFile===file?.id ? '#1677FF':'#A9A9A9',
              cursor: isCompleted ? 'not-allowed' : 'pointer',
            }}
            onClick={() => {
              if (!isCompleted) {
                onSelect(file?.id);
              }
            }}
          >
            <Tooltip title={originalName}>
         <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <ListItemText sx={{fontSize:'6px',marginRight:'20px'}} primary={shortName} />
            
            <ListItemIcon>
                <img
                  src={file?.status === 'Completed' ? completed : Draft}
                  alt={file?.status === 'Completed' ? 'Completed' : 'Draft'}
                  style={{ width: '24px', height: '24px' }} 
                />
              </ListItemIcon>
              </Box>
              </Tooltip>
          </ListItem>
        )
})}
      </List>
    </Box>
    </Box>
  );
};

export default FileList;
