import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import { useLocation } from "react-router-dom";
import legendLogo from "../../../assets/images/legendLogo.svg";
import { getRecruiterLead, getRthById } from "../../../Crud/rth";
import {
  assignRecruiterLead,
  resetStatus,
  setSelectedRecruiter,
} from "../../../redux/features/recruiterSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { toast } from "react-toastify";
import editIcon2 from "../../../assets/images/editIcon2.svg";

export interface AssignRecruiterProps {
  numOfPosition?: string;
  numOfOpenPosition?: number;
}

const AssignRecruiterLead: React.FC<AssignRecruiterProps> = ({
  numOfOpenPosition,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const recruiterId = useSelector(
    (state: RootState) => state.recruiter.selectedRecruiter
  );
  const status = useSelector((state: RootState) => state.recruiter.status);
  const [recruiterIds, setRecruiterIds] = useState<string>("");
  console.log("recruiterIds", recruiterIds);
  const [load, setLoad] = useState<number>(0);
  const [recruiterName, setRecruiterName] = useState<string>("");

  const [isButtonClicked, setButtonClicked] = useState(false);
  const [recruiterData, setRecruiterData] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [recruiterDesig, setRecruiterDesig] = useState<string | null>(null);
  const [isAssigned, setIsAssigned] = useState<boolean>();
  const [isRecruiterAssigned,setIsRecruiterAssigned]=useState<boolean>();
  const [imgUrl, setImgUrl] = useState<string | undefined>();
  const userId = localStorage.getItem("userId") ?? "";
  const location = useLocation();
  const { id } = location.state || {};
  const [view, setView] = useState<boolean>(false);

  useEffect(() => {
    setRecruiterName("");
    setSelectedDate(null);
    dispatch(setSelectedRecruiter(""));
    dispatch(resetStatus());
  }, [id, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const recruiterLeadResponse = await getRecruiterLead();
        const recruiterData = recruiterLeadResponse.data;
        setRecruiterData(recruiterData || []);

        const response = await getRthById(id);
        const assigned = response.data.recruiterLeadAssigned;
        const recruiterAssigned=response.data.recruiterAssigned;
        setIsAssigned(assigned);
        setIsRecruiterAssigned(recruiterAssigned);

        if (assigned) {
          setButtonClicked(true);
          setView(true);
          const response = await getRthById(id);
          console.log(response);
          setRecruiterName(response.data.assignedRecruiterLead[0].userName);
          setRecruiterDesig(response.data.assignedRecruiterLead[0].designation);
          setSelectedDate(response.data.recruiterLeadAssignedDate);
          setImgUrl(response.data.assignedRecruiterLead[0].imageUrl);
          console.log(recruiterName, recruiterDesig, imgUrl);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the async function
  }, [id, load,imgUrl,recruiterDesig,recruiterName]);

  const handleChange = (event: SelectChangeEvent) => {
    const selectedRecruiterId = event.target.value as string;
    dispatch(setSelectedRecruiter(selectedRecruiterId));

    const currentDate = new Date();

    const formattedDate = currentDate.toISOString().split("T")[0];
    setSelectedDate(formattedDate);
  };

  const handleButtonClick = () => {
    setButtonClicked(true);
  };

  const handleButtonCancel = () => {
    setButtonClicked(false);
    setRecruiterName("");
    setSelectedDate(null);
    dispatch(setSelectedRecruiter(""));
    dispatch(resetStatus());
    setLoad(8);
  };

  const handleSubmit = () => {
    if (recruiterId && selectedDate) {
      dispatch(
        assignRecruiterLead({
          rthId: id,
          recruiterLeadId: recruiterId,
          assignedDate: selectedDate,
          taId: userId,
        })
      );
      toast.success("Recruiter Lead assigned Sucessfully");
      setLoad(1);
      setView(true);
    } else if (!recruiterId && !selectedDate) {
      toast.warn("Select Recruiter Lead");
    }
  };

  const handleEditButton=()=>{
    console.log("isRecruiterAssigned",isRecruiterAssigned);
    if(isRecruiterAssigned)
    {
      toast.warning("You can't edit since the recruiter has been assigned.")
    }
    else{
      setView(false);
    }
  }

  const formatDateForDisplay = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = date.toLocaleString("en-GB", {
      month: "long",
      timeZone: "UTC",
    });
    const year = date.getUTCFullYear();
    return `${day} ${month} ${year}`;
  };

  return (
    <Box>
      {!isButtonClicked && (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          flexDirection={"row"}
        >
          <Typography variant="h6" sx={{ fontSize: "12px", color: "#8C8C8C" }}>
            Click "Assign Recruiter Lead" to allocate the {numOfOpenPosition}{" "}
            available positions.
          </Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{ border: "1.5px solid" }}
            onClick={handleButtonClick}
          >
            Assign Recruiter Lead
          </Button>
        </Box>
      )}

      {isButtonClicked && (
        <Box>
          {
            isAssigned && (
              <Box justifyContent={"end"} display={"flex"}>
            <Box
              component={ButtonBase}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              onClick={handleEditButton}
              sx={{
                padding: 1,
                borderRadius: 1,
                transition: "background-color 0.3s",
                "&:hover": { backgroundColor: "rgba(22, 119, 255, 0.1)" },
              }}
            >
              <IconButton sx={{ padding: 0 }}>
                <img src={editIcon2} alt="edit Icon" />
              </IconButton>
              <Typography color={"#1677FF"} sx={{ marginLeft: 0.5 }}>
                Edit
              </Typography>
            </Box>
          </Box>
            )
          }
          
          <TableContainer
            component={Paper}
            sx={{
              marginBottom: "1.5%",
              borderRadius: "0px",
              boxShadow: "none",
            }}
          >
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F9F9F9" }}>
                  <TableCell align="left">No. of Open Position</TableCell>
                  <TableCell align="left">Assign Recruiter Lead</TableCell>
                  <TableCell
                    sx={{ alignContent: "center", alignItems: "center" }}
                    align="center"
                  >
                    <span>Assigned Date</span>
                    <img src={legendLogo} alt="" />{" "}
                    <span style={{ color: "grey", fontSize: "10px" }}>
                      assigned date can't be edited
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">{numOfOpenPosition}</TableCell>
                  <TableCell align="left" sx={{ padding: "0px" }} width={"50%"}>
                    <FormControl fullWidth sx={{ paddingTop: "0px" }}>
                      <Select
                        disabled={view}
                        id="demo-simple-select"
                        value={recruiterId || ""}
                        name="recuriterAssign"
                        label="Choose Recruiter"
                        displayEmpty
                        onChange={handleChange}
                        renderValue={(selected) => {
                          if (isAssigned === false && selected === "") {
                            return (
                              <span style={{ color: "grey" }}>
                                Choose Recruiter
                              </span>
                            );
                          }

                          return (
                            <Box
                              display={"flex"}
                              alignItems="center"
                              gap={1}
                              borderRadius={"5px"}
                              border={"1px solid grey"}
                              width={"fit-content"}
                              padding={"5px"}
                            >
                              <Avatar
                                sizes="small"
                                alt={recruiterName}
                                src={imgUrl}
                              />
                              <Box display={"flex"} flexDirection={"column"}>
                                <Box
                                  display="flex"
                                  flexDirection={"row"}
                                  alignItems={"center"}
                                  gap={0.5}
                                >
                                  <Typography
                                    variant="h6"
                                    sx={{ fontSize: "12px", color: "black" }}
                                  >
                                    {recruiterName}
                                  </Typography>
                                </Box>
                                <Typography
                                  variant="body2"
                                  sx={{ fontSize: "12px", color: "#8C8C8C" }}
                                >
                                  {recruiterDesig}
                                </Typography>
                              </Box>
                            </Box>
                          );
                        }}
                        sx={{
                          color: "grey",
                          "& .MuiSelect-select": {
                            border: "none",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiSelect-iconOutlined": {
                            color: "inherit",
                            border: "none",
                          },
                        }}
                      >
                        {recruiterData.map((data, index) => (
                          <MenuItem
                            key={index}
                            value={data.userId}
                            sx={{ display: "flex", alignItems: "center" }}
                            onClick={() => {
                              setRecruiterIds(data?.userId);
                              setRecruiterName(data?.firstName);
                              setImgUrl(data.imageUrl);
                              setRecruiterDesig(data.designation);
                            }}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              width="100%"
                            >
                              <Box display="flex" gap={2} alignItems="center">
                                <Avatar
                                  sizes="small"
                                  alt={data.firstName}
                                  src={data?.imageUrl}
                                />
                                <Box display="flex" flexDirection="column">
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      fontSize: "12px",
                                      color: "black",
                                      marginBottom: "0.5em",
                                    }}
                                  >
                                    {data.firstName}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ fontSize: "10px", color: "#8C8C8C" }}
                                  >
                                    {data.designation}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align="center">
                    {selectedDate ? formatDateForDisplay(selectedDate) : "-"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {isAssigned === false && (
            <Box display={"flex"} justifyContent={"end"} gap={3}>
              <Button
                variant="text"
                sx={{ color: "grey" }}
                onClick={handleButtonCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={status === "loading"}
              >
                {status === "loading" ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          )}

          {
            (isAssigned === true && view === false) && (
              <Box display={"flex"} justifyContent={"end"} gap={3}>
              <Button
                variant="text"
                sx={{ color: "grey" }}
                onClick={handleButtonCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={status === "loading"}
              >
                {status === "loading" ? "Submitting..." : "Save Changes"}
              </Button>
            </Box>
            )
          }
        </Box>
      )}
    </Box>
  );
};

export default AssignRecruiterLead;
