import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../../redux/store";
import { fetchUsers } from "../../../../redux/features/userSlice";
import { DataGrid, GridColDef, GridActionsCellItem, GridPaginationModel } from '@mui/x-data-grid';
import { Box, InputAdornment, InputBase, CircularProgress } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import EditUserForm, { UserData } from "./EditUser";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input': {
                        fontSize: '12px',
                    },
                    '& .MuiInputLabel-root': {
                        fontSize: '12px',
                    },
                },
            },
        },
    },
});

const UserTable: React.FC = () => {
    const [selectedUser, setSelectedUser] = useState<UserData | null>(null);
    const [editOpen, setEditOpen] = useState(false);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 100 });
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const { users } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        const fetch = async () => {
            if (searchTerm) {
                setLoading(true); 
                await dispatch(fetchUsers(searchTerm));
                setLoading(false); 
            }
        };
        fetch();
    }, [searchTerm, dispatch]);

    useEffect(() => {
        const fetchInitialUsers = async () => {
            setSearchTerm(''); 
            setLoading(true);
            await dispatch(fetchUsers('')); 
            setLoading(false);
        };
        fetchInitialUsers(); 
    }, [dispatch]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value); 
    };

    const handleEditClick = (user: UserData) => {
        setSelectedUser(user);
        setEditOpen(true);
    };

    const handleEditClose = () => {
        setEditOpen(false);
        setSelectedUser(null);
    };

    const processedRows = useMemo(() => {
        if (!users?.data) return [];
        const startIndex = paginationModel.page * paginationModel.pageSize;
        return users.data.slice(startIndex, startIndex + paginationModel.pageSize).map((row, index) => ({
            ...row,
            id: `${row.userId}-${row.roleId}`,
            serialNumber: startIndex + index + 1,
        }));
    }, [users?.data, paginationModel.page, paginationModel.pageSize]);

    const columns: GridColDef[] = [
        {
            field: 'serialNumber',
            headerName: 'SI.No',
            flex: 0.01,
            sortable: false,
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            flex: 0.2,
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            flex: 0.2,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 0.2,
        },
        {
            field: 'mobileNumber',
            headerName: 'Mobile',
            flex: 0.2,
        },
        {
            field: 'role',
            headerName: 'Role',
            flex: 0.2,
            renderCell: (params) => {
                return params.row?.roleName || params.row?.role?.roleName || 'N/A';
            },
        },
        {
            field: 'department',
            headerName: 'Department',
            flex: 0.2,
        },
        {
            field: 'actions',
            headerName: 'Action',
            type: 'actions',
            flex: 0.05,
            getActions: (params) => [
                <GridActionsCellItem icon={<EditIcon sx={{ fontSize: "12px" }} onClick={() => handleEditClick(params.row)} />} label="Edit" />,
            ],
        },
    ];

    return (
        <>
        {/* Search */}
            <Box display="flex" alignItems="center" mt={2} justifyContent="space-between">
                <InputBase
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search Users..."
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
                        </InputAdornment>
                    }
                    endAdornment={
                        loading && (
                            <InputAdornment position="end">
                                <CircularProgress size={18} sx={{ marginRight: "10px" }} />
                            </InputAdornment>
                        )
                    }
                    sx={{
                        border: '1px solid #ccc',
                        borderRadius: 2,
                        padding: 0,
                        backgroundColor: 'white',
                        width: '180px',
                        height: '36px',
                        fontSize: 12,
                    }}
                />
            </Box>
            <Box sx={{ height: '80vh', marginTop: 2, marginBottom: 2, overflow: 'auto' }}>
                <DataGrid
                    rows={processedRows}
                    columns={columns}
                    getRowId={(row) => `${row.userId}-${row.roleId}`}
                    paginationModel={paginationModel}
                    onPaginationModelChange={(model) => setPaginationModel(model)}
                    pageSizeOptions={[100]}
                    disableColumnMenu
                    disableColumnSorting
                    rowHeight={40}
                    // hideFooter
                    rowCount={users?.data?.length || 0}
                    paginationMode="server"
                    sx={{
                        minWidth: '600px',
                        '& .MuiDataGrid-cell': {
                            fontSize: '10px',
                            fontWeight: '500',
                            backgroundColor: '#FFFFFF',
                            padding: '0 8px',
                            borderRight: '0.5px solid #B5B5B5'
                        },
                        '& .MuiDataGrid-columnHeader': {
                            fontSize: '10px',
                            fontWeight: '500',
                            backgroundColor: '#F9F9F9',
                            padding: '0 8px',
                            borderRight: '0.5px solid #B5B5B5',
                            height: '40px !important',
                            lineHeight: '40px !important',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            minHeight: '40px !important',
                            maxHeight: '40px !important',
                        },
                        '& .MuiDataGrid-columnHeader:last-child, & .MuiDataGrid-cell:last-child': {
                            borderRight: 'none',
                        },
                        '& .MuiDataGrid-columnHeader:nth-last-child(3)': {
                            borderRight: 'none',
                        },
                        '& .MuiDataGrid-columnSeparator': { display: 'none' },
                        '& .MuiDataGrid-iconButtonContainer': { visibility: 'visible' },
                        '& .MuiDataGrid-sortIcon': { opacity: 'inherit !important', color: '#CDCDCD', fontSize: '12px' },
                        '& .MuiDataGrid-selectedRowCount': { display: 'none' },
                        '& .MuiDataGrid-row:hover': { cursor: 'pointer' },
                        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': { outline: 'none' },
                    }}
                />
            </Box>

            {/* Edit User */}
            <ThemeProvider theme={theme}>
                {selectedUser && (
                    <EditUserForm
                        open={editOpen}
                        onClose={handleEditClose}
                        user={selectedUser}
                    />
                )}
            </ThemeProvider>
        </>
    );
};

export default UserTable;
