import React, { useState } from "react";
import { Box, Button, TextField, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { BASE_COPY_URL } from "../../constants/Crudurl";

const CopyUrlBox = ({id}:any) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const url = `${BASE_COPY_URL}/candidateForm/${id}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(url).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); 
    });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: "100%", 
        border: "1px solid #ccc",
        borderRadius: 2,
        padding: 0, 
      }}
    >
      <TextField
        value={url}
        size="small"
        InputProps={{
          readOnly: true,
          disableUnderline: true, 
          style: { cursor: "default", fontSize: "12px" }, 
        }}
        fullWidth
        sx={{
          marginRight: 1, 
          '& .MuiOutlinedInput-notchedOutline': { border: "none" }, 
        }}
      />
      <Tooltip title={copySuccess ? "Copied!" : "Copy to clipboard"}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCopy}
          size="small"
          sx={{
            minWidth: "30px", 
            fontSize: "10px", 
            padding: "4px 10px", 
            marginRight:"10px",
            fontWeight:"bolder"
          }}
          endIcon={<ContentCopyIcon sx={{ fontSize: "6px" }} />} 
        >
          Copy
        </Button>
      </Tooltip>
    </Box>
    
  );
};

export default CopyUrlBox;
