import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Button,
  Typography,
  CircularProgress
} from "@mui/material";
 
import { styled } from "@mui/material/styles";
import { ViewCardProps } from "../../ViewCard";
import { useAppSelector } from "../../../../redux/hooks";
import { getInProgressAPIData, getOverAllRthAPIData } from "../../../../Crud/card";
import priorityHighSymbol from "../../../../assets/images/priorityHighSymbol.svg";
import priorityMediumSymbol from "../../../../assets/images/priorityMediumSymbol.svg";
import priorityLowSymbol from "../../../../assets/images/priorityLowSymbol.svg";
import NoDataAvail from "../../../../assets/images/NoDataAvail.svg";
 
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
  "&:last-child": {
    borderRight: "none",
  },
}));
 
const RTHInProgressTable: React.FC<ViewCardProps> = ({ data, tabName }) => {
 
  const userId = localStorage.getItem("userId") ?? "";
 
  const [tableData, setTableData] = useState<any[]>([]);
 
  const [loading, setLoading] = useState<boolean>(false);
 console.log(loading)
  const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
 
  useEffect(() => {
    if (tabName === "In Progress") {
      setLoading(true);
      getInProgressAPIData(userId, teamHeadFilter)
        .then((res) => {
          // setLoading(false);
          setTableData(res?.data?.listOfRth);
          // console.log("why ?");
          // console.log(tableData);
        })
        .catch((error) => {
          console.error("Error fetching RTH data:", error);
        })
        .finally(() => {
          setLoading(false);
      });
    } else if (tabName === "Overall RTH") {
      setLoading(true);
      getOverAllRthAPIData(userId, teamHeadFilter)
        .then((res) => {
          // setLoading(false);
          setTableData(res?.data?.listOfRth);
        })
        .catch((error) => {
          console.error("Error fetching RTH data:", error);
        })
        .finally(() => {
          setLoading(false);
      });
        // console.log(tableData);
    }
  }, [userId,teamHeadFilter,tabName,setTableData]);
 
  // const datas = tableData?.map((job, index) => job?.listOfRth);
 
  return (
    <Box sx={{ width: "auto", backgroundColor: "#f0f4ff" }}>
      {loading ? (
          <Box
              sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
              }}
          >
              <CircularProgress size={100} sx={{color: '#2F54EB'}}/>
          </Box>
      ) : 
        tableData.length >0 ? (
 
          <Box sx={{ width: "100%", overflowX: "auto" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="RTH table">
            <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
              <TableRow
                sx={{
                  "& th": {
                    padding: "4px 8px", // Adjust padding for header cells here
                    fontSize: "14px", // Optional: Adjust font size
                  },
                }}
              >
                <StyledTableCell>SI.No.</StyledTableCell>
 
                <StyledTableCell>RTH Name</StyledTableCell>
 
                <StyledTableCell>Type of RTH</StyledTableCell>
 
                <StyledTableCell>Business</StyledTableCell>
 
                <StyledTableCell>Priority</StyledTableCell>
 
                <StyledTableCell>Status</StyledTableCell>
 
                <StyledTableCell>Stage</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.map((row: any, index: number) => (
                <TableRow
                  key={row.rthId}
                  sx={{
                    "& td, & th": {
                      padding: "4px 8px", // Adjust padding here
                    },
                  }}
                >
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  <StyledTableCell>{row.positionTitle}</StyledTableCell>
                  <StyledTableCell>
                    <Box
                      sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                    >
                      <Button
                        size="small"
                        sx={{
                          backgroundColor: "#F0F0F0",
                          border: "1px solid lightgray",
                          fontSize: "8px",
                          padding: "2px",
                          color: "#8C8C8C",
                          height: "20px",
                          minWidth: "50px",
                        }}
                      >
                        {row.typeOfRth}
                      </Button>
                      <Button
                        size="small"
                        sx={{
                          backgroundColor: "#F0F0F0",
                          border: "1px solid lightgray",
                          fontSize: "8px",
                          padding: "2px 4px",
                          color: "#8C8C8C",
                          height: "20px",
                          minWidth: "50px",
                        }}
                      >
                        {row.modeOfRth}
                      </Button>
                    </Box>
                  </StyledTableCell>
 
                  <StyledTableCell>
                    <Typography
                      sx={{
                        width: "fit-content",
                        fontSize: "12px",
                        padding: "3%",
                        height: "px",
                        border:
                          "1px solid " +
                          (row.business.businessName === "HEPL"
                            ? "#D3ADF7"
                            : "#87E8DE"),
                        backgroundColor:
                          row.business.businessName === "HEPL"
                            ? "#EFDBFF"
                            : "#B5F5EC",
                        color:
                          row.business.businessName === "HEPL"
                            ? "#722ED1"
                            : "#08979C",
                      }}
                    >
                      {row.business.businessName}
                    </Typography>
                  </StyledTableCell>
 
                  <StyledTableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      gap={1}
                    >
                      {row.priority === "High" ? (
                        <Box>
                          <img src={priorityHighSymbol} alt="" />
                        </Box>
                      ) : row.priority === "Medium" ? (
                        <Box>
                          <img src={priorityMediumSymbol} alt="" />
                        </Box>
                      ) : (
                        <Box>
                          <img src={priorityLowSymbol} alt="" />
                        </Box>
                      )}
                      <Typography
                        sx={{
                          color:
                            row.priority === "High"
                              ? "#F04E4E"
                              : row.priority === "Medium"
                              ? "#FFA940"
                              : "#73D13D",
                          fontSize: "100%",
                        }}
                      >
                        {row.priority}
                      </Typography>
                    </Box>
                  </StyledTableCell>
 
                  <StyledTableCell>
                    <Chip
                      label={row.status}
                      size="small"
                      sx={{
                        fontSize: "12px",
                        padding: "3px 6px 0px 6px",
                        height: "px",
                        borderRadius: "1px",
                        backgroundColor: "#389E0D1A",
                        color: "#389E0D",
                      }}
                    />
                  </StyledTableCell>
 
                  <StyledTableCell>
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Chip
                          label={row.rthProcess}
                          size="small"
                          sx={{
                            fontSize: "12px",
                            padding: "3px 6px 0px 6px",
                            height: "20px",
                            borderRadius: "1px",
                            backgroundColor: "transparent",
                            color: "#8C8A8A",
                          }}
                        />
                      </Box>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
 
 
        ) : (
 
          <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
          }}
        >
          <img src={NoDataAvail} alt="No Data Available" style={{ maxWidth: "200px" }} />
        </Box>
 
        )
      }
          </Box>
  );
};
 
export default RTHInProgressTable;