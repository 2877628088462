import { Box, Card, InputAdornment, InputBase, Grid, CircularProgress } from "@mui/material";
import NoDataAvail from "../../assets/images/NoDataAvail.svg";
import { getResumeCardAPIData } from "../../Crud/card";
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { useAppSelector } from "../../redux/hooks";
import ResumeHeader from "../common/ResumeHeader";
import ResumeTime from "../common/ResumeTime";
import ResumeDetails from "../common/ResumeDetails";
import ResumeCount from "../common/ResumeTotalCount";
import { useNavigate } from "react-router-dom";

interface ResumeCreationCardProps {
    drawerOpen: boolean;
}

const ResumeCreation: React.FC<ResumeCreationCardProps> = ({ drawerOpen }) => {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const userId = localStorage.getItem("userId") ?? "";
    const [searchQuery, setSearchQuery] = useState<string>('');
    const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (searchQuery.trim()) {
            setSearchLoading(true);
        } else {
            setSearchLoading(false);
        }

        const timeoutId = setTimeout(() => {
            setLoading(true);
            getResumeCardAPIData(userId, teamHeadFilter, searchQuery)
                .then((res) => {
                    setData(res?.data?.listOfRth || []);
                    console.log("Total Count:", res?.data?.count?.ResumeTotalCount?.TotalCount || 0);
                })
                .catch((error) => {
                    setData([]);
                })
                .finally(() => {
                    setLoading(false);
                    setSearchLoading(false);
                });
        }, 300);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [userId, teamHeadFilter, searchQuery]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    return (
        <>

            {/* Search */}
            <Grid item sx={{ marginTop: data.length > 0 ? "-60px" : "-58px" }} position='absolute'>
                <InputBase
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Search RTH..."
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
                        </InputAdornment>
                    }
                    sx={{
                        border: '1px solid #ccc',
                        borderRadius: 1,
                        padding: 0,
                        backgroundColor: 'white',
                        minHeight: '3px',
                        fontSize: 14,
                        '.MuiInputBase-input': {
                            padding: '5px 5px',
                        }
                    }}
                />
                {searchLoading && (
                    <CircularProgress
                        size={20}
                        sx={{
                            position: 'absolute',
                            right: '10px',
                            top: '25%',
                            transform: 'translateY(-50%)',
                            color: '#2F54EB',
                        }}
                    />
                )}
            </Grid>
            <Box>
                {loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '300px',
                        }}
                    >
                        <CircularProgress size={100} sx={{ color: '#2F54EB' }} />
                    </Box>
                ) : data?.length > 0 ? (
                    <Box sx={{ display: "grid", gridTemplateColumns: drawerOpen ? "repeat(4, 1fr)" : "repeat(5, 1fr)", gap: 2, alignItems: "flex-start", }}>
                        {data?.map((item: any) => (
                            <Card
                                key={item?.rthId}
                                sx={{
                                    height: "auto",
                                    minHeight: "120px",
                                    minWidth: "fit-content",
                                    boxShadow: "none",
                                    border: '1px solid #D9D9D9',
                                    borderRadius: "8px",
                                    backgroundColor: '#FFFFFF'
                                }}
                                onClick={() => navigate(`/dashboard/MRH/${item?.positionTitle}/${item?.rthId}`)}
                            >
                                <Box sx={{ display: "flex", flexDirection: "column", margin: 1, position: "relative" }}>
                                    <Box sx={{ paddingInline: "0.5rem", cursor: "pointer" }}>
                                        <ResumeHeader positionTitle={item?.positionTitle} business={item?.business} />
                                        <ResumeTime priority={item?.priority} seqId={item?.seqId} />
                                        <ResumeDetails typeOfRth={item?.typeOfRth} modeOfRth={item?.modeOfRth} rthProcess={item?.rthProcess} status={item?.status} modesOfRth={item?.modesofRth} />
                                        <ResumeCount count={item?.resumesCount || 0} />
                                    </Box>
                                </Box>
                            </Card>
                        ))}
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "300px",
                        }}
                    >
                        <img src={NoDataAvail} alt="No Data Available" style={{ maxWidth: "200px" }} />
                    </Box>
                )}
            </Box>
        </>
    );
}

export default ResumeCreation;
