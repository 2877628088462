import { Box, Grid, InputAdornment, InputBase, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import FilterSidebar from "../dashboard/commoncomponent/FilterSidebar"
import { useEffect, useRef, useState } from "react";
import SearchBar from "../dashboard/commoncomponent/SearchBar";
import SearchIcon from '@mui/icons-material/Search';
import CandidateCard from "../common/CandidateCard";
import { StyledTableCell } from "../dashboard/Rth/rthtable/RthClosedTable";
import { GridDeleteIcon } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getResumeRepo } from "../../redux/features/resume";

export const ResumeRepo = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [viewType, setViewType] = useState<'grid' | 'list'>('grid');
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [candidate, setCandidate] = useState<any>([]);
    // const [loading, setLoading] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const dispatch=useAppDispatch();
    // const [page, setPage] = useState(0); // Current page
    const size=10; // Items per page  
    const page=0
    const resumeRepoData=useAppSelector((state)=>state.resume.resumeRepo);
    const [teamHeadFilterDto, setTeamHeadFilterDto] = useState({
        l1StatusPending: false,
        l1StatusDeclined: false,
        l2StatusApproved: false,
        internalOnRole: false,
        internalContract: false,
        externalOneTime: false,
        externalContract: false,
        priorityLow: false,
        priorityMedium: false,
        priorityHigh: false,
        rthStages: null,
        createdBy: null,
        recruiterLead: null,
        PositionTitle: null,
        fromYear: 0,
        fromMonth: 0,
        fromDay: 0,
        toYear: 0,
        toMonth: 0,
        toDay: 0,
        page: 0,
        size: 10
      });

      
const handleViewChange = (view: 'grid' | 'list') => {
    setViewType(view);
  };
    
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
};

const tableHead = [
    "SI.No",
    "Profile Details",
    "Email ID",
    "Mobile Number",
    "Resume",
    "Arrived Status",
    "Interview Status",
    "Action",
  ];


const getFileName = (url: any) => {
    const fileNameWithParams = url.split("/").pop(); // Get the last part of the URL
    const fileName = fileNameWithParams.split("?")[0]; // Remove query params
    return fileName;
  };
  
  // const resumRepoDetails=async()=>{
  //   setLoading(true);
  //   const datapayload={
  //     "from": null,
  //     "to": null,
  //     "experienceLevel": null,
  //     "skillsSet": null,
  //     "education": null,
  //     "language": null,
  //     "interviewStatus": null,
  //     "positionTitle": null,
  //     "expectedSalary":null,
  //     "currentSalary": null,
  //     "page":page,
  //     "size":size
  //   }
  //   try {
  //     await dispatch(getResumeRepo({ search: searchQuery, data: datapayload }));
  //   } catch (error) {
  //     console.error("Error fetching candidates:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // }

//   const handleScroll = () => {
//     if (!containerRef.current || loading) return;
//     const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
//     if (scrollTop + clientHeight >= scrollHeight - 10) {
//       setLoading(true);
//       setPage((prev) => prev + 1); // Increment page
//     }
//   };

//   useEffect(() => {
//     const container = containerRef.current;
//     if (!container) return;

//     container.addEventListener("scroll", handleScroll);
//     return () => {
//       container.removeEventListener("scroll", handleScroll);
//     };
//   }, [loading]);

useEffect(()=>{
  const datapayload={
    "from": null,
    "to": null,
    "experienceLevel": null,
    "skillsSet": null,
    "education": null,
    "language": null,
    "interviewStatus": null,
    "positionTitle": null,
    "expectedSalary":null,
    "currentSalary": null,
    "page":page,
    "size":size
  }
   dispatch(getResumeRepo({ search: searchQuery, data: datapayload }));
},[dispatch,page,size,searchQuery])

useEffect(()=>{
  if(resumeRepoData){
    setCandidate(resumeRepoData);
  }
},[resumeRepoData])

// useEffect(() => {
//   setLoading(false); // Reset loading after fetching new data
// }, [resumeRepoData]);

  return (
    <Box sx={{ width: '100%', backgroundColor: '#f0f4ff', display: 'flex', position: 'relative', flexDirection:'row' }} >    
    <Box sx={{height:'80vh'}}>    
    <FilterSidebar onChangeFilter={setDrawerOpen} drawerOpen={drawerOpen} tabName={"Resume"} teamHeadFilterDto={teamHeadFilterDto} setTeamHeadFilterDto={setTeamHeadFilterDto}/>
    </Box>
    <Box sx={{ width: '100%', transition: 'margin-left 0.3s' }}>
    <Box sx={{ position:'sticky'}}>
        <Box sx={{mt:'20px',ml:'90px'}}>
    <Grid item position="absolute">
                <InputBase
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Search Profile..."
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
                        </InputAdornment>
                    }
                    sx={{
                        border: '1px solid #ccc', 
                        borderRadius: 1,
                        padding: 0,
                        backgroundColor: 'white',
                        minHeight: '3px',
                        fontSize: 14,
                        '.MuiInputBase-input': {
                            padding: '5px 5px',
                        },
                    }}
                />
            </Grid>
            </Box>
    <SearchBar viewType={viewType} handleViewChange={handleViewChange} pageType={'Resume'} />
    </Box>
    {viewType === "grid" ? (
        <Box
         ref={containerRef}
          sx={{
            ml: "20px",
            mt: "0",
            mb: 3,
            height: 510,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "0px",
              height: "0px",
            },
          }}
        >
          {candidate?.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50%",
              }}
            >
              <Typography sx={{fontWeight:600}}>Candidate not Assigned</Typography>
            </Box>
          ) : (
            <Grid container>
              {candidate?.map((candidate: any) => (
                <Grid item key={candidate.id}>
                  <CandidateCard
                    name={candidate?.firstName}
                    lastName={candidate?.lastName}
                    status={candidate?.status}
                    daysAgo={candidate?.daysAgo}
                    phone={candidate?.phoneNumber}
                    email={candidate?.emailAddress}
                    pdfLink={candidate?.resumeUrl || ""}
                    schedule={candidate?.interviewStatus}
                    candidate={candidate}
                    drawerOpen={drawerOpen}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      ) : (
        <Box sx={{ width: "auto", backgroundColor: "#f0f4ff" }} p={1}>
          {candidate?.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50%",
              }}
            >
              <Typography sx={{fontWeight:600}}>Table not Assigned</Typography>
            </Box>
          ) : (
            <Box sx={{ width: "100%", overflowX: "auto" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="RTH table">
                  <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
                    <TableRow>
                      {tableHead?.map((data, index) => (
                        <StyledTableCell key={index}>{data}</StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {candidate?.map((data: any, index: number) => (
                      <TableRow key={index}>
                        <StyledTableCell>{index + 1}</StyledTableCell>
                        <StyledTableCell>{data?.firstName}</StyledTableCell>
                        <StyledTableCell>{data?.emailAddress}</StyledTableCell>
                        <StyledTableCell>{data?.phoneNumber}</StyledTableCell>
                        <StyledTableCell>
                          {getFileName(data?.resumeUrl)}
                        </StyledTableCell>
                        <StyledTableCell>{data?.createdAt}</StyledTableCell>
                        <StyledTableCell>{data?.interviewStatus}</StyledTableCell>
                        <StyledTableCell>
                          <GridDeleteIcon />
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      )}
    </Box>
    </Box>
  )
}
