import { Box, Typography, Grid } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';

const chartColors: { [key: string]: string } = {
  Approved: '#4285F4',
  Rejected: '#EA4335',
  Pending: '#FBBC05',
};

const statusReport = [
  { label: 'Approved', value: 65 },
  { label: 'Rejected', value: 20 },
  { label: 'Pending', value: 15 },
];


function PieChartBoard() {
  const total = 24;

  return (
    <Box pr={2} sx={{ display: 'flex', justifyContent: 'space-around' }} gap={2}>
      <Grid item md={6} sx={{ position: 'relative', borderRadius: '12px', backgroundColor: '#FFFFFF', paddingTop: '0' }} >
        <Typography sx={{ fontWeight: 'bold', fontSize: '12px', padding: '8px' }}>L1 Approval Status</Typography>
        <Box >
          <PieChart
            height={200}
            width={400}
            slotProps={{
              legend: { hidden: true },
            }}
            series={[
              {
                data: statusReport.map((item) => ({
                  ...item,
                  color: chartColors[item.label],
                })),
                innerRadius: 80,
                outerRadius: '60%',
                arcLabel: () => '',
                arcLabelMinAngle: 20,
                paddingAngle: 2,
                cornerRadius: 5,
                startAngle: -240,
                endAngle: 200,
              },
            ]}
            // sx={{
            //   pointerEvents: 'none',
            // }}
          />
          <Typography sx={{position: 'absolute',top: '55%',left: '55%',transform: 'translate(-50%, -50%)',textAlign: 'center',}}>
            <Typography component="span"
              sx={{
                fontSize: '8px', 
                display: 'block',
              }}
            >Total</Typography>
            <Typography
              component="span"
              sx={{
                fontSize: '18px', 
                fontWeight: 'bold', 
                color: '#000', 
                display: 'block',
              }}
            >
              {total}
            </Typography>
          </Typography>
        </Box>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '16px'
          }}
        >
          {statusReport.map((item) => (
            <Grid key={item.label} sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: chartColors[item.label],
                  marginRight: 1,
                }}
              />
              <Typography sx={{ color: '#6B7280', backgroundColor: '#F5F7FF', borderRadius: '20px', fontSize: '8px' }}>{item.label}</Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item md={6} sx={{ position: 'relative', borderRadius: '12px', backgroundColor: '#FFFFFF' }} >
        <Typography sx={{ fontWeight: 'bold', fontSize: '12px', padding: '8px' }}>L2 Approval Status</Typography>
        <Box >
          <PieChart
            height={200}
            width={400}
            slotProps={{
              legend: { hidden: true },
            }}
            series={[
              {
                data: statusReport.map((item) => ({
                  ...item,
                  color: chartColors[item.label],
                })),
                innerRadius: 80,
                outerRadius: '60%',
                arcLabel: () => '',
                arcLabelMinAngle: 20,
                paddingAngle: 2,
                cornerRadius: 5,
                startAngle: -240,
                endAngle: 200,
              },
            ]}
            // sx={{
            //   pointerEvents: 'none',
            // }}
          />
          <Typography sx={{position: 'absolute',top: '55%',left: '55%',transform: 'translate(-50%, -50%)',textAlign: 'center',}}>
            <Typography component="span"
              sx={{
                fontSize: '8px', 
                display: 'block',
              }}
            >Total</Typography>
            <Typography
              component="span"
              sx={{
                fontSize: '18px', 
                fontWeight: 'bold', 
                color: '#000', 
                display: 'block',
              }}
            >
              {total}
            </Typography>
          </Typography>
        </Box>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '16px',
          }}
        >
          {statusReport.map((item) => (
            <Grid key={item.label} sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: chartColors[item.label],
                  marginRight: 1,
                }}
              />
              <Typography sx={{ color: '#6B7280', backgroundColor: '#F5F7FF', borderRadius: '20px', fontSize: '8px' }}>{item.label}</Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

export default PieChartBoard;
