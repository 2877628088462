import React from 'react';
import { Box, Typography } from '@mui/material';

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const ToBeAssigned: React.FC = () => {
  return (
    <Box>
    
    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding:"15px 10px", borderBottom: "0.2px solid #BFBFBF"}} >
        <Box>
    <Typography color="textSecondary" sx={{ fontSize: '10px', color: "#8C8C8C", }}> Click to Assign Recruiter</Typography>
    </Box>
    </Box>


    </Box>
  );
};

export default ToBeAssigned;
