import React, { useEffect, useState } from "react";
import {
  Box,
  Input,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  IconButton,
  Avatar,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { AppDispatch } from "../../../redux/store";
import {
  editRecruiter,
  getMrfByRthId,
  getRecruiter,
  getRthById,
} from "../../../Crud/rth";
import { assignRecruiter } from "../../../redux/features/recruiterSlice";
import { useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

interface Prop {
  numOfPosition?: string;
  numOfOpenPosition?: any;
  setView: Function;
  view: boolean;
}

export interface RowData {
  recruiter: string;
  recruiterId: string;
  assignedPosition: number;
  closedPosition:number;
  assignedDate: string;
}

export interface NewRowData {
  recruiterId: string;
  assignedPosition: number;
  assignedDate: string;
}

const AssignRecruiterNew: React.FC<Prop> = ({
  numOfPosition,
  numOfOpenPosition,
  view,
  setView,
}) => {
  const getFormattedDate = () => {
    const now = new Date();
    return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(now.getDate()).padStart(2, "0")}`;
  };
  const location = useLocation();
  const { id } = location.state || {};
  console.log("rth-id", id);
  const [apiRows, setApiRows] = useState<NewRowData[]>([]);
  const [rows, setRows] = useState<RowData[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [disable, setIsDisable] = useState<boolean>(false);
  const [reset, setReset] = useState<number>(0);
  console.log(rows);
  const [remainingPositions, setRemainingPositions] = useState<number>(0);
  const [recruiterData, setRecruiterData] = useState<any[]>([]);
  const [isRecruiterAssigned, setIsRecruiterAssigned] =
    useState<boolean>(false);
  const [edit,setEdit]=useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setRemainingPositions(numOfOpenPosition);
    // console.log("numOfOpenPosition -> " + numOfOpenPosition);
  }, [numOfPosition, numOfOpenPosition]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRthById(id);
        const assigned = response.data.recruiterAssigned;
        setIsRecruiterAssigned(assigned);

        if (assigned) {
          setIsOpen(false);
          setIsDisable(true);
          setReset(0);

          getMrfByRthId(id)
            .then((res) => {
              const mappedRows = res.map((recruiter: any) => ({
                recruiterId: recruiter?.recruiter?.userId,
                recruiter: recruiter?.recruiter?.userName,
                assignedPosition: recruiter?.assignedPosition,
                assignedDate: getFormattedDate(),
                closedPosition:recruiter.closedPosition
              }));
              const totalAssignedPositions = res.reduce(
                (total: number, recruiter: any) =>
                  total + parseInt(recruiter.assignedPosition, 10),
                0
              );
              console.log("mappedRows +",mappedRows);

              setRows(mappedRows);
              setRemainingPositions(numOfOpenPosition - totalAssignedPositions);
              console.log("totalAssignedPositions ", totalAssignedPositions);
            })
            .catch((err) => console.error("Error fetching recruiters:", err));
        } else {
          setRows([
            {
              recruiterId: "",
              recruiter: "",
              assignedPosition: numOfOpenPosition,
              assignedDate: getFormattedDate(),
              closedPosition:0,
            },
          ]);
        }
        setRemainingPositions(0);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    getRecruiter()
      .then((res) => setRecruiterData(res.data || []))
      .catch((err) => console.error("Error fetching recruiters:", err));
  }, [id, isRecruiterAssigned, reset, numOfOpenPosition]);

  const handleAddRowButton = () => {
    if (remainingPositions > 0) {
      // Assuming you're adding the maximum available positions for a new row
      const newAssignedPosition = Math.min(remainingPositions, numOfOpenPosition);
  
      // Update remainingPositions after adding the new row
      const updatedRemainingPositions = remainingPositions - newAssignedPosition;
  
      // Update the rows with the new row data
      setRows((prevRows) => [
        ...prevRows,
        {
          recruiterId: "",
          recruiter: "",
          assignedPosition: newAssignedPosition,
          assignedDate: getFormattedDate(),
          closedPosition: 0,
        },
      ]);
  
      // Update the remainingPositions
      setRemainingPositions(updatedRemainingPositions);
    }
  };
  

  const handleEditButton = () => {
    setIsDisable(false);
    setEdit(true);
  };

  const handleDeleteIconButton = (index: number) => {
    const deletedRow = rows[index];
    setRows((prevRows) => prevRows.filter((_, i) => i !== index));
    setApiRows((prevApiRows) => prevApiRows.filter((_, i) => i !== index));
    setRemainingPositions((prev) => prev + deletedRow.assignedPosition);
  };

  const handleInputChange = (index: number, value: number) => {

    console.log("initaial",index,value);
    const newRows = [...rows];
    const prevAssigned = newRows[index].assignedPosition;
    const updatedRemaining = remainingPositions - (value - prevAssigned);

    if (updatedRemaining < 0) {
      toast.warning("You can't assign more positions than available!");
      return;
    }
    newRows[index].assignedPosition = value;
    setRows(newRows);
    setRemainingPositions(updatedRemaining);

    console.log(rows,remainingPositions)
  };

  const handleSelectChange = (
    index: number,
    selectedRecruiter: { id: string; name: string }
  ) => {
    const newRows = [...rows];
    newRows[index].recruiterId = selectedRecruiter.id;
    newRows[index].recruiter = selectedRecruiter.name;

    setRows(newRows);

    // Update the apiRows array as well
    setApiRows(
      newRows.map((row) => ({
        recruiterId: row.recruiterId,
        assignedPosition: row.assignedPosition,
        assignedDate: row.assignedDate,
      }))
    );
  };

  const handleSubmitButton = () => {
    if (isRecruiterAssigned) {
      console.log("rows", rows);
      editRecruiter(id, rows);
      setReset(2);
      setView(false);
    } else {
      console.log(apiRows);
      if (apiRows.length > 0) {
        dispatch(assignRecruiter({ rthId: id, apiRows }));
        toast.success("Recruiter Assigned Successfully");
        setReset(1);
        setIsRecruiterAssigned(true);
        setView(true);
      } else {
        toast.warning("Assign Atleast 1 Recruiter");
      }
    }
  };

  const handleButtonClick = () => {
    setIsOpen(false);
  };

  const handleCancelButton = () => {
    if (isRecruiterAssigned) {
      setReset(3);
    } else {
      setIsOpen(true);
      if (view === true) {
        setView(false);
      }
    }
  };

  return (
    <Box>
      {isOpen ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          flexDirection={"row"}
        >
          <Box display={"flex"} flexDirection={"column"}>
            <Box>
              <Typography
                variant="h6"
                sx={{ fontSize: "16px", color: "#4A4646" }}
              >
                Recruiter Not Yet Assigned
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={{ fontSize: "12px", color: "#8C8C8C" }}
              >
                You’ve got {numOfOpenPosition} positions waiting. Ready to
                assign a recruiter?
              </Typography>
            </Box>
          </Box>

          <Button
            variant="outlined"
            size="small"
            sx={{ border: "1.5px solid" }}
            onClick={handleButtonClick}
          >
            Assign Recruiter
          </Button>
        </Box>
      ) : (
        <Box>
          <Box
            marginBottom={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box>
              <Typography>
                Total No of Position opened: {numOfOpenPosition}
              </Typography>
            </Box>
            {!isRecruiterAssigned ? (
              <Button
                onClick={handleAddRowButton}
                disabled={remainingPositions <= 0}
              >
                + Add Row
              </Button>
            ) : (
              <Button onClick={handleEditButton}>Edit</Button>
            )}
          </Box>

          <Box
            display={"flex"}
            gap={2}
            p={1}
            sx={{ backgroundColor: "#F9F9F9" }}
          >
            <Typography flexBasis={"20%"} fontSize={15}>
              No. of Open Position
            </Typography>
            <Typography flexBasis={"20%"} fontSize={15}>
              No. of Closed Position
            </Typography>
            <Typography flexBasis={"40%"} fontSize={15}>
              Assign Recruiter
            </Typography>
            <Box
              display={"flex"}
              flexBasis={"30%"}
              alignItems={"center"}
              gap={2}
            >
              <Typography fontSize={15}>Assigned Date</Typography>
              <Typography fontSize={12}>
                assigned date can’t be edited
              </Typography>
            </Box>
          </Box>

          {rows.map((row, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              gap={2}
              borderBottom="0.5px solid grey"
              pl={1}
              pr={1}
              pt={0}
              pb={0}
              mb={0.8}
            >
              <Box flexBasis={"20%"}>
                <Input
                  type="number"
                  placeholder="Enter No."
                  disableUnderline
                  // disabled={disable}
                  disabled={disable || row.closedPosition > 0}
                  value={row.assignedPosition || ""}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    handleInputChange(index, value >= 0 ? value : 0);
                  }}
                />
              </Box>
              <Box flexBasis={"20%"}>
                <Input
                  type="number"                  
                  disableUnderline
                  disabled={true}
                  value={row.closedPosition}
                />
              </Box>
              <Box flexBasis={"40%"}>
                <FormControl fullWidth>
                  <Select
                    value={row.recruiterId || ""}
                    onChange={(e) =>
                      handleSelectChange(index, {
                        id: e.target.value as string,
                        name:
                          recruiterData.find((r) => r.userId === e.target.value)
                            ?.firstName || "",
                      })
                    }
                    displayEmpty
                    disabled={disable || row.closedPosition > 0}
                    renderValue={(selected) => {
                      if (selected) {
                        const selectedRecruiter = recruiterData.find(
                          (r) => r.userId === selected
                        );
                        return selectedRecruiter
                          ?                         <Box display={"flex"}
                          alignItems="center"
                          gap={1}
                          borderRadius={"5px"}
                          border={"1px solid grey"}
                          width={"50%"}                              
                          padding={"5px"}>
                          <Box display="flex" gap={2} alignItems="center">
                            <Avatar
                              sizes="small"
                              alt={selectedRecruiter.firstName}
                              src={selectedRecruiter.imageUrl}
                            />
                            <Box display="flex" flexDirection="column">
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "12px",
                                  color: "black",
                                  marginBottom: "0.5em",
                                }}
                              >
                                {selectedRecruiter.firstName}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ fontSize: "10px", color: "#8C8C8C" }}
                              >
                                {selectedRecruiter.designation}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                          : row.recruiter;
                      } else {
                        return row.recruiter || "Select Recruiter"; // Show the initial recruiter name if available
                      }
                    }}
                    sx={{
                      border: "none",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select Recruiter
                    </MenuItem>
                    {recruiterData.map((recruiter) => (
                      <MenuItem key={recruiter.userId} value={recruiter.userId}>
                        <Box                               display={"flex"}
                              alignItems="center"
                              gap={1}
                              borderRadius={"5px"}
                              border={"1px solid grey"}
                              width={"50%"}                              
                              padding={"5px"}>
                          <Box display="flex" gap={2} alignItems="center">
                            <Avatar
                              sizes="small"
                              alt={recruiter.firstName}
                              src={recruiter.imageUrl}
                            />
                            <Box display="flex" flexDirection="column">
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "12px",
                                  color: "black",
                                  marginBottom: "0.5em",
                                }}
                              >
                                {recruiter.firstName}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ fontSize: "10px", color: "#8C8C8C" }}
                              >
                                {recruiter.designation}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box
                flexBasis={"30%"}
                display={"flex"}
                alignItems={"center"}
                gap={7}
              >
                <Typography sx={{ color: disable ? "grey" : "black" }}>
                  {row.assignedDate}
                </Typography>
                {
                  (isRecruiterAssigned === false || edit === true) && (
                    <IconButton
                    onClick={() => handleDeleteIconButton(index)}
                    disabled={disable || row.closedPosition > 0}
                  >
                    <DeleteIcon />
                  </IconButton>
                  )
                }              
              </Box>
            </Box>
          ))}
          <Box display={"flex"}>
            {!disable && (
              <>
                <Box flexBasis={"80%"}>
                  <Button
                    onClick={handleAddRowButton}
                    disabled={remainingPositions <= 0}
                  >
                    + Add Row
                  </Button>
                </Box>

                <Box display={"flex"} flexBasis={"20%"} gap={3}>
                  <Button variant="outlined" onClick={handleCancelButton}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handleSubmitButton}>
                    Submit
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AssignRecruiterNew;
