// export const calculateDays = (createdAt: string):number => {
//     const createdDate: Date = new Date(createdAt);
//     const today: Date = new Date(); 
//     const differenceInMilliseconds: number = today.getTime() - createdDate.getTime();
//     const differenceInDays: number = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
//     return differenceInDays;
//   };

  export const calculateDays = (createdAt: string): string => {
    const createdDate: Date = new Date(createdAt);
    const now: Date = new Date();
    const differenceInMilliseconds: number = now.getTime() - createdDate.getTime();

    const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
    if (differenceInMinutes < 60) {
        return differenceInMinutes === 0 ? 'Just now' : `${differenceInMinutes} minute${differenceInMinutes > 1 ? 's' : ''} ago`;
    }

    const differenceInHours = Math.floor(differenceInMinutes / 60);
    if (differenceInHours < 24) {
        return `${differenceInHours} hour${differenceInHours > 1 ? 's' : ''} ago`;
    }

    const differenceInDays = Math.floor(differenceInHours / 24);
    return `${differenceInDays} day${differenceInDays > 1 ? 's' : ''} ago`;
};
