import { Grid, Typography, Box, Tooltip, CircularProgress } from '@mui/material';
import DropDownIcon from '../../../../../assets/images/dropdown.svg';
import { RootState } from '../../../../../redux/store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BaseUrl } from '../../../../../constants/Crudurl';

function HorizontalBarChart() {
    const data = useSelector((state: RootState) => state.profile.data);
    const userID = data?.data?.userId;
    const [barsData, setBarsData] = useState<{ label: string; value: number }[]>([]);
    const [maxBarValue, setMaxBarValue] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchChartReport = async () => {
            try {
                setLoading(true);
                const response = await axios.get(
                    `${BaseUrl}/dashboard/sourceReportByR/${userID}`
                );
                const responseData = response?.data?.data;
                const updatedBarsData = Object.entries(responseData).map(([label, value]) => ({
                    label,
                    value: Number(value),
                }));
                setBarsData(updatedBarsData);
                setMaxBarValue(Math.max(...updatedBarsData.map(bar => bar.value)));
            } catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        if (userID) {
            fetchChartReport();
        }
    }, [userID]);

    return (
        <Grid container sx={{ borderRadius: '8px', height: '280px', backgroundColor: '#FFFFFF', padding: '16px', position: 'relative' }}>
            <Grid item xs={12} container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '30px', mt: -2 }}>
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: '500', fontSize: '10px' }}>
                        Source Report
                    </Typography>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '12px' }}>
                        User
                    </Typography>
                    <img src={DropDownIcon} alt="dropdownIcon" style={{ height: '18px', width: '18px', marginLeft: '4px' }} />
                </Grid>
            </Grid>
            {loading ? (
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10 }}>
                    <CircularProgress size={50} />
                </Box>
            ) : (
                <Box sx={{ flexGrow: 1, width: '100%', marginTop: 0 }}>
                    {barsData.map((bar, index) => (
                        <Grid container key={index} sx={{ alignItems: 'center', marginBottom: '8px' }}>
                            <Grid item xs={2}>
                                <Typography sx={{ fontSize: '8px' }}>{bar.label}</Typography>
                            </Grid>
                            <Grid item xs={10} sx={{ position: 'relative', padding: '4px', }}>
                                <Tooltip title={`${bar.value}`} arrow placement='top'>
                                    <Box
                                        sx={{
                                            height: '8px',
                                            width: `${(bar.value / maxBarValue) * 100 - 5}%`,
                                            backgroundColor: '#1677FF',
                                            borderRadius: '0 4px 4px 0',
                                            transition: 'width 0.3s ease',
                                        }}
                                    />
                                </Tooltip>
                                <Typography
                                    sx={{
                                        fontSize: '10px',
                                        position: 'absolute',
                                        top: 0,
                                        left: `${(bar.value / maxBarValue) * 100 + 2}%`,
                                        transform: 'translateX(-100%)',
                                        color: '#333',
                                    }}
                                >
                                    {bar.value}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            )}
            {!loading && (
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                <Typography sx={{ fontSize: '8px' }}>Resume Count</Typography>
            </Grid>
            )}
        </Grid>
    );
}

export default HorizontalBarChart;
