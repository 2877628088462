import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import ClosedIcon from '../../assets/images/closedIconRTH.svg';

interface Recruiter {
    userName: string;
    designation: string;
    imageUrl: string | null;
}

interface AssignedRecruiterListProps {
    assignedRecruiters: Recruiter[];
}

const AssignedRecruiterList: React.FC<AssignedRecruiterListProps> = ({ assignedRecruiters }) => {
    const visibleRecruiters = assignedRecruiters?.slice(0, 2);
    const extraCount = assignedRecruiters?.length - 2;
    console.log(assignedRecruiters, "assignedRecruiters");
    

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', pt: '5px' }} gap={1}>
            {visibleRecruiters?.map((recruiter, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }} >
                    <Avatar src={recruiter.imageUrl || ClosedIcon} alt={recruiter.userName} sx={{ width: 20, height: 20 }} />
                    <Box>
                        <Typography sx={{ fontSize: '0.625rem', color: '#5F5F5F', fontWeight: '500' }}>{recruiter.userName}</Typography>
                        <Typography sx={{ fontSize: '0.5rem', color: '#B5B5B5', fontWeight: '500' }}>{recruiter.designation}</Typography>
                    </Box>
                </Box>
            ))}
            {extraCount > 0 && (
                <Typography sx={{ fontSize: '12px', color: '#8C8C8C', fontWeight: 'bold' }}> +{extraCount} </Typography>
            )}
        </Box>
    );
};

export default AssignedRecruiterList;
