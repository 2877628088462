import {
    Box,
    Button,
    Chip,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    CircularProgress
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { StyledTableCell } from "./RthClosedTable";
  import {
    getJobPostAPIData,
    getMoveToJobAPIData,
    getReadyForJdCreationAPIData,
    getResumeCardAPIData,
    getWaitingToMoveToJobAPIData,
  } from "../../../../Crud/card";
  import { useAppSelector } from "../../../../redux/hooks";
  import priorityHighSymbol from "../../../../assets/images/priorityHighSymbol.svg";
  import priorityMediumSymbol from "../../../../assets/images/priorityMediumSymbol.svg";
  import priorityLowSymbol from "../../../../assets/images/priorityLowSymbol.svg";
  import { useNavigate } from "react-router-dom";
  import resumeIcon from '../../../../assets/images/resume.svg';
  import NoDataAvail from "../../../../assets/images/NoDataAvail.svg";
   
  interface prop {
    tabName: string;
  }
   
  const RecruiterTable: React.FC<prop> = ({ tabName }) => {
    const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
    const [loading, setLoading] = useState<boolean>(false);
    console.log(loading)
    const userId = localStorage.getItem("userId") ?? "";
    const [tableData, setTableData] = useState<any[]>([]);
    const [buttonName, setButtonData] = useState<string>();
    const [lastColumn, setLastColumn] = useState<string>("Action");
    const navigate = useNavigate();
    const tableHead = [
      "SI.No.",
      "RTH Name",
      "Type of RTH",
      "Posted Time",
      "Business",
      "Priority",
      "Status",
      "Stage",
      lastColumn,
    ];
   
    useEffect(() => {
      if (tabName === "Waiting to Move to Job") {
        setLoading(true);
        getWaitingToMoveToJobAPIData(userId, teamHeadFilter)
          .then((res) => {
            // setLoading(false);
            setTableData(res?.data?.listOfRth);
          })
          .catch((error) => {
            // setLoading(false);
            setTableData([]);
          })
          .finally(() => {
            setLoading(false);
        });
        setButtonData("Move to Job");
      } else if (tabName === "Ready for JD Creation") {
        setLoading(true);
        getReadyForJdCreationAPIData(userId, teamHeadFilter)
          .then((res) => {
            // setLoading(false);
            setTableData(res?.data?.listOfRth);
          })
          .catch((error) => {
            // setLoading(false);
            setTableData([]);
          })
          .finally(() => {
            setLoading(false);
        });
        setButtonData("Create JD");
      } else if (tabName === "Job Post") {
        setLoading(true);
        getJobPostAPIData(userId)
          .then((res) => {
            // setLoading(false);
            setTableData(res?.data?.listOfRth);
          })
          .catch((error) => {
            // setLoading(false);
            setTableData([]);
          })
          .finally(() => {
            setLoading(false);
        });
        setButtonData("Post a Job");
      } else if (tabName === "Resume") {
        setLoading(true);
        getResumeCardAPIData(userId, teamHeadFilter)
          .then((res) => {
            // setLoading(false);
            setTableData(res?.data?.listOfRth);
          })
          .catch((error) => {
            // setLoading(false);
            setTableData([]);
          })
          .finally(() => {
            setLoading(false);
        });
        setLastColumn("Resume Status");
      }
    }, [tabName,teamHeadFilter,userId]);
   
    const handleButtonClick = async (rthId: string, buttonValue: string) => {
      console.log(rthId, buttonValue);
      if (buttonValue === "Move to Job") {
        await getMoveToJobAPIData(rthId, teamHeadFilter);
        await getWaitingToMoveToJobAPIData(userId, teamHeadFilter)
          .then((res) => {
            setLoading(false);
            setTableData(res?.data?.listOfRth);
          })
          .catch((error) => {
            setLoading(false);
            setTableData([]);
          });
      } else if (buttonValue === "Create JD") {
        navigate(`/JDCreation/${rthId}`);
      } else if (buttonValue === "Post a Job") {
      }
    };
   
    return (
      <Box sx={{ width: "auto" }}>
                {loading ? (
          <Box
              sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
              }}
          >
              <CircularProgress size={100} sx={{color: '#2F54EB'}}/>
          </Box>
      ) : 
          tableData.length > 0 ? (
              <Box sx={{ width: "100%", overflowX: "auto" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="RTH table">
                  <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
                    <TableRow
                      sx={{
                        "& th": {
                          padding: "4px 8px", // Adjust padding for header cells here
                          fontSize: "14px", // Optional: Adjust font size
                        },
                      }}
                    >
                      {tableHead.map((data, index) => (
                        <StyledTableCell>{data}</StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      "& td, & th": {
                        padding: "4px 8px", // Adjust padding here
                      },
                    }}
                  >
                    {tableData?.map((data, index) => (
                      <TableRow key={data?.rthId}>
                        <StyledTableCell>{index + 1}</StyledTableCell>
                        <StyledTableCell>{data?.positionTitle}</StyledTableCell>
                        <StyledTableCell>
                          <Box
                            sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                          >
                            <Button
                              size="small"
                              sx={{
                                backgroundColor: "#F0F0F0",
                                border: "1px solid lightgray",
                                fontSize: "8px",
                                padding: "2px",
                                color: "#8C8C8C",
                                height: "20px",
                                minWidth: "50px",
                              }}
                            >
                              {data.typeOfRth}
                            </Button>
                            <Button
                              size="small"
                              sx={{
                                backgroundColor: "#F0F0F0",
                                border: "1px solid lightgray",
                                fontSize: "8px",
                                padding: "2px 4px",
                                color: "#8C8C8C",
                                height: "20px",
                                minWidth: "50px",
                              }}
                            >
                              {data.modeOfRth}
                            </Button>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>{data?.createdAt}</StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            sx={{
                              width: "fit-content",
                              fontSize: "12px",
                              padding: "3%",
                              height: "px",
                              border:
                                "1px solid " +
                                (data.business.businessName === "HEPL"
                                  ? "#D3ADF7"
                                  : "#87E8DE"),
                              backgroundColor:
                                data.business.businessName === "HEPL"
                                  ? "#EFDBFF"
                                  : "#B5F5EC",
                              color:
                                data.business.businessName === "HEPL"
                                  ? "#722ED1"
                                  : "#08979C",
                            }}
                          >
                            {data.business.businessName}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            gap={1}
                          >
                            {data.priority === "High" ? (
                              <Box>
                                <img src={priorityHighSymbol} alt="" />
                              </Box>
                            ) : data.priority === "Medium" ? (
                              <Box>
                                <img src={priorityMediumSymbol} alt="" />
                              </Box>
                            ) : (
                              <Box>
                                <img src={priorityLowSymbol} alt="" />
                              </Box>
                            )}
                            <Typography
                              sx={{
                                color:
                                  data.priority === "High"
                                    ? "#F04E4E"
                                    : data.priority === "Medium"
                                    ? "#FFA940"
                                    : "#73D13D",
                                fontSize: "100%",
                              }}
                            >
                              {data.priority}
                            </Typography>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Chip
                            label={data.status}
                            size="small"
                            sx={{
                              fontSize: "12px",
                              height: "px",
                              borderRadius: "1px",
                              backgroundColor: "#389E0D1A",
                              color: "#389E0D",
                            }}
                          />
                        </StyledTableCell>
     
                        <StyledTableCell>
                          <Box
                            sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Chip
                                label={data.rthProcess}
                                size="small"
                                sx={{
                                  fontSize: "12px",
                                  padding: "3px 6px 0px 6px",
                                  height: "20px",
                                  borderRadius: "1px",
                                  backgroundColor: "transparent",
                                  color: "#8C8A8A",
                                }}
                              />
                            </Box>
                          </Box>
                        </StyledTableCell>
     
                        {
                        (tabName === "Resume") && (
                          <StyledTableCell>
                            <Box display={"flex"} alignItems={"center"} gap={0.5}>
                              <img src={resumeIcon} alt="-" />
                              <Typography sx={{fontSize:"12px"}}>Resume Uploaded</Typography>
                              <Chip
                            label={data.resumesCount}
                            size="small"
                            sx={{
                              fontSize: "12px",                        
                              borderRadius: "3px",
                              backgroundColor: "#1677FF1A",
                              color: "#1677FF",
                              padding:"0px"
                            }}
                          />
                            </Box>
                          </StyledTableCell>
                        )
                      }
                      {
                          (tabName !== "Resume") &&
                      (
                          <StyledTableCell>
                            <Button
                              size="small"
                              sx={{ textTransform: "none" }}
                              onClick={(e) =>
                                handleButtonClick(
                                  data.rthId,
                                  e.currentTarget.innerText
                                )
                              }
                            >
                              {buttonName}
                            </Button>
                          </StyledTableCell>
                        )}
     
     
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
     
   
          ) : (
   
              <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "300px",
              }}
            >
              <img src={NoDataAvail} alt="No Data Available" style={{ maxWidth: "200px" }} />
            </Box>
   
          )
                }
      </Box>
    );
  };
   
  export default RecruiterTable;