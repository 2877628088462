import React from 'react';
import { Box, Typography } from '@mui/material';
import Resume from '../../assets/images/resume.svg';

interface ResumeCountProps {
  count?: number;
}

const ResumeCount: React.FC<ResumeCountProps> = ({ count }) => {
  const displayCount = count ?? 0;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: '8px', width: '200px', pt: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <img src={Resume} alt="Resume Icon" style={{ width: '15px', height: '15px' }}/>
        <Typography sx={{ fontSize: '10px', color: '#8C8C8C', fontWeight: '400' }}> Resume Uploaded </Typography>
        <Typography sx={{ backgroundColor: '#1677FF1A', color: '#1677FF', borderRadius: '2px', padding: '2px 6px', fontWeight: 600, fontSize: '12px', textAlign: 'center' }}> {displayCount} </Typography>
      </Box>
    </Box>
  );
};

export default ResumeCount;
