import React, { useState } from 'react';
import { Box, Typography, Chip } from '@mui/material';
import greenDot from '../../../assets/images/greenDot.svg'
import {
    Paper,
    Stepper,
    Step,
    StepLabel
  } from '@mui/material';
  
  import goodTick from '../../../assets/images/goodTick.svg'
  import orangeRoundClock from '../../../assets/images/orangeRoundClock.svg'
import AssignRecruiterLead from './assignRecruiterLead';
import AssignRecruiterNew from './assignRecruiterNew';
import { processMap } from '../../../constants/fieldConstants';
// import EditOrShowAssignRecruiter from './EditOrShowAssignRecruiter';
// import AssignRecruiterView from './AssignRecruiterView';
// import { assignRecruiter } from '../../../redux/features/recruiterSlice';


export interface RequestToHireProps {
  positionTitle?: string;
  status?: string;
  Steps: string[];
  approvalStatus: { l1: string, l2: string };
  l1?:string;
  l2?:string;
  numOfPosition?:string;
  recruiterAssigned?:boolean;
  numOfOpenPosition?:any;
  data?:any;
}

const Headergpt: React.FC<RequestToHireProps> = ({
  positionTitle,
  status,
  Steps,  
  numOfPosition,
  l1,
  l2,
  recruiterAssigned,
  numOfOpenPosition,
  data
}) => {      
  const {roleName} = localStorage.getItem("user")?JSON.parse(localStorage.user):null;

  const [view,setView]=useState<boolean>(false);

  console.log(view,roleName,recruiterAssigned);

  
 
  return (  
    <Box sx={{ p: 2, backgroundColor: '#f0f4ff', borderRadius: 2 }}>
      
      
      
      {/* Top Section: Logo, Title, Status, Edit/Delete Icons */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          
          <Typography variant="h4" sx={{ flexGrow: 1, fontSize: '20px', fontWeight: 'Bold' }}> {(positionTitle ?? '').length > 32 ? `${positionTitle?.substring(0, 32)}...` : positionTitle}</Typography>
          <Chip label={status} color={status === 'Open' ? 'success' : 'default'} sx={{ ml: 2,backgroundColor:'#00AB550D',border:'1px solid green',borderRadius:'5px',color:'#00AB55' }} icon={<img src={greenDot} alt="status indicator" />}  />
          {/* <Chip label="Waiting for L1 & L2 Approval" sx={{ ml: 1, backgroundColor: '#f0f0f0', color: '#555',border:'1px solid #707b7c ',borderRadius:'5px' }} /> */}
        </Box>
      </Box>
      
{
(roleName === "TALENTACQUISITIONHEAD") &&
      <Box sx={{ p: 2, backgroundColor: 'background.default',marginBottom:'1%',borderRadius:2 }}>
        <AssignRecruiterLead numOfPosition={numOfPosition} numOfOpenPosition={numOfOpenPosition}/>
      </Box>
}

{((roleName === "RECRUITERLEAD") || (roleName === "RECRUITER")) && (
  <Box>    
      <Box sx={{ p: 2, backgroundColor: 'background.default', marginBottom: '1%', borderRadius: 2 }}>
        <AssignRecruiterNew  numOfPosition={numOfPosition} numOfOpenPosition={numOfOpenPosition} view={view} setView={setView} />
      </Box>
  </Box>
)}

      
      {/* RTH Process Steps */}
      
      <Paper elevation={1} sx={{ p: 2, backgroundColor: 'background.default' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row'}}>
      <Box sx={{width: '80%'}}>
        <Typography variant="subtitle2" sx={{ mb: 1, fontSize: '10px', color: "gray", ml: '10px' }}>
          RTH Process
        </Typography>
        <Stepper activeStep={processMap[data?.rthProcess]} alternativeLabel >
          {Steps.map((label) => (
            <Step key={label}>
              <StepLabel
                    StepIconProps={{
                      sx: { fontSize: '18px' }, 
                    }}
                    sx={{
                      '& .MuiStepLabel-label': {
                        fontSize: '9px', 
                      },
                    }}
                  >{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end',alignItems: 'center' }}>
        <Chip label={"L1 "+l1} sx={
          l1 === 'Approved'?
          { mr: 1,backgroundColor:'#F6FFED',borderRadius:'5px',color:'#595959',border:'1px solid #D9F7BE'}:
          { mr: 1,backgroundColor:'#FFF7E6',border:'1px solid #FFE7BA',borderRadius:'5px',color:'#595959' }
          
          } icon={
            l1 === 'Approved'?
          <img src={goodTick} alt="status indicator" />:<img src={orangeRoundClock} alt="status indicator"/>          
          } />
        <Chip label={"L2 "+ (l2??"Pending")} sx={
          l2 === 'Approved'?
          { mr: 1,backgroundColor:'#F6FFED',borderRadius:'5px',color:'#595959',border:'1px solid #D9F7BE'}:
          { mr: 1,backgroundColor:'#FFF7E6',border:'1px solid #FFE7BA',borderRadius:'5px',color:'#595959' }
          
          } icon={
            l2 === 'Approved'?
          <img src={goodTick} alt="status indicator"/>:<img src={orangeRoundClock} alt="status indicator"/>          
          } />

          
        
      </Box>
      </Box>
      </Paper>
      

      {/* Approval Status */}

    </Box>
  );
};
export default Headergpt;