import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { DataGrid, GridColDef, GridActionsCellItem, GridPaginationModel } from '@mui/x-data-grid';
import { Avatar, Box, InputAdornment, InputBase, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import EditDepartment, { DepartmentData } from "./DepartmentEdit";
import { fetchDepartment } from "../../../../redux/features/departmentSlice";

const DepartmentTable: React.FC = () => {
    const [selectedUser, setSelectedUser] = useState<DepartmentData | null>(null);
    const [editOpen, setEditOpen] = useState(false);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 25 });
    const [searchQuery, setSearchQuery] = useState<string>("");  
    const dispatch = useDispatch<AppDispatch>();
    const { users } = useSelector((state: RootState) => state.department);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchQuery(query);
    };

    useEffect(() => {
        dispatch(fetchDepartment(searchQuery)); 
    }, [dispatch, searchQuery, paginationModel.page, paginationModel.pageSize]);

    const handleEditClick = (user: DepartmentData) => {
        setSelectedUser(user);
        setEditOpen(true);
    };

    const handleEditClose = () => {
        setEditOpen(false);
        setSelectedUser(null);
    };

    const processedRows = useMemo(() => {
        if (!users?.data) return [];
        const startIndex = paginationModel.page * paginationModel.pageSize;
        return users.data.slice(startIndex, startIndex + paginationModel.pageSize).map((row, index) => ({
            ...row,
            departmentId: row.departmentId || `temp-id-${index}`,
            serialNumber: startIndex + index + 1,
        }));
    }, [users?.data, paginationModel.page, paginationModel.pageSize]);

    const columns: GridColDef[] = [
        {
            field: 'serialNumber',
            headerName: 'SI.No',
            flex: 0.01,
            sortable: false,
        },
        {
            field: 'departmentName',
            headerName: 'Department Name',
            flex: 2.5,
        },
        {
            field: 'l1Approver',
            headerName: 'L1 Approver',
            flex: 2.4,
            renderCell: (params) => {
                const approver = params.row.l1Approver;
                return (
                    <Box display="flex" alignItems="center" sx={{ marginTop: '6px' }}>
                        {approver?.imageUrl ? (
                            <Avatar alt={approver?.userName} src={approver?.imageUrl} sx={{ width: 24, height: 24, marginRight: 1 }} />
                        ) : (
                            <Avatar alt={approver?.userName} sx={{ width: 24, height: 24, marginRight: 1 }} />
                        )}
                        <Box display="flex" flexDirection="column">
                            <Typography sx={{ fontSize: '0.625rem' }} fontWeight="500">{approver?.userName || 'N/A'}</Typography>
                            <Typography sx={{ fontSize: '0.563rem', color: '#0000004D' }} fontWeight="500">{approver?.designation || 'N/A'}</Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'l2Approver',
            headerName: 'L2 Approver',
            flex: 2.4,
            renderCell: (params) => {
                const approver = params.row.l2Approver;
                return (
                    <Box display="flex" alignItems="center" sx={{ marginTop: '6px' }}>
                        {approver?.imageUrl ? (
                            <Avatar alt={approver?.userName} src={approver?.imageUrl} sx={{ width: 24, height: 24, marginRight: 1 }} />
                        ) : (
                            <Avatar alt={approver?.userName} sx={{ width: 24, height: 24, marginRight: 1 }} />
                        )}
                        <Box display="flex" flexDirection="column">
                            <Typography sx={{ fontSize: '0.625rem' }} fontWeight="500">{approver?.userName || 'N/A'}</Typography>
                            <Typography sx={{ fontSize: '0.563rem', color: '#0000004D' }} fontWeight="500">{approver?.designation || 'N/A'}</Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Action',
            type: 'actions',
            flex: 0.4,
            getActions: (params) => [
                <GridActionsCellItem icon={<EditIcon sx={{ fontSize: "12px" }} onClick={() => handleEditClick(params.row)} />} label="Edit" />,
            ],
        },
    ];

    return (
        <>
            {/* Search */}
            <Box display="flex" alignItems="center" mt={2} justifyContent="space-between">
                <InputBase
                    value={searchQuery}  
                    onChange={handleSearchChange}  
                    placeholder="Search Department..."
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
                        </InputAdornment>
                    }
                    sx={{
                        border: '1px solid #ccc',
                        borderRadius: 2,
                        padding: 0,
                        backgroundColor: 'white',
                        width: '180px',
                        height: '36px',
                        fontSize: 12,
                    }}
                />
            </Box>
            <Box sx={{ height: '80vh', marginTop: 2, marginBottom: 2, overflow: 'auto' }}>
                <DataGrid
                    rows={processedRows}
                    columns={columns}
                    getRowId={(row) => row.departmentId || row.id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={(model) => setPaginationModel(model)}
                    pageSizeOptions={[25, 50, 100]}
                    rowCount={users?.data?.length || 0}
                    paginationMode="server"
                    disableColumnSorting
                    disableColumnMenu
                    rowHeight={40}
                    sx={{
                        minWidth: '600px',
                        '& .MuiDataGrid-cell': {
                            fontSize: '10px',
                            fontWeight: '500',
                            backgroundColor: '#FFFFFF',
                            padding: '0 8px',
                            borderRight: '0.5px solid #B5B5B5'
                        },
                        '& .MuiDataGrid-columnHeader': {
                            fontSize: '10px',
                            fontWeight: '500',
                            backgroundColor: '#F9F9F9',
                            padding: '0 8px',
                            borderRight: '0.5px solid #B5B5B5',
                            height: '40px !important',
                            lineHeight: '40px !important',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            minHeight: '40px !important',
                            maxHeight: '40px !important',
                        },
                        '& .MuiDataGrid-columnHeader:last-child, & .MuiDataGrid-cell:last-child': {
                            borderRight: 'none',
                        },
                        '& .MuiDataGrid-columnHeader:nth-last-child(3)': {
                            borderRight: 'none',
                        },
                        '& .MuiDataGrid-columnSeparator': { display: 'none' },
                        '& .MuiDataGrid-iconButtonContainer': { visibility: 'visible' },
                        '& .MuiDataGrid-sortIcon': { opacity: 'inherit !important', color: '#CDCDCD', fontSize: '12px' },
                        '& .MuiDataGrid-selectedRowCount': { display: 'none' },
                        '& .MuiDataGrid-row:hover': { cursor: 'pointer' },
                        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': { outline: 'none' },
                    }}
                />
            </Box>

            {/* Edit Department */}
            {selectedUser && (
                <EditDepartment
                    open={editOpen}
                    onClose={handleEditClose}
                    user={selectedUser}
                />
            )}
        </>
    );
};

export default DepartmentTable;
