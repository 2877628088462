import React from 'react';
import { Autocomplete, FormControl, FormHelperText, InputAdornment, MenuItem, Select, TextField } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import interviewLinkCopyIcon from '../../../../../assets/images/interviewLinkCopyIcon.svg';
import { useEffect, useState } from 'react';
interface Option {
  label: string;
  value: string;
}

export const TextFieldUI = ({value ,handleInputChange, placeholder, name, width, type,error,helperText }:any) => {
  const isTimeField = type === "time"; // Check if the type is time
  const timeInputProps = isTimeField
    ? {
        inputProps: {
          min: "08:00", // Minimum time
          max: "19:00", // Maximum time
          step: 300, // Optional: Step interval in seconds (e.g., 300 = 5 minutes)
        },
      }
    : {};

  return (
    <TextField
    id="outlined-basic"
    placeholder={placeholder}
    variant="outlined"
    name={name}
    value={value}
    onChange={handleInputChange}
    type = {type}
    error={error}
    helperText={helperText}
    {...timeInputProps}

        sx={{        
      '& .MuiOutlinedInput-root': {
        height: '50px',
        backgroundColor: 'white',
        color: '#9A9A9A',
        width: width,
        borderRadius:'8px',
        '& fieldset': {
          borderColor: '#D9D9D9',
        },
        '&:hover fieldset': {
          borderColor: '#D9D9D9',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#D9D9D9', // Ensure consistent border color on focus
        },
      },
      '& .MuiInputLabel-root': {
        color: '#9A9A9A',
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: '#9A9A9A',
      },
    }}
    />  
  )
}
 
export const SelectUI = ({value, handleInputChange, name, option, label, width,error=false,helperText=''}:any) => {
  return (
    <FormControl fullWidth sx={{ width }} error={error}>

                 <Select
                  fullWidth
                  defaultValue=""
                  IconComponent={KeyboardArrowDownIcon} // Use the custom icon for dropdown
                  value={value}
                  onChange={handleInputChange}
                  name={name}
                  sx={{
                    width: width,
                    bgcolor:'white',
                    borderRadius:'8px',
                    height:'50px',
                    color: '#000', // Black color for the selected text
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #9A9A9A', // Custom border for normal state
                      borderRadius: '8px', // Rounded corners if needed
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #9A9A9A', // Keep border color consistent on hover
                    },
                    '& .MuiSelect-select': {
                      padding: '10px', // Adjust padding inside the select box
                      color: '#000', // Black color for the selected text
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #9A9A9A', // Keep border color consistent when focused
                    },
                  }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <span style={{ color: '#9A9A9A' }}>{label || 'Select an option'}</span>; // Placeholder text styling
                    }
                    const selectedOption = option.find((opt: any) => opt.value === selected);
                    return selectedOption ? selectedOption.label : selected;            
                  }}
                >
                    {option.map((option:any)=> (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>            
                ))}
               
              </Select>
              {helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>

  )
}
 
export const InterviewLinkUI = ({ width, value, handleInputChange, name }:any) => {
    return(
        <TextField
        // disabled
        value={value}
        onChange={handleInputChange}
        name={name}
        id="outlined-disabled"
        placeholder="Interview Link"
        sx={{
            '& .MuiOutlinedInput-root': {
            height: '50px',
            backgroundColor: 'white',
            color: '#9A9A9A',
            width: width, // Adjust the width as per your requirement
            borderRadius: '8px',
            '& fieldset': {
                borderColor: '#D9D9D9',
            },
            '&:hover fieldset': {
                borderColor: '#D9D9D9',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#D9D9D9', // Ensure consistent border color on focus
            },
            },
            '& .MuiInputLabel-root': {
            color: '#9A9A9A',
            },
            '& .MuiInputLabel-root.Mui-focused': {
            color: '#9A9A9A',
            },
        }}
        InputProps={{
            endAdornment: (
            <InputAdornment position="end">
                <img src={interviewLinkCopyIcon} alt="interviewLinkCopyIcon" />
            </InputAdornment>
            ),
        }}
        />
    )
}
 
export const CommonAutocomplete= ({
  value,
  handleChange,
  name,
  width = '100%',
  fetchOptions,
  label,
  initialOptions = [],
  error=false,
  helperText=''
}:any) => {

  const formattedInitialOptions = initialOptions.map((option: any) => ({
    label: `${option.firstName} ${option.lastName}`,
    value: option.userId,
  }));

  // const[inputValue,setInputValue]=useState<any[]>([]);
  const [options, setOptions] = useState(formattedInitialOptions);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);


  const handleInputChange = async (query: string) => {
    if (!query) return;
    try {
      const fetchedOptions = await fetchOptions(query);
      setOptions(fetchedOptions);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  
  useEffect(() => {
    const matchedOption = options.find((opt:any) => opt.value === value);
    if (matchedOption) {
      setSelectedOption(matchedOption);
      // setInputValue(matchedOption.label); 
    } else {
      // setInputValue(value); 
    }
  }, [value, options]); // This hook runs when either 'value' or 'options' changes

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.label || ''}
      value={selectedOption || null} // Set value to selectedOption
      onInputChange={(_event, newInputValue) => {
        handleInputChange(newInputValue); // Fetch options dynamically
      }}
      onChange={(_event, newValue) => {
        handleChange({
          target: {
            name,
            value: newValue?.value || '',
          },
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={label}
          variant="outlined"
          fullWidth
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            // endAdornment: (
            //   <>
            //     {loading ? <CircularProgress color="inherit" size={20} /> : null}
            //     {params.InputProps.endAdornment}
            //   </>

            // ),
          }}
        />
      )}
      sx={{        
        '& .MuiOutlinedInput-root': {
          height: '50px',
          backgroundColor: 'white',
          color: '#9A9A9A',
          width: width,
          borderRadius:'8px',
          '& fieldset': {
            borderColor: '#D9D9D9',
          },
          '&:hover fieldset': {
            borderColor: '#D9D9D9',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#D9D9D9', // Ensure consistent border color on focus
          },
        },
        '& .MuiInputLabel-root': {
          color: '#9A9A9A',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: '#9A9A9A',
        },
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
    />
  );
};
