import React from "react";
import { Box, Button, Typography } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";

import { IconButton } from "@mui/material";
import { useLocation } from "react-router-dom";
export const SearchBar = ({
  viewType,
  handleViewChange,
  handleButtonClick,
  handleBulkUploadClick,
  pageType,
  tabName,
}: any) => {
  const location = useLocation();

  const buttonContent = location.pathname.includes("MRH")
    ? "Import"
    : "New MRF Form";

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      padding="20px"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        flexGrow={1}
      >
        {tabName === "My Requests" && (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box
              sx={{
                width: 16,
                height: 16,
                borderRadius: 1,
                border: "2px solid #FFE546",
                backgroundColor: "#FAF8EC",
                mr: 1,
              }}
            />
            <Typography sx={{ mr: 1, fontSize: "12px" }}>
              L1 Approval Pending
            </Typography>
            <Box
              sx={{
                width: 16,
                height: 16,
                borderRadius: 1,
                border: "2px solid #5708B8",
                backgroundColor: "#F2ECFA",
                mr: 1,
              }}
            />
            <Typography sx={{ mr: 1, fontSize: "12px" }}>
              L2 Approval Pending
            </Typography>
            <Box
              sx={{
                width: 16,
                height: 16,
                borderRadius: 1,
                border: "2px solid #36CFC9",
                backgroundColor: "#EAF5FE",
                mr: 1,
              }}
            />
            <Typography sx={{ mr: 1, fontSize: "12px" }}>
              Both Approved
            </Typography>
          </Box>
        )}
        {tabName === "Overall RTH" && (<Box display="flex" flexDirection="row" alignItems="center">
            {/* <Box
              sx={{
                width: 16,
                height: 16,
                borderRadius: 1,
                border: "2px solid #FFE546",
                backgroundColor: "#FAF8EC",
                mr: 1,
              }}
            />
            <Typography sx={{ mr: 1, fontSize: "12px" }}>L1 Approval Pending</Typography> */}
            <Box
              sx={{
                width: 16,
                height: 16,
                borderRadius: 1,
                border: "2px solid #5708B8",
                backgroundColor: "#F2ECFA",
                mr: 1,
              }}
            />
            <Typography sx={{ mr: 1, fontSize: "12px" }}>L2 Approval Pending</Typography>
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  borderRadius: 1,
                  border: "2px solid #36CFC9",
                  backgroundColor: "#EAF5FE",
                  mr: 1,
                }}
              />
              <Typography sx={{ mr: 1, fontSize: "12px" }}>Both Approved</Typography>
          </Box>
        )}
        <Box display="flex" justifyContent="flex-end">
          {
            tabName !== "MRF Hub" && (
              <IconButton onClick={() => handleViewChange("grid")}>
              <GridViewRoundedIcon
                sx={{ color: viewType === "grid" ? "#1677FF" : "inherit" }}
              />
            </IconButton>              
            )
          }
          <IconButton onClick={() => handleViewChange("list")}>
            <FormatListBulletedIcon
              sx={{ color: (viewType === "list" || tabName === "MRF Hub") ? "#1677FF" : "inherit" }}
            />
          </IconButton>

          {location.pathname.includes("MRH") && pageType !== "Resume" && (
            <Button
              variant="outlined"
              color="primary"
              sx={{ fontSize: "12px", textTransform: "none", mr: 2 }}
              onClick={handleBulkUploadClick}
            >
              Bulk Upload
            </Button>
          )}
          {pageType !== "Resume" && (
            <Button
              variant="contained"
              color="primary"
              sx={{ fontSize: "12px", textTransform: "none" }}
              onClick={handleButtonClick}
            >
              {buttonContent}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SearchBar;
