import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Grid, Alert } from "@mui/material";
import { useDispatch } from 'react-redux';
import { getRthBusinessInputData, submitRthForm } from '../../../../redux/features/rthSlice';
import CommonSelect from "../../../common/CommomSelect";
import { SelectChangeEvent } from "@mui/material";
import CommonTextField from "../../../common/CommonTextField";
import type { AppDispatch } from '../../../../redux/store';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { getRthBandData, getRthDepartmentData, getRthLocationData, getRthPositionReportsTo, getRthPrimarySkillsData, getRthQualificationData, getRthSecondarySkillsData, getRthWorkModeData, getRthL1ApproverData, getRthL2ApproverData  } from "../../../../Crud/rth";
import { uploadFile } from "../../../../Crud/choosefile";
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-toastify';
import { getRthL1L2GetAPIData } from "../../../../Crud/newRth";
import { useNavigate } from "react-router-dom";

interface SkillOption {
  value: string;
  label: string;
}



export const useAppDispatch: () => AppDispatch = useDispatch;

interface FormData {
  userId: string;
  businessId: string;
  positionTitle: string;
  numOfPosition: string;
  internalSpoc: string;
  location: string;
  departmentId: string;
  band: string;
  qualNeeded: string;
  minExperience: string;
  maxExperience: string;
  primarySkills?: any;  
  secondarySkills?: any;  
  prefStartDate: string;
  workMode: string;
  priority: string;
  comments: string;
  clientName: string;
  clientpoc: string;
  clientRateCard: string;
  OneTimeAmount: string;
  OneTimeCharge: string;
  approvalAttachment?: string;
  typeOfRth:string,
  modeOfRth:string,
  l1ApprovalId: string;
  l2ApprovalId: string;
  jdAttachmentFileUrl: string;
  otherFileUrl: String;
}

const arr: any = [];
const secondarySkills: string[] = [];
const ExternalDetails: React.FC = () => {

  const [chooseFile, setChooseFile] = useState()
  const [chooseFile2, setChooseFile2] = useState()
  const [chooseFile1, setChooseFile1] = useState()
  const [posReportsTo, setposReportsTo] = useState([])
  const [LocationData, setLocationData] = useState([])
  const [ QualificationData, setQualificationData] = useState([])
  // const [PriorityData,setPriorityData] = useState([])
  const [L2ApproverData,setL2ApproverData] = useState([])
  const [L1ApproverData,setL1ApproverData] = useState([])
  const [secondarySkillsData,setSecondarySkillsData] = useState([])
  const [primarySkillsData,setPrimarySkillsData] = useState([])
  const [WorkModeData,setWorkModeData] = useState([])
  console.log("WorkModeData",WorkModeData)
  const [departmentData,setDepartmentData] = useState([])
  const [bandData,setBandData] = useState([])
  const [businessData, setBusinessData] = useState([])
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [submitted, setSubmitted] = useState(false);
  const [pSkills,setPSkills] = useState<any>([]);
  const [sSkills,setSSkills] = useState<any>([]);

const navigate = useNavigate();
  const token: any = localStorage.getItem("token")
  const decoded: any = jwtDecode(token);
  const userId = decoded.userId;

  const [formData, setFormData] = useState<FormData>({
    userId: userId || "",
    businessId: "",
    positionTitle: "",
    numOfPosition: "",
    internalSpoc: "",
    location: "",
    departmentId: "",
    band: "",
    qualNeeded: "",
    minExperience: "",
    maxExperience: "",
    primarySkills: "",  
    secondarySkills: "",
    prefStartDate:"",
    workMode:"",
    priority: "",
    comments: "",
    clientName: "",
    clientpoc: "",
    clientRateCard: "",
    OneTimeAmount: "",
    OneTimeCharge: "",
    approvalAttachment: "",
    typeOfRth:"External",
    modeOfRth:"OneTime",
    l1ApprovalId: "",
    l2ApprovalId: "",
    jdAttachmentFileUrl: "",
    otherFileUrl: "",
  });
  
  useEffect(() => {
    getRthBusinessInputData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.businessId, label: i.businessName } })
      setBusinessData(data)
     // console.log('data', data)
    }).catch((err) => console.error(err));
    
    getRthBandData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.bandName, label: i.bandName } })
      setBandData(data)
     // console.log('data', data)
    }).catch((err) => console.error(err));

    getRthDepartmentData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.departmentId, label: i.departmentName } })
      setDepartmentData(data)
      //console.log('data', data)
    }).catch((err) => console.error(err));

    getRthWorkModeData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.workId, label: i.workName } })
      setWorkModeData(data)
     // console.log('data', data)
    }).catch((err) => console.error(err));

    getRthPrimarySkillsData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.id, label: i.skillName } })
      setPrimarySkillsData(data)
      // console.log('data', data)
    }).catch((err) => console.error(err));

    getRthSecondarySkillsData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.id, label: i.skillName } })
      setSecondarySkillsData(data)
      // console.log('data', data)
    }).catch((err) => console.error(err));

    getRthL1ApproverData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.userId, label: i.firstName } })
      setL1ApproverData(data)
    }).catch((err) => console.error(err));

    getRthL2ApproverData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.userId, label: i.firstName } })
      setL2ApproverData(data)
    }).catch((err) => console.error(err));

    // getRthPriorityData().then(res => {
    //   const data = res.data?.map((i: any) => { return { value: i.priorityId, label: i.priorityName } })
    //   setPriorityData(data)
    // }).catch((err) => console.error(err));

    getRthQualificationData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.degree, label: i.degree } })
      setQualificationData(data)
      //console.log('data', data)
    }).catch((err) => console.error(err));

    getRthLocationData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.locationName, label: i.locationName } })
      setLocationData(data)
      //console.log('data', data)
    }).catch((err) => console.error(err));

    getRthPositionReportsTo().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.userId, label: i.firstName } })
      setposReportsTo(data)
      //console.log('data', data)
    }).catch((err) => console.error(err));
  }, []);
  
  const workModeOptions = [
    
    { value: 'onsite', label: 'Onsite' },
    { value: 'remote', label: 'Remote' },
    { value: 'hybrid', label: 'Hybrid' },
  ];

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target;
    console.log("change", name, value);
    if (name === "secondarySkills") {
      secondarySkills.push(value);
     
    }
    if (name === "primarySkills") {
      arr.push(value);
      console.log("arr", arr)
      setFormData({
        ...formData,
        primarySkills: [arr],
      });
      console.log("....formData", formData);
    }
    
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      const formData = new FormData();
      formData.append("image", file);

      // setProfilePic("")
      reader.onload = (e) => {
        // setProfilePic(e.target?.result as string);
        // const imageData = e.target?.result as string;
        uploadFile(formData).then((res) => setChooseFile(res.data.data)).catch(err => console.error(err));
      };
      reader.readAsDataURL(file);
      // console.log("profilePic33", profilePic);
    }
  };
  const handleFileChange1 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      const formData = new FormData();
      formData.append("file", file);

      reader.onload = (e) => {
       
        uploadFile(formData).then((res) => setChooseFile1(res.data.data)).catch(err => console.error(err));
      };
      reader.readAsDataURL(file);
    }
  }

  const handleFileChange2 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      const formData = new FormData();
      formData.append("file", file);

      reader.onload = (e) => {
       
        uploadFile(formData).then((res) => setChooseFile2(res.data.data)).catch(err => console.error(err));
      };
      reader.readAsDataURL(file);
      
    }
  }

  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    
    Object.keys(formData).forEach((key) => {
      if (key === 'approvalAttachment' && formData.approvalAttachment === '') {
        return;
      }
      if (key === 'jdAttachmentFileUrl' && formData.jdAttachmentFileUrl === '') {
        return;
      }
      if (key === 'otherFileUrl' && formData.otherFileUrl === '') {
        return;
      }
      if (!formData[key as keyof typeof formData]) {
        newErrors[key] = key;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setSubmitted(true);
    console.log("arr", arr) 

    let data = {
      ...formData
    }
    if (pSkills.length>=1) {
      data = {
        ...data,
        primarySkills: pSkills
      }
    }
    if (setSSkills.length>=1) {
      data = {
        ...data,
        secondarySkills: sSkills
      }
    }
    if (chooseFile) {
      data = {
        ...data,
        approvalAttachment: chooseFile
      }
    }
    if (chooseFile2) {
      data = {
        ...data,
        jdAttachmentFileUrl: chooseFile2
      }
    }
    if (chooseFile1) {
      data = {
        ...data,
        otherFileUrl: chooseFile1
      }
    }
    console.log("Contract", data)

    if (validate()) {
      await submitRthForm(data);
      // Form is valid, handle form submission
      toast.success("Form Submitted Sucessfully")
      navigate('/dashboard?tab=my-requests')
      setFormData({
        userId: userId || "",
        businessId: "",
      positionTitle: "",
      numOfPosition: "",
      internalSpoc: "",
      location: "",
      departmentId: "",
      band: "",
      qualNeeded: "",
      minExperience: "",
      maxExperience: "",
      primarySkills: "",  
      secondarySkills: "",
      prefStartDate:"",
      workMode:"",
      priority: "",
      comments: "",
      clientName: "",
      clientpoc: "",
      clientRateCard: "",
      OneTimeAmount: "",
      OneTimeCharge: "",
      approvalAttachment: "",
      typeOfRth:"External",
      modeOfRth:"OneTime",
      l1ApprovalId: "",
    l2ApprovalId: "",
    jdAttachmentFileUrl: "",
    otherFileUrl: "",
    });
      setErrors({});
      setSubmitted(false);
    }
  };
  useEffect(() => {
    if (formData?.departmentId) {
      getRthL1L2GetAPIData(formData.departmentId)
        .then((res) => {
          if (res.data?.data) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              l1ApprovalId: res.data.data?.l1Approver?.userId,
              l2ApprovalId: res.data.data?.l2Approver?.userId,
            }));
  
            // Clear the validation errors for l1ApprovalId and l2ApprovalId
            setErrors((prevErrors) => ({
              ...prevErrors,
              l1ApprovalId: "",
              l2ApprovalId: "",
            }));
          }
        })
        .catch((error) => console.error(error));
    }
  }, [formData?.departmentId]);

  return (
    <>
     <Box component="form" onSubmit={handleSubmit}>
      <Box
        sx={{
          p: 3,
          backgroundColor: "white",
          marginTop: "10px",          
        }}
      >
        <Typography color="gray" sx={{ fontSize: '12px' }}>On-Role Details</Typography>
        {/* Main content goes here */}
        <Box component="form" sx={{ mt: 3 }}>
            <Grid container spacing={4}>
              <Grid
                item
                xs={12}
                md={2}
                sx={{ paddingTop: "33px", paddingLeft: "30px" }}
              >
                <CommonSelect
                  labelId="business-label"
                  label="Business"
                  name="businessId"
                  value={formData.businessId}
                  onChange={handleChange}
                  options={businessData}
                  error={!!errors.businessId}
                  helperText={errors.businessId === "businessId" ? "Business" : "" } 
                />
              </Grid>
              <Grid item xs={12} md={2}>
              <CommonTextField
                  label="Position Title"
                  name="positionTitle"
                  value={formData.positionTitle
                    ?.replace(/[^a-zA-Z\s]/g, "")
                    .substring(0, 32)}
                  onChange={handleChange}
                  error={
                    !!errors.positionTitle ||
                    (formData.positionTitle?.length || 0) > 32
                  }
                  helperText={
                    (formData.positionTitle?.length || 0) > 32
                      ? "Position Title cannot exceed 32 characters"
                      : errors.positionTitle === "positionTitle"
                      ? "Position Title field is required"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
              <CommonTextField
                  label="Number of Positions"
                  name="numOfPosition"
                  value={formData.numOfPosition
                    ?.replace(/[^0-9]/g, "")
                    .substring(0, 4)}
                  onChange={handleChange}
                  error={
                    !!errors.numOfPosition ||
                    (formData.numOfPosition?.length || 0) > 4
                  }
                  helperText={
                    (formData.numOfPosition?.length || 0) > 4
                      ? "Number of Positions cannot exceed 4 Letters"
                      : errors.numOfPosition === "numOfPosition"
                      ? "Number of Positions field is requierd"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Internal SPOC"
                  label="Internal SPOC"
                  name="internalSpoc"
                  value={formData.internalSpoc}
                  onChange={handleChange}
                  error={!!errors.internalSpoc}
                  helperText={errors.internalSpoc === "internalSpoc" ? "Internal SPOC" : ""}
                  options={posReportsTo}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="location"
                  label="Location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  error={!!errors.location}
                  helperText={errors.location === "location" ? "Location " : ""}
                  options={LocationData}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Department"
                  label="Department"
                  name="departmentId"
                  value={formData.departmentId}
                  onChange={handleChange}
                  options={departmentData}
                  error={!!errors.departmentId}
                  helperText={errors.departmentId === "departmentId" ? "Department" : ""}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Band"
                  label="Band"
                  name="band"
                  value={formData.band}
                  onChange={handleChange}
                  options={bandData}
                  error={!!errors.band}
                  helperText={errors.band === "band" ? "Band " : ""}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Qualification "
                  label="Qualification "
                  name="qualNeeded"
                  value={formData.qualNeeded}
                  onChange={handleChange}
                  options={QualificationData}
                  error={!!errors.qualNeeded}
                  helperText={errors.qualNeeded === "qualNeeded" ? "Qualification" : ""}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Min Experience"
                  label="Min Experience"
                  name="minExperience"
                  value={formData.minExperience}
                  onChange={handleChange}
                  options={[
                    { value: "1 year", label: "1 year" },
                    { value: "2 years", label: "2 years" },
                  ]}
                  error={!!errors.minExperience}
                  helperText={errors.minExperience === "minExperience" ? "Min Experience" : ""}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Max Experience"
                  label="Max Experience"
                  name="maxExperience"
                  value={formData.maxExperience}
                  onChange={handleChange}
                  options={[
                    { value: "5 years", label: "5 years" },
                    { value: "10 years", label: "10 years" },
                  ]}
                  error={!!errors.maxExperience}
                  helperText={errors.maxExperience === "maxExperience" ? "Max Experience" : ""}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Autocomplete
                  size="small"
                  multiple
                  limitTags={1}
                  options={primarySkillsData}
                  getOptionLabel={(option: SkillOption) => option.label}
                  onChange={(event, newValue) => {
                    const pData = newValue.map(i=>i.value)
                    setPSkills(pData)
                    const sSkills = secondarySkillsData.filter((i:any)=>!pData.includes(i.value))
                    
                    if(newValue.length>=1){
                      setSecondarySkillsData(sSkills)
                    }else{
                      setSecondarySkillsData(primarySkillsData)
                    }
                    setFormData((prevData) => ({
                      ...prevData,
                      primarySkills: pData,
                    }));
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      primarySkills: "",
                    }));
                  }}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Primary Skills"
                      error={!!errors.primarySkills}
                      helperText={errors.primarySkills === "primarySkills" ? "Primary skills field is requierd" : ""}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          fontSize: '0.8rem',
                          paddingLeft:'2px',
                           paddingRight:'40px',
                           paddingBottom: "9px",
                           margin:'0px',
                         
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: '0.8rem',
                          fontWeight: 410, color: "gray",
                        },
                      }}
                       />
                  )}
                  disableCloseOnSelect
                  renderOption={(props, option: SkillOption, { selected }) => (
                    <li {...props}
                      style={{
                        fontSize: '0.8rem',
                        padding: '1px 1px',
                        margin: '2px 0',
                      }}
                    >
                      <Checkbox
                        sx={{
                          transform: 'scale(0.8)',
                          marginRight: 2,
                          padding: 0,
                        }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Autocomplete
                 size="small"
                 multiple
                 limitTags={1}
                 options={secondarySkillsData}
                 getOptionLabel={(option: SkillOption) => option.label}
                 onChange={(event, newValue) => {
                   const sData = newValue.map(i=>i.value)
                   setSSkills(sData);
                   
                   setErrors((prevErrors) => ({
                     ...prevErrors,
                     secondarySkills: "",
                   }));
                   setFormData((prevData) => ({
                     ...prevData,
                     secondarySkills: sData
                   }));
                 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Secondary Skills"
                      error={!!errors.secondarySkills}
                      helperText={errors.secondarySkills === "secondarySkills" ? "Secondary skills field is requierd" : ""}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          fontSize: '0.8rem',
                          paddingLeft:'2px',
                           paddingRight:'40px',
                           paddingBottom: "9px",
                           margin:'0px',
                         
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: '0.8rem',
                          fontWeight: 410, color: "gray",
                        },
                      }}

                    />
                  )}
                  disableCloseOnSelect
                  renderOption={(props, option: SkillOption, { selected }) => (
                    <li {...props}
                      style={{
                        fontSize: '0.8rem',
                        padding: '1px 1px',
                        margin: '2px 0',
                      }}
                    >
                      <Checkbox
                        sx={{
                          transform: 'scale(0.8)',
                          marginRight: 2,
                          padding: 0,
                        }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonTextField
                  label="Preferred Start Date"
                  type="date"
                  name="prefStartDate"
                  value={formData.prefStartDate}
                  onChange={handleChange}
                  error={!!errors.prefStartDate}
                  helperText={errors.prefStartDate === "prefStartDate" ? "Preferred Start Date" : ""}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="work-mode-label"
                  label="Work Mode"
                  name="workMode"
                  value={formData.workMode}
                  onChange={handleChange}
                  options={workModeOptions}
                  error={!!errors.workMode}
                  helperText={errors.workMode === "workMode" ? "Work Mode" : ""}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="priority-label"
                  label="Priority"
                  name="priority"
                  value={formData.priority}
                  onChange={handleChange}
                  options={[
                    { value: "High", label: "High" },
                    { value: "Medium", label: "Medium" },
                    { value: "Low", label: "Low" },
                  ]}
                  error={!!errors.priority}
                  helperText={errors.priority === "priority" ? "Priority" : ""}
                />
              </Grid>
              <Grid item xs={12} md={4}>
              <CommonTextField
                  label="Comments"
                  name="comments"
                  value={formData.comments.substring(0, 500)}
                  onChange={handleChange}
                  error={!!errors.comments||
                    (formData.comments?.length || 0) > 500}
                  helperText={
                    (formData.comments?.length || 0) > 500
                      ? "comments cannot exceed 500 characters"
                      : errors.comments === "comments"
                      ? "Comments field is requierd"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="l1-approval-label"
                  label="L1 Approval"
                  name="l1ApprovalId"
                  value={formData.l1ApprovalId}
                  onChange={handleChange}
                  options={L1ApproverData}
                  error={!!errors.l1ApprovalId}
                  helperText={errors.l1ApprovalId === "l1ApprovalId" ? "L1 Approval " : ""}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="l2-approval-label"
                  label="L2 Approval"
                  name="l2ApprovalId"
                  value={formData.l2ApprovalId}
                  onChange={handleChange}
                  options={L2ApproverData}
                  error={!!errors.l2ApprovalId}
                  helperText={errors.l2ApprovalId === "l2ApprovalId" ? "L2 Approval" : ""}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <input
                  accept=".pdf,.doc,.docx"
                  id="file-input2"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange2}
                 
                />
                <label htmlFor="file-input2">
                  <Button
                    variant="outlined"
                    component="span"
                    sx={{
                      width: "100%",
                      fontSize: "0.75rem",
                      padding: "2px 4px",
                      minHeight: "40px",
                      border: "1px solid" ,
                      borderColor: "darkgray",
                      borderRadius: "4px",
                      marginTop: "1px",
                      backgroundColor: "transparent",
                      
                    }}
                    
                  // sx={{ textTransform: "none" }}
                  >
                    {(!formData.jdAttachmentFileUrl&&!chooseFile2 )? "JD Upload" : (formData.jdAttachmentFileUrl||"JD Uploaded")}
                  </Button>
                </label>
              
              </Grid>
              <Grid item xs={12} md={2}>
                <input
                  accept=".pdf,.doc,.docx"
                  id="file-input1"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange1}
                 
                />
                <label htmlFor="file-input1">
                  <Button
                    variant="outlined"
                    component="span"
                    sx={{
                      width: "100%",
                      fontSize: "0.75rem",
                      padding: "2px 4px",
                      minHeight: "40px",
                      border: "1px solid" ,
                      borderColor: "darkgray",
                      borderRadius: "4px",
                      marginTop: "1px",
                      backgroundColor: "transparent",
                      
                    }}
                    
                  // sx={{ textTransform: "none" }}
                  >
                    {(!formData.otherFileUrl&&!chooseFile1 )? "File Upload" : (formData.otherFileUrl||"File Uploaded")}
                  </Button>
                </label>
              
              </Grid>
            </Grid>
          </Box>
      </Box>
      <Box
        sx={{
          p: 3,
          backgroundColor: "white",
          marginTop: "10px",          
        }}
      >
        <Typography color="gray" sx={{ fontSize: '12px' }}>ExternalDetails</Typography>
        {/* Main content goes here */}
        <Box component="form" sx={{ mt: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={2}>
            <CommonTextField
                  label="Client Name"
                  name="clientName"
                  value={formData.clientName?.replace(/[^a-zA-Z\s]/g, '').substring(0, 100)}
                  onChange={handleChange}
                  error={!!errors.clientName||
                    (formData.clientName?.length || 0) > 100}
                  helperText={(formData.clientName?.length || 0) > 100
                    ? "Client Name cannot exceed 100 characters"
                    : errors.clientName === "clientName" ? "Client Name field is requierd" : ""}
                />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                 labelId="Client POC"
                 label="Client POC"
                 name="clientpoc"
                 value={formData.clientpoc}
                 onChange={handleChange}
                 options={[
                  { value: "Jannai", label: "Jannai" },
                  { value: "Amarnath", label: "Amarnath" },
                  { value: "Senthil", label: "Senthil" },
                  { value: "Sudarsanam", label: "Sudarsanam" },
                ]}
                 error={!!errors.clientpoc}
                 helperText={errors.clientpoc === "clientpoc" ? "Client POC" : ""}
                 />
            </Grid>
            <Grid item xs={12} md={2}>
              <input
                accept=".pdf,.doc,.docx"
                id="file-input"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <label htmlFor="file-input">
                <Button
                  variant="outlined"
                  component="span"
                  sx={{
                    width: "100%",
                    fontSize: "0.75rem",
                    padding: "2px 4px",
                    minHeight: "40px",
                    border: "1px solid",
                    borderColor: "darkgray",
                    borderRadius: "4px",
                    marginTop: "1px",
                    backgroundColor: "transparent",
                  }}
                  // sx={{ textTransform: "none" }}
                >
                  {(!formData.approvalAttachment&&!chooseFile )? "File Upload" : (formData.approvalAttachment||"File Uploaded")}
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                labelId="Client Rate Card"
                label="Client Rate Card"
                name="clientRateCard"
                value={formData.clientRateCard}
                onChange={handleChange}
                options={[
                  { value: "100000", label: "100000" },
                  { value: "500000", label: "500000" },
                  { value: "700000", label: "700000" },
                  { value: "900000", label: "900000" },
                  { value: "1200000", label: "1200000" },
                ]}
                error={!!errors.clientRateCard}
                helperText={errors.clientRateCard === "clientRateCard" ? "Client Rate Card" : ""}
                />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonTextField
                 label="One Time Charge%"
                 name="OneTimeCharge"
                 value={`${formData.OneTimeCharge?.replace(/[^0-9]/g, '')}%`}
                 onChange={(event) => {
                  const sanitizedValue = event.target.value?.replace(/[^0-9]/g, '');
                  setFormData((prevData) => ({
                    ...prevData,
                    OneTimeCharge:sanitizedValue,
                  }));
                  if (errors.clientMarginInPer) {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      OneTimeCharge: '',
                    }));
                  }
                }}
                 error={!!errors.OneTimeCharge}
                 helperText={errors.OneTimeCharge  === "OneTimeCharge" ? "One Time Charge% field is requierd" : ""}
                
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <CommonTextField
                label="One Time Amount"
                name="OneTimeAmount"
                value={formData.OneTimeAmount?.replace(/[^0-9]/g, '')}
                onChange={handleChange}
                error={!!errors.OneTimeAmount}
                helperText={errors.OneTimeAmount  === "OneTimeAmount" ? "One Time Amount field is requierd" : ""}
                
              />
            </Grid>
            
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          sx={{
            mr: 2,
            color: "black",
            backgroundColor: "transparent",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "transparent", // Ensure hover doesn't add background color
              boxShadow: "none",
            },
          }}
          onClick={() => {
            setFormData({
              userId: userId || "",
              businessId: "",
              positionTitle: "",
              numOfPosition: "",
              internalSpoc: "",
              location: "",
              departmentId: "",
              band: "",
              qualNeeded: "",
              minExperience: "",
              maxExperience: "",
              primarySkills: [],  
              secondarySkills: [],
              prefStartDate:"",
              workMode:"",
              priority: "",
              comments: "",
              clientName: "",
              clientpoc: "",
              clientRateCard: "",
              OneTimeAmount: "",
              OneTimeCharge: "",
              approvalAttachment: "",
              typeOfRth:"External",
              modeOfRth:"OneTime",
              l1ApprovalId: "",
              l2ApprovalId: "",
              jdAttachmentFileUrl: "",
              otherFileUrl: "",
            });
            setErrors({});
            setSubmitted(false);
            navigate(-1)
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" sx={{ color: "#F0F5FF" }} type="submit">
          Submit
        </Button>
      </Box>
      {submitted && Object.keys(errors).length > 0 && (
        <Alert severity="error" sx={{ mt: 2 }}>
          Please correct the errors in the form before submitting.
        </Alert>
      )}
      </Box>
    </>
  );
};

export default ExternalDetails;
