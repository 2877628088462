import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { BaseUrl } from '../../constants/Crudurl';
import { DepartmentData } from '../../components/admin/adminTabs/Department/DepartmentEdit';

interface UserState {
    users: {
        data: DepartmentData[];
    };
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: UserState = {
    users: { data: [] },
    status: 'idle',
    error: null,
};

export const fetchDepartment = createAsyncThunk('department/fetchdepartment', async (search?:string) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${BaseUrl}/master/getDepartmentList${search?`?search=${search}`:''}`, {
        headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
});

export const editDepartment = createAsyncThunk<DepartmentData, Partial<DepartmentData>>('department/updateDepartment',
    async (department) => {
        const token = localStorage.getItem("token");
        const { l1Approver, l2Approver, departmentId } = department;
        const params = { l1ApproverId: l1Approver?.userId, l2ApproverId: l2Approver?.userId };
        const response = await axios.put(
            `${BaseUrl}/master/addApproversForDepartment/${departmentId}`,
            null,
            { headers: { Authorization: `Bearer ${token}`, }, params, }
        );
        return response.data;
    }
);

const departmentSlice = createSlice({
    name: 'department',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDepartment.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchDepartment.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.users = action.payload;
            })
            .addCase(fetchDepartment.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || null;
            })
            .addCase(editDepartment.fulfilled, (state, action: PayloadAction<DepartmentData>) => {
                const index = state.users.data.findIndex(user => user.departmentId === action.payload.departmentId);
                if (index !== -1) {
                    state.users.data[index] = action.payload;
                } else {
                    state.users.data.push(action.payload);
                }
            });
    },
});

export default departmentSlice.reducer;