import { Box, Typography, Grid, CircularProgress } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import DropDownIcon from '../../../../../assets/images/dropdown.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BaseUrl } from '../../../../../constants/Crudurl';

const chartColors: { [key: string]: string } = {
  L1Pending: '#00A76F',
  L2Pending: '#FFAB00',
  HR: '#00B8D9',
  Screening: '#05EEFF',
  Others: '#FF5630'
};

function PieChartBoard1() {
  const data = useSelector((state: RootState) => state.profile.data);
  const userID = data?.data?.userId;
  const [statusReport, setStatusReport] = useState<{ label: string; value: number; }[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStatusReport = async () => {
        try {
          setLoading(true);
            const response = await axios.get(
                `${BaseUrl}/dashboard/candIntStatByR/${userID}`
            );
            const responseData = response?.data?.data;
            const updatedStatusReport = Object.entries(responseData).map(([label, value]) => ({
                label,
                value: Number(value),
            }));
            setStatusReport(updatedStatusReport);
            setTotal(updatedStatusReport.reduce((acc, item) => acc + item.value, 0));
        } catch (error) {
          setLoading(false);
            console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
    };

    if (userID) {
        fetchStatusReport();
    }
}, [userID]);

  return (
    <Grid container sx={{ borderRadius: '8px', height: '200px', backgroundColor: '#FFFFFF', padding: '8px', position: 'relative' }}>
      <Grid item xs={12} container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '30px', marginTop: 0 }}>
        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: '500', fontSize: '12px' }}>
            Candidate Interview Status
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '12px' }}>
            MRF
          </Typography>
          <img src={DropDownIcon} alt="dropdownIcon" style={{ height: '12px', width: '12px', marginLeft: '4px' }} />
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '12px' }}>
            Category
          </Typography>
          <img src={DropDownIcon} alt="dropdownIcon" style={{ height: '12px', width: '12px', marginLeft: '4px' }} />
        </Grid>
      </Grid>
      {loading ? (
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10 }}>
            <CircularProgress size={50} />
          </Box>
        ) : (
      <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
        <Box sx={{ position: 'relative', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', mt: -3.5 }}>
          <PieChart
            height={200}
            width={240}
            slotProps={{
              legend: { hidden: true },
            }}
            series={[
              {
                data: statusReport.map((item) => ({
                  ...item,
                  color: chartColors[item.label],
                })),
                innerRadius: 50,
                outerRadius: '50%',
                arcLabel: () => '',
                arcLabelMinAngle: 20,
                startAngle: -340,
                endAngle: 160,
              },
            ]}
          />
              {!loading && (
                <Grid item sx={{ position: 'absolute', top: '50%', left: '30%', transform: 'translate(-50%, -50%)', textAlign: 'center', pointerEvents: 'none' }}>
                  <Typography component="span" sx={{ fontSize: '16px', fontWeight: 'bold', color: '#000' }}>
                    {total}
                  </Typography>
                </Grid>
              )}
        </Box>
      </Grid>
        )}
      <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mt: -2 }}>
        <Box>
          {statusReport.map((item, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
              <Box sx={{ width: 9, height: 9, borderRadius: '1px', backgroundColor: chartColors[item.label], marginRight: '8px' }}></Box>
              <Typography sx={{ color: '#6B7280', fontSize: '10px', flex: 1, padding: '2px' }}>{item.label}</Typography>
              <Typography sx={{ color: '#6B7280', fontSize: '10px', fontWeight: 'bold' }}>
                {item.value}
              </Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
}

export default PieChartBoard1;
