import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import BaseJobCard from '../BaseJobCard';
import { Job } from '../../commoncard/types';

interface ClosedJobCardProps {
  job?: Job;
}

const InprogressCard: React.FC<ClosedJobCardProps> = ({ job }) => {
  return (
    <BaseJobCard job={job} label="In Progress">
      <Box>
        <Box sx={{ display: "flex", marginTop: "10px", paddingLeft: "2px" }}>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Avatar src={job?.posReportsTo?.profilePicture} alt="jobcardimagea" sx={{ width: 24, height: 24 }} />
            <Box display={'flex'} flexDirection={'column'} sx={{paddingLeft:"10px"}}>
              <Typography sx={{ fontSize: '12px' }}>{job?.posReportsTo?.userName}</Typography>
              <Typography sx={{ fontSize: '10px' }}>{job?.posReportsTo?.designation}</Typography>
            </Box>
          </Box>
          {/* <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1, marginTop: "2px" }}>
            <Typography variant='body2' sx={{ fontSize: '10px', lineHeight: 1.2 }}>{job?.name}</Typography>
            <Typography variant='caption' sx={{ fontSize: '8px', lineHeight: 1.2, color: 'gray' }}>{job?.reqName}</Typography>
          </Box> */}
        </Box>
      </Box>
    </BaseJobCard>
  );
};

export default InprogressCard;