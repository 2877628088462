import React from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { setL1Status, setL2Status } from '../../../../redux/features/rthSlice';
// import { fetchJobs } from '../../../../redux/features/jobcardSlice';
import BaseJobCard from '../BaseJobCard';
import ApprovalActions from '../jobApprovel';
import { Job } from '../../commoncard/types';
import { toast } from "react-toastify";
import { getOverAllRthAPIData } from '../../../../Crud/card';
import { overallRthUpdate } from '../../../../redux/features/overallRthSlice';
import { useSelector } from 'react-redux';
import JobProgress from '../../commoncard/jobProgress';

interface RTHJobCardProps {
  job?: Job;
  userId?: string;
  teamHeadFilterDto?: any;
  teamHeadFilter?:any;
}

const RTHJobCard: React.FC<RTHJobCardProps> = ({ job, userId, teamHeadFilterDto,teamHeadFilter }) => {
  const dispatch = useDispatch<AppDispatch>();
  const approvedStatus = useSelector((state:RootState)=>state.overallRth.approved)
const userIds = localStorage?.getItem("userId")??"";

  const handleApproveL1 = async (jobId?: string) => {
    await setL1Status({ rthId: jobId, status: "Approved" })
    .then(() => toast.success("Successfully Approved"))
        .catch(() => toast.error("Failed Approved"));
    await getOverAllRthAPIData(userIds,teamHeadFilter);
    await dispatch(overallRthUpdate(true))
    await dispatch(overallRthUpdate(!approvedStatus))
  };

  const handleApproveL2 = async (jobId?: string) => {
    await setL2Status({ rthId: jobId, status: "Approved" })
    .then(() => toast.success("Successfully Approved"))
        .catch(() => toast.error("Failed Approved"));
    await getOverAllRthAPIData(userIds,teamHeadFilter);
    await dispatch(overallRthUpdate(true))
    await dispatch(overallRthUpdate(!approvedStatus))

  };

  const handleDeclineL1 = async (jobId?: string) => {
    await setL1Status({ rthId: jobId, status: "Declined" });
    await getOverAllRthAPIData(userIds,teamHeadFilter);
  };

  const handleDeclineL2 = async (jobId?: string) => {
    await setL2Status({ rthId: jobId, status: "Declined" });
    await getOverAllRthAPIData(userIds,teamHeadFilter);
  };
// console.warn("jobId=======?",job?.status ==="Declined")
  return (
    <BaseJobCard job={job} label="overall RTH">
      <Box sx={{ width: "100%" }}>
        {job?.l1Status === "Approved" && job?.l2Status === "Approved" ? (
          <Box p={1}>
            <Typography variant="h6" fontSize={"100%"} sx={{ color: 'green' }}>Accepted</Typography>
          </Box>
        )
         :job?.l1Approval?.userId === userIds && job?.l1Status === "Approved" && job?.l2Status === "Pending" ? (
          
            <JobProgress L1Status={job?.l1Status} L2Status={job?.l2Status} />
          )
        :  job?.status !=="Declined" &&(
          <ApprovalActions
          data={job}
            onApprove={(event: any) => {
              event.stopPropagation();
              if (job?.l1Status === "Pending") {
                handleApproveL1(job?.rthId);
              }
              if (job?.l1Status === "Approved") {
                handleApproveL2(job?.rthId);
              }
            }}
            onDecline={(event: any) => {
              event.stopPropagation();
              if (job?.l1Status === "Pending") {
                handleDeclineL1(job?.rthId);
              }
              if (job?.l1Status === "Approved") {
                handleDeclineL2(job?.rthId);
              }
            }}
          />
        )}
        {
          job?.status ==="Declined" &&
         <Box p={1}>
            <Typography variant="h6" fontSize={"100%"} sx={{ color: 'red' }}>Declined</Typography>
          </Box>
        }

      </Box>
    </BaseJobCard>
  );
};

export default RTHJobCard;