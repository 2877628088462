import React from 'react';
import { Box, Grid, Typography, Avatar } from '@mui/material';
import priorityHighSymbol  from '../../../assets/images/priorityHighSymbol.svg'
import priorityMediumSymbol  from '../../../assets/images/priorityMediumSymbol.svg'
import priorityLowSymbol  from '../../../assets/images/priorityLowSymbol.svg'

// interface jobData {
//   business?:{
//     businessId:string,
//     businessName:string
//   };
//   positionTitle?: string;
//   numOfPosition?: string;
//   department?: {
//     departmentId: string,
//     departmentName: string
//   }
//   location?: string;
//   qualNeeded?: {
//     qualificationId: string,
//     degree: string
//   };
//   workMode?: string;
//   maxExperience?: string;
//   band?: {
//     bandId: string;
//     bandName: string;
//   };
//   ctc?: string;
//   priority?: string;
//   reportsTo?: any;
//   l1Approval?: {
//     id:string
//     firstName:string
//     profilePicture:string
//     role:string
//   }
//   l2Approval?:{
//     id:string
//     firstName:string
//     profilePicture:string
//     role:string
//   };
//   raisedBy?: string;
//   position?: string;
//   posReportsTo?:{
//     id:string
//     firstName:string
//     profilePicture:any
//     role:string
//   } ; 
//   userId?:{
//     id:string
//     firstName:string
//     profilePicture:any
//     role:string
//   } 
//   typeOfRth?:string;  
//   modeOfRth?:string
// }

// interface InternalOnRoleDetailsProps {
//   jobData?: jobData;
// }

const InternalOnRoleDetails= ({ jobData }:any) => {
  
  return (
    <Box sx={{ p: 3, backgroundColor: 'background.default', borderRadius: 2, ml: "16px", mr:"16px"}}>
      <Typography variant="h6" sx={{ mb: 2 }}>{jobData?.typeOfRth} - {jobData?.modeOfRth} Details</Typography>
      <Grid container spacing={2}>
        {/* Existing fields */}
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Business</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{jobData?.business?.businessName}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Position Title</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{jobData?.positionTitle}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Number Of Positions</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{jobData?.numOfPosition}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Department</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{jobData?.department?.departmentName}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Location</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{jobData?.location}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Qualification Needed</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{jobData?.qualNeeded}</Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt='10px'>
        {/* Additional fields */}
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Work Mode</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{jobData?.workMode}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Experience</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{jobData?.maxExperience}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Band</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{jobData?.band}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>CTC</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{jobData?.ctc}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Priority</Typography>
                                      <Box
                            sx={{
                              display: "flex",
                              alignItems:"center"
                            }}
                            gap={1}
                          >
                            {jobData?.priority === "High" ? (
                              <Box>
                                <img src={priorityHighSymbol} alt="" />
                              </Box>
                            ) : jobData?.priority === "Medium" ? (
                              <Box>
                                <img src={priorityMediumSymbol} alt="" />
                              </Box>
                            ) : (
                              <Box>
                                <img src={priorityLowSymbol} alt="" />
                              </Box>
                            )}
                            <Typography
                              sx={{
                                color:
                                  jobData?.priority === "High"
                                    ? "#F04E4E"
                                    : jobData?.priority === "Medium"
                                    ? "#FFA940"
                                    : "#73D13D",
                                fontSize: "100%",
                              }}
                            >
                              {jobData?.priority}
                            </Typography>
                          </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt='10px'>
        {/* New fields for Position Reports to, L1 Approval, L2 Approval, and Who Raised */}
        
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Position Reports To</Typography>
            <Box display="flex" alignItems="center" flexDirection={'row'}>
              <Box>
              <Avatar alt={jobData?.posReportsTo?.imageUrl} src={jobData?.posReportsTo?.imageUrl??"https://mui.com/static/images/avatar/1.jpg"} />
              </Box>
              <Box>
              <Typography variant="h6" sx={{ fontSize: "13px", ml: 1, mt: 1 }}>{jobData?.posReportsTo?.userName}</Typography>
              <Typography variant="h6" sx={{ fontSize: "11px", ml: 1, color: "gray" }}>{jobData?.posReportsTo?.designation}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>L1 Approval</Typography>
            <Box display="flex" alignItems="center" flexDirection={'row'}>
              <Box>
              <Avatar alt={jobData?.l1Approval?.imageUrl} src={jobData?.l1Approval?.imageUrl??"https://mui.com/static/images/avatar/2.jpg"} />
              </Box>
              <Box>
              <Typography variant="h6" sx={{ fontSize: "13px", ml: 1, mt: 1 }}>{jobData?.l1Approval?.userName}</Typography>
              <Typography variant="h6" sx={{ fontSize: "11px", ml: 1, color: "gray" }}>{jobData?.l1Approval?.designation}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>L2 Approval</Typography>
            <Box display="flex" alignItems="center" flexDirection={'row'}>
              <Box>
              <Avatar alt={jobData?.l2Approval?.imageUrl} src={jobData?.l2Approval?.imageUrl??"https://mui.com/static/images/avatar/3.jpg"} />
              </Box>
              <Box>
              <Typography variant="h6" sx={{ fontSize: "13px", ml: 1, mt: 1 }}>{jobData?.l2Approval?.userName}</Typography>
              <Typography variant="h6" sx={{ fontSize: "11px", ml: 1, color: "gray" }}>{jobData?.l2Approval?.designation}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Created by</Typography>
            <Box display="flex" alignItems="center" flexDirection={'row'}>
              <Box>
              <Avatar alt={jobData?.user?.imageUrl} src={jobData?.user?.imageUrl??"https://mui.com/static/images/avatar/4.jpg"} />
              </Box>
              <Box>
              <Typography variant="h6" sx={{ fontSize: "13px", ml: 1, mt: 1 }}>{jobData?.user?.userName}</Typography>
              <Typography variant="h6" sx={{ fontSize: "11px", ml: 1, color: "gray" }}>{jobData?.user?.designation}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InternalOnRoleDetails;
