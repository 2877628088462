import React from 'react';
import { Box, Typography } from '@mui/material';
// import priorityHighSymbol from '../../assets/images/priorityHighSymbol.svg'
// import priorityLowSymbol from '../../assets/images/priorityLowSymbol.svg'
// import priorityMediumSymbol from '../../assets/images/priorityMediumSymbol.svg'

interface JobHeaderProps {
  department?: string;
  business?: {
    businessId: string,
    businessName: string
  };
  priority?: string
  seqId?: string
}

const JobHeader: React.FC<JobHeaderProps> = ({ department, business, priority, seqId }) => {

  return (
    <Box sx={{ display: "flex", justifyContent: 'space-between', pt: '5px' }} gap={1}>
      <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>
        <abbr title={department} style={{ textDecoration: 'none' }}>
          {department ? (department.length > 40 ? `${department.slice(0, 40)}...` : department) : "N/A"}
        </abbr>
      </Typography>
      <Typography sx={{ fontSize: '8px', color: '#08979C', padding: "2px", backgroundColor: `${business?.businessName === "HEPL" ? "#D3ADF7" : '#B5F5EC'}`, borderRadius: '1px', border: '1px solid #87E8DE' }}>{business?.businessName}</Typography>
    </Box>
  );
}

export default JobHeader;
