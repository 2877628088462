import { Box, Button, Paper, Tab, Tabs } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { PersonalInformation } from './EmployeeInformationPages/PersonalInformation';
import AdditionalInformation from './AdditionalInformation';
import { Education } from './EmployeeInformationPages/Education';
import { Experience } from './EmployeeInformationPages/Experience';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { editCandidate, getCandidateByCandidateId } from '../../../../redux/features/resume';
import ResumeReUpload from './ResumeReUpload';
import Address from './AddressForm';
import { toast } from 'react-toastify';

export const EmployeInformation = () => {
  const [value, setValue] = React.useState(0);
  const [formValues,setFormValues]=useState<any>([]);
  const {candidateId}=useParams();
  const dispatch=useAppDispatch();
  const state=useAppSelector((state)=>state.resume.candidate);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  
  const sectionRefs = {
    resume: useRef<HTMLDivElement>(null),
    personalInfo: useRef<HTMLDivElement>(null),
    additionalInfo: useRef<HTMLDivElement>(null),
    address: useRef<HTMLDivElement>(null),
    education: useRef<HTMLDivElement>(null),
    experience: useRef<HTMLDivElement>(null),
  };

  // Handle tab change
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    scrollToSection(newValue);
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formValues.howDidYouHeardAbtUs) {
      newErrors.howDidYouHeardAbtUs = 'This is required';
    }
    if (!formValues.firstName) {
      newErrors.firstName = 'First Name is required';
    }
    if (!formValues.lastName) {
      newErrors.lastName = 'Last Name is required';
    }
    // if(!formValues.middleName){
    //   newErrors.middleName='Middle Name is required'
    // }
    if(!formValues.expectedCtc){
      newErrors.expectedCtc='Expected CTC is required'
    }
    if(!formValues.noticePeriods){
      newErrors.noticePeriods='Required field'
    }
    if(!formValues.currentCtc){
      newErrors.currentCtc='Current CTC is required'
    }
    if (!formValues.emailAddress) {
      newErrors.emailAddress = 'Email Address is required';
    } else if (!/\S+@\S+\.\S+/.test(formValues.emailAddress)) {
      newErrors.emailAddress = 'Email Address is invalid';
    }
    if (!formValues.phoneNumber) {
      newErrors.phoneNumber = 'Phone Number is required';
    } else if (!/^\d{10}$/.test(formValues.phoneNumber)) {
      newErrors.phoneNumber = 'Phone Number must be 10 digits';
    }
    // if (!formValues.dateOfBirth) {
    //   newErrors.dateOfBirth = 'Date of Birth is required';
    // }
 
    setErrors(newErrors);
 
    return Object.keys(newErrors).length === 0;
  };
      
  const scrollToSection = (sectionIndex: number) => {
    const sectionKeys = Object.keys(sectionRefs) as (keyof typeof sectionRefs)[];
    const sectionKey = sectionKeys[sectionIndex];
    sectionRefs[sectionKey]?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSave=async ()=>{
    const isValid=validateForm();
    if(isValid){
    const payload={
      ...formValues,
      workExperience: 
      typeof formValues.workExperience === "string" 
        ? formValues.workExperience.toLowerCase() === "yes"
        : !!formValues.workExperience,
      status:"Completed",
    }
    try {
      const response = await dispatch(editCandidate({candidateId:candidateId,data:payload })); // Dispatch the thunk
      if(editCandidate.fulfilled.match(response)){
        const {status}=response.payload||{};
        if(status===true){
        dispatch(getCandidateByCandidateId(candidateId));
        toast.success("Candidate Updated Successfully!");
        }
        else if(status===400){
          toast.error('Data is not Proper');
        }
      }
      else{
        toast.error('Failed to Submit the Details');
      }
      
  } catch (error) {
      console.error("Error updating candidate:", error); 
  }
    }
  }
  
  useEffect(()=>{
    dispatch(getCandidateByCandidateId(candidateId));
  },[candidateId,dispatch])
  
  useEffect(() => {
    if (state) {
      setFormValues(state);
    }
  }, [state]);  
  
  return (
    <Box sx={{ mt: '20px', ml: '180px', height:'70vh'}}>
      <Paper sx={{ height: '100%', width: '74vw', boxShadow: 'none', borderRadius:'10px' }}>
        <Box sx={{ position:'sticky', top:0, backgroundColor:'white', zIndex:1, borderRadius:'10px'}}>
        <Tabs value={value} onChange={handleTabChange}  TabIndicatorProps={{
          style: {        
            width: '20px',
            marginLeft: `${value === 0 ? '35px' : value ===1 ? '66px': value === 2 ? '66px': value === 3 ? '32px': value === 4 ?'34px':'36px'}`, // Adjusting indicator based on selected tab            
          },
        }}>
          <Tab label="Resume" sx={{ textTransform: 'none' }}/>
          <Tab label="Personal Information"sx={{ textTransform: 'none' }} />
          <Tab label="Additional Information"sx={{ textTransform: 'none' }} />
          <Tab label="Address"sx={{ textTransform: 'none' }} />
          <Tab label="Education"sx={{ textTransform: 'none' }} />
          <Tab label="Experience" sx={{ textTransform: 'none' }}/>
        </Tabs>
        </Box>   

        <Box sx={{ height: '58vh', overflowY: 'auto', marginTop: 5, m:3}}>
          <Box ref={sectionRefs.resume} sx={{ height: 'auto', padding: 2 }}>
            <ResumeReUpload formValues={formValues} setFormValues={setFormValues}/>
          </Box>

          <Box ref={sectionRefs.personalInfo} sx={{ height: 'auto', padding: 2 }}>
            <PersonalInformation formValues={formValues} setFormValues={setFormValues} errors={errors}/>
          </Box>

          <Box ref={sectionRefs.additionalInfo} sx={{ height: 'auto', padding: 2 }}>
            <AdditionalInformation formValues={formValues} setFormValues={setFormValues}/>
          </Box>

          <Box ref={sectionRefs.address} sx={{ height: 'auto', padding: 2,mt:0 }}>
            <Address formValues={formValues} setFormValues={setFormValues}/>
          </Box>

          <Box ref={sectionRefs.education} sx={{ height: 'auto', padding: 2}}>
           <Education formValues={formValues} setFormValues={setFormValues}/>
          </Box>

          <Box ref={sectionRefs.experience} sx={{ height: 'auto', padding: 2, overflowY:'auto' ,mb:4}}>
            <Experience formValues={formValues} setFormValues={setFormValues}/>
          </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', boxShadow: 'none', gap:2, pb:5, pr:3}}>
         <Button variant="outlined" color="primary" sx={{textTransform:'none',borderRadius:'6px' }}>
            Cancel
          </Button>
          <Button variant="contained" color="primary"sx={{textTransform:'none',borderRadius:'6px'}} onClick={handleSave} >
            Save Changes
          </Button>
        </Box>
        </Box>
      </Paper>
    </Box>
  );
};
