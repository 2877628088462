import React, { useEffect, useState } from "react";
import { Box, Grid, InputAdornment, InputBase, CircularProgress } from "@mui/material";
import NoDataAvail from "../../../../assets/images/NoDataAvail.svg";
import { getToBeAssignedAPIData } from "../../../../Crud/card";
import ToBeAssignedCard from "./TobeAssignedCard";
import { useAppSelector } from "../../../../redux/hooks";
import SearchIcon from "@mui/icons-material/Search";

interface MyRequestsCardProps {
  drawerOpen: boolean;
}

const ToBeAssigned: React.FC<MyRequestsCardProps> = ({ drawerOpen }) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const userId = localStorage.getItem("userId") ?? "";
  const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const role = localStorage.getItem("user")?JSON.parse(localStorage.user)?.roleName:"";

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (searchQuery.trim()) {
      setSearchLoading(true);
    } else {
      setSearchLoading(false);
    }

    const timeoutId = setTimeout(() => {
      setLoading(true);
      getToBeAssignedAPIData(userId, teamHeadFilter, searchQuery)
        .then((res) => {          
          if(role === "TALENTACQUISITIONHEAD")
          {            
            const filteredData=res?.data?.listOfRth?.filter((item:any)=>
              item.recruiterAssigned === false && item.recruiterLeadAssigned === false            
            ) || [];
            setData(filteredData);            
          }
          else if(role === "RECRUITERLEAD")
          {
            const filteredData=res?.data?.listOfRth?.filter((item:any)=>
              item.recruiterAssigned === false && item.recruiterLeadAssigned === true
            ) || [];
            console.log("filteredData",filteredData);
            setData(filteredData);
          }
          // setData(res?.data?.listOfRth || []);
        })
        .catch((error) => {
          setData([]);
          console.error("Error fetching ToBeAssigned data:", error);
        })
        .finally(() => {
          setLoading(false);
          setSearchLoading(false);
        });
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [userId, searchQuery, teamHeadFilter,role]);

  // if (loading) {
  //   return <Box>Loading...</Box>;
  // }

  return (
    <>
      <Grid
        item
        sx={{ marginTop: data.length > 0 ? "-40px" : "-58px" }}
        position="absolute"
      >
        <InputBase
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search RTH..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
            </InputAdornment>
          }
          sx={{
            border: "1px solid #ccc",
            borderRadius: 1,
            padding: 0,
            backgroundColor: "white",
            minHeight: "3px",
            fontSize: 14,
            ".MuiInputBase-input": {
              padding: "5px 5px",
            },
          }}
        />
        {searchLoading && (
          <CircularProgress
            size={20}
            sx={{
              position: 'absolute',
              right: '10px',
              top: '25%',
              transform: 'translateY(-50%)',
              color: '#2F54EB',
            }}
          />
        )}
      </Grid>
      <Box>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '300px',
            }}
          >
            <CircularProgress size={100} sx={{ color: '#2F54EB' }} />
          </Box>
        ) :

          data.length > 0 ? (
            <Grid
              container
              spacing={0}
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}

            >
              {data.map((job: any, index: number) => (
                <Grid
                  item
                  key={index}
                  xs={12} // Full-width on extra-small screens
                  sm={6} // Two cards per row on small screens
                  md={2} // Three cards per row on medium screens
                  lg={drawerOpen ? 3 : 2.4} // Conditional layout for large screens
                  sx={{
                    minWidth: "100px", // Ensure a minimum width for cards
                    maxWidth: "300px", // Limit max width for uniform appearance
                    flexGrow: 3, // Allow flexibility in layout
                  }}
                >
                  <ToBeAssignedCard job={job} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "300px",
              }}
            >
              <img
                src={NoDataAvail}
                alt="No Data Available"
                style={{ maxWidth: "200px" }}
              />
            </Box>
          )}
      </Box>
    </>
  );
};

export default ToBeAssigned;
