import { createSlice } from "@reduxjs/toolkit";
interface initialStates{
    loading: boolean,
    approved:boolean
}
const initialState:initialStates = {
  loading: false,
  approved: false,
};

export const overallRthSlice = createSlice({
  name: "overallRth",
  initialState,
  reducers: {
    overallRthSliceLoading: (state, action) => {
      state.loading = action.payload;
    },
    overallRthUpdate: (state, action) => {
      state.approved = action.payload;
    },
  },
});

export const { overallRthSliceLoading, overallRthUpdate } =
  overallRthSlice.actions;
export default overallRthSlice.reducer;
