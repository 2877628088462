import React, { useState, useEffect, useCallback } from "react";
import { TextField, CircularProgress, MenuItem } from "@mui/material";
import { Autocomplete } from "@mui/material";

interface Option {
  id: string | number;
  label: string;
}

interface SearchableDropdownProps {
  searchAPICall: (query?: string) => Promise<any>;
  label: string;
  onOptionSelect: (selectedOption: Option | null) => void;
  value?: Option | null;
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  searchAPICall,
  label,
  onOptionSelect,
  value,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const fetchOptions = useCallback(
    async (query?: string) => {
      setLoading(true);
      try {
        const response = await searchAPICall(query);
        const fetchedOptions = response.data?.map((item: any) => ({
          id: item.userId,
          label: item.firstName,
        }));
        setOptions(fetchedOptions);
      } catch (error) {
        console.error("Error fetching options:", error);
      } finally {
        setLoading(false);
      }
    },
    [searchAPICall]
  );

  const handleFocus = () => {
    if (!isFocused) {
      setIsFocused(true);
      fetchOptions();
    } else {
      setIsFocused(false);
      fetchOptions("");
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchTerm) {
        fetchOptions(searchTerm);
      } else {
        fetchOptions("");
      }
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [searchTerm, fetchOptions]);

  return (
    <Autocomplete
      fullWidth
      size="small"
      options={options}
      getOptionLabel={(option) => option.label}
      loading={loading}
      onFocus={handleFocus}
      onInputChange={(event, value) => setSearchTerm(value)}
      onChange={(event, newValue) => onOptionSelect(newValue)}
      value={
        typeof value === "string"
          ? options.find((option) => option.id === value) || null
          : options.find((option) => option.id === value?.id) || value || null
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={{
            '& .MuiInputLabel-root': {
              fontSize: '0.8rem',
              fontWeight: 410, color: "gray",
            },
          }}
        />
      )}
      renderOption={(props, option) => (
        <MenuItem {...props}>{option.label}</MenuItem>
      )}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
    />
  );
};

export default SearchableDropdown;
