import axios from "axios";
import { BaseUrl } from "../../constants/Crudurl";
// import { CANDIDATE_API_URL } from "../../constants/Crudurl";

export const getCandidateByRthId=async(id:any)=>{
    const token=localStorage.getItem('token');

try{
    const response=await axios.get(
        `${BaseUrl}/candidate/getCandidateByRthId/${id}`,
        {
            headers:{
                Authorization:`Bearer ${token}`,
            },
        }
    );
    return response.data;
}
catch(error:any){
    return error.response?.data || { message: 'An unknown error occurred' };
}
}

export const deleteCandidateById=async(id:string)=>{
    try{
        console.log("into the api");
        const response=await axios.delete(
            `${BaseUrl}/candidate/deleteCandidateById/${id}`
        );
        return response.data;
    }
    catch(error:any){
        return error.response?.data || { message: 'An unknown error occurred' };
    }
}

