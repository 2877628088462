import React from 'react';
import { Box, Typography } from '@mui/material';
import priorityHighSymbol from '../../assets/images/priorityHighSymbol.svg';
import priorityLowSymbol from '../../assets/images/priorityLowSymbol.svg';
import priorityMediumSymbol from '../../assets/images/priorityMediumSymbol.svg';
import clockIcon from '../../assets/images/clockIcon.svg';
import { calculateDays } from '../../helpers/calculateDays';

interface JobTimeProps {
  posted?: string;
  priority?:string
  seqId?:string
  createdAt?:string;
}

const JobTime: React.FC<JobTimeProps> = ({ posted, priority, seqId, createdAt }) => { 
  
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 1, pb: '5px' }} gap={2}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', ml: '1px' }}>
        <Typography sx={{ fontWeight: '600', fontSize: '10px', color: '#A3A1A1' }}>#{seqId}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <img src={clockIcon} alt="Clock Icon" style={{ width: '10px', height: '10px' }} />
          { createdAt && <Typography color="textSecondary" sx={{ fontSize: '10px', color: '#8C8C8C', fontWeight: '400' }}>{ ` posted ${createdAt?calculateDays(createdAt):"No date"}`}</Typography>}
        </Box>
        <Typography sx={{ display: 'flex', alignItems: 'center', gap: '4px', fontWeight: '700', fontSize: '10px', color: priority === 'High' ? '#F04E4E' : priority === 'Medium' ? 'orange' : 'green', ml: 1}}>
        {priority === 'High' ? (
          <img src={priorityHighSymbol} alt="High Priority" />
        ) : priority === 'Medium' ? (
          <img src={priorityMediumSymbol} alt="Medium Priority" />
        ) : (
          <img src={priorityLowSymbol} alt="Low Priority" />
        )}
        <span style={{position: "relative", top: "2px"}}>{priority}</span>
      </Typography>
      </Box>
    </Box>
  );
};

export default JobTime;
