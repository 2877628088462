import * as React from "react";
import Button from "@mui/material/Button";
import { Tooltip } from "@mui/material";


export default function SelectData({
  // handleUpdate,
  // rowData,
  data,
  // options,
  // pageType,
  // fieldName,
}: any) {
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  const [selectedOption, setSelectedOption] = React.useState(data); // State for selected option
console.log("setSelectedOption",setSelectedOption)

  return (
    <>
    <Button
      // onClick={(event) => handleClick(event, data)}
      sx={{
        color: '#1677FF',
        textTransform: 'none',
        backgroundColor: selectedOption ? '#1677FF1A' : '',
        fontSize: '12px',
        width: 'auto',
        height: '24px',
      }}
    >
      <Tooltip title={selectedOption && selectedOption.length > 22 ? selectedOption : ''}>
        <span>
          {selectedOption && selectedOption.length > 22
            ? `${selectedOption.slice(0, 20)}...`
            : selectedOption}
        </span>
      </Tooltip>
    </Button>      
    </>
  );
}