import React, { useEffect, useState } from 'react';
import { Paper, Typography, Grid, Box } from '@mui/material';
import RequestIcon from '../../../../../assets/images/requestIcon.svg';
import ApprovalIcon from '../../../../../assets/images/approvalIcon.svg';
import ClosedIcon from '../../../../../assets/images/closedIcon.svg';
import InProgressIcon from '../../../../../assets/images/inProgress.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import axios from 'axios';
import { BaseUrl } from '../../../../../constants/Crudurl';

type StatisticsCardProps = {
    activePaper: string;
    onPaperClick: (paperType: string) => void;
};

type DashboardData = {
    OpenRth: number;
    TotalRth: number;
    Declined: number; 
    ApprovedRth: number;
};

const StatisticsCard: React.FC<StatisticsCardProps> = ({ activePaper, onPaperClick }) => {
    const getPaperBackgroundColor = (paperType: string) => (activePaper === paperType ? '#36BDC9' : '#FFFFFF');
    const getBoxBackgroundColor = (paperType: string) => (activePaper === paperType ? '#FFFFFF' : '#9123940D');
    const data = useSelector((state: RootState) => state.profile.data);
    const userID = data?.data?.userId;
    const [dashboardData, setDashboardData] = useState<DashboardData>({
        OpenRth: 0,
        TotalRth: 0,
        Declined: 0,
        ApprovedRth: 0,
    })

    useEffect(() => {
        const fetchStatisticsReport = async () => {
            try {
                const response = await axios.get(
                    `${BaseUrl}/dashboard/overAllApprovedMrfForAssigned/${userID}`
                );
                const { OpenRth, TotalRth, Declined, ApprovedRth } = response?.data?.data;
                setDashboardData({ OpenRth, TotalRth, Declined, ApprovedRth });
            } catch (error) {
                console.error('Error:', error);
            }
        };
        if (userID) fetchStatisticsReport();
    }, [userID]);

    return (
        <Paper sx={{ borderRadius: '8px', height: '200px', display: 'flex', flexDirection: 'column', boxShadow: 'none', overflow: 'hidden' }}>
            <Typography p={1} sx={{ fontSize: '12px', fontWeight: '500' }}>Overall (in No.)</Typography>
            <Grid container spacing={1} p={1}>
                {[ 
                    { type: 'total', count: dashboardData.OpenRth, label: 'Total MRF', icon: RequestIcon },
                    { type: 'approval', count:dashboardData.TotalRth, label: 'Open MRF', icon: ApprovalIcon },
                    { type: 'closed', count: dashboardData.Declined, label: 'Declined', icon: ClosedIcon },
                    { type: 'inProgress', count: dashboardData.ApprovedRth, label: 'Approved MRF', icon: InProgressIcon },
                ].map(({ type, count, label, icon }) => (
                    <Grid item xs={6} key={type}>
                        <Paper
                            sx={{
                                borderRadius: '12px',
                                boxShadow: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start', 
                                backgroundColor: getPaperBackgroundColor(type),
                                border: '0.5px solid #0000000D',
                                padding: '12px', 
                                cursor: 'pointer',
                            }}
                            onClick={() => onPaperClick(type)}
                        >
                            <Box sx={{
                                width: '40px', height: '40px', backgroundColor: getBoxBackgroundColor(type),
                                borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                marginRight: '8px', marginLeft: '16px',
                            }}>
                                <Box component="img" src={icon} alt="Icon" sx={{ width: '18px', height: '18px' }} />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '2px' }}>
                                <Typography sx={{ fontWeight: 600, fontSize: '16px', color: activePaper === type ? '#FFFFFF' : '#000000' }}>
                                    {count}
                                </Typography>
                                <Typography sx={{ color: activePaper === type ? '#FFFFFF' : '#000000', fontWeight: '400', fontSize: '0.563rem' }}>
                                    {label}
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
};

export default StatisticsCard;
