import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { BusinessData } from '../../components/admin/adminTabs/Business/AddBusiness';
import { BaseUrl } from '../../constants/Crudurl';
import { toast } from 'react-toastify';

interface UserState {
    users: {
        data: BusinessData[];
    };
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: UserState = {
    users: { data: [] },
    status: 'idle',
    error: null,
};

export const fetchBusiness = createAsyncThunk('business/fetchBusiness', async (search?:string) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${BaseUrl}/master/getBusinessList${search?`?search=${search}`:''}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
});

export const addBusiness = createAsyncThunk('business/addBusiness', async (businessData: { businessName: string }) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${BaseUrl}/master/addBusiness`, null, {
        headers: { Authorization: `Bearer ${token}` },
        params: { businessName: businessData.businessName },
    });
    return response.data;
});

export const deleteBusiness = createAsyncThunk('business/deleteBusiness', async (businessId: string) => {
    const token = localStorage.getItem("token");
    await axios.delete(`${BaseUrl}/master/removeBusiness/${businessId}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return businessId;
});

export const editBusiness = createAsyncThunk('business/editBusiness', async (businessData: { businessId: string; businessName: string }) => {
    const token = localStorage.getItem("token");
    const response = await axios.put(`${BaseUrl}/master/editBusinessById`, null, {
        headers: { Authorization: `Bearer ${token}` }, params: { businessId: businessData.businessId, businessName: businessData.businessName, },
    });
    if(response?.data?.message === "Business Updated"){
      toast.success("Business name updated successfully")
    } else {
        toast.error("Business name already exist")
    }
    return response.data;
});

const businessSlice = createSlice({
    name: 'business',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBusiness.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchBusiness.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.users = action.payload;
            })
            .addCase(fetchBusiness.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || null;
            })
            .addCase(addBusiness.fulfilled, (state, action: PayloadAction<{ data: BusinessData }>) => {
                if (state.users.data) {
                    state.users.data.push(action.payload.data);
                } else {
                    state.users.data = [action.payload.data];
                }
            })
            .addCase(deleteBusiness.fulfilled, (state, action) => {
                state.users.data = state.users.data.filter((user: BusinessData) => user.businessId !== action.payload);
            })
            .addCase(editBusiness.fulfilled, (state, action: PayloadAction<BusinessData>) => {
                const index = state.users.data.findIndex(user => user.businessId === action.payload.businessId);
                if (index !== -1) {
                    state.users.data[index] = action.payload;
                }
            })
    },
});

export default businessSlice.reducer;