import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import BarChart from './BarChart';
import { useNavigate } from 'react-router-dom';
import PieChartBoard1 from './PieChartBoard1';
import WelcomeSection from './WelcomeSection';
import StatisticsCard from './StatisticsCard';
import PieChartBoard2 from './PieChartBoard2';

const DashBoardRTH: React.FC = () => {
    const [activePaper, setActivePaper] = useState<string>('total');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/', { replace: true });
        }
    }, [navigate]);

    return (
        <>
            <Grid container spacing={2} p={2} justifyContent="space-between">
                <Grid item xs={12} md={4} maxWidth="100%">
                    <WelcomeSection />
                </Grid>
                <Grid item xs={12} md={4} maxWidth="100%">
                    <StatisticsCard activePaper={activePaper} onPaperClick={setActivePaper} />
                </Grid>
                <Grid item xs={12} md={4} maxWidth="100%">
                    <PieChartBoard1 />
                </Grid>
            </Grid>
            <Grid container spacing={2} p={2} justifyContent="space-between" paddingBottom='24px'>
                <Grid item xs={12} md={8} maxWidth="100%">
                    <BarChart />
                </Grid>
                <Grid item xs={12} md={4} maxWidth="100%">
                    <PieChartBoard2 />
                </Grid>
            </Grid>
        </>
    );
};

export default DashBoardRTH;
