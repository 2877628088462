import axios from 'axios';
import { BaseUrl, FILE_API_URL, RTH_API_URL } from '../constants/Crudurl';



//GET Business 
export const getRthBusinessInputData = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getBusinessList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

  // GET Band
  export const getRthBandData = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getBandList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
  
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

  // GET Department
  export const getRthDepartmentData = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getDepartmentList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
  
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

  // GET WorkMode
  export const getRthWorkModeData = async () => {
    const token = localStorage.getItem('token');
    //console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getWorkModeList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
  
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

  // GET Primary Skill

  export const getRthPrimarySkillsData = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getSkillList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
  
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

   // GET Secondary Skill

   export const getRthSecondarySkillsData = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getSkillList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
     // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

  // GET Position Reports to

  export const getRthPositionReportsTo = async (search?:string) => {
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getPositionReportsTo${search?`?search=${search}`:""}`,
      );
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

   // GET  l1 approver

   export const getRthL1ApproverData = async (search?:string) => {
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getL1Approvers${search?`?search=${search}`:""}`
      );
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

   // GET  l2 approver

   export const getRthL2ApproverData = async (search?:string) => {
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getL2Approvers${search?`?search=${search}`:""}`        
      );
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

  // GET  Priority

  export const getRthPriorityData = async () => {
    const token = localStorage.getItem('token');
    //console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getPriorityList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
  
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

  // GET  Qualification

  export const getRthQualificationData = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getQualificationList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
  
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

    // GET Location

    export const getRthLocationData = async () => {
      const token = localStorage.getItem('token');
     // console.log('token', token);
    
      try {
        const response = await axios.get(
          `${RTH_API_URL}/getLocationList`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log('response.data', response.data);
        return response.data;
      } catch (error: any) {
        console.error('getRthData', error);
    
        return error.response?.data || { message: 'An unknown error occurred' };
      }
    };

    // GET Reason of Hire

    export const getRthReasonForHire = async () => {
      const token = localStorage.getItem('token');
     // console.log('token', token);
    
      try {
        const response = await axios.get(
          `${RTH_API_URL}/getReasonOfHireList`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log('response.data', response.data);
        return response.data;
      } catch (error: any) {
        console.error('getRthData', error);
    
        return error.response?.data || { message: 'An unknown error occurred' };
      }
    };

   // GET Role
   export const getRoleList = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getActiveRole`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };


  export const getPositionTitle=async()=>{
    const token=localStorage.getItem('token');

    try {
      const response = await axios.get(
        `${RTH_API_URL}/getPositionTitle`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data.data;
    } catch (error: any) {
      console.error('getRthData', error);
      return error.response?.data || { message: 'An unknown error occurred' };
    }

  }

   // GET Designation

   export const getDesignationList = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getDesignationList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };


  // GET Designation

  export const getOrganization = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getOrganization`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

// GET getSupervisors

  export const getSupervisors = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getSupervisors`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };


  // GET getRecruiterLead

  export const getRecruiterLead = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getRecruiterLead`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };



  // GET getRecruiter

  export const getRecruiter = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getRecruiter`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

  export const editRecruiter=async(rthId:"string",data:any)=>{
    const token = localStorage.getItem('token');
    try {
      const response = await axios.put(`${FILE_API_URL}/editRecruiter/${rthId}`,data,{
        headers:{
          Authorization:`Bearer ${token}`,
        }
      });      
      return response.data;
  }
catch (error: any) {
  return error.response?.data || { message: 'An unknown error occurred' };
}

}

export const getMrfByRthId=async(rthId:"string")=>{
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${BaseUrl}/mrf/getMrfByRthId/${rthId}`,{
      headers:{
        Authorization:`Bearer ${token}`,
      }
    });
    return response.data.data;
}
catch (error: any) {
return error.response?.data || { message: 'An unknown error occurred' };
}
}

// Get Approvers
export const getApprovers = async (search?:string) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(
      `${RTH_API_URL}/getPositionReportsTo${search?`?search=${search}`:""}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: 'An unknown error occurred' };
  }
};
 

export const getRthById = async (id:string) => {
  const token = localStorage.getItem('token');
 // console.log('token', token);

  try {
    const response = await axios.get(
      `${BaseUrl}/rth/getRthById/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );            
    return response.data;
  } catch (error: any) {      
    return error.response?.data || { message: 'An unknown error occurred' };
  }
};

  

  







  