import React from 'react';
import { Typography, Grid, Box, Paper, SelectChangeEvent } from '@mui/material';
// import CommonSelect from '../../../common/CommomSelect';
// import { Citizenship, Nationality } from '../../../../constants/dummyConst';
// import { getCitizenship, getNationality } from '../../../../redux/features/resume';
// import { useAppDispatch } from '../internal/contracttab';
// import { useAppSelector } from '../../../../redux/hooks';
import { useLocation } from 'react-router-dom';
import CommonTextField from '../../../common/CommonTextField';
 
 
const AdditionalInformation = ({formValues,setFormValues}:any) => {
// const dispatch=useAppDispatch();
// const state=useAppSelector((state)=>state.resume)
const location = useLocation();
const isFormsPath = location.pathname.includes('Forms');
const path=location.pathname.includes("candidateForm");
 
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };
   
  return (
   
    <Box sx={{ pt: '4px',ml:path?'4px':''}}>
        <Paper elevation={0} sx={{overflowY:'auto',pt:'10px',position:'relative'}}>
        {isFormsPath ? (
  <Typography sx={{ fontSize: '20px', fontWeight: '500', mb: '27px', mt: '14px' }}>
    Additional Information
  </Typography>
) : path ? (
  <Typography
    sx={{
      fontSize: '20px',
      fontWeight: '500',
      mt: '10px',
      ml:'20px',
      color: '#000',
    }}
  >
    3.Additional Information
  </Typography>
) : (
  <Typography
    sx={{
      fontSize: '14px',
      mb: '17px',
      m: '14px',
      backgroundColor: '#1677FF1A',
      color: '#1677FF',
      width: '159px',
      p: '3px',
      borderRadius: '3px',
      textAlign: 'center',
      fontWeight: '500',
    }}
  >
    Additional Information
  </Typography>
)}
 
 
      <Box sx={{width:'50vw'}}>
      <Grid container spacing={4} sx={isFormsPath ? {} : {m: '1px'}}>
 
      <Grid item xs={12} md={4} >
        <Typography sx={{fontSize:'14px',color:'#bababa' , mb:'17px'}}>Nationality</Typography>
      <CommonTextField
                  label=""
                  name="nationality"
                  value={formValues.nationality||''}
                  onChange={handleInputChange}
                  // error={!!errors.nationality}
                  // helperText={errors.nationality}
                />
          </Grid>
          <Grid item xs={12} md={4}>
             <Typography sx={{fontSize:'14px',color:'#bababa' , mb:'17px'}}>CitizenShip</Typography>
             <CommonTextField
                  label=""
                  name="citizenship"
                  value={formValues.citizenship||''}
                  onChange={handleInputChange}
                  // error={!!errors.citizenShip}
                  // helperText={errors.citizenShip}
                />
             </Grid>
        </Grid>
     
      </Box>
      {/* <Box sx={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',gap:'20px',mt:'50px',mr:'10px', position:"absolute", bottom:'10px', right:'10px'}}>
        <Button  sx={{textTransform:'none',border:'InactiveBorder',color:'#848484'}} onClick={handlePreviousPage}>Previous</Button>
      <Button variant='contained'onClick={handleContinue} sx={{textTransform:'none'}}>Continue</Button>
      </Box> */}
      </Paper>
    </Box>
  );
};
 
export default AdditionalInformation;