import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Grid, Radio, RadioGroup, FormControlLabel, Typography, SelectChangeEvent } from '@mui/material';
import DeleteIcon from '../../../../../assets/images/del_Icon.svg';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CommonAutocomplete, InterviewLinkUI, SelectUI, TextFieldUI } from './InputsInterviewSchedule';
import { getCandidateByRthId } from '../../../../../redux/features/candidate';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { addInterview, getCandidateInterviewDetails, getInterviewDetailsByRecruiterId, getL1Approvers } from '../../../../../redux/features/interviewSchedule';
import { InterviewMode } from '../../../../../constants/dummyConst';
import { toast } from 'react-toastify';
 
 
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
 
 
 
export default function ScheduleInterviewDialog ( {DialogOpen, close,handleFirstStep }:any ) {
 
  const [typeOfRTH, setTypeOfRTH] = React.useState('Internal');
  const hasInitialized = useRef(false);
  const [rounds, setRounds] = React.useState<string[]>([]);  // Array to store each round name
  const [values, setValues]=useState<any>({
    candidateId:'',
    interviewPlatform:'',
    interviewDet: [
      {
        interviewRound: '',
        interviewName: '',
        interviewMode: '',
        interviewLink: '',
        interviewDate: '',
        from: '',
        to: '',
        interviewerId:'',
        optionalAttendeesId:null,
      }
    ]  
  });
  const emptyState = {
    candidateId: '',
    interviewPlatform: '',
    interviewDet: [
      {
        interviewRound: '',
        interviewName: '',
        interviewMode: '',
        interviewLink: '',
        interviewDate: '',
        from: '',
        to: '',
        interviewerId: '',
        optionalAttendeesId: '',
      }
    ]  
  };
  
  
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const dispatch=useAppDispatch();
  const state=useAppSelector((state)=>state.interviewSchedule);
  const {l1Approvers}=state;
  const recruiterId=localStorage.getItem('userId');
 
  const location = useLocation();
  const {candidateName,candidateId} = useParams();
  const path = location.pathname.includes('candidate');
  const {id} = useParams();
  
 
  const handleInitialRound = () => {
    const newRound = `Interview Round - ${rounds.length + 1}`;
    setRounds((prevRounds) => [...prevRounds, newRound]); // Directly update rounds  
    setValues({
      ...values,
      interviewDet: [
        {
          interviewRound: '',
          interviewName: newRound,
          interviewMode: '',
          interviewLink: '',
          interviewDate: '',
          from: '',
          to: '',
          interviewerId:'',
          optionalAttendeesId:null,
        }
      ] 
    })
  };
 
  const handleclose = () => {
    hasInitialized.current = false;
    close();
    setRounds([]);
    setErrors({});
  }
 
 
  // Add a new round and close the dialog
 
  const handleDeleteRound = (index: number) => {
    const updatedRounds = rounds.filter((_, i) => i !== index);
  
    const updatedInterviewDet = values.interviewDet.filter((_:any, i:any) => i !== index);

    setRounds(updatedRounds);
    setValues({
      ...values,
      interviewDet: updatedInterviewDet.map((det:any, i:any) => ({
        ...det,
        interviewName: `Interview Round - ${i + 1}`, // Reindex rounds if necessary
      })),
    });
  };
 
  const fetchInterviewers = async (query: string) => {
    // Replace with your API logic
     await dispatch(getL1Approvers(query));
    return l1Approvers.map((item: { userId: any; firstName: string ,lastName:string }) => ({
      label: item.firstName + item.lastName,
      value: item.userId,
    }));
  };
  
  const handleInputChangeNormal = (event: React.ChangeEvent<HTMLInputElement>) => {   
    const { name, value } = event.target;    
    setValues({
      ...values,
      [name]: value,
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear the error
    }));
  };
 
  const handleSelectChangeNormal=(event:SelectChangeEvent<string>)=>{
    const {name,value}=event.target;
    
    setValues({
      ...values,
      [name]:value,
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear the error
    }));
  };

  const handleInputChangeTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // e.g., name = 'interviewDet[0].from', value = '10:00'
    const [prefix, index, field] = name.match(/([a-zA-Z]+)\[(\d+)\]\.([a-zA-Z]+)/)!.slice(1);
  
    setValues((prevValues:any) => {
      const updatedValues = [...prevValues[prefix]]; // Clone interviewDet array
      const currentItem = { ...updatedValues[+index] }; // Clone the object at the index
  
      if (field === 'from' || field === 'to') {
        // Get today's date in YYYY-MM-DD format
        const today = new Date().toISOString().split('T')[0];
        const date = currentItem?.interviewDate || today; // Use current date if interviewDate is not provided
        currentItem[field] = `${date}T${value}:00`; // Update the field with ISO format
      }
      updatedValues[+index] = currentItem; // Update the array at the index
  
      return { ...prevValues, [prefix]: updatedValues }; // Return the updated state
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear the error
    }));
  };

  const handleInputChange = (e:any) => {
    const { name, value } = e.target;
    const nameParts = name.split(/[.[\]]+/).filter(Boolean);
    const index = nameParts[1];  // Extract index (for example, 0 for the first round)
    const field = nameParts[2];  // Extract field name (e.g., interviewRound)
 
    setValues((prevValues:any) => {
      const updatedInterviewDet = [...prevValues.interviewDet];
      updatedInterviewDet[index] = {
        ...updatedInterviewDet[index],
        [field]: value
      };
      return { ...prevValues, interviewDet: updatedInterviewDet };
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear the error
    }));

  };
 
 
  const handleSelectChange = (e:any) => {
    const { name, value } = e.target;
    const nameParts = name.split(/[.[\]]+/).filter(Boolean);
    const index = nameParts[1];
    const field = nameParts[2];
 
    setValues((prevValues:any) => {
      const updatedInterviewDet = [...prevValues.interviewDet];
      updatedInterviewDet[index] = {
        ...updatedInterviewDet[index],
        [field]: value
      };
      return { ...prevValues, interviewDet: updatedInterviewDet };
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear the error
    }));
  };
  
 
  const handleAddRound=(index:any)=>{
    const newRound = `Interview Round - ${rounds.length + 1}`;
    setValues({
      ...values,
      interviewDet: [
        ...values.interviewDet, 
        {
          interviewRound: '',
          interviewName: newRound,
          interviewMode: '',
          interviewLink: '',
          interviewDate: '',
          from: '',
          to: '',
          interviewerId:'',
          optionalAttendeesId:null,
        }
      ] 
    })
    setRounds((prevRounds) => [...prevRounds, newRound]); // Directly update rounds
  };
 
  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to the start of the day
  
    // Define the valid time range for 'from' (8:00 AM - 6:55 PM) and 'to' (8:05 AM - 7:00 PM)
    const fromStart = new Date(today.setHours(8, 0, 0, 0)); // 8:00 AM
    const fromEnd = new Date(today.setHours(18, 55, 0, 0)); // 6:55 PM
    const toStart = new Date(today.setHours(8, 5, 0, 0)); // 8:05 AM
    const toEnd = new Date(today.setHours(19, 0, 0, 0)); // 7:00 PM
  
    values.interviewDet.forEach((field: any, index: number) => {
      if (!field.interviewRound) {
        newErrors[`interviewDet[${index}].interviewRound`] = 'Interview round is required';
      }
      if (!field.interviewMode) {
        newErrors[`interviewDet[${index}].interviewMode`] = 'Interview mode is required';
      }
      if (!field.interviewDate) {
        newErrors[`interviewDet[${index}].interviewDate`] = 'Interview date is required';
      } else {
        const interviewDate = new Date(field.interviewDate);
        if (interviewDate < today) {
          newErrors[`interviewDet[${index}].interviewDate`] = 'Select a valid date (today or later)';
        }
      }
  
      if (!field.from) {
        newErrors[`interviewDet[${index}].from`] = 'Start time is required';
      } else {
        // Create a Date object for comparison
        const [fromHour, fromMinute] = field.from.split(':').map(Number);
        const fromTime = new Date(today.setHours(fromHour, fromMinute, 0, 0));
        if (fromTime < fromStart || fromTime > fromEnd) {
          newErrors[`interviewDet[${index}].from`] = 'Start time must be between 8:00 AM and 6:55 PM';
        }
      }
  
      if (!field.to) {
        newErrors[`interviewDet[${index}].to`] = 'End time is required';
      } else {
        // Create a Date object for comparison
        const [toHour, toMinute] = field.to.split(':').map(Number);
        const toTime = new Date(today.setHours(toHour, toMinute, 0, 0));
        if (toTime < toStart || toTime > toEnd) {
          newErrors[`interviewDet[${index}].to`] = 'End time must be between 8:05 AM and 7:00 PM';
        }
      }
  
      if (!field.interviewerId) {
        newErrors[`interviewDet[${index}].interviewerId`] = 'Interviewer is required';
      }
    });
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit=async()=>{

    if(validateFields()){
    let payload;

    if(path){
    const commonFields={
        rthId:id,
        recruiterId:recruiterId,
        candidateId:candidateId,
        interviewPlatform:values?.interviewPlatform
      }
      const interviewFields: { [key: string]: any } = {};

  if (values.interviewDet && values.interviewDet.length > 0) {
    values.interviewDet.forEach((interview:any, index:any) => {
      const fieldName = `interviewDetL${index + 1}`;
      interviewFields[fieldName] = interview;
    });
  }

  payload = { ...commonFields, ...interviewFields };
    }
    
    else{
      const commonFields = {
        rthId: id,
        recruiterId: recruiterId,
        candidateId:values?.candidateId,
        interviewPlatform:values?.interviewPlatform
      };    
      const interviewFields: { [key: string]: any } = {};

  if (values.interviewDet && values.interviewDet.length > 0) {
    values.interviewDet.forEach((interview:any, index:any) => {
      const fieldName = `interviewDetL${index + 1}`;
      interviewFields[fieldName] = interview;
    });
  }

  payload = { ...commonFields, ...interviewFields };

    }
     
    const resultAction=await dispatch(addInterview(payload));
    if(addInterview.fulfilled.match(resultAction)){
      const {status,message}=resultAction.payload||{};
      if(status===true){
        toast.success(message);
        dispatch(getCandidateInterviewDetails(candidateId));
        if(path){
          handleFirstStep();
          setValues(emptyState);
        }
        if(!path){
          await dispatch(getInterviewDetailsByRecruiterId(recruiterId));
        }
        setValues(emptyState);
      }
      else{
        toast.error('Improper Data');
      }
    }
    close();
  }
  }
 
 
  useEffect(()=>{
    getCandidateByRthId(id).then(response=>{
      const transformedOptions = response.data.map((candidate: any) => ({
        label: candidate.firstName, // Assuming `name` exists in the response data
        value: candidate.candidateId,   // Assuming `id` exists in the response data
      }));
      setOptions(transformedOptions);
    });
  },[id])
 
  useEffect(()=>{
    dispatch(getL1Approvers(''));
  },[rounds,dispatch])

       
  return (
    <React.Fragment>
      <Dialog
      open={DialogOpen}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="schedule-interview-dialog-description"
      maxWidth={false}
      fullWidth
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          close();
        }
      }}
      PaperProps={{
        style: {
          width: '1400px',
          maxWidth: '1400px',  
          borderRadius:'8px'        
        },
      }}
    >
        <Box sx={{border:'1px solid #D9D9D9', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center' }}>
        <DialogTitle sx={{ p:'20px', pl:'28px' }}>{path?`Schedule Interview For ${candidateName}`: "Schedule Interview" }</DialogTitle>
        <Box sx={{pr:'20px'}}>
        <Box sx={{width:'30px', height:'30px', bgcolor:'#ECECEC', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center' }}>
        <CloseRoundedIcon sx={{color:'#989696',  fontSize:'24px', cursor: 'pointer', '&:hover':{color:'#4a4a4a'} }}  onClick={handleclose} />
        </Box>
        </Box>
        </Box>
        <DialogContent>
          <Box component="form" sx={{ mt: 2 }}>
            <Grid container spacing={3}>
             
              {/* Candidate */}
              {!path &&
              <Grid item xs={4}>
              <Typography sx={{mb:'10PX'}}>Candidate</Typography>
              <SelectUI value={values.candidateId} handleInputChange={handleSelectChangeNormal} name={'candidateId'} option={options} label={'Choose Candidate'}/>
              </Grid>}
             
              {/* Type of RTH */}
              <Grid item xs={12}>
              <Typography>Type of RTH</Typography>
              <RadioGroup row value={typeOfRTH} onChange={(e) => setTypeOfRTH(e.target.value)}>
                <Box
                  sx={{
                    bgcolor: typeOfRTH === "Internal" ? "#F0F5FF" : "#00000008",
                    mr: "10px",
                    height: "50px",
                    borderRadius: "8px",
                    paddingLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                    border: typeOfRTH === "Internal" ? "1px solid #1677FF" : "none",
                    mt: "10px",
                    width: "182px",
                  }}
                >
                  <FormControlLabel
                    value="Internal"
                    sx={{ color: typeOfRTH === "Internal" ? "#1677FF" : "#919EAB" }}
                    control={<Radio sx={{ color: typeOfRTH === "Internal" ? "#1677FF" : "#919EAB", '&.Mui-checked': { color: '#1677FF' } }} />}
                    label="Internal"
                  />
                </Box>
 
                <Box
                  sx={{
                    bgcolor: typeOfRTH === "External" ? "#F0F5FF" : "#00000008",
                    mr: "10px",
                    height: "50px",
                    borderRadius: "8px",
                    paddingLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                    border: typeOfRTH === "External" ? "1px solid #1677FF" : "none",
                    mt: "10px",
                    width: "182px",
                  }}
                >
                  <FormControlLabel
                    value="External"
                    sx={{ color: typeOfRTH === "External" ? "#1677FF" : "#919EAB" }}
                    control={<Radio sx={{ color: typeOfRTH === "External" ? "#1677FF" : "#919EAB", '&.Mui-checked': { color: '#1677FF' } }} />}
                    label="External"
                  />
                </Box>
              </RadioGroup>
            </Grid>
 
            {/* Interview Platform */}
            <Grid item xs={12}>
              <Typography>Interview Platform</Typography>
              <RadioGroup row value={values?.interviewPlatform} name='interviewPlatform' onChange={(e) => handleInputChangeNormal(e)}>
                <Box
                  sx={{
                    bgcolor: values?.interviewPlatform === "Theron Connect" ? "#F0F5FF" : "#00000008",
                    mr: "10px",
                    height: "50px",
                    borderRadius: "8px",
                    paddingLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                    border: values?.interviewPlatform === "Theron Connect" ? "1px solid #1677FF" : "none",
                    mt: "10px",
                    width: "182px",
                  }}
                >
                  <FormControlLabel
                    value="Theron Connect"
                    sx={{ color: values?.interviewPlatform === "Theron Connect" ? "#1677FF" : "#919EAB" }}
                    control={<Radio sx={{ color: values?.interviewPlatform === "Theron Connect" ? "#1677FF" : "#919EAB", '&.Mui-checked': { color: '#1677FF' } }} />}
                    label="Theron Connect"
                  />
                </Box>
 
                <Box
                  sx={{
                    bgcolor: values?.interviewPlatform === "External" ? "#F0F5FF" : "#00000008",
                    mr: "10px",
                    height: "50px",
                    borderRadius: "8px",
                    paddingLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                    border: values?.interviewPlatform === "External" ? "1px solid #1677FF" : "none",
                    mt: "10px",
                    width: "182px",
                  }}
                >
                  <FormControlLabel
                    value="External"
                    sx={{ color: values?.interviewPlatform === "External" ? "#1677FF" : "#919EAB" }}
                    control={<Radio sx={{ color: values?.interviewPlatform === "External" ? "#1677FF" : "#919EAB", '&.Mui-checked': { color: '#1677FF' } }} />}
                    label="External"
                  />
                </Box>
              </RadioGroup>
            </Grid>
             
              {/* Interview Round - 01 */}
              <Grid item xs={12} sx={{overflowY:'auto', height:'auto'}}>
              {rounds.map((round, index) => (
                <Box sx={{ bgcolor: '#f7f7f7', height: '100px', p: '20px', borderRadius: '8px', mb: '20px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pb: '16px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>{round}</Typography>
                    {index === rounds.length - 1 && (
                      <Typography sx={{ fontSize: '12px', color: '#1677FF', fontWeight: 600, cursor: 'pointer' }} onClick={() => handleAddRound(index)}>
                        +Add Round
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', overflowX: 'auto', flexWrap: 'nowrap', gap: 1, height:55 }}>
                    <TextFieldUI value={values?.interviewDet[index]?.interviewRound || ''} name={`interviewDet[${index}].interviewRound`} placeholder={"L1"} handleInputChange={handleInputChange} width={'150px'} 
                    error={!!errors[`interviewDet[${index}].interviewRound`]}
                     helperText={errors[`interviewDet[${index}].interviewRound`]}
                    />

                    <SelectUI value={values?.interviewDet[index]?.interviewMode || ''} handleInputChange={handleSelectChange} name={`interviewDet[${index}].interviewMode`} option={InterviewMode} label={'Interview mode'} width={'150px'}
                      error={!!errors[`interviewDet[${index}].interviewMode`]}
                       helperText={errors[`interviewDet[${index}].interviewMode`]}
                     />

                    <InterviewLinkUI value={values?.interviewDet[index]?.interviewLink || ''} name={`interviewDet[${index}].interviewLink`} placeholder={"Interview Link"} handleInputChange={handleInputChange} width={'150px'}/>

                    <TextFieldUI value={values?.interviewDet[index]?.interviewDate || ''} name={`interviewDet[${index}].interviewDate`} placeholder={"L1"} handleInputChange={handleInputChange} width={'150px'} type={'date'} 
                      error={!!errors[`interviewDet[${index}].interviewDate`]}
                      helperText={errors[`interviewDet[${index}].interviewDate`]}  
                    />
                    <TextFieldUI value={values.interviewDet[index]?.from?.split('T')[1]?.slice(0, 5) || ''} name={`interviewDet[${index}].from`} placeholder={""} handleInputChange={handleInputChangeTime} width={'150px'} type={'time'} 
                      error={!!errors[`interviewDet[${index}].from`]}
                       helperText={errors[`interviewDet[${index}].from`]}
                    />
                    <TextFieldUI value={values.interviewDet[index]?.to?.split('T')[1]?.slice(0, 5) || ''} name={`interviewDet[${index}].to`} placeholder={""} handleInputChange={handleInputChangeTime} width={'150px'} type={'time'}
                        error={!!errors[`interviewDet[${index}].to`]}
                        helperText={errors[`interviewDet[${index}].to`]}
                     />
                    <CommonAutocomplete
                      value={values?.interviewDet[index]?.interviewerId || {}}
                      handleChange={handleSelectChange}
                      name={`interviewDet[${index}].interviewerId`}
                      label="Choose Interviewer"
                      width="150px"
                      fetchOptions={fetchInterviewers}
                      initialOptions={l1Approvers} // Optional: Pass static options initially
                      error={!!errors[`interviewDet[${index}].interviewerId`]}
                      helperText={errors[`interviewDet[${index}].interviewerId`]}
          
                    />

                    <CommonAutocomplete
                      value={values?.interviewDet[index]?.optionalAttendeesId || {}}
                      handleChange={handleSelectChange}
                      name={`interviewDet[${index}].optionalAttendeesId`}
                      label="Optional Attendees"
                      width="150px"
                      fetchOptions={fetchInterviewers}
                      initialOptions={l1Approvers} // Optional: Pass static options initially
                    />
                    
                    {/* delete icon */}
                    <img src={DeleteIcon} alt='Delete Icon' style={{ paddingLeft: '6px', cursor: 'pointer', width:'20px' }} onClick={() => handleDeleteRound(index)} />
                  </Box>
                </Box>

                ))}
                 
                 {rounds.length <= 0 && (
                <Typography sx={{ fontSize: '12px', color: '#1677FF', fontWeight: 600, cursor: 'pointer', mt: 2 }} onClick={handleInitialRound}>
                  + Create Interview Round
                </Typography>
                 )}
                             
              </Grid>
 
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{height:'50px'}}>
          <Box sx={{pb:'20px'}}>
          <Button variant="contained" sx={{ bgcolor:"#3185FC", borderRadius:'8px', mr: 2, textTransform:'none','&:hover':{bgcolor:'#3185FC'}}} disabled={rounds.length <= 0} onClick={handleSubmit}  >
            Schedule Interview
          </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>    
  );
}

