import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getCandidateInterviewDetails, getInterviewDetailsByInterviewId, getInterviewDetailsByRecruiterId, getL1Approvers } from "./interviewSchedule";

export const initialState={
    interviewSchedule:[],
    l1Approvers:[],
    candidateInterviewDetails:[],
    interviewDetailsByInterviewId:[],
    status:''
}

const InterviewSchedule=createSlice({
    name:'interviewSlice',
    initialState,
    reducers:{
        setStatus(state, action: PayloadAction<string>) {
            state.status = action.payload;
          },      
    },
    extraReducers:(builder)=>{
        builder.addCase(getInterviewDetailsByRecruiterId.fulfilled,(state:any,action:any)=>{
            // console.log(action?.payload?.data,'522');
            state.interviewSchedule=action?.payload?.data;
        })
        builder.addCase(getInterviewDetailsByRecruiterId.rejected,(state:any,action:any)=>{
            state.error = action.payload ? action.payload?.error : null;
        })
        builder.addCase(getL1Approvers.fulfilled,(state:any,action:any)=>{
            state.l1Approvers=action.payload?.data;
        })
        builder.addCase(getL1Approvers.rejected,(state:any,action:any)=>{
            state.error = action.payload ? action.payload?.error : null;
        })
        builder.addCase(getCandidateInterviewDetails.fulfilled,(state:any,action:any)=>{
            state.candidateInterviewDetails=action.payload?.data;
        })
        builder.addCase(getCandidateInterviewDetails.rejected,(state:any,action:any)=>{
            state.error = action.payload ? action.payload?.error : null;
        })
        builder.addCase(getInterviewDetailsByInterviewId.fulfilled,(state:any,action:any)=>{
            // console.log(action?.payload?.data,'522');
            state.interviewDetailsByInterviewId=action?.payload?.data;
        })
        builder.addCase(getInterviewDetailsByInterviewId.rejected,(state:any,action:any)=>{
            state.error = action.payload ? action.payload?.error : null;
        })
    }
})

export const {setStatus}=InterviewSchedule.actions;
export default InterviewSchedule.reducer;