import axios from "axios";

import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { Job } from "./jobcardSlice";
import { BaseUrl } from "../../constants/Crudurl";

interface allViewersLoginState{
    allViewersLoginData:Job[];
    searchSuggestionsData:Job[];
    loading:boolean;
    error:string | null;
}

const initialState:allViewersLoginState={
    allViewersLoginData:[],
    searchSuggestionsData:[],
    loading:false,
    error:null,
}

export const token=localStorage.getItem('token');

export const getAllPostedJobs=createAsyncThunk('allViewersLogin/fetchAllViewersLogin',async({industry,location}:{industry:string,location:string},{rejectWithValue})=>{
    try{
        
        const response=await axios.get(`${BaseUrl}/allLogin/getPostedJobs`,{
            params:{industry,location},
        });
        return response.data.data;
    }
    catch(error:any)
    {
        return rejectWithValue(error.response.data)
    }
})

export const getSearchSuggestions=createAsyncThunk('searchSuggestions/fetchSearchSuggestion',async({searchString}:{searchString:string})=>{
    try{
        const response=await axios.get(`${BaseUrl}/allLogin/getJobsBySearch`,{
            params:{searchString},            
    });
    return response.data.data;
    }
    catch(error:any)
    {
        return error.response.data;
    }
})

const allUserSlice=createSlice({
    name:"allUserLogin",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getAllPostedJobs.pending,(state)=>{
            state.loading=true;
            state.error=null;
        })
        .addCase(getAllPostedJobs.fulfilled,(state,action)=>{
            state.loading=false;
            state.allViewersLoginData=action.payload;
        })
        .addCase(getAllPostedJobs.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload as string;
        })

        // searchSuggestions
        .addCase(getSearchSuggestions.pending,(state)=>{
            state.loading=true;
            state.error=null;
        })
        .addCase(getSearchSuggestions.fulfilled,(state,action)=>{
            state.loading=false;
            state.searchSuggestionsData=action.payload;
        })
        .addCase(getSearchSuggestions.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload as string;
        })
    }
})

export default allUserSlice.reducer;