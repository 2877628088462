import React, { useState } from 'react';
import { Grid } from '@mui/material';
import ApprovedRTH from './PieChartBoard2';
import BarChart from './BarChart';
import WelcomeSection from './WelcomeSection';
import StatisticsCard from './StatisticsCard';
import PieChartBoard1 from './PieChartBoard1';

const DashBoardAH: React.FC = () => {
    const [activePaper, setActivePaper] = useState<string>('total');

    return (
        <>
            <Grid container spacing={2} p={2} justifyContent="space-between">
                <Grid item xs={12} md={4} maxWidth="400px">
                    <WelcomeSection />
                </Grid>
                <Grid item xs={12} md={4} maxWidth="400px">
                    <StatisticsCard activePaper={activePaper} onPaperClick={setActivePaper}/>
                </Grid>
                <Grid item xs={12} md={4} maxWidth="400px">
                    <PieChartBoard1 />
                </Grid>
            </Grid>
            <Grid container spacing={2} p={2} justifyContent="space-between"  paddingBottom='24px'>
                <Grid item xs={12} md={8} maxWidth="800px">
                    <BarChart />
                </Grid>
                <Grid item xs={12} md={4} maxWidth="400px">
                    <ApprovedRTH />
                </Grid>
            </Grid>
        </>
    );
};

export default DashBoardAH;
