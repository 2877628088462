import {
    Box,
    Button,
    Chip,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    CircularProgress
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { StyledTableCell } from "./RthClosedTable";
  import { getMyRequestsAPIData } from "../../../../Crud/card";
  import { useAppSelector } from "../../../../redux/hooks";
  import priorityHighSymbol from "../../../../assets/images/priorityHighSymbol.svg";
  import priorityMediumSymbol from "../../../../assets/images/priorityMediumSymbol.svg";
  import priorityLowSymbol from "../../../../assets/images/priorityLowSymbol.svg";
  import CheckCircleIcon from "@mui/icons-material/CheckCircle";
  import pendingIcon from "../../../../assets/images/pendingicon.svg";
  import NoDataAvail from "../../../../assets/images/NoDataAvail.svg";
   
  const MyRequestTableView: React.FC = () => {
    const tableHead = [
      "SI.No.",
      "RTH Name",
      "Type of RTH",
      "Business",
      "Priority",
      "Status",
      "L1 Status",
      "L2 Status",
    ];
   
    const userId = localStorage.getItem("userId") ?? "";
    const [loading, setLoading] = useState<boolean>(false);
    console.log(loading)
    const [tableData, setTableData] = useState<any[]>([]);
    const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
    const [searchQuery, setSearchQuery] = useState<string>("");
   console.log(setSearchQuery)
    useEffect(() => {
      setLoading(true);
      getMyRequestsAPIData(userId, teamHeadFilter, searchQuery)
        .then((res) => {
          // setLoading(false);
          setTableData(res?.data?.listOfRth);
        })
        .catch((error) => {
          console.error("Error fetching RTH data:", error);
        })
        .finally(() => {
          setLoading(false);
      });
    }, [searchQuery,teamHeadFilter,userId]);
   
    return (
      <Box sx={{ width: "auto" }} >
        {
         loading ? (
          <Box
              sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
              }}
          >
              <CircularProgress size={100} sx={{color: '#2F54EB'}}/>
          </Box>
      ) :  tableData.length > 0 ? (
            <Box sx={{ width: "100%", overflowX: "auto"}}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="RTH table">
              <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
                <TableRow
                  sx={{
                    "& th": {
                      padding: "4px 8px", // Adjust padding for header cells here
                      fontSize: "14px", // Optional: Adjust font size
                    },
                  }}
                >
                  {tableHead.map((data, index) => (
                    <StyledTableCell>{data}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "& td, & th": {
                    padding: "4px 8px", // Adjust padding here
                  },
                }}
              >
                {tableData.map((row, index) => (
                  <TableRow key={index}>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>{row?.positionTitle}</StyledTableCell>
                    <StyledTableCell>
                      <Box
                        sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                      >
                        <Button
                          size="small"
                          sx={{
                            backgroundColor: "#F0F0F0",
                            border: "1px solid lightgray",
                            fontSize: "8px",
                            padding: "2px",
                            color: "#8C8C8C",
                            height: "20px",
                            minWidth: "50px",
                          }}
                        >
                          {row.typeOfRth}
                        </Button>
                        <Button
                          size="small"
                          sx={{
                            backgroundColor: "#F0F0F0",
                            border: "1px solid lightgray",
                            fontSize: "8px",
                            padding: "2px 4px",
                            color: "#8C8C8C",
                            height: "20px",
                            minWidth: "50px",
                          }}
                        >
                          {row.modeOfRth}
                        </Button>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        sx={{
                          width: "fit-content",
                          fontSize: "12px",
                          padding: "3%",
                          height: "px",
                          border:
                            "1px solid " +
                            (row.business.businessName === "HEPL"
                              ? "#D3ADF7"
                              : "#87E8DE"),
                          backgroundColor:
                            row.business.businessName === "HEPL"
                              ? "#EFDBFF"
                              : "#B5F5EC",
                          color:
                            row.business.businessName === "HEPL"
                              ? "#722ED1"
                              : "#08979C",
                        }}
                      >
                        {row.business.businessName}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",                        
                        }}
                        gap={1}
                      >
                        {row.priority === "High" ? (
                          <Box>
                            <img src={priorityHighSymbol} alt="" />
                          </Box>
                        ) : row.priority === "Medium" ? (
                          <Box>
                            <img src={priorityMediumSymbol} alt="" />
                          </Box>
                        ) : (
                          <Box>
                            <img src={priorityLowSymbol} alt="" />
                          </Box>
                        )}
                        <Typography
                          sx={{
                            color:
                              row.priority === "High"
                                ? "#F04E4E"
                                : row.priority === "Medium"
                                ? "#FFA940"
                                : "#73D13D",
                            fontSize: "100%",
                          }}
                        >
                          {row.priority}
                        </Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Chip
                        label={row.status}
                        size="small"
                        sx={{
                          fontSize: "12px",
                          height: "px",
                          borderRadius: "1px",
                          backgroundColor: "#389E0D1A",
                          color: "#389E0D",
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.l1Status === "Pending" ? (
                        <Chip
                          label={
                            row.l1Status === null
                              ? "L1 Pending"
                              : "L1 " + row.l1Status
                          }
                          icon={
                            <img
                              src={pendingIcon}
                              alt="pending"
                              style={{ width: "10px", height: "10px" }}
                            />
                          }
                          size="small"
                          sx={{
                            backgroundColor: "#FFF7E6",
                            color: "#595959",
                            border: "1px solid #FFE7BA",
                          }}
                        />
                      ) : (
                        <Chip
                          label={row.l1Status}
                          icon={
                            <CheckCircleIcon
                              style={{ color: "#4caf50", fontSize: "12px" }}
                            />
                          }
                          size="small"
                          sx={{
                            backgroundColor: "#F6FFED",
                            color: "#595959",
                            border: "1px solid #D9F7BE",
                          }}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.l2Status === "Pending" || row.l2Status === null ? (
                        <Chip
                          label={
                            row.l2Status === null
                              ? "L2 Pending"
                              : "L2 " + row.l2Status
                          }
                          icon={
                            <img
                              src={pendingIcon}
                              alt="pending"
                              style={{ width: "10px", height: "10px" }}
                            />
                          }
                          size="small"
                          sx={{
                            backgroundColor: "#FFF7E6",
                            color: "#595959",
                            border: "1px solid #FFE7BA",
                          }}
                        />
                      ) : (
                        <Chip
                          label={row.l2Status}
                          icon={
                            <CheckCircleIcon
                              style={{ color: "#4caf50", fontSize: "12px" }}
                            />
                          }
                          size="small"
                          sx={{
                            backgroundColor: "#F6FFED",
                            color: "#595959",
                            border: "1px solid #D9F7BE",
                          }}
                        />
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
   
   
          ) : (
   
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "300px",
              }}
            >
              <img src={NoDataAvail} alt="No Data Available" style={{ maxWidth: "200px" }} />
            </Box>
   
          )
        }
            </Box>
    );
  };
   
  export default MyRequestTableView;