import React from 'react';
import { Box,Typography } from '@mui/material';


interface JobHeaderProps {
  department?: string;
  business?:{
    businessId:string,
    businessName:string
  };
}

const JobCardHeader: React.FC<JobHeaderProps> = ({ department, business}) => {
  
  return (

    <Box sx={{ display: "flex", justifyContent: 'space-between', paddingTop: '5px', margin: "0 5px" }} gap={1}>      
      
      <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>
        <abbr title={department} style={{textDecoration:'none'}}>
        {department ? (department.length > 20 ? `${department.slice(0, 20)}...` : department) : "N/A"}
        </abbr>
        </Typography>


          
      <Typography sx={{ fontSize: '8px', color: '#08979C', padding: "3px 6px 0px 6px", backgroundColor: `${business?.businessName === "HEPL" ? "#D3ADF7" : '#B5F5EC'}`, borderRadius: '1px',border:'1px solid #87E8DE' }}>{business?.businessName}</Typography>
    </Box>
  );
}

export default JobCardHeader;
