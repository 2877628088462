import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AjHeader from './AjHeader'
import AjBody from './AjBody'
import { getAllPostedJobs } from '../../redux/features/allUserSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch, RootState } from '../../redux/store'
import { useSelector } from 'react-redux'

const AjMain:React.FC = () => {

  const dispatch=useDispatch<AppDispatch>();

  const [clickedIndex,setClickedIndex]=useState(0);

  const allPostedJobs=useSelector((state:RootState)=>state.allUserLogin.allViewersLoginData);

  
  useEffect(()=>{
    dispatch(getAllPostedJobs({industry:"",location:""}));
  },[dispatch])

  console.log(allPostedJobs);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={3} marginBottom={"10px"}>
        <AjHeader clickedIndex={clickedIndex} setClickedIndex={setClickedIndex} allPostedJobs={allPostedJobs}/>
        <AjBody  allPostedJobs={allPostedJobs} clickedIndex={clickedIndex} setClickedIndex={setClickedIndex}/>
    </Box>
  )
}

export default AjMain