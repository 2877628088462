import React from 'react';
import { Box, Button, Typography, Grid, Paper } from '@mui/material';
import Person from '../../../assets/images/Person.svg';
import RequestIcon from '../../../assets/images/requestIcon.svg';
import ApprovalIcon from '../../../assets/images/approvalIcon.svg';
import ClosedIcon from '../../../assets/images/closedIcon.svg';
import { useNavigate } from 'react-router-dom';
import PieChartBoard from './PieChartBoard';

const BoardDash: React.FC = () => {
    const name = "Ram Kumar";
    const navigate = useNavigate();
    const maxBarValue = 35;

    const handleButtonClick = () => {
        navigate('/newrthd');
    };

    const barsData = [
        { label: 'OPEN', value: 5 },
        { label: 'MOVE TO JOB', value: 10 },
        { label: 'CREATE JD', value: 15 },
        { label: 'JOB POST', value: 5 },
        { label: 'UPLOADING RESUMES', value: 10 },
        { label: 'HIRING PROCESS', value: 15 },
        { label: 'CLOSED', value: 5 },
    ];

    return (
        <>
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Paper
                            sx={{ background: '#36BDC9', padding: '2px', borderRadius: '6px', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid item p={2}>
                                <Typography sx={{ fontWeight: '500', fontSize: '16px', letterSpacing: '1px' }} gutterBottom>
                                    Welcome, {name}!
                                </Typography>
                                <Typography sx={{ fontWeight: '100', fontSize: '10px', mt: '-6px' }}>
                                    You can request new hires for your team.
                                </Typography>
                            </Grid>
                            <Grid item sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                                <Button
                                    variant="contained"
                                    onClick={handleButtonClick}
                                    sx={{
                                        backgroundColor: '#fff',
                                        color: '#36CFC9',
                                        textTransform: 'none',
                                        borderRadius: '6px',
                                        '&:hover': {
                                            backgroundColor: '#fff',
                                            color: '#36CFC9',
                                        },
                                        padding: '6px 4px',
                                        fontSize: '12px',
                                        minWidth: '180px',
                                    }}
                                >
                                    New Request to Hire (RTH)
                                </Button>
                            </Grid>
                            <Grid item>
                                <img src={Person} alt="Person Icon" style={{ maxWidth: '130px' }} />
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Paper
                            sx={{
                                padding: '20px', borderRadius: '10px', boxShadow: 'none', display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative', backgroundColor: '#FFFFFF', overflow: 'hidden',
                                '::before': { content: '""', position: 'absolute', top: '-20px', right: '-20px', width: '100px', height: '100px', backgroundColor: '#1677FF1A', borderRadius: '50%', zIndex: 1 },
                                '::after': { content: '""', position: 'absolute', top: '-55px', right: '-55px', width: '150px', height: '150px', backgroundColor: '#1677FF0A', borderRadius: '50%', zIndex: 0, }
                            }}
                        >
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="h5" sx={{ fontWeight: 600, color: '#1D1D1F', zIndex: 2, marginBottom: '4px' }}>
                                        12
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#6B7280', zIndex: 2, fontWeight: '400', fontSize: '10px' }}>
                                        Total Request
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', zIndex: 2 }}>
                                    <img
                                        src={RequestIcon}
                                        alt="Request Icon"
                                        style={{ width: '30px', height: '30px', color: '#2F54EB', marginTop: '-25px' }}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Paper
                            sx={{
                                padding: '20px', borderRadius: '10px', boxShadow: 'none', display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative', backgroundColor: '#FFFFFF', overflow: 'hidden',
                                '::before': { content: '""', position: 'absolute', top: '-20px', right: '-20px', width: '100px', height: '100px', backgroundColor: '#1677FF1A', borderRadius: '50%', zIndex: 1 },
                                '::after': { content: '""', position: 'absolute', top: '-55px', right: '-55px', width: '150px', height: '150px', backgroundColor: '#1677FF0A', borderRadius: '50%', zIndex: 0, }
                            }}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={7}>
                                    <Typography variant="h5" sx={{ fontWeight: 600, color: '#1D1D1F', zIndex: 2, marginBottom: '4px' }}>
                                        12
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#6B7280', zIndex: 2, fontWeight: '400', fontSize: '10px' }}>
                                        Total Approval
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <img
                                        src={ApprovalIcon}
                                        alt="Approval Icon"
                                        style={{ width: '30px', height: '30px', color: '#2F54EB', marginTop: '-25px' }}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Paper
                            sx={{
                                padding: '20px', borderRadius: '10px', boxShadow: 'none', display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative', backgroundColor: '#FFFFFF', overflow: 'hidden',
                                '::before': { content: '""', position: 'absolute', top: '-20px', right: '-20px', width: '100px', height: '100px', backgroundColor: '#1677FF1A', borderRadius: '50%', zIndex: 1 },
                                '::after': { content: '""', position: 'absolute', top: '-55px', right: '-55px', width: '150px', height: '150px', backgroundColor: '#1677FF0A', borderRadius: '50%', zIndex: 0, }
                            }}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="h5" sx={{ fontWeight: 600, color: '#1D1D1F', zIndex: 2, marginBottom: '4px' }}>
                                        12
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#6B7280', zIndex: 2, fontWeight: '400', fontSize: '10px' }}>
                                        Total Closed
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <img
                                        src={ClosedIcon}
                                        alt="Closed Icon"
                                        style={{ width: '30px', height: '30px', color: '#2F54EB', marginTop: '-25px' }}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            <Box ml={2} p={2}>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={6} sx={{ borderRadius: '12px', backgroundColor: '#FFFFFF' }}>
                        <Grid display='flex' justifyContent="space-between" alignItems="center">
                            <Typography sx={{ fontWeight: '600', fontSize: '12px' }}>
                                In Progress
                            </Typography>
                            <Typography variant="body2" sx={{ color: '#6B7280', backgroundColor: '#FAFAFA', padding: '4px 14px', borderRadius: '20px', fontSize: '10px', marginRight: '16px' }}>
                                Total Number of RTH: <span style={{ fontWeight: 'bold' }}>38</span>
                            </Typography>
                        </Grid>
                        <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '16px' }}>
                            <Grid item xs={1} display="flex" flexDirection="column" alignItems="flex-end" sx={{ position: 'relative', paddingRight: '24px', marginBottom: '24px' }}>
                                <Typography sx={{ position: 'absolute', top: '0%', fontSize: '10px' }}>35</Typography>
                                <Typography sx={{ position: 'absolute', top: '14%', fontSize: '10px' }}>30</Typography>
                                <Typography sx={{ position: 'absolute', top: '29%', fontSize: '10px' }}>25</Typography>
                                <Typography sx={{ position: 'absolute', top: '43%', fontSize: '10px' }}>20</Typography>
                                <Typography sx={{ position: 'absolute', top: '57%', fontSize: '10px' }}>15</Typography>
                                <Typography sx={{ position: 'absolute', top: '71%', fontSize: '10px' }}>10</Typography>
                                <Typography sx={{ position: 'absolute', top: '86%', fontSize: '10px' }}>05</Typography>
                            </Grid>
                            {barsData.map((bar, index) => (
                                <Grid item xs={1.5} key={index} display="flex" flexDirection="column" alignItems="center">
                                    <Box sx={{ height: '120px', position: 'relative', alignItems: 'flex-end' }}>
                                        <Box
                                            sx={{
                                                width: '6px',
                                                height: `${(bar.value / maxBarValue) * 120}px`,
                                                backgroundColor: '#2F54EB',
                                                borderRadius: '2px 2px',
                                                position: 'absolute',
                                                bottom: 0,
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                width: '6px',
                                                height: `${(maxBarValue - bar.value) / maxBarValue * 120}px`,
                                                backgroundColor: '#E5E5EF',
                                                borderRadius: '2px 2px',
                                                position: 'absolute',
                                                bottom: `${(bar.value / maxBarValue) * 120 + 1}px`
                                                ,
                                            }}
                                        />
                                    </Box>
                                    <Typography sx={{ color: '#6B7280', textAlign: 'center', fontSize: '8px', marginTop: '24px' }}>
                                        {bar.label}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingTop: '0px !important' }}>
                        <PieChartBoard />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default BoardDash;
