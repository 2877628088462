import React, { useState } from 'react';
import { Box, Typography, Stepper, Step, StepLabel, Button, Breadcrumbs, Link } from '@mui/material';
import InterviewEvaluationFormLogo from "../../../../assets/images/InterviewEvaluationFormLogo.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BulkUploadForm from './forms';
import AdditionalInformation from './AdditionalInformation';
import Address from './AddressForm';
import Experience from './ExperienceForm';
import Education from './EducationForm';
import Review from './ReviewForm';
import backgroundImage from '../../../../assets/images/feedbackBacgroundImage.svg';
import UploadResume from './UploadResume';
import { useAppDispatch } from '../../../../redux/hooks';
import { addCandidate } from '../../../../redux/features/resume';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
 
 
const steps = ['Upload Resume', 'Personal Information', 'Additional Information', 'Address', 'Experience', 'Education', 'Review'];
 
const CustomStepIcon = styled('div')<{ active?: boolean; completed?: boolean }>(
  ({ theme, active, completed }) => ({
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: active
      ? 'linear-gradient(135deg, #36CFC9 0%, #2F54EB 100%)' // Gradient for active step
      : completed
        ? 'linear-gradient(135deg, #36CFC9 0%, #2F54EB 100%)' // Same gradient for completed
        : 'transparent',
    border: completed || active
      ? 'none'
      : `2px solid ${theme.palette.grey[400]}`,
    color: active || completed ? '#fff' : theme.palette.grey[500], // White text for active/completed
    fontWeight: 500,
    fontSize: 16,
    transition: 'all 0.3s ease',
    position: 'relative',
    boxSizing: 'border-box',
    boxShadow: active || completed ? '0px 0px 6px rgba(0, 0, 0, 0.2)' : 'none',
 
    '& svg': {
      fontSize: 20,
      display: completed ? 'block' : 'none', // Tick icon for completed step
      padding: 0, // Remove extra padding
      margin: 0,
      // color:'#fff',
    },
 
  })
 
);
 
 
const CustomStepIconComponent = (props: { active?: boolean; completed?: boolean; icon: React.ReactNode }) => {
  const { active, completed, icon } = props;
  
  return (
    <CustomStepIcon active={active} completed={completed}>
      {completed ? <CheckCircleIcon /> : icon}
    </CustomStepIcon>
  );
};
 
export const CandidateFormsStepper = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [formValues, setFormValues] = useState<any>({});
  const[selectedFile,setSelectedFile]=useState<File[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [aadharError,setAadharError]=useState<string | null>(null);
  const [aadharFile,setAadharFile]=useState<File[]>([]);
  const dispatch=useAppDispatch();
  const {id}=useParams();
 
  const handleNext = () => {
    // const isValid = validateForm();
    // if(isValid){
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      handleSubmit();
    }
  // }
  };
 
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
 
  const handleSubmit=async()=>{
   
    const payload={
      ...formValues,
      rthId:id,
      workExperience:formValues.workExperience==='Yes',
      status:'Completed',
      candidateType:'Uploaded'
    }
    const resultAction=await dispatch(addCandidate(payload));
    if (addCandidate.fulfilled.match(resultAction)) {
      const { status } = resultAction.payload || {};
      if(status===true){
        setFormValues([]);
        setAadharFile([]);
        toast.success('Candidate form submitted successfully');
      }
      else if(status===400){
        toast.error('Check the data')
      }
    } else {
      toast.error('Failed to submit candidate form');
    } 
    setActiveStep(0);
  }

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formValues.howDidYouHeardAbtUs) {
      newErrors.howDidYouHeardAbtUs = 'This is required';
    }
    const namePattern = /^[A-Za-z]+$/;
  
  if (!formValues.firstName) {
    newErrors.firstName = 'First Name is required';
  } else if (formValues.firstName.length > 100) {
    newErrors.firstName = 'First Name cannot exceed 100 characters';
  } else if (!namePattern.test(formValues.firstName)) {
    newErrors.firstName = 'First Name should contain only alphabets';
  }
  
  if (!formValues.lastName) {
    newErrors.lastName = 'Last Name is required';
  } else if (formValues.lastName.length > 100) {
    newErrors.lastName = 'Last Name cannot exceed 100 characters';
  } else if (!namePattern.test(formValues.lastName)) {
    newErrors.lastName = 'Last Name should contain only alphabets';
  }
  
    
    if (!formValues.expectedCtc) {
      newErrors.expectedCtc = 'Expected CTC is required';
    } else if (!/^\d{1,10}$/.test(formValues.expectedCtc)) {
      newErrors.expectedCtc = 'Expected CTC must be a numeric value up to 10 digits';
    }
    
    if (!formValues.noticePeriods) {
      newErrors.noticePeriods = 'Required field';
    } else if (!/^\d{1,3}$/.test(formValues.noticePeriods)) {
      newErrors.noticePeriods = 'Notice period must be a numeric value up to 3 digits';
    }
    
    if (!formValues.currentCtc) {
      newErrors.currentCtc = 'Current CTC is required';
    } else if (!/^\d{1,10}$/.test(formValues.currentCtc)) {
      newErrors.currentCtc = 'Current CTC must be a numeric value up to 10 digits';
    }
     
    if (!formValues.emailAddress) {
      newErrors.emailAddress = 'Email Address is required';
    } else if (!/\S+@\S+\.\S+/.test(formValues.emailAddress)) {
      newErrors.emailAddress = 'Email Address is invalid';
    }
    if (!formValues.phoneNumber) {
      newErrors.phoneNumber = 'Phone Number is required';
    } else if (!/^\d{10}$/.test(formValues.phoneNumber)) {
      newErrors.phoneNumber = 'Phone Number must be 10 digits';
    }
  
    if (!formValues.aadharNumber) {
      newErrors.aadharNumber = 'Aadhar Number is required';
    } else if (!/^\d{12}$/.test(formValues.aadharNumber)) {
      newErrors.aadharNumber = 'Aadhar Number must be 12 digits';
    }
    if (!formValues.dateOfBirth) {
      newErrors.dateOfBirth = 'Date of Birth is required';
    }
    if (!formValues.gender) {
      newErrors.gender = 'Gender is required';
    }
    if(aadharFile.length <=0){
      setAadharError('Aadhar card is required');
    }
  
    setErrors(newErrors);
  
    return Object.keys(newErrors).length === 0;
  };

  const stepComponents = [
    <UploadResume selectedFile={selectedFile} setSelectedFile={setSelectedFile} handleNext={handleNext} formValues={formValues} setFormValues={setFormValues}/>,
    <BulkUploadForm formValues={formValues} setFormValues={setFormValues} handleNextPage={handleNext} handleBack={handleBack} validateForm={validateForm} 
    errors={errors} aadharError={aadharError} setAadharFile={setAadharFile} aadharFile={aadharFile} setErrors={setErrors} setAadharError={setAadharError} />,
    <AdditionalInformation formValues={formValues} setFormValues={setFormValues} errors={errors} setErrors={setErrors} />,
    <Address formValues={formValues} setFormValues={setFormValues} errors={errors} setErrors={setErrors}/>,
    <Experience formValues={formValues} setFormValues={setFormValues} errors={errors} setErrors={setErrors} />,
    <Education formValues={formValues} setFormValues={setFormValues} errors={errors} setErrors={setErrors} />,
    <Review formValues={formValues} setFormValues={setFormValues} selectedFile={formValues} />,
  ];

 
  return (
    <Box sx={{ backgroundColor: 'white', height: '100vh' }}>
      {/* Header */}
      <Box sx={{ background: 'linear-gradient(90deg, #36CFC9 0%, #2F54EB 100%)', p: 2 ,height:'40px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={InterviewEvaluationFormLogo}
            alt="Theron logo"
            style={{ width: '50px', height: '50px', marginRight: '10px' }}
          />
          <Typography sx={{ color: 'white', fontWeight: 500, fontSize: '20px' }}>
            Theron-Cavin Infotech
          </Typography>
        </Box>
      </Box>
 
      {/* Job Position */}
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'contain',
          backgroundPosition: 'right',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'white',
          width: '100%',
          height: '80vh',
        }}
      >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1,mt:'20px',ml:'20px' }}>
      {/* Back Button and Title */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        {/* <IconButton onClick={() => console.log('Back button clicked')}> */}
          <ArrowBackIcon />
        {/* </IconButton> */}
        <Typography sx={{ml:'50px',fontSize:'22px'}} fontWeight="bold">
          Software Developer
        </Typography>
      </Box>
 
      {/* Breadcrumbs */}
      <Box sx={{ml:'90px'}}>
      <Breadcrumbs separator="•" aria-label="breadcrumb">
        <Link underline="hover" color="text.primary" href="/">
          Home
        </Link>
        <Link underline="hover" color="text.primary" href="/job-listing">
          Job Listing
        </Link>
        <Link underline="hover" color="text.primary" href="/job-details">
          Job Details
        </Link>
        <Typography color="inherit">Software Developer</Typography>
      </Breadcrumbs>
      </Box>
    </Box>
 
 
        {/* Stepper */}
        <Box sx={{ mt: '20px', ml: '80px', width: '80vw' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={CustomStepIconComponent}
                  sx={{ '& .MuiStepLabel-label': { fontWeight: activeStep === steps.indexOf(label) ? 'bold' : 'normal' } }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
 
 
        <Box sx={{ ml: '90px',overflowY:'scroll',height:'60vh'}}>
          {/* <Paper sx={{overflowY:'scroll',height:'30vh'}}> */}
          {stepComponents[activeStep]}
          {/* </Paper> */}
        </Box>
 
        {activeStep !== 0 && activeStep !== 1 && ( // Conditionally render the box
        <Box
          sx={{
            position: 'absolute',
            bottom: '50px',
            left: '120px',
            display: 'flex',
            gap: 2,
          }}
        >
          {activeStep !== 0 && (
            <Button
              onClick={handleBack}
              sx={{border:'InactiveBorder',color:'#848484',textTransform:'none'}}
            >
              Previous
            </Button>
          )}
 
          <Button
            variant="contained"
            onClick={handleNext}
            sx={{
              minWidth: '100px',
              textTransform:'none',
              backgroundColor:'#1677FF',
              color:'#fff',
            }}
          >
            {activeStep === steps.length - 1 ? 'Submit Application' : 'Continue'}
          </Button>
        </Box>
        )}
      </Box>
    </Box>
  );
};
 
