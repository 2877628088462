import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    wtMJobStatus:false,
    jdEditStatus:false
};

export const wtMJobSlice = createSlice({
  name: "wtMJob",
  initialState,
  reducers: {
    wtMJobGetData: (state, action) => {
      state.wtMJobStatus = action.payload;
    },
    jdEditPageRefresh: (state, action) => {
      state.jdEditStatus = action.payload;
    },
  },
});

export const { wtMJobGetData ,jdEditPageRefresh} = wtMJobSlice.actions;
export default wtMJobSlice.reducer;
