import React, { useEffect, useState } from 'react';
import { Button, Grid, Box, Typography, IconButton } from '@mui/material';
import CommonTextField from '../../../common/CommonTextField';
import { useLocation } from 'react-router-dom';
import DeleteIcon from '../../../../assets/images/del_Icon.svg';

const predefinedLabels = [
  'Highest Education',
  'Postgraduate Degree',
  'Undergraduate Degree',
  'Diploma/Certification',
  'Secondary Education',
  'Other Education/Training',
];
 
const Education = ({ formValues, setFormValues,errors,setErrors }: any) => {
  // const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [educationLabelsUsed, setEducationLabelsUsed] = useState<number>(1);
  const location=useLocation();
  const path=location.pathname.includes("candidateForm");
 
 
  // Handle closing the dialog
 
  // Handle the input change for the current form values (including education entries)
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index?: number) => {
    const { name, value } = event.target;
    if (typeof index === 'number') {
      setErrors((prevErrors: any) => {
        const updatedErrors = [...(prevErrors.educations || [])];
        updatedErrors[index] = updatedErrors[index] || {};
  
        // Validation: Check for max length (64 characters)
        if (value.length > 64) {
          updatedErrors[index][name] = `Maximum of 64 characters allowed for ${name}`;
        } else {
          updatedErrors[index][name] = ''; // Clear the error if input is valid
        }
  
        return { ...prevErrors, educations: updatedErrors };
      });
  
      const updatedEducation = [...formValues.educations];
      updatedEducation[index][name] = value;
      setFormValues({
        ...formValues,
        educations: updatedEducation,
      });
      
    } else {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: value.length > 64 ? `Maximum of 64 characters allowed for ${name}` : '',
      }));

      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };
 
  // Add a new education entry with the label from the dialog
  const handleAddEducation = () => {
    if (educationLabelsUsed < predefinedLabels.length) {
      const newEducation = {
        university: '',
        degree: '',
        fieldOfStudy: '',
        from: '',
        to: '',
        eduLabel: predefinedLabels[educationLabelsUsed],
      };
      setFormValues({
        ...formValues,
        educations: [...(formValues.educations || []), newEducation],
      });
      setErrors((prevErrors: any) => {
        const updatedErrors = [...(prevErrors.educations || []), {}];
        return { ...prevErrors, educations: updatedErrors };
      });
      setEducationLabelsUsed((prev) => prev + 1);
    }
  };

  const handleDeleteEducation = (index: number) => {
    const updatedEducation = [...formValues.educations];
    updatedEducation.splice(index, 1);
    setFormValues({ ...formValues, educations: updatedEducation });
  };
 
  // Helper function to convert a number to Roman numerals
const toRoman = (num: number) => {
  const romanNumerals: [number, string][] = [
    [1000, 'M'], [900, 'CM'], [500, 'D'], [400, 'CD'],
    [100, 'C'], [90, 'XC'], [50, 'L'], [40, 'XL'],
    [10, 'X'], [9, 'IX'], [5, 'V'], [4, 'IV'],
    [1, 'I'],
  ];
 
  let result = '';
  for (let [value, symbol] of romanNumerals) {
    while (num >= value) {
      result += symbol;
      num -= value;
    }
  }
  return result;
};
 
  useEffect(()=>{
    if (!Array.isArray(formValues.educations) || formValues.educations.length === 0) {
      setFormValues((prevFormData:any) => ({
        ...prevFormData,
          educations: [{
          university: '',
          degree: '',
          fieldOfStudy: '',
          from: '',
          to: '',
          eduLabel: predefinedLabels[0],
        }]
      }));
    }
  },[formValues,setFormValues])
 
 
  return (
    <Box sx={{ pt: '4px',ml:path?'30px':'' }}>
      {/* <Paper elevation={0} sx={{ overflowY: 'scroll', pt: '10px', position: 'relative', height: '62vh' }}> */}
        <Typography sx={{ fontSize: '22px', fontWeight: '500', mb: '27px',mt:'14px' }}>
        {path ? '6.Education' : 'Education'}
          </Typography>
 
        {/* Loop through education entries in formValues */}
        {(formValues.educations || []).map((entry: any, index: number) => (
          <Box key={index} sx={{ width: '50vw', mb: 2 }}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography sx={{ mt: '10px', fontSize: '16px', fontWeight: 'bold' }}>
            {index === 0
            ? `${toRoman(index + 1)}) Highest Education`
        : `${toRoman(index + 1)}) ${entry.eduLabel || `Education ${index + 1}`}`}
            </Typography>
            {index > 0 && (
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteEducation(index)}
                size="small"
                sx={{
                  ml: 1,
                  color: 'inherit',
                  '&:hover': {
                    color: 'red',
                  },
                }}
              >
                <img src={DeleteIcon} alt="Delete Icon" style={{ width: '16px', height: '16px' }} />
              </IconButton>
            )}
           </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '17px', mt: '10px' }}>University</Typography>
                <CommonTextField
                  label=""
                  name="university"
                  value={entry.university}
                  onChange={(event) => handleInputChange(event, index)}
                  error={!!errors?.educations?.[index]?.university} // Check error for specific index
                  helperText={errors?.educations?.[index]?.university} // Display error for specific index
                />
              </Grid>
 
              <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '17px', mt: '10px' }}>Degree</Typography>
                <CommonTextField
                  label=""
                  name="degree"
                  value={entry.degree}
                  onChange={(event) => handleInputChange(event, index)}
                  error={!!errors?.educations?.[index]?.degree} // Check error for specific index
                  helperText={errors?.educations?.[index]?.degree} // Display error for specific index
                />
              </Grid>
 
              <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '17px', mt: '10px' }}>Field of Study</Typography>
                <CommonTextField
                  label=""
                  name="fieldOfStudy"
                  value={entry.fieldOfStudy}
                  onChange={(event) => handleInputChange(event, index)}
                  error={!!errors?.educations?.[index]?.fieldOfStudy} // Check error for specific index
                  helperText={errors?.educations?.[index]?.fieldOfStudy} // Display error for specific index
                />
              </Grid>
 
              <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '17px' }}>From</Typography>
                <CommonTextField
                  label=""
                  type="date"
                  name="from"
                  value={entry.from}
                  onChange={(event) => handleInputChange(event, index)}
                  // error={!!errors.from}
                  // helperText={errors.from}
                />
              </Grid>
 
              <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '17px' }}>To</Typography>
                <CommonTextField
                  label=""
                  type="date"
                  name="to"
                  value={entry.to}
                  onChange={(event) => handleInputChange(event, index)}
                  // error={!!errors.to}
                  // helperText={errors.to}
                />
              </Grid>
            </Grid>
          </Box>
        ))}
        {educationLabelsUsed < predefinedLabels.length && (
        <Box sx={{ mt: '8px' }}>
          <Button onClick={handleAddEducation}>+ Add New Education</Button>
        </Box>
        )}
      {/* </Paper> */}
 
      {/* Dialog for Adding Education */}
      
    </Box>
  );
};
 
export default Education;
 