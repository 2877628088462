import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchJobById } from "../../../redux/features/jobcardSlice";
import Headergpt from "./headergpt";
import InternalOnRoleDetails from "./internalonroledetails";
import heplLogo from "../../../assets/images/heplLogo.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// import CavinKare from "../../../assets/images/cavinKare.png";
import Indeed from "../../../assets/images/indeed.svg";
import Naukri from "../../../assets/images/naukri.svg";
import LinkedIn from "../../../assets/images/linkedin.svg";
import CITPL from "../../../assets/images/citplLogo.svg";
import Pencil from "../../../assets/images/pencilIcon.svg";
import ReactQuill from "react-quill";
import { TransitionProps } from "@mui/material/transitions";
import { getJdEditAPIData, getJobPostedInAPIData } from "../../../Crud/card";
import SwitchWithLabel from "../../common/SwitchWithLabel";
import { toast } from "react-toastify";
import CopyUrlBox from "../../common/CopyUrlBox";
import InterviewRounds from "../../CustomComponents/InterviewRounds";
import OverallDetail from "../../card/Jobcard/overallrth/OverallDetail";
// import AssignRecruiterView from "./AssignRecruiterView";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UxDesign: React.FC = () => {
  const location = useLocation();
  const { jobData } = useAppSelector((state) => state.jobs);
  const [switchStates, setSwitchStates] = useState({
    indeed: false,
    naukri: false,
    linkedIn: false,
  });

  const { id, status, label } = location.state || {};
  const [openJdModel, setOpenJDModel] = useState(false);
  const [jdValue, setJdValue] = useState("");
  const [value, setValue] = useState("");
  console.log("switchStates", switchStates);
  console.log("switchStates", switchStates);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  console.log("status", status);
  console.log("labelllllllllllll", label);
  console.log("Complete location state:", location.state);
  

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  console.log("jobData===>", jobData);
  useEffect(() => {
    if (id) {
      dispatch(fetchJobById(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (jobData?.postedIn) {
      setSwitchStates((prevState) => {
        const updatedStates = Object.keys(prevState).reduce((acc, key) => {
          acc[key] = jobData.postedIn.includes(key);
          return acc;
        }, {} as Record<string, boolean>);
        
        return {
          ...prevState,
          ...updatedStates,
        };
      });
    }
  }, [jobData?.postedIn]);
  const processSteps = [
    "Open",
    "Move to Job",
    "Create JD",
    "Job Post",
    "Uploading Resumes",
    "Hiring Process",
    "Closed",
  ];
  const approvalStatus = { l1: "Approved", l2: "Pending" };

  console.log("jobData", jobData);
  // const htmlSnippet = `
  // <h1 class="text-muted text-center logwith">
  //   <strong class="line">Thameem</strong>
  //   <span>or Login with</span>
  //   <div class="line"></div>
  // </h1>
  // <h3>hello</h3>
  // `;
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ align: [] }],
      ["blockquote", "code-block"],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };
  const formats = [
    "header",
    "font",
    "size",
    "align",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "background",
    "script",
  ];
  const handleJDEdit = (data: any) => {
    console.log("handleJDEdit", data);
    setOpenJDModel(false);
    const datas = {
      jdText: value,
    };
    getJdEditAPIData(data?.rthId, datas);
    setJdValue(value);
    setValue(jdValue);
  };
  const jdData = jdValue || jobData?.jdTemplateText;

  const handleSwitchChange =
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setSwitchStates((prevStates) => ({
        ...prevStates,
        [key]: event.target.checked,
      }));
    };
  const handleSubmit = async () => {
    await getJobPostedInAPIData(jobData?.rthId, switchStates)
      .then(() => toast.success("Posted job sites successfully"))
      .catch(() => toast.error("Posted job sites failed"));
  };
  // console.warn('jjjj',Object.values(switchStates).every((value)=>value))
  // const disabledButton = Object.values(switchStates).every((value)=>value)
  return (
    <Box sx={{ p: 3 }}>
      <Box display={"flex"}>
        <IconButton onClick={handleBackButtonClick}>
          <KeyboardBackspaceIcon />
        </IconButton>
        <Box
          sx={{
            backgroundColor: "white",
            width: "fit-content",
            borderRadius: "5%",
          }}
          p={0.5}
        >
          <img
            src={
              jobData?.business?.businessName === "HEPL"
                ? heplLogo
                : jobData?.business?.businessName === "HEPL"
                ? CITPL
                : heplLogo
            }
            alt=""
          />
        </Box>
      </Box>

      <Headergpt
        l1={jobData?.l1Status}
        l2={jobData?.l2Status}
        recruiterAssigned={jobData?.recruiterAssigned}
        positionTitle={jobData?.positionTitle}
        status={jobData?.status}
        numOfPosition={jobData?.numOfPosition}
        Steps={processSteps}
        data={jobData}
        approvalStatus={approvalStatus}
        numOfOpenPosition={jobData?.numOfOpenPosition}
      />
      {status && (
        <>
          <InterviewRounds />
          <Box
            sx={{
              p: 3,
              backgroundColor: "background.default",
              borderRadius: 2,
              ml: "16px",
              mr: "16px",
              mb: "20px",
              position: "relative",
            }}
          >
            
            <div
              dangerouslySetInnerHTML={{
                __html: jdData?.replace(/class=/g, "className="),
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 10,
                right: 15,
                cursor: "pointer",
              }}
              onClick={() => {
                setOpenJDModel(true);
                setValue(jdValue || jobData?.jdTemplateText);
              }}
            >
              <img src={Pencil} alt="Pencil icon edit" width={15} />
            </Box>
          </Box>
          <Dialog
            open={openJdModel}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenJDModel(false)}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>Edit Job Description</DialogTitle>
            <DialogContent>
              <ReactQuill
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                  outline: "none",
                  border: "none",
                }}
                value={value}
                onChange={setValue}
                modules={modules}
                formats={formats}
                placeholder="Write something..."
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenJDModel(false)}>Closed</Button>
              <Button onClick={() => handleJDEdit(jobData)}>Update</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      <InternalOnRoleDetails jobData={jobData} />
      {label === "overall RTH" &&
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 1, paddingRight: "20px" }}> 
        <OverallDetail job={jobData}/>
        </Box> }
      {status && (
        <Box
          sx={{
            p: 3,
            backgroundColor: "background.default",
            borderRadius: 2,
            ml: "16px",
            mr: "16px",
            my: "20px",
            position: "relative",
          }}
        >
          <Typography sx={{ color: "#8C8C8C", fontSize: "14px" }}>
            Job Post{" "}
          </Typography>
          <Box my={1} width={"33%"}>
            <CopyUrlBox id={jobData?.rthId} />
          </Box>
          <SwitchWithLabel
            label="Indeed"
            imageSrc={Indeed}
            checked={switchStates.indeed}
            onChange={handleSwitchChange("indeed")}
          />
          <SwitchWithLabel
            label="Naukri"
            imageSrc={Naukri}
            checked={switchStates.naukri}
            onChange={handleSwitchChange("naukri")}
          />
          <SwitchWithLabel
            label="LinkedIn"
            imageSrc={LinkedIn}
            checked={switchStates.linkedIn}
            onChange={handleSwitchChange("linkedIn")}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "auto", fontSize: "10px", marginTop: "10px" }}
            onClick={handleSubmit}
            // disabled={disabledButton}
          >
            Submit
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default UxDesign;
