import React, { useEffect, useState } from "react";
import {
  FormControl,
  Checkbox,
  Typography,
  Button,
  Box,
  Divider,
  Collapse,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput,
  ListItemText,
  Chip,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import NavigateNext from "@mui/icons-material/NavigateNext";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import { styled } from "@mui/system";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { getRthBusinessInputData } from "../../../redux/features/rthSlice";
import { getPositionTitle, getRthLocationData } from "../../../Crud/rth";
import { useDispatch } from "react-redux";
import { setFilterState } from "../../../redux/features/teamHeadFilterSlice";
import { AppDispatch } from "../../../redux/store";
import { useAppSelector } from "../../../redux/hooks";

const SmallCheckbox = styled(Checkbox)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    fontSize: 12,
  },
}));

const CustomSmallFormControlLabel = (props: {
  label: string;
  number?: string;
  isChecked?: boolean;
  onChange?: (e: any) => void;
}) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    }}
  >
    <SmallCheckbox
      checked={props.isChecked}
      sx={{ paddingLeft: "15px", borderColor: "#D9D9D9" }}
      onChange={props.onChange}
    />
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        marginLeft: "8px", // Optional spacing between checkbox and label
      }}
    >
      <Box sx={{ flexBasis: "70%" }}>
        <Typography sx={{ fontSize: "12px", paddingRight: "10px" }}>
          {props.label}
        </Typography>
      </Box>
      <Box sx={{ flexBasis: "30%" }}>
        <Typography
          sx={{
            fontSize: "12px",
            textAlign: "right",
            color: "#8C8C8C",
          }}
        >
          {props.number}
        </Typography>
      </Box>
    </Box>
  </Box>
);

const FilterSidebar = ({
  drawerOpen,
  onChangeFilter,
  tabName,
  lowCount,
  mediumCount,
  highCount,
  l1StatusPending,
  l1StatusDeclined,
  l2StatusPending,
  l2StatusApproved,  
}: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
  const toggleDrawer = () => onChangeFilter(!drawerOpen);
  const rthStagesData = [
    "Open",
    "Move to Job",
    "Create Job",
    "Job Post",
    "Resume",
    "Screening",
    "Interview",
    "Feedback",
  ];
  const [PosTitleNames, setPosTitleNames] = useState<string[]>([]);
  const jobSocialNames = ["Naukri", "Linkedin", "Indeed", "Website"];
  const [businessData, setBusinessData] = useState([]);  
  const [locationData, setLocationData] = useState([]);
  const [business, setBusinessName] = React.useState<string[]>([]);
  const [businessId, setBusinessId] = React.useState<string[]>([]);
  const [postedIn, setPostedIn] = React.useState<string[]>([]);
  const [rthStages, setRthStages] = React.useState<string[]>([]);
  const [positionTitle, setPositionTitle] = React.useState<string[]>([]);
  const [location, setLocation] = React.useState<string[]>([]);

  useEffect(() => {
    getRthBusinessInputData()
      .then((res) => {
        const data = res.data?.map((i: any) => {
          return { value: i.businessId, label: i.businessName };
        });
        setBusinessData(data);
      })
      .catch((err) => console.error(err));

    getRthLocationData()
      .then((res) => {
        const data = res.data?.map((i: any) => {
          return { value: i.locationId, label: i.locationName };
        });
        setLocationData(data);
        console.log("locatio", res);
      })
      .catch((err) => console.error(err));

    getPositionTitle()
      .then((res) => {
        setPosTitleNames(res); // Set the list of strings directly
        console.log("pos data", res);
      })
      .catch((err) => console.error(err));
  }, []);

  const handleBusinessChangee = (event: SelectChangeEvent<typeof business>) => {
    const {
      target: { value },
    } = event;

    // Parse the selected names
    const selectedBusinessNames =
      typeof value === "string" ? value.split(",") : value;

    // Find the corresponding IDs based on selected names
    const selectedBusinessIds = businessData
      .filter((item: any) => selectedBusinessNames.includes(item.label))
      .map((item: any) => item.value);

    setBusinessName(selectedBusinessNames);
    setBusinessId(selectedBusinessIds);

    // Dispatch with the selected IDs
    dispatch(
      setFilterState({
        business: selectedBusinessIds.length ? selectedBusinessIds : [],
      })
    );
  };

  const handlepostedInChangee = (event: SelectChangeEvent<typeof postedIn>) => {
    const {
      target: { value },
    } = event;
    setPostedIn(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    dispatch(setFilterState({}));
  };

  const handleRthStagesChangee = (
    event: SelectChangeEvent<typeof rthStages>
  ) => {
    const {
      target: { value },
    } = event;

    // Parse and set the selected RTH stages
    const selectedRthStages =
      typeof value === "string" ? value.split(",") : value;
    setRthStages(selectedRthStages);

    // Dispatch with an empty array if no data is present, otherwise with the selected RTH stages
    dispatch(
      setFilterState({
        rthStages: selectedRthStages.length ? selectedRthStages : [],
      })
    );
  };

  const handlePositionTitleChangee = (
    event: SelectChangeEvent<typeof positionTitle>
  ) => {
    const {
      target: { value },
    } = event;

    const selectedPositionTitle =
      typeof value === "string" ? value.split(",") : value;

    setPositionTitle(selectedPositionTitle);
    console.log("logic", selectedPositionTitle);
    dispatch(
      setFilterState({
        positionTitle: selectedPositionTitle.length
          ? selectedPositionTitle
          : [],
      })
    );
  };

  const handleLocationChangee = (event: SelectChangeEvent<typeof location>) => {
    const {
      target: { value },
    } = event;

    // Parse and set the selected location
    const selectedLocation =
      typeof value === "string" ? value.split(",") : value;
    setLocation(selectedLocation);

    // Dispatch with an empty array if no data is present, otherwise with the selected location
    dispatch(
      setFilterState({
        location: selectedLocation.length ? selectedLocation : [],
      })
    );
  };

  const handleDateChange = (value: [Date, Date]) => {
    // Format the dates to yyyy-mm-dd
    const fromDate = value[0].toISOString().slice(0, 10);
    const toDate = value[1].toISOString().slice(0, 10);

    // Dispatch the action to update Redux store with the selected date range
    dispatch(
      setFilterState({
        fromDate: fromDate,
        toDate: toDate,
      })
    );
  };

  const handleDateClean = () => {
    dispatch(
      setFilterState({
        fromDate: null,
        toDate: null,
      })
    );
  };

  const jobKind: string[] = [
    "Internal-Onrole",
    "Internal-Contract",
    "External-One Time",
    "External-Contract",
  ];
  
  return (
    <Box
      bgcolor={"white"}
      sx={{
        margin: "20px",
        borderRadius: "10px",
        marginRight: "0px",
        width: drawerOpen ? "216px" : "50px",
        height: drawerOpen ? "250px" : "0px",
        position: drawerOpen ? "relative" : "absolute",
        zIndex: 1000,
        top: "-10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          bgcolor: "white",
          padding: "10px",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
          margin: "2px",
          borderRadius: "10px",
        }}
      >
        <TuneIcon sx={{ fontSize: 20 }} />

        {drawerOpen && (
          <Typography variant="h6" sx={{ fontSize: 14 }}>
            Filter
          </Typography>
        )}

        <Box
          sx={{ cursor: "pointer", padding: "0px", backgroundColor: "#F6F6F6" }}
        >
          {drawerOpen ? (
            <NavigateBefore onClick={toggleDrawer} sx={{ fontSize: 14 }} />
          ) : (
            <NavigateNext onClick={toggleDrawer} sx={{ fontSize: 14 }} />
          )}
        </Box>
      </Box>
      {drawerOpen && <Divider />}

      <Collapse
        in={drawerOpen}
        timeout="auto"
        unmountOnExit
        orientation="horizontal"
      >
        <Box
          width={200}
          padding={1}
          bgcolor={"white"}
          sx={{
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            marginBottom: "30px",
            overflowX: "auto",
            maxHeight: "100%",
          }}
        >
          {(tabName === "My Requests" || tabName === "Overall RTH") && (
            <Box
              marginTop={2}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                L1 Status
              </Typography>
              <FormControl component="fieldset">
                <CustomSmallFormControlLabel
                  label="Pending"
                  number={l1StatusPending}
                  isChecked={teamHeadFilter.l1StatusPending}
                  onChange={() =>
                    dispatch(
                      setFilterState({
                        l1StatusPending: !teamHeadFilter.l1StatusPending,
                      })
                    )
                  }
                />
                <CustomSmallFormControlLabel
                  label="Declined"
                  number={l1StatusDeclined}
                  isChecked={teamHeadFilter.l1StatusDeclined}
                  onChange={() =>
                    dispatch(
                      setFilterState({
                        l1StatusDeclined: !teamHeadFilter.l1StatusDeclined,
                      })
                    )
                  }
                />
              </FormControl>
            </Box>
          )}

          {(tabName === "My Requests" || tabName === "Overall RTH") && (
            <Box
              marginTop={2}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                L2 Status
              </Typography>
              <FormControl component="fieldset">
                <CustomSmallFormControlLabel
                  label="Pending"
                  number={l2StatusPending}
                  isChecked={teamHeadFilter.l2StatusPending}
                  onChange={() =>
                    dispatch(
                      setFilterState({
                        l2StatusPending: !teamHeadFilter.l2StatusPending,
                      })
                    )
                  }
                />
                <CustomSmallFormControlLabel
                  label="Approved"
                  number={l2StatusApproved}
                  isChecked={teamHeadFilter.l2StatusApproved}
                  onChange={() =>
                    dispatch(
                      setFilterState({
                        l2StatusApproved: !teamHeadFilter.l2StatusApproved,
                      })
                    )
                  }
                />
              </FormControl>
            </Box>
          )}

          {tabName === "Job Post" && (
            <Box marginTop={2}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                  marginBottom: "10px",
                }}
              >
                Posted in
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Choose
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  size="small"
                  value={postedIn}
                  onChange={handlepostedInChangee}
                  input={<OutlinedInput label="Choose" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {jobSocialNames.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={postedIn.includes(name)} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}

          {(tabName === "In Progress" || tabName === "Assigned RTH") && (
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                  marginBottom: "5px", // Reduced margin for better compactness
                  textAlign: "left", // Align the label to the left
                }}
              >
                RTH Stages
              </Typography>
              <FormControl sx={{ width: "100%", padding: 0, margin: 0 }}>
                <InputLabel
                  id="demo-multiple-checkbox-label"
                  sx={{ fontSize: "12px", padding: "0"}} // Reduced font size of label
                >
                  Choose
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  size="small"
                  multiple
                  value={rthStages}
                  onChange={handleRthStagesChangee}
                  input={
                    <OutlinedInput label="Choose"/>
                  } 
                  renderValue={(selected) => {
                    const maxVisible = 1;
                    const visibleSelected = selected.slice(0, maxVisible);

                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {visibleSelected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            sx={{
                              fontSize: "10px",
                              height: "20px",
                            }}
                          />
                        ))}
                        {selected.length > maxVisible && (
                          <Chip
                            label={`+${selected.length - maxVisible}`}
                            sx={{
                              fontSize: "10px",
                              height: "20px",
                              backgroundColor: "#f0f0f0",
                              color: "#8C8C8C",
                            }}
                          />
                        )}
                      </Box>
                    );
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // Limit dropdown height
                        overflowY: "auto",
                        marginTop: 8, // Space between select and dropdown
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                  sx={{
                    ".MuiSelect-icon": {
                      top: "50%", // Vertically center the dropdown arrow
                      transform: "translateY(-50%)",
                    },
                  }}
                >
                  {rthStagesData.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      sx={{
                        fontSize: "10px", // Reduced font size
                        padding: "4px 6px", // Compact padding
                        gap: "10px",
                      }}
                    >
                      <Checkbox
                        checked={rthStages.includes(name)}
                        size="small"
                        sx={{
                          padding: "2px",
                          "& .MuiSvgIcon-root": { fontSize: "14px" }, // Smaller checkbox icon
                        }}
                      />
                      <ListItemText
                        primary={name}
                        sx={{
                          margin: 0,
                          fontSize: "10px", // Reduced text size
                        }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}

          {(tabName === "My Requests" ||
            tabName === "In Progress" ||
            tabName === "Closed" ||
            tabName === "Overall RTH" ||
            tabName === "Job Post" ||
            tabName === "Waiting to Move to Job" ||
            tabName === "Ready for JD Creation" ||
            tabName === "Resume" ||
            tabName === "To Be Assigned" ||
            tabName === "Assigned RTH" ||
            tabName === "MRF Hub") && (
            <Box marginTop={2}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                Job Kind
              </Typography>
              {jobKind.map((jobs) => (
                <Box
                  display="flex"
                  alignItems="center"
                  mt={1}
                  sx={{ paddingLeft: "15px", gap: "8px" }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      width: "auto",
                      fontSize: "8px",
                      height: "20px",
                      color:
                        (jobs === "Internal-Onrole" &&
                          teamHeadFilter.internalOnRole) ||
                        (jobs === "Internal-Contract" &&
                          teamHeadFilter.internalContract) ||
                        (jobs === "External-One Time" &&
                          teamHeadFilter.externalOneTime) ||
                        (jobs === "External-Contract" &&
                          teamHeadFilter.externalContract)
                          ? "blue"
                          : "grey",
                      border:
                        (jobs === "Internal-Onrole" &&
                          teamHeadFilter.internalOnRole) ||
                        (jobs === "Internal-Contract" &&
                          teamHeadFilter.internalContract) ||
                        (jobs === "External-One Time" &&
                          teamHeadFilter.externalOneTime) ||
                        (jobs === "External-Contract" &&
                          teamHeadFilter.externalContract)
                          ? "1px solid blue"
                          : "1px solid grey",
                      padding: "4px 8px",
                    }}
                    data-value={jobs}
                    onClick={(e) => {
                      const target = e.currentTarget as HTMLButtonElement;

                      if (target.dataset.value === "Internal-Onrole") {
                        dispatch(
                          setFilterState({
                            internalOnRole: !teamHeadFilter.internalOnRole,
                          })
                        );
                      }
                      if (target.dataset.value === "Internal-Contract") {
                        dispatch(
                          setFilterState({
                            internalContract: !teamHeadFilter.internalContract,
                          })
                        );
                      }
                      if (target.dataset.value === "External-One Time") {
                        dispatch(
                          setFilterState({
                            externalOneTime: !teamHeadFilter.externalOneTime,
                          })
                        );
                      }
                      if (target.dataset.value === "External-Contract") {
                        dispatch(
                          setFilterState({
                            externalContract: !teamHeadFilter.externalContract,
                          })
                        );
                      }
                    }}
                  >
                    {jobs}
                  </Button>
                </Box>
              ))}
            </Box>
          )}

          {(tabName === "My Requests" ||
            tabName === "In Progress" ||
            tabName === "Closed" ||
            tabName === "Overall RTH" ||
            tabName === "Job Post" ||
            tabName === "Waiting to Move to Job" ||
            tabName === "Ready for JD Creation" ||
            tabName === "Resume" ||
            tabName === "To Be Assigned" ||
            tabName === "Assigned RTH" ||
            tabName === "MRF Hub") && (
            <Box marginTop={2}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                Priority
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Typography sx={{ color: "#73D13D", fontSize: "10px" }}>
                  <CustomSmallFormControlLabel
                    label="Low"
                    isChecked={teamHeadFilter.priorityLow}
                    number={lowCount}
                    onChange={() =>
                      dispatch(
                        setFilterState({
                          priorityLow: !teamHeadFilter.priorityLow,
                        })
                      )
                    }
                  />
                </Typography>
                <Typography sx={{ color: "#FFA940", fontSize: "10px" }}>
                  <CustomSmallFormControlLabel
                    label="Medium"
                    number={mediumCount}
                    isChecked={teamHeadFilter.priorityMedium}
                    onChange={() =>
                      dispatch(
                        setFilterState({
                          priorityMedium: !teamHeadFilter.priorityMedium,
                        })
                      )
                    }
                  />
                </Typography>
                <Typography sx={{ color: "#FF4D4F", fontSize: "10px" }}>
                  <CustomSmallFormControlLabel
                    label="High"
                    isChecked={teamHeadFilter.priorityHigh}
                    number={highCount}
                    onChange={() =>
                      dispatch(
                        setFilterState({
                          priorityHigh: !teamHeadFilter.priorityHigh,
                        })
                      )
                    }
                  />
                </Typography>
              </Box>
            </Box>
          )}

          {(tabName === "My Requests" ||
            tabName === "In Progress" ||
            tabName === "Closed" ||
            tabName === "Overall RTH" ||
            tabName === "Job Post" ||
            tabName === "Waiting to Move to Job" ||
            tabName === "Ready for JD Creation" ||
            tabName === "Resume" ||
            tabName === "To Be Assigned" ||
            tabName === "Assigned RTH") && (
            <Box marginTop={2} alignItems="center">
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginRight: "8px",
                  paddingLeft: "10px",
                }}
              >
                RTH Posted Date
              </Typography>
              <Box>
                <DateRangePicker
                  showOneCalendar
                  placement="rightEnd"
                  onOk={handleDateChange}
                  onClean={handleDateClean}
                  style={{ width: "100%", left:"10px",maxWidth: "300px" }}
                  disabledDate={(date) => {                    
                    const today = new Date();
                    today.setHours(0, 0, 0, 0); 
                    return date > today;
                  }}
                />
              </Box>
            </Box>
          )}
          {(tabName === "Job Post" ||
            tabName === "Waiting to Move to Job" ||
            tabName === "Ready for JD Creation" ||
            tabName === "Resume" ||
            tabName === "Assigned RTH" ||
            tabName === "MRF Hub") && (
            <Box marginTop={2}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                  marginBottom: "10px",
                }}
              >
                Position Title
              </Typography>
              <FormControl sx={{ width: "100%", padding: 0, margin: 0 }}>
                <InputLabel
                  id="demo-multiple-checkbox-label"
                  sx={{ fontSize: "12px", padding: 0 }} // Reduced font size of label
                >
                  Choose
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  size="small"
                  multiple
                  value={positionTitle}
                  onChange={handlePositionTitleChangee}
                  input={
                    <OutlinedInput label="Choose" sx={{ padding: "2px 6px" }} />
                  } // Reduced padding for input field
                  renderValue={(selected) => {
                    const maxVisible = 1;
                    const visibleSelected = selected.slice(0, maxVisible);

                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {visibleSelected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            sx={{
                              fontSize: "10px",
                              height: "20px",
                            }}
                          />
                        ))}
                        {selected.length > maxVisible && (
                          <Chip
                            label={`+${selected.length - maxVisible}`}
                            sx={{
                              fontSize: "10px",
                              height: "20px",
                              backgroundColor: "#f0f0f0",
                              color: "#8C8C8C",
                            }}
                          />
                        )}
                      </Box>
                    );
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // Limit dropdown height
                        overflowY: "auto",
                        marginTop: 8, // Space between select and dropdown
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                  sx={{
                    ".MuiSelect-icon": {
                      top: "50%", // Vertically center the dropdown arrow
                      transform: "translateY(-50%)",
                    },
                  }}
                >
                  {PosTitleNames.map((name, index) => (
                    <MenuItem
                      key={index}
                      value={name}
                      sx={{
                        fontSize: "10px", // Reduced font size
                        padding: "4px 6px", // Compact padding
                        gap: "10px", // Gap between checkbox and label
                      }}
                    >
                      <Checkbox
                        checked={positionTitle.includes(name)}
                        size="small"
                        sx={{
                          padding: "2px",
                          "& .MuiSvgIcon-root": { fontSize: "14px" }, // Smaller checkbox icon
                        }}
                      />
                      <ListItemText
                        primary={name}
                        sx={{
                          margin: 0,
                          fontSize: "10px", // Reduced text size
                        }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          {(tabName === "Job Post" ||
            tabName === "Waiting to Move to Job" ||
            tabName === "Ready for JD Creation" ||
            tabName === "Resume" ||
            tabName === "Assigned RTH" ||
            tabName === "MRF Hub") && (
            <Box marginTop={2}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                  marginBottom: "10px",
                }}
              >
                Business
              </Typography>
              <FormControl sx={{ width: "100%", padding: 0, margin: 0 }}>
                <InputLabel
                  id="demo-multiple-checkbox-label"
                  sx={{ fontSize: "12px", padding: 0 }} // Reduced font size of label
                >
                  Choose
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  size="small"
                  multiple
                  value={business}
                  onChange={handleBusinessChangee}
                  input={
                    <OutlinedInput label="Choose" sx={{ padding: "2px 6px" }} />
                  } // Reduced padding for input field
                  renderValue={(selected) => {
                    const maxVisible = 1;
                    const visibleSelected = selected.slice(0, maxVisible);

                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {visibleSelected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            sx={{
                              fontSize: "10px",
                              height: "20px",
                            }}
                          />
                        ))}
                        {selected.length > maxVisible && (
                          <Chip
                            label={`+${selected.length - maxVisible}`}
                            sx={{
                              fontSize: "10px",
                              height: "20px",
                              backgroundColor: "#f0f0f0",
                              color: "#8C8C8C",
                            }}
                          />
                        )}
                      </Box>
                    );
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // Limit dropdown height
                        overflowY: "auto",
                        marginTop: 8, // Space between select and dropdown
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                  sx={{
                    ".MuiSelect-icon": {
                      top: "50%", // Vertically center the dropdown arrow
                      transform: "translateY(-50%)",
                    },
                  }}
                >
                  {businessData.map((name: any) => (
                    <MenuItem
                      key={name.value}
                      value={name.label}
                      sx={{
                        fontSize: "10px", // Reduced font size
                        padding: "4px 6px", // Compact padding
                        gap: "10px", // Gap between checkbox and label
                      }}
                    >
                      <Checkbox
                        checked={
                          business.includes(name.label) &&
                          businessId.includes(name.value)
                        }
                        size="small"
                        sx={{
                          padding: "2px",
                          "& .MuiSvgIcon-root": { fontSize: "14px" }, // Smaller checkbox icon
                        }}
                      />
                      <ListItemText
                        primary={name.label}
                        sx={{
                          margin: 0,
                          fontSize: "10px", // Reduced text size
                        }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}

          {(tabName === "Job Post" ||
            tabName === "Waiting to Move to Job" ||
            tabName === "Ready for JD Creation" ||
            tabName === "Resume" ||
            tabName === "Assigned RTH" || 
            tabName === "MRF Hub") && (
            <Box marginTop={2}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                  marginBottom: "10px",
                }}
              >
                Location
              </Typography>
              <FormControl sx={{ width: "100%", padding: 0, margin: 0 }}>
                <InputLabel
                  id="demo-multiple-checkbox-label"
                  sx={{ fontSize: "12px", padding: 0 }} // Reduced font size of label
                >
                  Choose
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  size="small"
                  multiple
                  value={location}
                  onChange={handleLocationChangee}
                  input={
                    <OutlinedInput label="Choose" sx={{ padding: "2px 6px" }} />
                  } // Reduced padding for input field
                  renderValue={(selected) => {
                    const maxVisible = 1;
                    const visibleSelected = selected.slice(0, maxVisible);

                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {visibleSelected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            sx={{
                              fontSize: "10px",
                              height: "20px",
                            }}
                          />
                        ))}
                        {selected.length > maxVisible && (
                          <Chip
                            label={`+${selected.length - maxVisible}`}
                            sx={{
                              fontSize: "10px",
                              height: "20px",
                              backgroundColor: "#f0f0f0",
                              color: "#8C8C8C",
                            }}
                          />
                        )}
                      </Box>
                    );
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // Limit dropdown height
                        overflowY: "auto",
                        marginTop: 8, // Space between select and dropdown
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                  sx={{
                    ".MuiSelect-icon": {
                      top: "50%", // Vertically center the dropdown arrow
                      transform: "translateY(-50%)",
                    },
                  }}
                >
                  {locationData.map((name: any) => (
                    <MenuItem
                      key={name.value}
                      value={name.label}
                      sx={{
                        fontSize: "10px", // Reduced font size
                        padding: "4px 6px", // Compact padding
                        gap: "10px", // Gap between checkbox and label
                      }}
                    >
                      <Checkbox
                        checked={location.includes(name.label)}
                        size="small"
                        sx={{
                          padding: "2px",
                          "& .MuiSvgIcon-root": { fontSize: "14px" }, // Smaller checkbox icon
                        }}
                      />
                      <ListItemText
                        primary={name.label}
                        sx={{
                          margin: 0,
                          fontSize: "10px", // Reduced text size
                        }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default FilterSidebar;
