import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton,  TextField, Divider, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { editBusiness, fetchBusiness } from "../../../../redux/features/businessSlice";
import { AppDispatch } from "../../../../redux/store/index";
import { BusinessData } from "./AddBusiness";

interface EditUserFormProps {
  open: boolean;
  onClose: () => void;
  user: BusinessData;
}

const EditBusinessForm: React.FC<EditUserFormProps> = ({ open, onClose, user }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [formData, setFormData] = useState<BusinessData>(user);

  // useEffect(() => {
  //   setFormData(user);
  // }, [user]);

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    if (name && typeof value === "string") {
      const sanitizedValue = value.replace(/[^a-zA-Z]/g, "");
      setFormData((prev) => ({ ...prev, [name]: sanitizedValue }));
  }
  };

  const handleSubmit = async () => {
    if (!formData.businessId) {
      console.error("Business ID is missing!");
      return;
    }
    try {
      await dispatch(
        editBusiness({
          businessId: formData.businessId,
          businessName: formData.businessName || "", 
        })
      ).unwrap();
      await dispatch(fetchBusiness()).unwrap();
      onClose();
    } catch (error) {
      console.error("Failed to update the business:", error);
    }
  };
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false} fullWidth sx={{ maxWidth: '500px', margin: 'auto', }}  >
      <DialogTitle sx={{ p: 2, borderBottom: '1px solid #f0f0f0' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 500 }}>Edit Business</Typography>
          <IconButton aria-label="close" onClick={onClose} sx={{ position: "absolute", right: 8, top: 8, backgroundColor: "#ECECEC", }}>
            <CloseIcon sx={{ fontSize: '12px' }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ mt: 1 }}>
          <Typography variant="subtitle2" sx={{ mb: 1, fontSize: '12px' }}> Business Name </Typography>
          <TextField
            name="businessName"
            placeholder="Enter business Name"
            fullWidth
            value={formData.businessName || ''}
            onChange={handleTextFieldChange}
            size="small"
            sx={{
              mb: 3,
              "& .MuiInputBase-input": {
                fontSize: "12px",
              },
              "& .MuiInputLabel-root": {
                fontSize: "12px",
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2.5, pt: 0, bottom: 0, bgcolor: 'background.paper' }}>
        <Button onClick={handleSubmit}
          variant="contained"
          disabled={!formData.businessName?.trim()} 
          sx={{ textTransform: 'none', fontSize: '12px', borderRadius: '8px', backgroundColor: '#1677FF' }} > Update </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditBusinessForm;