import {
  Box,
  Card,
  InputAdornment,
  InputBase,
  Grid,
  CircularProgress,
  Typography,
  Button,
} from "@mui/material";
import NoDataAvail from "../../../../assets/images/NoDataAvail.svg";
import { useEffect, useState } from "react";
import { getAssignedAPIData } from "../../../../Crud/card";
import { useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { calculateDays } from "../../../../helpers/calculateDays";
import priorityHighSymbol from "../../../../assets/images/highPriority.svg";
import priorityLowSymbol from "../../../../assets/images/lowPriority.svg";
import priorityMediumSymbol from "../../../../assets/images/mediumPriority.svg";
import clockIcon from "../../../../assets/images/clockIcon.svg";
import AssignedRecruiterList from "../../../common/AssignedInRecruitesList";

interface ReadyForJdCreationCardProps {
  drawerOpen: boolean;
}

const AssignedRth: React.FC<ReadyForJdCreationCardProps> = ({ drawerOpen }) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const userId = localStorage.getItem("userId") ?? "";
  const navigate = useNavigate();
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
  const role = localStorage.getItem("user")?JSON.parse(localStorage.user)?.roleName:"";

  console.log("dataJD", data);
  console.log("dataJD", teamHeadFilter);

  useEffect(() => {
    if (searchQuery.trim()) {
      setSearchLoading(true);
    } else {
      setSearchLoading(false);
    }

    const timeoutId = setTimeout(() => {
      setLoading(true);
      getAssignedAPIData(userId, teamHeadFilter, searchQuery)
        .then((res) => {
          if(role === "TALENTACQUISITIONHEAD")
          {
            const filteredData=res?.data?.listOfRth?.filter((item:any)=>
              item.recruiterLeadAssigned === true
            )
            setData(filteredData);
          }
          else if(role === "RECRUITERLEAD"){
            const filteredData=res?.data?.listOfRth?.filter((item:any)=>
              item.recruiterAssigned === true && item.recruiterLeadAssigned === true                                        
            )
            setData(filteredData);
          }
          setData(res?.data?.listOfRth);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
          setSearchLoading(false);
        });
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchQuery, userId, teamHeadFilter,role]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleCardClick = (rthId: string) => {
    navigate(`/request-hire`, { state: { id: rthId } });
  };

  return (
    <>
      {/* Search */}
      <Grid
        item
        sx={{ marginTop: data.length > 0 ? "-60px" : "-58px" }}
        position="absolute"
      >
        <InputBase
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search RTH..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
            </InputAdornment>
          }
          sx={{
            border: "1px solid #ccc",
            borderRadius: 1,
            padding: 0,
            backgroundColor: "white",
            minHeight: "3px",
            fontSize: 14,
            ".MuiInputBase-input": {
              padding: "5px 5px",
            },
          }}
        />
        {searchLoading && (
          <CircularProgress
            size={20}
            sx={{
              position: "absolute",
              right: "10px",
              top: "25%",
              transform: "translateY(-50%)",
              color: "#2F54EB",
            }}
          />
        )}
      </Grid>
      <Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            <CircularProgress size={100} sx={{ color: "#2F54EB" }} />
          </Box>
        ) : data?.length > 0 ? (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: drawerOpen
                ? "repeat(4, 1fr)"
                : "repeat(5, 1fr)",
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            {data?.map((item: any) => (
              <Card
                key={item?.rthId}
                sx={{
                  height: "auto",
                  minHeight: "100%",
                  minWidth: "fit-content",
                  boxShadow: "none",
                  border: "1px solid #D9D9D9",
                  borderRadius: "8px",
                  backgroundColor: "#FFFFFF",
                }}
                onClick={() => handleCardClick(item.rthId)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    margin: 1,
                    position: "relative",
                  }}
                >
                  <Box sx={{ paddingInline: "0.5rem", cursor: "pointer" }}>
                    {/* PositionTitle, SeqId and Business name */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        pt: "5px",
                      }}
                      gap={1}
                    >
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "0.75rem",
                          color: "#1F1F1F",
                        }}
                      >
                        <abbr
                          title={item?.positionTitle}
                          style={{ textDecoration: "none" }}
                        >
                          {item?.positionTitle
                            ? item?.positionTitle.length > 20
                              ? `${item?.positionTitle.slice(0, 20)}...`
                              : item?.positionTitle
                            : "N/A"}
                        </abbr>
                      </Typography>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "600",
                            fontSize: "0.625rem",
                            color: "#A3A1A1",
                          }}
                        >
                          #{item?.seqId}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "8px",
                            color: "#08979C",
                            padding: "2px",
                            backgroundColor: `${
                              item?.business?.businessName === "HEPL"
                                ? "#D3ADF7"
                                : "#B5F5EC"
                            }`,
                            borderRadius: "1px",
                            border: "1px solid #87E8DE",
                          }}
                        >
                          {item?.business?.businessName}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Mode of RTH, Type of Rth and Priority */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                      gap={1}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Button
                            size="small"
                            sx={{
                              mr: 1,
                              borderRadius: "8px",
                              backgroundColor: "#F0F0F0",
                              border: "1px solid lightgray",
                              fontSize: "0.438rem",
                              padding: "1px",
                              margin: "1px",
                              color: "#8C8C8C",
                              minWidth: "50px",
                            }}
                          >
                            {item?.typeOfRth}
                          </Button>
                          <Button
                            size="small"
                            sx={{
                              mr: 1,
                              borderRadius: "8px",
                              backgroundColor: "#F0F0F0",
                              border: "1px solid lightgray",
                              fontSize: "0.438rem",
                              padding: "1px",
                              margin: "1px",
                              color: "#8C8C8C",
                              minWidth: "50px",
                            }}
                          >
                            {item?.modeOfRth}
                          </Button>
                        </Box>
                      </Box>
                      <Typography
                        gap={1}
                        sx={{
                          fontWeight: "bold",
                          fontSize: "0.625rem",
                          color: "#8C8C8C",
                        }}
                      >
                        {item?.priority === "High" && (
                          <img
                            src={priorityHighSymbol}
                            alt="High Priority"
                            style={{ width: "80%" }}
                          />
                        )}
                        {item?.priority === "Medium" && (
                          <img
                            src={priorityMediumSymbol}
                            alt="Medium Priority"
                            style={{ width: "80%" }}
                          />
                        )}
                        {item?.priority === "Low" && (
                          <img
                            src={priorityLowSymbol}
                            alt="Low Priority"
                            style={{ width: "50%" }}
                          />
                        )}
                      </Typography>
                    </Box>

                    {/* posted days */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "2px 3px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <img
                          src={clockIcon}
                          alt="Clock Icon"
                          style={{ width: "10px", height: "10px" }}
                        />
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: "0.7rem",
                            color: "#8C8C8C",
                            fontWeight: "400",
                          }}
                        >
                          {` posted ${
                            item?.createdAt
                              ? calculateDays(item?.createdAt)
                              : "No date"
                          }`}
                        </Typography>
                      </Box>
                    </Box>
                                        
                    {role === "RECRUITERLEAD" ? (
                      <AssignedRecruiterList
                        assignedRecruiters={item?.assignedRecruiter}
                      />
                    ) : (
                      <AssignedRecruiterList
                        assignedRecruiters={item?.assignedRecruiterLead}
                      />
                    )}
                  </Box>
                </Box>
              </Card>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "300px",
            }}
          >
            <img
              src={NoDataAvail}
              alt="No Data Available"
              style={{ maxWidth: "200px" }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default AssignedRth;
